import Dropdown from 'core/elements/dropdown'
import React from 'react'

const options = [
  { label: 'PRIMARY', value: 'PRIMARY' },
  { label: 'SECONDARY', value: 'SECONDARY' },
]

const ZoneTypePicklist = (props) => {
  return <Dropdown label="Zone Type" items={options} {...props} />
}

export default ZoneTypePicklist
