import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import ModalForm from 'core/elements/modal/ModalForm'
import { addSecurityGroups, removeSecurityGroups } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import Text from 'core/elements/Text'
import ListTableField from 'core/components/validatedForm/ListTableField'
import { listSecurityGroups } from '../networks/security-groups/actions'
import { securityGroupsSelector } from '../networks/security-groups/selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { difference } from 'ramda'
import Info from 'core/components/validatedForm/Info'
import { getCurrentUser } from 'openstack/helpers'
import { hasOtherUserVm } from './helpers'
import { portsSelector } from '../networks/selectors'
import { useSelector } from 'react-redux'
import { listPorts, updatePort } from '../networks/actions'
import Grid from 'core/elements/grid'
import MultiDropdownField from 'core/components/validatedForm/MultiDropdownField'

const securityGroupColumns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'description',
    label: 'Description',
  },
]

export default function EditVmSecurityGroupsModal({ rows: vms, onClose }) {
  const vm = vms?.[0]
  const [loaded, setLoaded] = useState(false)
  const defaultParams = {
    securityGroups: [],
    currentSecurityGroups: [],
    currentInterfaces: [],
  }

  const { params, getParamsUpdater, updateParams, setParams } = useParams<{
    securityGroups?: any[]
    currentInterfaces?: any
    currentSecurityGroups?: string[]
  }>(defaultParams)

  const { loading: loadingPorts } = useListAction(listPorts)
  const { loading: loadingSecurityGroups } = useListAction(listSecurityGroups, {
    params: {},
  })
  const securityGroupsList = useSelectorWithParams(securityGroupsSelector, {})

  const addresses = useMemo(() => {
    const networkNames = Object.keys(vm?.addresses)
    const interfaces = networkNames.reduce((accum, name) => {
      const networkAddresses = vm.addresses[name]
      const withNetworkName = networkAddresses.map((address, idx) => {
        return {
          ...address,
          networkName: name,
          index: idx,
        }
      })
      return [...accum, ...withNetworkName]
    }, [])
    return interfaces.filter((iface) => iface['OS-EXT-IPS:type'] === 'fixed')
  }, [vm])

  const ports = useSelector(portsSelector)
  const vmPorts = useMemo(() => {
    return ports?.filter((port) => port.device_id === vm?.id)
  }, [ports, vm])

  useEffect(() => {
    const filteredPortSecurityGroup = vmPorts
      .filter((port) => port.security_groups.length > 0)
      .reduce((acc, port) => {
        return { ...acc, [port.id]: port.security_groups }
      }, {})

    getParamsUpdater('currentInterfaces')(filteredPortSecurityGroup)
  }, [vmPorts])

  const addressTableColumns = [
    {
      key: 'networkName',
      label: 'Network',
    },
    { key: 'addr', label: 'IP Address' },
    { key: 'OS-EXT-IPS-MAC:mac_addr', label: 'MAC Address' },
    { key: 'version', label: 'Version' },
    {
      key: 'OS-EXT-IPS:type',
      label: 'Type',
    },
    {
      key: 'index',
      label: 'Security Groups',
      render: (val, item) => {
        // not sure if there could be duplicate mac addresses or not
        const port = vmPorts.find(
          (vmPort) => item['OS-EXT-IPS-MAC:mac_addr'] === vmPort.mac_address,
        )
        const securityGroupItems = securityGroupsList.map((item) => {
          return { label: item.name, value: item.id }
        })
        return (
          <MultiDropdownField
            id={`macOfInterface${item.addr}`}
            value={port?.id ? params.currentInterfaces[port.id] : []}
            options={securityGroupItems}
            onChange={(value) => {
              updateParams({ currentInterfaces: { ...params.currentInterfaces, [port.id]: value } })
            }}
          />
        )
      },
    },
  ]

  const {
    update: addGroups,
    updating: addingGroups,
    error: addGroupsError,
    reset: resetAddGroups,
  } = useUpdateAction(addSecurityGroups)
  const {
    update: updatePorts,
    updating: updatingPorts,
    error: portError,
    reset: resetPort,
  } = useUpdateAction(updatePort)
  const {
    update: removeGroups,
    updating: removingGroups,
    error: removeGroupsError,
    reset: resetRemoveGroups,
  } = useUpdateAction(removeSecurityGroups)

  useEffect(() => {
    // Prevent resetting of form from background reloads
    if (loaded || loadingSecurityGroups) {
      return
    }
    const initialSecurityGroups = vm?.security_groups?.map((group) => group?.name) || []
    const securityGroups = securityGroupsList.filter((group) =>
      initialSecurityGroups.includes(group?.name),
    )
    updateParams({
      securityGroups,
      currentSecurityGroups: initialSecurityGroups,
    })
    setLoaded(true)
  }, [vm, loadingSecurityGroups, securityGroupsList, loaded])

  const handleClose = () => {
    setParams(defaultParams)
    resetAddGroups()
    resetRemoveGroups()
    resetPort()
    setLoaded(false)
    onClose(true)
  }

  const submitForm = useCallback(async () => {
    const groupNames = params.securityGroups.map((group) => group?.name)
    const securityGroupsToAdd = difference(groupNames, params.currentSecurityGroups)
    const securityGroupsToRemove = difference(params.currentSecurityGroups, groupNames)
    const { success: addSuccess } = await addGroups({
      id: vm.id,
      securityGroupNames: securityGroupsToAdd,
    })
    const { success: removeSuccess } = await removeGroups({
      id: vm.id,
      securityGroupNames: securityGroupsToRemove,
    })

    const securityGroups = vmPorts.map((port) => {
      let body = {
        port: {
          port_security_enabled: false,
          security_groups: [],
        },
      }
      if (!!params.currentInterfaces[port.id] && params.currentInterfaces[port.id].length > 0) {
        body = {
          port: {
            port_security_enabled: true,
            security_groups: params.currentInterfaces[port.id],
          },
        }
      }
      return updatePorts({ id: port.id, body })
    })

    const results = await Promise.allSettled(securityGroups)
    const allPortsUpdated = results.every((result) => result.status === 'fulfilled')

    if ((addSuccess || removeSuccess) && allPortsUpdated) {
      handleClose()
    }
  }, [vm, params, handleClose])

  const { role: userRole, userId } = getCurrentUser()

  return (
    <ModalForm
      open
      title={`Edit Security Groups`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={addingGroups || removingGroups || updatingPorts}
      error={addGroupsError || removeGroupsError || portError}
      submitTitle={`Update Security Groups`}
      loading={!loaded}
    >
      {userRole === 'member' && hasOtherUserVm({ vms, userId }) && (
        <Info error>NOTE: You are performing an operation on another user's VM.</Info>
      )}
      <br />
      <FormFieldSection title="Virtual Machine Security Groups">
        <Text variant="body2">
          Add or remove security groups on VM <b>{vm?.name || vm?.id}</b>
        </Text>
        <ListTableField
          id="vmSecurityGroups"
          data={securityGroupsList}
          loading={loadingSecurityGroups}
          columns={securityGroupColumns}
          onChange={getParamsUpdater('securityGroups')}
          value={params.securityGroups}
          uniqueIdentifier="id"
          searchTargets={['name', 'description']}
          multiSelection
        />
      </FormFieldSection>
      <FormFieldSection title="Interface Security Groups">
        <Text variant="body2">
          Add or remove interface security groups on VM <b>{vm?.name || vm?.id}</b>
        </Text>
        <Grid
          uniqueIdentifier="addr"
          data={addresses}
          columns={addressTableColumns}
          loading={loadingPorts}
          disableToolbar
        />
      </FormFieldSection>
    </ModalForm>
  )
}
