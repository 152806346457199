import React, { useMemo } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick, prop } from 'ramda'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { listNetworks, deleteNetwork } from './actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { networksSelector, physicalNetworksSelector } from './selectors'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import useReactRouter from 'use-react-router'
import { durationBetweenDates } from 'utils/misc'
import Text from 'core/elements/Text'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { routes } from 'core/utils/routes'
import { INetworkDetailsPageTabs } from './network-details/model'
import CreatePhysicalNetworkModal from './CreatePhysicalNetworkModal'
import EditNetworkModal from './EditNetworkModal'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { useSelector } from 'react-redux'
import DeleteNetworkDialog from './DeleteNetworkDialog'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'
import CreateNetworkModal from './CreateNetworkModal'
import { isAdminRole } from 'k8s/util/helpers'

type ModelDataKey = DataKeys.OpenstackNetworks
type SelectorModel = ArrayElement<ReturnType<typeof networksSelector>>
type ActionParams = InferActionParams<typeof listNetworks>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'OpenstackNetworks',
  listTablePrefs,
)

const searchTargets = [
  'name',
  'id',
  'subnetDetails',
  'provider:network_type',
  'status',
  'availableIps',
  'provider:physical_network',
  'provider:segmentation_id',
]

const searchFunctions = [
  {
    property: 'subnetDetails',
    searchFn: (value, searchString) => {
      return value.some(
        (subnet) =>
          subnet.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
          subnet.cidr.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()),
      )
    },
  },
]

const SubnetsCellComponent = ({ value }) => {
  return (
    <>
      {value.map((subnet) => (
        <Text variant="body2" key={subnet.id}>
          {subnet.name}: <b>{subnet.cidr}</b>
        </Text>
      ))}
    </>
  )
}

const networkColumns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ id }) =>
        routes.openstack.networkDetails.path({ id, tab: INetworkDetailsPageTabs.Overview }),
    }),
  },
  {
    key: 'id',
    label: 'UUID',
    display: false,
  },
  {
    key: 'subnetDetails',
    label: 'Subnets',
    render: (value) => <SubnetsCellComponent value={value} />,
  },
  {
    key: 'port_security_enabled',
    label: 'Port Security',
    render: (val) => (val ? 'Enabled' : 'Disabled'),
  },
  {
    key: 'provider:network_type',
    label: 'Network Type',
  },
  {
    key: 'mtu',
    label: 'MTU',
  },
  {
    key: 'provider:physical_network',
    label: 'Physical Network',
  },
  {
    key: 'router:external',
    label: 'External',
    render: (val) => (val ? 'Yes' : 'No'),
  },
  {
    key: 'provider:segmentation_id',
    label: 'Segmentation ID',
    render: (val) => (val ? val : 'N/A'),
  },
  {
    key: 'admin_state_up',
    label: 'Admin State',
    render: (val) => (val ? 'Up' : 'Down'),
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'availableIps',
    label: 'Available IPs',
  },
  {
    key: 'shared',
    label: 'Shared',
    render: (value) => (value ? 'True' : 'False'),
  },
]

const ssuNetworkColumns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ id }) =>
        routes.openstack.networkDetails.path({ id, tab: INetworkDetailsPageTabs.Overview }),
    }),
  },
  {
    key: 'id',
    label: 'UUID',
    display: false,
  },
  {
    key: 'subnetDetails',
    label: 'Subnets',
    render: (value) => <SubnetsCellComponent value={value} />,
  },
  {
    key: 'port_security_enabled',
    label: 'Port Security',
    render: (val) => (val ? 'Enabled' : 'Disabled'),
  },
  {
    key: 'router:external',
    label: 'External',
    render: (val) => (val ? 'Yes' : 'No'),
  },
  {
    key: 'admin_state_up',
    label: 'Admin State',
    render: (val) => (val ? 'Up' : 'Down'),
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'shared',
    label: 'Shared',
    render: (value) => (value ? 'True' : 'False'),
  },
]

export default function NetworksListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { history } = useReactRouter()
  const { prefs } = useScopedPreferences()
  const { currentTenant } = prefs
  const session = useSelector(prop<string, SessionState>(sessionStoreKey))
  const { features } = session
  const isAdminUser = isAdmin()
  const isVmware = features?.experimental?.pmov2_du_type === 'vmware'
  if (isVmware) {
    history.push(routes.openstack.vmwareNetworks.path())
  }

  const { message, loading, reload } = useListAction(listNetworks, {
    params,
  })
  const data = useAppSelector(physicalNetworksSelector)

  const networksInTenant = useMemo(() => {
    return data.filter((network) => {
      return network.project_id === currentTenant || network.shared
    })
  }, [data, currentTenant])

  return (
    <>
      <DocumentMeta title={isAdminRole(session) ? 'Physical Networks' : 'Networks'} />
      {isAdminUser ? (
        <CreatePhysicalNetworkModal addRoute={routes.openstack.createNetwork} />
      ) : (
        <CreateNetworkModal addRoute={routes.openstack.createNetwork} />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.OpenstackNetworks}
        label={isAdminUser ? 'Physical Networks' : 'Networks'}
        searchTargets={searchTargets}
        searchFunctions={searchFunctions}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={networksInTenant}
        columns={isAdminUser ? networkColumns : ssuNetworkColumns}
        addUrl={routes.openstack.createNetwork.path()}
        addText={isAdminUser ? 'Create Physical Network' : 'Create Network'}
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteNetwork}
        DeleteDialogComponent={DeleteNetworkDialog}
        EditDialogComponent={EditNetworkModal}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
