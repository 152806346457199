import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from 'k8s/components/common/entity/info-card'
import Progress from 'core/components/progress/Progress'
import Card from 'core/elements/card'
import Grid from 'core/elements/grid'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
}))

const metadataFields = [
  {
    id: 'stack_name',
    title: 'Name',
  },
  {
    id: 'id',
    title: 'ID',
  },
  {
    id: 'stack_status',
    title: 'Status',
  },
  {
    id: 'description',
    title: 'Description',
  },
  {
    id: 'creation_time',
    title: 'Creation Time',
  },
  {
    id: 'updated_time',
    title: 'Updated Time',
  },
]

const outputsColumns = [
  {
    key: 'output_key',
    label: 'Key',
  },
  {
    key: 'output_value',
    label: 'Value',
  },
  {
    key: 'description',
    label: 'Description',
  },
]

export default function Overview({ stack, loading }) {
  const classes = useStyles({})

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, stack)
  }, [stack])

  const parameters = useMemo(() => {
    if (!stack?.parameters) {
      return {}
    }
    const parameterKeys = Object.keys(stack?.parameters)
    const parameterFields = parameterKeys?.map((param) => {
      return {
        id: param,
        title: param,
      }
    })
    return getFieldsForCard(parameterFields, stack?.parameters)
  }, [stack])

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <InfoCard items={metadata} title="Properties" />
          <Card title="Outputs" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={stack?.outputs || []}
              columns={outputsColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
        </div>
        <div className={classes.column}>
          <InfoCard items={parameters} title="Parameters" />
        </div>
      </div>
    </Progress>
  )
}
