export const usefulImageProperties = [
  'architecture',
  'hw_boot_menu',
  'hw_cdrom_bus',
  'hw_cpu_cores',
  'hw_cpu_policy',
  'hw_cpu_sockets',
  'hw_cpu_thread_policy',
  'hw_cpu_threads',
  'hw_disk_bus',
  'hw_firmware_type',
  'hw_machine_type',
  'hw_mem_encryption',
  'hw_pmu',
  'hw_pointer_model',
  'hw_rng_model',
  'hw_scsi_model',
  'hw_serial_port_count',
  'hw_time_hpet',
  'hw_video_model',
  'hw_video_ram',
  'hw_vif_model',
  'hw_vif_multiqueue_enabled',
  'hw_watchdog_action',
  'hypervisor_type',
  'img_config_drive',
  'img_hide_hypervisor_id',
  'img_type',
  'instance_uuid',
  'kernel_id',
  'os_admin_user',
  'os_command_line',
  'os_distro',
  'os_secure_boot',
  'os_shutdown_timeout',
  'os_type',
  'os_version',
  'ramdisk_id',
  'rootfs_uuid',
  'trait:',
  'vm_mode',
  'vmware_adaptertype',
  'vmware_ostype',
]
