import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import { unrescueVmAction } from './actions'
import Info from 'core/components/validatedForm/Info'
import { getCurrentUser } from 'openstack/helpers'
import { hasOtherUserVm } from './helpers'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    gap: 16,
    display: 'grid',
  },
}))

export default function UnrescueVmDialog({ rows: vms, onClose }) {
  const vm = vms?.[0]
  const classes = useStyles()

  const { role: userRole, userId } = getCurrentUser()
  const { update, updating, error, reset } = useUpdateAction(unrescueVmAction)

  const handleSubmit = useCallback(async () => {
    const { success } = await update({ id: vm?.id })
    if (success) {
      handleClose()
    }
  }, [vm])

  const handleClose = () => {
    reset()
    onClose(true)
  }

  return (
    <ModalForm
      title="Unrescue VM"
      onSubmit={handleSubmit}
      onClose={handleClose}
      loading={false}
      submitting={updating}
      error={error}
      submitTitle="Unrescue VM"
      panel="dialog"
      open
    >
      <div className={classes.fields}>
        {userRole === 'member' && hasOtherUserVm({ vms, userId }) && (
          <Info error>NOTE: You are performing an operation on another user's VM.</Info>
        )}
        <Text variant="body2">You are about to unrescue the following VM instance:</Text>
        <Text variant="body2">
          <strong>{vm?.name || vm?.id}</strong>
        </Text>
      </div>
    </ModalForm>
  )
}
