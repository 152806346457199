import React from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { listServerGroups, deleteServerGroup } from './actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { serverGroupsSelector } from './selectors'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import { routes } from 'core/utils/routes'
import CreateServerGroupModal from './CreateServerGroupModal'
import { humanReadableSize } from 'openstack/helpers'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'

type ModelDataKey = DataKeys.ServerGroups
type SelectorModel = ArrayElement<ReturnType<typeof serverGroupsSelector>>
type ActionParams = InferActionParams<typeof listServerGroups>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'ServerGroups',
  listTablePrefs,
)

const searchTargets = ['name', 'id', 'policies']

const serverGroupColumns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
  },
  {
    key: 'policies',
    label: 'Policy',
    render: (policies) => policies?.[0],
  },
]

export default function ServerGroupsListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)

  const { message, loading, reload } = useListAction(listServerGroups, {
    params,
  })
  const data = useAppSelector(serverGroupsSelector)

  return (
    <>
      <DocumentMeta title="Server Groups" />
      <CreateServerGroupModal addRoute={routes.openstack.createServerGroup} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.ServerGroups}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={serverGroupColumns}
        addUrl={routes.openstack.createServerGroup.path()}
        addText="Create Server Group"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteServerGroup}
        multiSelection
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
