import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { ITypes } from '../model'

const options = [
  { label: ITypes.TCP, value: ITypes.TCP },
  { label: ITypes.UDPCONNECT, value: ITypes.UDPCONNECT },
  { label: ITypes.SCTP, value: ITypes.SCTP },
]
const algorithmOptions = () => {
  return options.map((option) => ({
    label: option.label === ITypes.SCTP ? `${option.label} (Not Supported)` : option.label,
    value: option.value,
    disabled: option.value === ITypes.SCTP,
  }))
}
interface Props {}

export default function TypePicklist({
  name = 'type',
  label = 'Type',
  compact = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return (
    <AsyncDropdown
      compact={compact}
      name={name}
      label={label}
      items={algorithmOptions()}
      {...rest}
    />
  )
}
