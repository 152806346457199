import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import PicklistField from 'core/components/validatedForm/DropdownField'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createVirtualMachine } from './actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listImages } from '../images/actions'
import { listFlavors } from '../flavors/actions'
import { listNetworks } from '../networks/actions'
import { listVolumes } from '../storage/volumes/actions'
import { listSecurityGroups } from '../networks/security-groups/actions'
import { imagesSelector } from '../images/selectors'
import { flavorsSelector } from '../flavors/selectors'
import { networksSelector } from '../networks/selectors'
import { volumesSelector } from '../storage/volumes/selectors'
import VolumeTypesPicklist from '../storage/volume-types/VolumeTypesPicklist'
import { securityGroupsSelector } from '../networks/security-groups/selectors'
import ListTableField from 'core/components/validatedForm/ListTableField'
import { humanReadableSize } from 'openstack/helpers'
import { durationBetweenDates } from 'utils/misc'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import PaginatedSelectableCards from 'core/components/PaginatedSelectableCards'
import SelectableCard from 'core/components/SelectableCard'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import SshKeyPicklist from './SshKeyPicklist'
import ServerGroupsPicklist from './server-groups/ServerGroupsPicklist'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import { requiredValidator, yamlValidator, validIpv4Validator } from 'core/utils/fieldValidators'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import SourceTypesPicklist from './SourceTypesPicklist'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import Dropdown from 'core/elements/dropdown'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { prop } from 'ramda'
import { BlueprintTypes } from '../infrastructure/blueprint/model'
import { noop, stopPropagation } from 'utils/fp'
import { imageLibrayHostSelector } from '../infrastructure/selectors'
import { listResmgrHosts } from '../infrastructure/actions'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { customValidator } from 'core/utils/fieldValidators'
import { IPVersions } from 'app/constants'
import * as IpAddress from 'ip-address'
import ServiceUnhealthyInfo from '../common/ServiceUnhealthyInfo'

const useStyles = makeStyles<Theme>((theme) => ({
  pairs: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    justifyContent: 'space-between',
  },
  // This CSS is weird, is a workaround
  card: {
    height: 192,
    '& .card-body': {
      display: 'grid',
      height: 160,
    },
  },
  cardHeader: {
    fontSize: 20,
  },
  cardText: {
    fontSize: 16,
  },
  successIcon: {
    color: theme.components.badge.success.color,
    marginRight: 8,
  },
  passwordScreen: {
    display: 'grid',
    gap: 24,
  },
  errorMessage: {
    display: 'grid',
    gridTemplateColumns: '26px auto',
    alignItems: 'center',
  },
  errorText: {
    color: theme.components.graph.error,
  },
  portSelection: {
    display: 'grid',
    gridTemplateColumns: '240px auto',
  },
  fixedIPInput: {
    gap: 0,
  },
}))

interface Props {
  addRoute: Route
}

export const getPrivateIpValidator = (network) => {
  return customValidator((value, formValues) => {
    if (!value) {
      return true
    }
    try {
      const subnets = network?.subnetDetails
      const pass = subnets?.some((subnet) => {
        const testCidr =
          subnet?.ip_version === IPVersions.IPv4
            ? new IpAddress.Address4(subnet?.cidr)
            : new IpAddress.Address6(subnet?.cidr)
        return subnet?.ip_version === IPVersions.IPv4
          ? new IpAddress.Address4(value).isInSubnet(testCidr)
          : new IpAddress.Address6(value).isInSubnet(testCidr)
      })
      return pass
    } catch (err) {
      return false
    }
  }, 'IP Address must be within one of the subnet CIDRs')
}

const ErrorText = ({ children }) => {
  const classes = useStyles({})
  return (
    <div className={classes.errorMessage}>
      <FontAwesomeIcon>circle-exclamation</FontAwesomeIcon>
      <Text variant="body1" className={classes.errorText}>
        {children}
      </Text>
    </div>
  )
}

const SubnetsCellComponent = ({ value }) => {
  return (
    <>
      {value.map((subnet) => (
        <Text key={subnet.name} variant="body2">
          {subnet.name}: <b>{subnet.cidr}</b>
        </Text>
      ))}
    </>
  )
}

const securityGroupColumns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'description',
    label: 'Description',
  },
]

const volumeColumns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'id',
    label: 'UUID',
    display: false,
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'size',
    label: 'Capacity',
    render: (size) => humanReadableSize(size * 1024 * 1024 * 1024),
  },
  {
    id: 'bootable',
    label: 'Bootable',
  },
  {
    id: 'created_at',
    label: 'Age',
    render: (value) => durationBetweenDates({ labels: ['d'] })(value),
  },
]

const placeholderCloudInitYaml = `#cloud-config
password: winterwonderland
chpasswd: { expire: False }
ssh_pwauth: True
manage_etc_hosts: true
runcmd:
- [ 'sh', '-c', 'echo "Hello World" > /tmp/helloworld.txt']`

export default function CreateVirtualMachineModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const [showVmPassword, setShowVmPassword] = useState(false)
  const [vmPassword, setVmPassword] = useState('')
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)
  const defaultParams = {
    name: '',
    image: null,
    flavor: null,
    networks: [],
    sshKey: null,
    useCloudInit: false,
    cloudInitYaml: placeholderCloudInitYaml,
    useSecurityGroups: false,
    securityGroups: [],
    sourceType: 'image',
    volume: [],
    volumeSize: '20',
    deleteOnTermination: true,
    metadata: [],
    serverGroup: null,
    volumeType: null,
    selectedPorts: [],
    fixedIPs: [],
  }

  const session = useSelector(prop<string, SessionState>(sessionStoreKey))
  const { blueprintType } = session

  const { prefs } = useScopedPreferences()
  const { currentTenant } = prefs

  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)

  const { loading: loadingImages } = useListAction(listImages, {
    params: {},
  })
  const images = useSelectorWithParams(imagesSelector, {})

  const { loading: loadingVolumes } = useListAction(listVolumes, {
    params: {},
  })
  const volumesList = useSelectorWithParams(volumesSelector, {})

  const { loading: loadingFlavors } = useListAction(listFlavors, {
    params: {},
  })
  const flavors = useSelectorWithParams(flavorsSelector, {})

  const { loading: loadingNetworks } = useListAction(listNetworks, {
    params: {},
  })
  const _networksList = useSelectorWithParams(networksSelector, {})
  const networksList = _networksList.filter(
    (network) =>
      !!network.subnets?.length && (network.tenant_id === currentTenant || network.shared),
  )

  const { loading: loadingSecurityGroups } = useListAction(listSecurityGroups, {
    params: {},
  })
  const securityGroupsList = useSelectorWithParams(securityGroupsSelector, {})

  // Check if image library host is available in the host list
  const { loading: loadingResmgrHosts } = useListAction(listResmgrHosts, {
    params,
  })
  const imageLibraryHost = useSelector(imageLibrayHostSelector)
  const isImageLibraryHostAvailable = !!imageLibraryHost

  const { update, updating, error, reset } = useUpdateAction(createVirtualMachine)

  const networkColumns = [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'subnetDetails',
      label: 'Subnets',
      render: (value) => <SubnetsCellComponent value={value} />,
    },
    {
      id: 'ports',
      label: 'Ports',
      Component: ({ row, isSelected }) => {
        const classes = useStyles()
        // Filter out ports that are already bound to a device
        const filteredPorts = row?.ports?.filter(
          (port) =>
            port['binding:vif_type'] === 'unbound' &&
            (port?.device_owner.trim() === '' || !port?.device_owner),
        )

        const options = filteredPorts?.length
          ? [
              { label: 'Automatic', value: '' },
              { label: 'Private IP', value: 'fixedIP' },
              ...filteredPorts.map((port) => {
                const ipAddressString = port.fixed_ips?.map((ip) => ip.ip_address)?.join(', ')
                return {
                  label: `${ipAddressString} (${port.name || port.id})`,
                  value: port.id,
                }
              }),
            ]
          : [
              { label: 'Automatic', value: '' },
              { label: 'Private IP', value: 'fixedIP' },
            ]
        return (
          <div className={classes.portSelection} onClick={isSelected ? stopPropagation : noop}>
            <Dropdown
              name="ports"
              value={params.selectedPorts[row.id] || ''}
              items={options}
              onChange={(value) =>
                getParamsUpdater('selectedPorts')({
                  ...params.selectedPorts,
                  [row.id]: value,
                })
              }
            ></Dropdown>
          </div>
        )
      },
    },
    {
      id: 'fixedIP',
      label: 'Private IP',
      Component: ({ row, isSelected }) => {
        const [fixedIP, setFixedIP] = useState(params.fixedIPs[row.id])
        const isRequired = params.selectedPorts[row.id] === 'fixedIP'
        const selectedPort = row?.ports?.find((port) => port.id === params.selectedPorts[row.id])
        return isRequired ? (
          <div onClick={isSelected ? stopPropagation : noop}>
            <TextField
              id={`${row.id}-fixedIP`}
              placeholder="Enter private IP"
              className={classes.fixedIPInput}
              value={fixedIP}
              onChange={setFixedIP}
              onBlur={() => {
                getParamsUpdater('fixedIPs')({ ...params.fixedIPs, [row.id]: fixedIP })
              }}
              disabled={!isRequired}
              validations={
                isRequired && isSelected
                  ? [validIpv4Validator, requiredValidator, getPrivateIpValidator(row)]
                  : null
              }
            />
          </div>
        ) : selectedPort ? (
          <div>
            {selectedPort?.fixed_ips?.map((ip) => (
              <Text variant="body2">{ip.ip_address}</Text>
            ))}
          </div>
        ) : null
      },
    },
  ]

  const submitForm = useCallback(async () => {
    setAttemptedSubmit(true)
    if (!params.flavor || (!params.image && ['images', 'newVolume'].includes(params.sourceType))) {
      return
    }
    // not sure why encode/decode is needed
    const encodedScript = params.useCloudInit ? btoa(params.cloudInitYaml) : undefined
    const securityGroups = params.useSecurityGroups
      ? params.securityGroups.map((group) => ({
          name: group.name,
        }))
      : undefined
    const metadata = params.metadata.reduce((accum, pair) => {
      return {
        ...accum,
        [pair.key]: pair.value,
      }
    }, {})

    const body = {
      server: {
        imageRef: params.sourceType === 'image' ? params.image?.id : undefined,
        block_device_mapping_v2:
          params.sourceType === 'existingVolume'
            ? [
                {
                  source_type: 'volume',
                  destination_type: 'volume',
                  boot_index: 0,
                  delete_on_termination: false,
                  uuid: params.volume[0]?.id,
                },
              ]
            : params.sourceType === 'newVolume'
            ? [
                {
                  source_type: 'image',
                  destination_type: 'volume',
                  boot_index: 0,
                  delete_on_termination: params.deleteOnTermination,
                  uuid: params.image?.id,
                  volume_size: params.volumeSize,
                  volume_type: params.volumeType ? params.volumeType : undefined,
                },
              ]
            : undefined,
        flavorRef: params.flavor?.id,
        name: params.name,
        networks: params.networks.map((network) => ({
          uuid: network.id,
          // When port is selected as fixedIP, use fixedIP
          // When any other port is selected, use portId
          ...(params.selectedPorts[network.id]
            ? params.selectedPorts[network.id] === 'fixedIP'
              ? {
                  fixed_ip: params.fixedIPs[network.id],
                }
              : { port: params.selectedPorts[network.id] }
            : {}),
        })),
        key_name: params.sshKey
          ? params.sshKey === 'None'
            ? undefined
            : params.sshKey
          : undefined,
        config_drive: false, // Maybe could just remove this line?
        user_data: params.useCloudInit ? encodedScript : undefined,
        security_groups: securityGroups,
        metadata,
      },
      'OS-SCH-HNT:scheduler_hints': params.serverGroup
        ? {
            group: params.serverGroup?.id,
          }
        : undefined,
    }
    const { success, response } = await update({ body })
    if (success) {
      setVmPassword(response?.adminPass)
      setShowVmPassword(true)
    }
  }, [params])

  const handleClose = () => {
    setParams(defaultParams)
    setShowVmPassword(false)
    setVmPassword('')
    reset()
    history.push(routes.openstack.vms.path())
  }

  const renderImageCard = useMemo(
    () => (item) => {
      const imageSize = item?.virtual_size
      const volumeSize = Number(params?.volumeSize) * 1024 * 1024 * 1024
      // Flavor must be larger than the image size
      const flavorSize = params?.flavor?.disk ? params.flavor.disk * 1024 * 1024 * 1024 : 0
      const imageValid = flavorSize ? flavorSize > imageSize : true
      const disabled =
        params?.sourceType === 'newVolume' ? imageSize > volumeSize : !imageValid ? true : false
      return (
        <SelectableCard
          id={item}
          onClick={(item) => updateParams({ image: item })}
          active={params.image?.id === item.id}
          className={classes.card}
          disabled={disabled}
          showCheckmarkIcon
        >
          <Text variant="body2" className={classes.cardHeader}>
            <b>{item.name}</b>
          </Text>
          <div className={classes.pairs}>
            <Text variant="body2" className={classes.cardText}>
              Format:
            </Text>
            <Text variant="body2" className={classes.cardText}>
              <b>{item?.disk_format}</b>
            </Text>
            <Text variant="body2" className={classes.cardText}>
              Virtual Size:
            </Text>
            <Text variant="body2" className={classes.cardText}>
              <b>
                {item?.virtual_size && humanReadableSize(item?.virtual_size)}
                {disabled && ' (Too Large)'}
              </b>
            </Text>
            <Text variant="body2" className={classes.cardText}>
              Age:
            </Text>
            <Text variant="body2" className={classes.cardText}>
              <b>{item?.created_at && durationBetweenDates({ labels: ['d'] })(item?.created_at)}</b>
            </Text>
          </div>
        </SelectableCard>
      )
    },
    [params.image, params.volumeSize, params.sourceType, params.flavor],
  )

  const renderFlavorCard = (item) => {
    // Flavor must be larger than the image size
    const imageSize = params.image?.virtual_size
    const flavorSize = item.disk * 1024 * 1024 * 1024
    const imageValid = flavorSize === 0 ? true : imageSize ? flavorSize > imageSize : true
    const disabled = !imageValid
    return (
      <SelectableCard
        id={item}
        onClick={(item) => updateParams({ flavor: item })}
        active={params.flavor?.id === item.id}
        className={classes.card}
        disabled={disabled}
        showCheckmarkIcon
      >
        <Text variant="body2" className={classes.cardHeader}>
          <b>{item.name}</b>
        </Text>
        <br />
        <div className={classes.pairs}>
          <Text variant="body2" className={classes.cardText}>
            vCPUs:
          </Text>
          <Text variant="body2" className={classes.cardText}>
            <b>{item?.vcpus}</b>
          </Text>
          <Text variant="body2" className={classes.cardText}>
            RAM:
          </Text>
          <Text variant="body2" className={classes.cardText}>
            <b>{item?.ram && humanReadableSize(item?.ram * 1024 * 1024)}</b>
          </Text>
          <Text variant="body2" className={classes.cardText}>
            Disk:
          </Text>
          <Text variant="body2" className={classes.cardText}>
            <b>{item?.disk && humanReadableSize(item?.disk * 1024 * 1024 * 1024)}</b>
            {disabled && ' (Too Small)'}
          </Text>
        </div>
      </SelectableCard>
    )
  }

  const filteredImages = useMemo(() => {
    return images.filter((image) => {
      if (image.status !== 'active') {
        return false
      }
      return true
    })
  }, [images])

  // When sourceType is newVolume/image, disable submit if image library is not available
  const disableFormSubmit = useMemo(() => {
    return ['image', 'newVolume'].includes(params.sourceType) && !isImageLibraryHostAvailable
  }, [params.sourceType, isImageLibraryHostAvailable])

  return (
    <ModalForm
      route={addRoute}
      title={`Deploy Virtual Machine`}
      onSubmit={showVmPassword ? handleClose : submitForm}
      onClose={handleClose}
      submitting={updating}
      disableSubmit={disableFormSubmit}
      error={error}
      submitTitle={showVmPassword ? `Finish` : `Deploy Virtual Machine`}
      maxWidth={1200}
      loading={loadingResmgrHosts}
    >
      <ServiceUnhealthyInfo action="VM Creation" hypervisor imageLibrary blockStorage />
      {!showVmPassword ? (
        <>
          <FormFieldSection title="Choose a Source" step={1}>
            <PicklistField
              DropdownComponent={SourceTypesPicklist}
              id="sourceType"
              label="Boot VM From"
              onChange={(value) =>
                updateParams({
                  sourceType: value,
                  image: null,
                })
              }
              value={params.sourceType}
              required
            />
            {['image', 'newVolume'].includes(params.sourceType) && (
              <>
                {params.sourceType === 'newVolume' && (
                  <>
                    <TextField
                      id="volumeSize"
                      label="Volume Size (GiB)"
                      onChange={getParamsUpdater('volumeSize')}
                      value={params.volumeSize}
                      info="Size of the primary VM volume"
                      required
                    />
                    <PicklistField
                      id="volumeType"
                      DropdownComponent={VolumeTypesPicklist}
                      value={params.volumeType}
                      onChange={getParamsUpdater('volumeType')}
                      includeNoneOption
                    />
                    <CheckboxField
                      id="deleteOnTermination"
                      value={params.deleteOnTermination}
                      label="Delete Volume on VM Termination"
                      onChange={getParamsUpdater('deleteOnTermination')}
                      info="If checked, the VM boot volume will be deleted when the VM is deleted. If unchecked, the volume will remain after the VM is deleted."
                    />
                  </>
                )}
                {/* Check if image host is availanle */}
                {isImageLibraryHostAvailable ? (
                  <>
                    <Text variant="caption1">Select an Image:</Text>
                    <Text variant="body2">Only active images will be shown below.</Text>
                    {attemptedSubmit && !params.image && (
                      <ErrorText>You must select an image</ErrorText>
                    )}
                    <PaginatedSelectableCards
                      renderSelectableCardComponent={renderImageCard}
                      data={filteredImages}
                      searchProps={['name', 'disk_format']}
                    />
                  </>
                ) : (
                  <ErrorText>No image library found. Choose a different source</ErrorText>
                )}
              </>
            )}
            {params.sourceType === 'existingVolume' && (
              <>
                <Text variant="body2">
                  Only <b>bootable</b> volumes with <b>available</b> status may be selected below.
                </Text>
                <ListTableField
                  id="volume"
                  data={volumesList}
                  loading={loadingVolumes}
                  columns={volumeColumns}
                  onChange={getParamsUpdater('volume')}
                  value={params.volume}
                  uniqueIdentifier="id"
                  searchTargets={['name']}
                  checkboxCond={(volume) =>
                    volume.status === 'available' && volume.bootable === 'true'
                  }
                  required
                />
              </>
            )}
            {/* <ListTableField
            id="image"
            data={images}
            loading={loadingImages}
            columns={imageColumns}
            onChange={getParamsUpdater('image')}
            value={params.image}
            uniqueIdentifier="id"
            searchTargets={['name']}
            required
          /> */}
          </FormFieldSection>
          <FormFieldSection title="Choose a Flavor" step={2}>
            {attemptedSubmit && !params.flavor && <ErrorText>You must select a flavor</ErrorText>}
            <PaginatedSelectableCards
              renderSelectableCardComponent={renderFlavorCard}
              data={flavors}
              searchProps={['name']}
            />
            {/* <ListTableField
            id="flavor"
            data={flavors}
            loading={loadingFlavors}
            columns={flavorColumns}
            onChange={getParamsUpdater('flavor')}
            value={params.flavor}
            uniqueIdentifier="id"
            searchTargets={['name']}
            required
          /> */}
          </FormFieldSection>
          <FormFieldSection title="Choose your Networks" step={3}>
            <ListTableField
              id="networks"
              data={networksList}
              loading={loadingNetworks}
              columns={networkColumns}
              onChange={getParamsUpdater('networks')}
              value={params.networks}
              uniqueIdentifier="id"
              searchTargets={['name']}
              multiSelection
              noScroll
              required
            />
          </FormFieldSection>
          <FormFieldSection title="Customize your VM" step={4}>
            <TextField
              id="name"
              label="Name"
              onChange={getParamsUpdater('name')}
              value={params.name}
              info="Choose a name for your VM"
              required
            />
            <PicklistField
              id="sshKey"
              DropdownComponent={SshKeyPicklist}
              value={params.sshKey}
              onChange={getParamsUpdater('sshKey')}
              tooltip="Select an SSH key for your VM"
            />
            <PicklistField
              id="serverGroup"
              DropdownComponent={ServerGroupsPicklist}
              value={params.serverGroup}
              onChange={getParamsUpdater('serverGroup')}
              tooltip="Optionally select a server group for your VM"
            />
            <CheckboxField
              id="useCloudInit"
              value={params.useCloudInit}
              label="Use cloud-init"
              onChange={getParamsUpdater('useCloudInit')}
            />
            {params.useCloudInit && (
              <CodeMirror
                variant="light"
                label="cloud-init YAML"
                id="cloudInitYaml"
                onChange={getParamsUpdater('cloudInitYaml')}
                value={params.cloudInitYaml}
                // className={fullWidth}
                validations={[yamlValidator]}
              />
            )}
            {blueprintType !== BlueprintTypes.Consolidated && (
              <CheckboxField
                id="useSecurityGroups"
                value={params.useSecurityGroups}
                label="Assign Security Groups"
                onChange={getParamsUpdater('useSecurityGroups')}
                info="Launch your instance with the selected security groups. If no security group is selected, the default security group will be used automatically (allow all outbound connections)."
              />
            )}
            {params.useSecurityGroups && (
              <ListTableField
                id="securityGroups"
                data={securityGroupsList}
                loading={loadingSecurityGroups}
                columns={securityGroupColumns}
                onChange={getParamsUpdater('securityGroups')}
                value={params.securityGroups}
                uniqueIdentifier="id"
                searchTargets={['name']}
                multiSelection
                required
              />
            )}
            <KeyValuesField
              id="metadata"
              label="Metadata"
              value={params.metadata}
              onChange={getParamsUpdater('metadata')}
              addLabel="Add Metadata"
              allowMultipleValues
            />
          </FormFieldSection>
        </>
      ) : (
        <div className={classes.passwordScreen}>
          <Text variant="subtitle2">
            <FontAwesomeIcon className={classes.successIcon}>circle-check</FontAwesomeIcon>Your VM
            has been scheduled for creation
          </Text>
          <div>
            <Text variant="body2">
              If this is a <b>Windows VM</b>, below will be the default credentials for logging in.
              Otherwise, you may safely ignore this information.
            </Text>
            <Text variant="body2">
              It is important to store this password now, as this data will not be accessible once
              you exit this wizard.
            </Text>
          </div>
          <div>
            <Text variant="body2">Windows Username:</Text>
            <Text variant="body2">
              <b>Admin</b>
            </Text>
          </div>
          <div>
            <Text variant="body2">Password:</Text>
            <Text variant="body2">
              <b>{vmPassword}</b>
            </Text>
          </div>
        </div>
      )}
    </ModalForm>
  )
}
