import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import { listResmgrHosts, deauthResmgrHost } from '../actions'
import useListAction from 'core/hooks/useListAction'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    gap: 16,
    display: 'grid',
  },
}))

interface Props {
  rows: any[]
  onClose: (boolean) => void
}

export default function DeauthorizeHostDialog({ rows: hosts, onClose }: Props) {
  const classes = useStyles()
  const [submitting, setSubmitting] = useState(false)

  const { reload } = useListAction(listResmgrHosts)

  const { update, updating, error, reset } = useUpdateAction(deauthResmgrHost)

  const handleSubmit = useCallback(async () => {
    setSubmitting(true)
    await Promise.all(
      hosts.map((host) => {
        return update({ id: host.id })
      }),
    )
    reload(true)
    handleClose()
  }, [hosts])

  const handleClose = () => {
    setSubmitting(false)
    reset()
    onClose(true)
  }

  return (
    <ModalForm
      title={'Deauthorize Hosts'}
      onSubmit={handleSubmit}
      onClose={handleClose}
      loading={false}
      submitting={submitting}
      error={error}
      submitTitle={`Deauthorize Hosts`}
      panel="dialog"
      // maxWidth={1200}
      open
    >
      <div className={classes.fields}>
        <Text variant="body2">You are about to deauthorize the following hosts: </Text>
        {hosts.map((host) => (
          <Text variant="caption1" key={host.id}>
            <b>{host.name}</b>
          </Text>
        ))}
        <Text variant="body2">
          Deauthorizing a host will fully remove it from the region. All resources associated with
          this host will no longer be accessible.
        </Text>
      </div>
    </ModalForm>
  )
}
