import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from 'k8s/components/common/entity/info-card'
import Progress from 'core/components/progress/Progress'
import HostsListPageHeader from '../HostsListPageHeader'
import Card from 'core/elements/card'
import Text from 'core/elements/Text'
import { getResources, listResourceInventory } from '../../actions'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  interfaces: {
    display: 'grid',
    gap: 20,
    gridTemplateColumns: 'max-content auto',
  },
}))

const ALLOCATION_RATIO_KEYS = {
  VCPU: 'CPU Allocation Ratio',
  MEMORY_MB: 'Memory Allocation Ratio',
  DISK_GB: 'Disk Allocation Ratio',
}

const cpuInfoFields = [
  {
    id: 'cpu_info.cpu_vendor',
    title: 'Vendor',
  },
  {
    id: 'cpu_info.cpu_model.model_name',
    title: 'Model',
  },
  {
    id: 'arch',
    title: 'Architecture',
  },
  {
    id: 'cpu_info.cpu_cores',
    title: 'Cores',
  },
  {
    id: 'cpu_info.cpu_threads.total',
    title: 'Threads',
  },
  {
    id: 'cpu_info.cpu_sockets',
    title: 'Sockets',
  },
  {
    id: 'cpu_info.cpu_features',
    title: 'Features',
    render: (features) => features.join(', '),
  },
]

const propertyFields = [
  {
    id: 'name',
    title: 'Name',
  },
  {
    id: 'id',
    title: 'ID',
  },
  {
    id: 'info.os_info',
    title: 'Operating System',
  },
  {
    id: 'displayRoles',
    title: 'Roles',
    render: (roles) => roles.join(', '),
  },
]

export default function Overview({ host, loading }) {
  const classes = useStyles({})

  const [allocationRatio, setAllocationRatio] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const cpuInfo = useMemo(() => {
    return getFieldsForCard(cpuInfoFields, host?.info)
  }, [host])

  useEffect(() => {
    const getAllocationRatios = async () => {
      const resources = await getResources()
      const hostInventoryDetails = resources.filter((resource) => resource.name === host.name)

      try {
        const { inventories } = await listResourceInventory({ id: hostInventoryDetails?.[0]?.uuid })

        if (inventories) {
          const filteredRatios = Object.keys(inventories).map((obj: any) => {
            return {
              [ALLOCATION_RATIO_KEYS[obj]]: {
                ...inventories[obj],
                value: inventories[obj].allocation_ratio,
              },
            }
          })

          setAllocationRatio(filteredRatios)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    }

    getAllocationRatios()
  }, [host])

  const propInfo = useMemo(() => {
    const getPropertyField = { ...getFieldsForCard(propertyFields, host) }
    return Object.assign(getPropertyField, ...allocationRatio)
  }, [host, allocationRatio])

  return (
    <Progress loading={loading || isLoading}>
      <HostsListPageHeader hosts={[host]} />
      <div className={classes.overview}>
        <div className={classes.column}>
          <InfoCard items={propInfo} title="Properties" />
          <Card title="Network Interfaces">
            <div className={classes.interfaces}>
              {host?.interfaces?.map((iface) => (
                <>
                  <Text variant="body2">{iface.name}</Text>
                  <Text variant="caption1">{iface.ip}</Text>
                </>
              ))}
            </div>
          </Card>
        </div>
        <div className={classes.column}>
          <InfoCard items={cpuInfo} title="CPU Info" />
        </div>
      </div>
    </Progress>
  )
}
