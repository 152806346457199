import React, { useCallback, useMemo, useState } from 'react'
import Text from 'core/elements/Text'
import { migrateVirtualMachine } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import useParams from 'core/hooks/useParams'
import ListTableField from 'core/components/validatedForm/ListTableField'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listResmgrHosts, listHypervisors } from 'openstack/components/infrastructure/actions'
import {
  resmgrHostsSelector,
  hypervisorsSelector,
} from 'openstack/components/infrastructure/selectors'
import ModalForm from 'core/elements/modal/ModalForm'
import {
  HostStatusCell,
  IpAddressCellComponent,
} from 'openstack/components/infrastructure/hosts/HostsListPage'
import StatsCell from 'app/plugins/infrastructure/components/common/cells/StatsCell'
import { uniq, without } from 'ramda'
import { migrationActions } from './migrations/reducers'
import { useDispatch } from 'react-redux'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'

const searchTargets = ['name', 'id', 'readableInterfaces']

const hostColumns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'id',
    label: 'ID',
    display: false,
  },
  {
    id: 'info.responding',
    label: 'Responding',
    render: (value) => (value === undefined ? 'Unknown' : value ? 'True' : 'False'),
  },
  {
    id: 'readableInterfaces',
    label: 'IP Address',
    render: (value) => value?.map((iface) => <div key={iface}>{iface}</div>),
  },
  {
    id: 'usage',
    label: 'Resource Utilization',
    render: (value, item) => <StatsCell value={value} item={item} />,
  },
]

export default function MigrateVirtualMachineDialog({ rows: vms, onClose }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [submitting, setSubmitting] = useState(false)
  const isAdminUser = isAdmin()

  const { error, update: migrateFn, updating } = useUpdateAction(migrateVirtualMachine)
  const title = `Migrate ${vms.length > 1 ? 'VMs' : 'VM'}`
  const vmHostIds = useMemo(() => {
    return uniq(vms.map((vm) => vm['OS-EXT-SRV-ATTR:host']))
  }, [vms])
  const defaultParams = {
    hosts: [],
  }
  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)

  const { loading: loadingHosts } = useListAction(listResmgrHosts, {
    params: {},
  })
  const hosts = useSelectorWithParams(resmgrHostsSelector, {})

  const { loading: loadingHypervisors } = useListAction(listHypervisors, {
    params: {},
  })
  const hypervisors = useSelectorWithParams(hypervisorsSelector, {})

  const hypervisorHosts = useMemo(() => {
    if (loadingHosts || loadingHypervisors) {
      return []
    }

    const hypervisorIds = hypervisors.flatMap((hypervisor) => {
      // Filter hypervisors which have scheduling disabled
      if (hypervisor?.status === 'enabled') {
        return [hypervisor?.service?.host]
      }
      return []
    })
    const validHypervisorIds = without(vmHostIds, hypervisorIds)
    return hosts.filter((host) => {
      return validHypervisorIds.includes(host?.id)
    })
  }, [hosts, hypervisors, loadingHosts, loadingHypervisors, vmHostIds])

  const handleSubmit = useCallback(() => {
    const asyncActions = async () => {
      let isSuccess = true
      const successfulVms = []
      for (const vm of vms) {
        try {
          const body = ['shutoff', 'stopped'].includes(vm?.state)
            ? {
                migrate: {
                  host: isAdminUser ? params.hosts[0]?.id : null,
                },
              }
            : {
                'os-migrateLive': {
                  host: isAdminUser ? params.hosts[0]?.id : null,
                  block_migration: 'auto',
                },
              }

          const { success } = await migrateFn({ id: vm.id, body })
          successfulVms.push(vm)
          isSuccess = success
        } catch (e) {
          isSuccess = false
        }
      }
      dispatch(
        migrationActions.newMigrations({ vms: successfulVms, destination: params.hosts[0]?.name }),
      )

      if (isSuccess) {
        onClose(true)
      }
      setSubmitting(false)
    }
    setSubmitting(true)
    asyncActions()
  }, [params, vms, isAdminUser])

  return (
    <ModalForm
      title={title}
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={loadingHosts || loadingHypervisors}
      submitting={submitting}
      error={error}
      submitTitle={title}
      panel="dialog"
      maxWidth={isAdminUser ? 1200 : 600}
      open
    >
      <div className={classes.container}>
        <Text variant="body2">
          You are about to migrate the following VM instances:{' '}
          <b>{vms.map((vm) => vm.name || vm.id).join(', ')}</b>
        </Text>
        {isAdminUser ? (
          <>
            <Text variant="body2">
              Select the hypervisor you would like to migrate the selected VMs to
            </Text>
            <Text variant="body2">
              <b>Note:</b> Hypervisors hosting a selected VM instance will not be displayed in the
              list below.
            </Text>
            <ListTableField
              id="hosts"
              data={hypervisorHosts}
              loading={loadingHosts || loadingHypervisors}
              columns={hostColumns}
              onChange={getParamsUpdater('hosts')}
              value={params.hosts}
              uniqueIdentifier="id"
              searchTargets={searchTargets}
              emptyText="No available hypervisors found"
              required
            />
          </>
        ) : (
          <Text variant="body2">A new hypervisor will be selected automatically.</Text>
        )}
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'grid',
    gap: 24,
  },
}))
