import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from 'k8s/components/common/entity/info-card'
import Progress from 'core/components/progress/Progress'
import { durationBetweenDates } from 'utils/misc'
import SimpleLink from 'core/components/SimpleLink'
import { IVmDetailsPageTabs } from 'openstack/components/vms/vm-details/model'
import { routes } from 'core/utils/routes'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
}))

const metadataFields = [
  {
    id: 'name',
    title: 'Name',
  },
  {
    id: 'id',
    title: 'ID',
  },
  {
    id: 'description',
    title: 'Description',
  },
  {
    id: 'bootable',
    title: 'Bootable',
  },
  {
    id: 'volume_type',
    title: 'Volume Type',
  },
  {
    id: 'status',
    title: 'Status',
  },
  {
    id: 'created',
    title: 'Age',
    render: (value) => durationBetweenDates({ labels: ['d'] })(value),
  },
  {
    id: 'attachedVm',
    title: 'Attached VM',
    render: (vm) =>
      vm ? (
        <SimpleLink
          src={routes.openstack.vmDetails.path({ id: vm?.id, tab: IVmDetailsPageTabs.Overview })}
        >
          {vm?.name}
        </SimpleLink>
      ) : null,
  },
]

export default function Overview({ volume, loading }) {
  const classes = useStyles({})

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, volume)
  }, [volume])

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <InfoCard items={metadata} title="Properties" />
        </div>
      </div>
    </Progress>
  )
}
