import React, { useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import { listVolumeSnapshots, deleteVolumeSnapshot } from './actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { volumeSnapshotsSelector } from './selectors'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import { durationBetweenDates } from 'utils/misc'
import { humanReadableSize } from 'openstack/helpers'
import PollingData from 'core/components/PollingData'
import DeleteVolumeSnapshotDialog from './DeleteVolumeSnapshotDialog'

type ModelDataKey = DataKeys.OpenstackVolumeSnapshots
type SelectorModel = ArrayElement<ReturnType<typeof volumeSnapshotsSelector>>
type ActionParams = InferActionParams<typeof listVolumeSnapshots>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'OpenstackVolumeSnapshots',
  listTablePrefs,
)

const searchTargets = ['name', 'id', 'description', 'status']

const volumeColumns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
  },
  {
    key: 'id',
    label: 'UUID',
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'size',
    label: 'Capacity',
    render: (size) => humanReadableSize(size * 1024 * 1024 * 1024),
  },
  {
    key: 'created_at',
    label: 'Age',
    formatFn: (value) => durationBetweenDates({ labels: ['d'] })(value),
  },
]

export default function VolumeSnapshotsListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listVolumeSnapshots, {
    params,
  })
  const data = useAppSelector(volumeSnapshotsSelector)

  const [selectedVolumeSnapshot, setSelectedVolumeSnapshot] = useState(null)
  const [showDeleteVolumeSnapshotDialog, setShowDeleteVolumeSnapshotDialog] = useState(null)

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (volumeSnapshot) => {
        setSelectedVolumeSnapshot(volumeSnapshot)
        setShowDeleteVolumeSnapshotDialog(true)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: false,
    },
  ]

  return (
    <>
      <DocumentMeta title="Volume Snapshots" />
      <PollingData hidden loading={loading} onReload={reload} refreshDuration={1000 * 30} />
      {showDeleteVolumeSnapshotDialog && (
        <DeleteVolumeSnapshotDialog
          rows={[selectedVolumeSnapshot]}
          onClose={() => setShowDeleteVolumeSnapshotDialog(false)}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.OpenstackVolumeSnapshots}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={volumeColumns}
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteVolumeSnapshot}
        DeleteDialogComponent={DeleteVolumeSnapshotDialog}
        rowMenuItems={rowMenuItems}
        multiSelection
        showRowMenuForSingleRowActions
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
