import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'

const { designate } = ApiClient.getInstance()

export const poolActions = ActionsSet.make<DataKeys.DnsPools>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey[DataKeys.DnsPools],
  cacheKey: DataKeys.DnsPools,
})

export const listPools = poolActions.add(
  new ListAction<DataKeys.DnsPools>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get DNS Pools')
    return designate.getPools()
  }),
)

export const createPool = poolActions.add(
  new CreateAction<DataKeys.DnsPools>(async (body) => {
    Bugsnag.leaveBreadcrumb('Attempting to create DNS Pool')
    return designate.createPool(body)
  }),
)

export const updatePool = poolActions.add(
  new UpdateAction<DataKeys.DnsPools>(async (body) => {
    Bugsnag.leaveBreadcrumb('Attempting to update kube role', body)
    return designate.updatePool(body.poolId, body)
  }),
)

export const deletePool = poolActions.add(
  new DeleteAction<DataKeys.DnsPools>(async (poolId) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete DNS Pool')
    return designate.deletePool(poolId)
  }),
)
