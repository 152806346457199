import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import { enableHypervisorScheduling, disableHypervisorScheduling } from '../actions'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    gap: 16,
    display: 'grid',
  },
}))

interface Props {
  rows: any[]
  onClose: (boolean) => void
}

export default function ToggleMaintenanceModeDialog({ rows: hosts, onClose }: Props) {
  const classes = useStyles()

  const {
    update: enableHypervisor,
    updating: enablingHypervisor,
    error: errorEnablingHypervisor,
    reset: resetEnableHypervisor,
  } = useUpdateAction(enableHypervisorScheduling)
  const {
    update: disableHypervisor,
    updating: disablingHypervisor,
    error: errorDisablingHypervisor,
    reset: resetDisableHypervisor,
  } = useUpdateAction(disableHypervisorScheduling)

  const handleSubmit = useCallback(async () => {
    const responses = await Promise.all(
      hosts.map((host) => {
        const body = {
          host: host.id,
          binary: 'nova-compute',
        }
        return host.hypervisor.status === 'disabled'
          ? enableHypervisor({ id: host.hypervisor.id, body })
          : disableHypervisor({ id: host.hypervisor.id, body })
      }),
    )

    // Check success response
    if (responses.every((response) => response.success)) {
      handleClose()
    }
  }, [hosts])

  const handleClose = () => {
    resetEnableHypervisor()
    resetDisableHypervisor()
    onClose(true)
  }

  return (
    <ModalForm
      title={'Enable/Disable Scheduling'}
      onSubmit={handleSubmit}
      onClose={handleClose}
      loading={false}
      submitting={enablingHypervisor || disablingHypervisor}
      error={errorEnablingHypervisor || errorDisablingHypervisor}
      submitTitle={`Submit`}
      panel="dialog"
      // maxWidth={1200}
      open
    >
      <div className={classes.fields}>
        <Text variant="body2">You are about to Enable/Disable scheduling on the below hosts: </Text>
        {hosts.map((host) => (
          <Text>
            <b>{host.name}</b> -{' '}
            {host.hypervisor.status === 'disabled' ? 'Enable Scheduling' : 'Disable Scheduling'}
          </Text>
        ))}
        <Text variant="body2">
          Disabling scheduling on a host will prevent additional VMs from being scheduled on it.
        </Text>
        <Text variant="body2">
          Enabling scheduling on a host will allow new VMs to be scheduled on it.
        </Text>
      </div>
    </ModalForm>
  )
}
