import React, { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import useParams from "core/hooks/useParams"
import { getResources, listResourceInventory, updateResourceInventory } from "../actions"
import TextField from "core/components/validatedForm/TextField"
import ModalForm from "core/elements/modal/ModalForm"

const defaultParams = {
  VCPU: '',
  MEMORY_MB: '',
  DISK_GB: '',
}

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    marginBottom: '15px'
  },
}))

export default function AllocationRatioModal({ rows: hosts, onClose }) {
  const classes = useStyles()
  const { params, getParamsUpdater } = useParams(defaultParams)
  const [resources, setResources] = useState([])
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    const getAllRsources = async () => {
      const resources = await getResources()
      setResources(resources)
    }

    getAllRsources()
  }, [])

  const handleSubmit = async () => {
    setSubmitting(true)
    const filterResourcesUUIDByHostName = resources.filter(resource => {
      const filteredHostByName = hosts.map(host => host?.hypervisor?.hypervisor_hostname)      
      return filteredHostByName.includes(resource.name)
    }).map(resource => resource.uuid)

    const allocationRatioPromises = filterResourcesUUIDByHostName.map(async (uuid) => {
      const { resource_provider_generation, inventories } =
        await listResourceInventory({ id: uuid })

      const filteredInvetories = Object.keys(inventories).reduce((accum, current) => {
        return {
          ...accum,
          [current]:
          {
            ...inventories[current],
            allocation_ratio: !!params[current] ?
              Number(params[current]) :
              inventories[current].allocation_ratio
          }
        }
      }, {})

      return updateResourceInventory({
        id: uuid,
        body: {
          inventories: filteredInvetories,
          resource_provider_generation: resource_provider_generation
        }
      })
    })

    const data = await Promise.allSettled(allocationRatioPromises)
    if (data)
      onClose()
    setSubmitting(false)
  }

  return (
    <ModalForm
      title={'Update Allocation Ratios'}
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={false}
      submitting={submitting}
      submitTitle={`Submit`}
      panel="dialog"
      open
    >
      <>
        <>
          <TextField
            id="cpu"
            label="CPU Allocation Ratio"
            onChange={getParamsUpdater('VCPU')}
            value={params.VCPU}
            className={classes.fields}
          />
          <TextField
            id="memory"
            label="Memory Allocation Ratio (in MB)"
            onChange={getParamsUpdater('MEMORY_MB')}
            value={params.MEMORY_MB}
            className={classes.fields}
          />
          <TextField
            id="disk"
            label="Disk Allocation Ratio (in GB)"
            onChange={getParamsUpdater('DISK_GB')}
            value={params.DISK_GB}
            className={classes.fields}
          />
        </>
      </>
    </ModalForm>
  )
}