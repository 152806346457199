import React, { useCallback, useState } from 'react'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteListener, listOctaviaPools } from './actions'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import Modal from 'core/elements/modal'
import useListAction from 'core/hooks/useListAction'
import { useAppSelector } from 'app/store'
import { octaviaPoolsSelector } from './selectors'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FontAwesomeIcon from 'pf9-ui-components/built/components/FontAwesomeIcon'
import clsx from 'clsx'

export default function DeleteListenersDialog({ rows, onClose }) {
  const [error, setError] = useState(null)
  const classes = useStyles()
  const { update, updating } = useUpdateAction(deleteListener)
  const listenerNames = rows?.map((listener) => listener?.name).join(', ')
  const { message, loading, reload } = useListAction(listOctaviaPools)
  const poolDetails = useAppSelector(octaviaPoolsSelector)
  const isLoadBalancerAssociated = rows.some((row) =>
    poolDetails.some((detail) => detail.listeners[0]?.id === row.id),
  )

  const handleDelete = useCallback(async () => {
    const promises = []
    for (const listener of rows) {
      promises.push(update(listener))
    }
    await Promise.all(promises)
      .then(() => {
        onClose(true)
      })
      .catch((err) => {
        console.error(err)
        setError({ title: 'Error Deleting Listeners', message: err?.message })
      })
  }, [rows])

  return (
    <Modal
      panel="dialog"
      title={
        !isLoadBalancerAssociated ? (
          'Delete Listener'
        ) : (
          <div className={classes.root}>
            <FontAwesomeIcon solid className={clsx(classes.circleIcon, classes.circleError)}>
              circle-xmark
            </FontAwesomeIcon>
            <div className={classes.header}>Delete Listener</div>
          </div>
        )
      }
      onClose={onClose}
      open
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={updating}>
            Cancel
          </Button>
          {!isLoadBalancerAssociated && (
            <Button onClick={handleDelete} loading={updating}>
              Confirm
            </Button>
          )}
        </>
      }
    >
      {!isLoadBalancerAssociated ? (
        <>
          <Text variant="body2">This will permanently delete {listenerNames}.</Text>
          {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
        </>
      ) : (
        <Text variant="body2">
          You cannot delete this Listener because it is associated with a Pool. Please delete the
          associated Pool first.
        </Text>
      )}
    </Modal>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    paddingLeft: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  circleIcon: {
    fontSize: '18px',
    color: '#fff',
  },
  circleError: {
    background: theme.palette.graphRed[100],
    color: theme.palette.graphRed[500],
  },
}))
