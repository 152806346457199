import React, { useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import { listVolumeTypes, deleteVolumeType } from './actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { volumeTypesSelector } from './selectors'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import { durationBetweenDates } from 'utils/misc'
import CreateVolumeTypeModal from './CreateVolumeTypeModal'
import EditVolumeTypeModal from './EditVolumeTypeModal'
// import SnapshotVolumeDialog from './SnapshotVolumeDialog'
// import AttachVolumeDialog from './AttachVolumeDialog'
// import DetachVolumeDialog from './DetachVolumeDialog'
import { humanReadableSize } from 'openstack/helpers'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import DeleteVolumeTypeDialog from './DeleteVolumeTypeDialog'

type ModelDataKey = DataKeys.VolumeTypes
type SelectorModel = ArrayElement<ReturnType<typeof volumeTypesSelector>>
type ActionParams = InferActionParams<typeof listVolumeTypes>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'created_at',
  orderDirection: 'desc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'VolumeTypes',
  listTablePrefs,
)

const searchTargets = ['name', 'id', 'description']

const volumeTypeColumns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
  },
  {
    key: 'id',
    label: 'UUID',
    display: false,
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'extra_specs',
    label: 'Metadata',
    disableSorting: true,
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
  // {
  //   key: 'status',
  //   label: 'Status',
  // },
  // {
  //   key: 'volume_type',
  //   label: 'Type',
  // },
  // {
  //   key: 'attachedVm',
  //   label: 'VM',
  //   render: (vm) => vm?.name,
  // },
  // {
  //   key: 'device',
  //   label: 'Device',
  // },
  // {
  //   key: 'size',
  //   label: 'Capacity',
  //   render: (size) => humanReadableSize(size * 1024 * 1024 * 1024),
  // },
  // {
  //   key: 'bootable',
  //   label: 'Bootable',
  // },
  // {
  //   key: 'created_at',
  //   label: 'Age',
  //   formatFn: (value) => durationBetweenDates({ labels: ['d'] })(value),
  // },
]

export default function VolumeTypesListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listVolumeTypes, {
    params,
  })
  const data = useAppSelector(volumeTypesSelector)

  const [selectedVolumeType, setSelectedVolumeType] = useState(null)
  const [showDeleteVolumeTypeDialog, setShowDeleteVolumeTypeDialog] = useState(null)
  const [showEditVolumeTypeModal, setShowEditVolumeTypeModal] = useState(false)
  // const [showAttachVolumeDialog, setShowAttachVolumeDialog] = useState(false)
  // const [showDetachVolumeDialog, setShowDetachVolumeDialog] = useState(false)

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      cond: (volumeTypes) => {
        return volumeTypes?.length === 1
      },
      icon: 'edit',
      label: 'Edit',
      handleAction: (volumeTypes) => {
        setSelectedVolumeType(volumeTypes[0] ?? null)
        setShowEditVolumeTypeModal(true)
      },
    },
  ]

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      label: 'Edit',
      icon: 'edit',
      handleClick: (volumeType) => {
        setSelectedVolumeType(volumeType)
        setShowEditVolumeTypeModal(true)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: false,
    },
    {
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (volumeType) => {
        setSelectedVolumeType(volumeType)
        setShowDeleteVolumeTypeDialog(true)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: false,
    },
  ]

  return (
    <>
      <DocumentMeta title="Volume Types" />
      <CreateVolumeTypeModal addRoute={routes.openstack.createVolumeType} />
      {showEditVolumeTypeModal && (
        <EditVolumeTypeModal
          rows={[selectedVolumeType]}
          onClose={() => setShowEditVolumeTypeModal(false)}
        />
      )}
      {showDeleteVolumeTypeDialog && (
        <DeleteVolumeTypeDialog
          rows={[selectedVolumeType]}
          onClose={() => setShowDeleteVolumeTypeDialog(false)}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.VolumeTypes}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={volumeTypeColumns}
        addUrl={routes.openstack.createVolumeType.path()}
        addText="Create New Volume Type"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteVolumeType}
        DeleteDialogComponent={DeleteVolumeTypeDialog}
        batchActions={batchActions}
        rowMenuItems={rowMenuItems}
        multiSelection
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
