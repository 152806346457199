import { listTablePrefs } from 'app/constants'
import { listPools } from 'app/plugins/openstack/components/networks/dns/pools/actions'
import { poolsSelector } from 'app/plugins/openstack/components/networks/dns/pools/selectors'
import { useAppSelector } from 'app/store'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import DataKeys from 'k8s/DataKeys'
import { pick } from 'ramda'
import React from 'react'

const columns = [
  {
    key: 'name',
    label: 'Name',
  },
  // {
  //   key: 'phase',
  //   label: 'Status',
  //   CellComponent: createGridStatusCell({
  //     dataFn: (phase) => getPoolStatus(phase),
  //   }),
  // },
  // {
  //   key: 'backend',
  //   label: 'Backend',
  // },
  // {
  //   key: 'servers',
  //   label: 'Servers',
  // },
  {
    key: 'description',
    label: 'Description',
    formatFn: (description) => description || '-',
  },
]
const searchTargets = ['name']

const PoolsListPage = () => {
  const { params, getParamsUpdater } = useParams({})

  const { loading, message, reload } = useListAction(listPools)
  const data = useAppSelector(poolsSelector)

  return (
    <>
      {/*  Commenting due to BE dependancy*/}
      {/* <AddPoolPage />
      <UpdatePoolPage /> */}
      <ListContainer<any, any>
        dataKey={DataKeys.DnsPools}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data || []}
        columns={columns}
        //Commenting due to BE dependancy
        // addText="Add DNS Pool"
        // addUrl={routes.openstack.createDnsResrouce.path({
        //   tab: DnsPageTabs.Pools,
        // })}
        // editUrl={(item) =>
        //   routes.openstack.editDnsResource.path({
        //     tab: DnsPageTabs.Pools,
        //     id: item?.id,
        //   })
        // }
        getParamsUpdater={getParamsUpdater}
        // deleteAction={deletePool}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}

export default PoolsListPage
