import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Progress from 'core/components/progress/Progress'
import Card from 'core/elements/card'
import Grid from 'core/elements/grid'
import Text from 'core/elements/Text'
import ErrorModal from '../ErrorModal'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listPorts } from 'openstack/components/networks/actions'
import { portsSelector } from 'openstack/components/networks/selectors'
import TasksPage from './TasksPage'
import OverviewCard from './OverviewCard'
import VmStateCellComponent from '../VmStateCellComponent'
import { isNilOrEmpty } from 'utils/fp'
import { Labels } from 'k8s/components/common/entity/labels-and-annotations/LabelsOrAnnotations'
import Tooltip from 'core/elements/tooltip'
import { humanReadableSize } from 'openstack/helpers'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { IVolumeDetailsPageTabs } from 'openstack/components/storage/volumes/volume-details/model'
import { routes } from 'core/utils/routes'
import SimpleLink from 'core/components/SimpleLink'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '370px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  flavorTooltip: {
    padding: 8,
  },
  tooltipIcon: {
    color: theme.palette.grey[300],
  },
  withTooltip: {
    display: 'grid',
    gap: 8,
    gridTemplateColumns: 'max-content max-content',
    alignItems: 'center',
  },
}))

const volumeTableColumns = [
  {
    key: 'name',
    label: 'Name',
    render: (val, volume) => {
      return (
        <SimpleLink
          src={routes.openstack.volumeDetails.path({
            id: volume?.id,
            tab: IVolumeDetailsPageTabs.Overview,
          })}
        >
          {volume?.name || volume?.id}
        </SimpleLink>
      )
    },
  },
  { key: 'description', label: 'Description' },
  { key: 'attachments', label: 'Device', render: (val) => val?.[0]?.device },
]

export default function Overview({ vm, loading }) {
  const classes = useStyles({})
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  const { message, loading: loadingPorts, reload: reloadPorts } = useListAction(listPorts)
  const ports = useSelector(portsSelector)
  const vmPorts = useMemo(() => {
    return ports?.filter((port) => port.device_id === vm?.id)
  }, [ports, vm])

  const addressTableColumns = useMemo(
    () => [
      {
        key: 'networkName',
        label: 'Network',
      },
      { key: 'addr', label: 'IP Address' },
      { key: 'OS-EXT-IPS-MAC:mac_addr', label: 'MAC Address' },
      { key: 'version', label: 'Version' },
      {
        key: 'OS-EXT-IPS:type',
        label: 'Type',
      },
      {
        key: 'index',
        label: 'Security Groups',
        render: (val, item) => {
          // not sure if there could be duplicate mac addresses or not
          const port = vmPorts.find(
            (vmPort) => item['OS-EXT-IPS-MAC:mac_addr'] === vmPort.mac_address,
          )
          const securityGroupsString = port?.securityGroups?.map(
            (group) => group?.name || group?.id,
          )
          return securityGroupsString || 'N/A'
        },
      },
    ],
    [vmPorts],
  )

  const addresses = useMemo(() => {
    if (!vm?.addresses) {
      return []
    }
    const networkNames = Object.keys(vm?.addresses)
    return networkNames.reduce((accum, name) => {
      const networkAddresses = vm.addresses[name]
      const withNetworkName = networkAddresses.map((address, idx) => {
        return {
          ...address,
          networkName: name,
          index: idx,
        }
      })
      return [...accum, ...withNetworkName]
    }, [])
  }, [vm])

  const volumes = useMemo(() => {
    if (!vm?.volumeIds) {
      return []
    }
    return vm.volumeIds.reduce((accum, id) => {
      return [...accum, vm?.volumeDetails?.[id]]
    }, [])
  }, [vm])

  const overviewData = useMemo(() => {
    if (!vm) {
      return []
    }
    return [
      {
        key: 'ID',
        value: vm?.id,
      },
      {
        key: 'Host',
        value: vm?.['OS-EXT-SRV-ATTR:hypervisor_hostname'],
      },
      {
        key: 'VM State',
        value: vm?.state,
        render: (item) => (
          <VmStateCellComponent
            item={item}
            state={vm?.state}
            setErrorVm={() => setShowErrorDialog(true)}
          />
        ),
      },
      {
        key: 'Image',
        value: vm?.imageName,
      },
      {
        key: 'Security Groups',
        value: vm?.security_groups?.map((group) => group?.name).join(', '),
      },
      {
        key: 'Metadata',
        value: vm?.metadata,
        render: (item) => {
          return !isNilOrEmpty(vm?.metadata) ? (
            <Labels labels={vm?.metadata} variant="default" showMoreButton ellipsisAt={75} />
          ) : (
            <Text variant="caption1">No Metadata</Text>
          )
        },
      },
      {
        key: 'Specs',
        value: vm?.flavorDetails,
        render: (item) => {
          const flavor = item?.flavorDetails
          return (
            <div className={classes.withTooltip}>
              <Text variant="caption1">{flavor?.name}</Text>
              <Tooltip
                customBody={
                  <div className={classes.flavorTooltip}>
                    <div>
                      RAM: <b>{humanReadableSize(flavor?.ram * 1024 * 1024)}</b>
                    </div>
                    <div>
                      vCPUs: <b>{flavor?.vcpus}</b>
                    </div>
                    <div>
                      Disk: <b>{humanReadableSize(flavor?.disk * 1024 * 1024 * 1024)}</b>
                    </div>
                  </div>
                }
              >
                <FontAwesomeIcon className={classes.tooltipIcon}>circle-question</FontAwesomeIcon>
              </Tooltip>
            </div>
          )
        },
      },
    ]
  }, [vm])

  return (
    <Progress loading={loading}>
      {showErrorDialog && (
        <ErrorModal error={vm?.fault?.message} onClose={() => setShowErrorDialog(false)} />
      )}
      <div className={classes.overview}>
        <div className={classes.column}>
          <OverviewCard item={vm} data={overviewData} icon="desktop" />
        </div>
        <div className={classes.column}>
          <TasksPage vm={vm} loading={loading} />
          <Card title="Networks" withCustomBody>
            <Grid
              uniqueIdentifier="addr"
              data={addresses}
              columns={addressTableColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
          <Card title="Volumes" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={volumes}
              columns={volumeTableColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}
