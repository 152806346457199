import { makeStyles } from '@material-ui/styles'
import { DnsPageTabs } from 'app/plugins/openstack/components/networks/dns/model'
import ExternalLink from 'core/components/ExternalLink'
import ModalForm from 'core/elements/modal/ModalForm'
import Text from 'core/elements/Text'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import {
  createRecord,
  listRecords,
} from 'app/plugins/openstack/components/networks/dns/records/actions'
import RecordConfigurationFields from 'app/plugins/openstack/components/networks/dns/records/RecordConfigurationFields'
import React from 'react'
import useReactRouter from 'use-react-router'
import useListAction from 'core/hooks/useListAction'
import Alert from 'core/components/Alert'
import { listZones } from 'app/plugins/openstack/components/networks/dns/zones/actions'
import { zonesSelector } from 'app/plugins/openstack/components/networks/dns/zones/selectors'
import { useAppSelector } from 'app/store'

const defaultParams = {
  recordName: '',
  zoneId: '',
  recordType: '',
  content: '',
  ttl: 3600,
  priority: '',
  weight: '',
  port: '',
}

const AddRecordPage = () => {
  const classes = useStyles({})
  const url = new URL(window.location.href)
  const selectedView = url.searchParams.get('view')

  const { history } = useReactRouter()
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)

  const { reload } = useListAction(listRecords)
  useListAction(listZones)
  const { update: create, updating, error, reset } = useUpdateAction(createRecord)
  const zones = useAppSelector(zonesSelector)

  const zone = zones?.find((zone) => zone?.id === params?.zoneId)

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(
      routes.openstack.dns.path({
        tab: DnsPageTabs.Records,
        view: selectedView,
      }),
    )
    reload(true)
  }

  const handleSubmit = async (params) => {
    const body = {
      name: `${params.recordName}.${zone.name}`,
      type: params.recordType,
      ttl: params.ttl,
      records: [params.content],
    }

    const { success } = await create({ zoneId: params.zoneId, body })
    if (success) handleClose()
  }
  return (
    <ModalForm
      route={routes.openstack.createDnsResource}
      title={`Add DNS Record`}
      maxWidth={464}
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitting={updating}
      submitTitle="Add DNS Record"
    >
      <div>
        {error && <Alert className={classes.alert} variant="error" message={error?.message} />}
        <div className={classes.iconTextWrapper}>
          <div className={classes.icon}>
            <img src="/ui/images/globe-pointer.svg" alt="DNS record" />
          </div>
          <Text variant="body2">
            Create a new{' '}
            <ExternalLink url="https://platform9.com/docs/private-cloud-director/private-cloud-director/dns-as-a-service--dnsaas-">
              DNS record
            </ExternalLink>{' '}
            for your zone.
          </Text>
        </div>
        <RecordConfigurationFields params={params} getParamsUpdater={getParamsUpdater} />
      </div>
    </ModalForm>
  )
}

export default AddRecordPage
const useStyles = makeStyles<Theme>((theme) => ({
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconTextWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}))
