import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { IProtocols } from '../model'

const options = [
  { label: IProtocols.TCP, value: IProtocols.TCP },
  { label: IProtocols.UDP, value: IProtocols.UDP },
  { label: IProtocols.SCTP, value: IProtocols.SCTP },
]

interface Props {}

export default function ProtocolPicklist({
  name = 'protocol',
  label = 'Protocol',
  compact = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
