import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { useAppSelector } from 'app/store'
import { ArrayElement } from 'core/actions/Action'
import InferActionParams from 'core/actions/InferActionParams'
import ListContainer from 'core/containers/ListContainer'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { routes } from 'core/utils/routes'
import DataKeys from 'k8s/DataKeys'
import { pick } from 'ramda'
import React, { useCallback } from 'react'
import CreatePoolsModal from '../CreatePoolsModal'
import DeletePoolsDialog from '../DeletePoolsDialog'
import { deletePools, listOctaviaListener, listOctaviaPools } from '../actions'
import { ILoadBalancerDetailsPageTabs } from '../model'
import { octaviaPoolsSelector } from '../selectors'
import EmptyContent from 'core/components/statusCard/EmptyContent'

type ModelDataKey = DataKeys.OctaviaPools
type SelectorModel = ArrayElement<ReturnType<typeof octaviaPoolsSelector>>
type ActionParams = InferActionParams<typeof listOctaviaPools>
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'OpenCreatePoolstackNetworks',
  listTablePrefs,
)

const searchTargets = ['name', 'ipAddress', 'operatingStatus', 'provisioningStatus', 'adminStateUp']

const searchFunctions = [
  {
    property: 'subnetDetails',
    searchFn: (value, searchString) => {
      return value.some(
        (subnet) =>
          subnet.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
          subnet.cidr.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()),
      )
    },
  },
]

const loadBalancerColumns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    // width: 'medium',
  },
  {
    key: 'listener',
    label: 'Listener',
  },
  {
    key: 'protocol',
    label: 'Protocol',
  },

  {
    key: 'algorithm',
    label: 'Algorithm',
  },
]

export default function PoolsListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { prefs } = useScopedPreferences()
  const { message, loading, reload } = useListAction(listOctaviaPools)
  const { reload: reloadListeners } = useListAction(listOctaviaListener)

  const data = useAppSelector(octaviaPoolsSelector)
  const handleRefresh = useCallback(() => {
    reload(true)
    reloadListeners(true)
  }, [])
  return (
    <>
      <CreatePoolsModal addRoute={routes.openstack.createLoadBalancers} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.OctaviaPools}
        label="Pools"
        searchTargets={searchTargets}
        searchFunctions={searchFunctions}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={'Loading...'}
        onRefresh={handleRefresh}
        data={data}
        columns={loadBalancerColumns}
        getParamsUpdater={getParamsUpdater}
        deleteAction={deletePools}
        DeleteDialogComponent={DeletePoolsDialog}
        multiSelection
        addUrl={routes.openstack.createLoadBalancers.path({
          tab: ILoadBalancerDetailsPageTabs.Pools,
        })}
        addText="Create Pool"
        emptyContent={
          <EmptyContent title={'No pools'} subTitle={'Create one in order to see details'} />
        }
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
