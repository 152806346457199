import { makeStyles } from '@material-ui/styles'
import { useAppSelector } from 'app/store'
import Alert from 'core/components/Alert'
import ExternalLink from 'core/components/ExternalLink'
import SimpleLink from 'core/components/SimpleLink'
import PicklistField from 'pf9-ui-components/built/components/validatedForm/DropdownField'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'pf9-ui-components/built/components/validatedForm/TextField'
import Text from 'pf9-ui-components/built/elements/Text'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { Route } from 'core/plugins/route'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import { loadBalancerHelpLink } from 'k8s/links'
import { middleLeft } from 'pf9-ui-components/built/elements/menu/defaults'
import React, { useCallback, useState } from 'react'
import useReactRouter from 'use-react-router'
import { addMemberToPool, listOctaviaPoolMembers, listOctaviaPools } from './actions'
import { getMemberRequestBody } from './helpers'
import { ILoadBalancerDetailsPageTabs } from './model'
import PoolsPicklist from './network-details/PoolsPicklist'
import SubnetPicklist from './network-details/SubnetPicklist'
import { octaviaPoolsSelector } from './selectors'
import { portValidator, validIpv4Validator } from 'core/utils/fieldValidators'
import ModalForm from 'pf9-ui-components/built/elements/modal/ModalForm'

const useStyles = makeStyles<Theme>((theme) => ({
  formFieldSection: {
    marginBottom: '32px',
    border: 'none',
    padding: 0,
  },
  pageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'grid',
    gap: 16,
    gridAutoRows: 'max-content',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  iconWrapper: {
    display: 'flex',
    height: '32px',
    width: '32px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoText: {
    position: 'absolute',
    top: 'unset',
    left: 'unset',
    right: '0%',
    bottom: '0%',
    width: 'max-content',
    maxWidth: '290px',
    padding: '8px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: theme.palette.grey[900],
    zIndex: 10000,
    transition: 'opacity 0.2s, transform 0.2s',
  },
  customFormFieldSection: {
    '& .content': {
      marginLeft: '0px',
    },
  },
}))

interface Props {
  addRoute: Route
}

export default function CreateMemberModal({ addRoute }: Props) {
  const { match, history } = useReactRouter()
  const imagePath = '/ui/images/add-member-logo.svg'
  const classes = useStyles()
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)
  const defaultParams = {
    name: '',
    pool: '',
    vipSubnetId: '',
    ipAddress: '',
    port: '',
    provider: 'ovn',
  }
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)

  const { reload } = useListAction(listOctaviaPoolMembers, {})
  const { update, updating, error, reset } = useUpdateAction(addMemberToPool)
  useListAction(listOctaviaPools)
  const pools = useAppSelector(octaviaPoolsSelector)
  const isDisabled = pools.length === 0
  const createListenerFn = async (params) => {
    const body = getMemberRequestBody(params)
    const { success, response } = await update({ poolId: params.pool, body })
    return { success, response }
  }

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(
      routes.openstack.loadBalancers.details.path({
        tab: ILoadBalancerDetailsPageTabs.Members,
      }),
    )
  }

  const submitForm = useCallback(async () => {
    setAttemptedSubmit(true)

    const { success } = await createListenerFn(params)
    if (success) {
      handleClose()
      reload()
    }
    setAttemptedSubmit(false)
  }, [params, createListenerFn, handleClose, reload])

  const redirectLink = () => {
    history.push(
      routes.openstack.createLoadBalancers.path({
        tab: ILoadBalancerDetailsPageTabs.Pools,
      }),
    )
  }
  return (
    <ModalForm
      route={addRoute}
      title={`Add Member`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating || attemptedSubmit}
      error={error}
      submitTitle={`Add Member`}
      maxWidth={470}
      disableSubmit={isDisabled}
    >
      <>
        <div className={classes.formFieldSection}>
          {isDisabled && (
            <div className={classes.formFieldSection}>
              <Alert title="No Pool Detected" variant="error">
                <Text
                  variant="body2"
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  In order to create a member, you first need to create a pool.
                </Text>
                <SimpleLink onClick={redirectLink}>Create Pool</SimpleLink>
              </Alert>
            </div>
          )}
          <div className={classes.contentContainer}>
            <img className={classes.iconWrapper} alt="Member" src={imagePath} />
            <div className={classes.content}>
              <Text
                variant="body2"
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                Add a <ExternalLink url={loadBalancerHelpLink}>member</ExternalLink> (Virtual
                Machines) to the pool that will receive client requests.
              </Text>
            </div>
          </div>
        </div>
        <FormFieldSection className={classes.customFormFieldSection} title="Member Configuration">
          <TextField
            id="name"
            label="Member Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            disabled={isDisabled}
            required
            enterKeyHint={undefined}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            crossOrigin={undefined}
          />
          <PicklistField
            DropdownComponent={PoolsPicklist}
            name="pool"
            id={`fixedIps.{idx}.pool`}
            label="Pool"
            compact={false}
            onChange={getParamsUpdater('pool')}
            info="Pool that will handle the client requests from the load balancer."
            align={middleLeft.align}
            disabled={isDisabled}
            required
          />
          <PicklistField
            DropdownComponent={SubnetPicklist}
            name="subnet"
            id={`fixedIps.{idx}.subnet`}
            label="Subnet"
            compact={false}
            onChange={getParamsUpdater('vipSubnetId')}
            info="This subnet should be the same tenant network where your backend servers (Virtual Machines) are deployed."
            disabled={isDisabled}
            align={middleLeft.align}
            required
          />
          <TextField
            id="ipAddress"
            label="IP Address"
            onChange={getParamsUpdater('ipAddress')}
            value={params.ipAddress}
            placeholder="Enter IP Address (eg. 192.168.2.100)"
            info={
              <div className={classes.infoText}>
                Virtual machines that will receive the client requests.
              </div>
            }
            disabled={isDisabled}
            validations={[validIpv4Validator]}
            required
            enterKeyHint={undefined}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
            crossOrigin={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
          <TextField
            id="port"
            label="Port"
            onChange={getParamsUpdater('port')}
            value={params.port}
            placeholder="Enter Port (eg. 80)"
            info={
              <div className={classes.infoText}>
                The port on which the backend server (Virtual machine) is listening.
              </div>
            }
            type="number"
            disabled={isDisabled}
            validations={[portValidator]}
            required
            enterKeyHint={undefined}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
            crossOrigin={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
