import React, { useEffect, useMemo, useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import { listVolumes, listAllVolumes, deleteVolume } from './actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { volumesSelector, allVolumesSelector } from './selectors'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import { durationBetweenDates } from 'utils/misc'
import CreateVolumeModal from './CreateVolumeModal'
import SnapshotVolumeDialog from './SnapshotVolumeDialog'
import EditVolumeDialog from './EditVolumeDialog'
import AttachVolumeDialog from './AttachVolumeDialog'
import DetachVolumeDialog from './DetachVolumeDialog'
import RetypeVolumeDialog from './RetypeVolumeDialog'
import UploadVolumeAsImageDialog from './UploadVolumeAsImageDialog'
import { humanReadableSize } from 'openstack/helpers'
import Text from 'core/elements/Text'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'
import ExtendVolumeDialog from './ExtendVolumeDialog'
import DeleteVolumeDialog from './DeleteVolumeDialog'
import TransferVolumeDialog from './TransferVolumeDialog'
import CancelTransferDialog from './CancelTransferDialog'
import AcceptVolumeTransferDialog from './AcceptVolumeTransferDialog'
import PollingData from 'core/components/PollingData'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import { GridDropdownBatchActionGroup } from 'core/elements/grid/hooks/useGridSelectableRows'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { IVolumeDetailsPageTabs } from './volume-details/model'
import { getBlueprints } from '../../infrastructure/actions'
import { IVmDetailsPageTabs } from 'openstack/components/vms/vm-details/model'
import SimpleLink from 'core/components/SimpleLink'
import CreateButton from 'core/components/buttons/CreateButton'
import useScopedPreferences from 'core/session/useScopedPreferences'

type ModelDataKey = DataKeys.OpenstackVolumes
type SelectorModel = ArrayElement<ReturnType<typeof volumesSelector>>
type ActionParams = InferActionParams<typeof listVolumes>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'created_at',
  orderDirection: 'desc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'OpenstackVolumes',
  listTablePrefs,
)

const searchTargets = [
  'name',
  'id',
  'tenantName',
  'status',
  'volume_type',
  'description',
  'attachedVm.name',
  'device',
]

const statusDisplay = {
  ['awaiting-transfer']: 'Awaiting Transfer',
}

const volumeColumns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ id, name }) =>
        !!name
          ? routes.openstack.volumeDetails.path({ id, tab: IVolumeDetailsPageTabs.Overview })
          : '',
    }),
  },
  {
    key: 'id',
    label: 'UUID',
    display: false,
  },
  {
    key: 'tenantName',
    label: 'Tenant',
  },
  {
    key: 'status',
    label: 'Status',
    render: (status) => statusDisplay[status] || status,
  },
  {
    key: 'volume_type',
    label: 'Type',
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'attachedVm',
    label: 'VM',
    render: (val) =>
      val ? (
        <SimpleLink
          src={routes.openstack.vmDetails.path({ id: val?.id, tab: IVmDetailsPageTabs.Overview })}
        >
          {val?.name}
        </SimpleLink>
      ) : null,
  },
  {
    key: 'device',
    label: 'Device',
  },
  {
    key: 'size',
    label: 'Capacity',
    render: (size) => humanReadableSize(size * 1024 * 1024 * 1024),
  },
  {
    key: 'bootable',
    label: 'Bootable',
  },
  {
    key: 'created_at',
    label: 'Age',
    formatFn: (value) => durationBetweenDates({ labels: ['d'] })(value),
  },
]

export default function VolumesListPage() {
  const classes = useStyles()
  const { prefs } = useScopedPreferences()
  const { currentTenant } = prefs
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listVolumes, {
    params,
  })
  const data = useAppSelector(volumesSelector)
  const { message: allMessage, loading: allLoading, reload: allReload } = useListAction(
    listAllVolumes,
    {
      params,
    },
  )
  const allVolumes = useAppSelector(allVolumesSelector)

  const [selectedVolume, setSelectedVolume] = useState(null)
  const [showAcceptVolumeTransferDialog, setShowAcceptVolumeTransferDialog] = useState(false)
  const [showEditVolumeDialog, setShowEditVolumeDialog] = useState(false)
  const [showSnapshotVolumeDialog, setShowSnapshotVolumeDialog] = useState(false)
  const [showUploadVolumeDialog, setShowUploadVolumeDialog] = useState(false)
  const [showAttachVolumeDialog, setShowAttachVolumeDialog] = useState(false)
  const [showDetachVolumeDialog, setShowDetachVolumeDialog] = useState(false)
  const [showExtendVolumeDialog, setShowExtendVolumeDialog] = useState(false)
  const [showRetypeVolumeDialog, setShowRetypeVolumeDialog] = useState(false)
  const [showDeleteVolumeDialog, setShowDeleteVolumeDialog] = useState(false)
  const [showTransferVolumeDialog, setShowTransferVolumeDialog] = useState(false)
  const [showCancelTransferVolumeDialog, setShowCancelTransferVolumeDialog] = useState(false)
  const [blueprintDetails, setBlueprintDetails] = useState({})
  const [allTenants, setAllTenants] = useState(false)

  useEffect(() => {
    const getDriverDetails = async () => {
      const blueprints = await getBlueprints()
      setBlueprintDetails(blueprints[0]?.storageBackends)
    }

    getDriverDetails()
  }, [])

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      cond: (volumes) => {
        return volumes?.length === 1
      },
      icon: 'edit',
      iconOnly: true,
      tooltip: 'Edit',
      label: 'Edit',
      handleAction: (volumes) => {
        setSelectedVolume(volumes[0] ?? null)
        setShowEditVolumeDialog(true)
      },
    },
    {
      cond: (volumes) => {
        return volumes?.length === 1
      },
      icon: 'camera',
      iconOnly: true,
      tooltip: 'Snapshot',
      label: 'Snapshot',
      handleAction: (volumes) => {
        setSelectedVolume(volumes[0] ?? null)
        setShowSnapshotVolumeDialog(true)
      },
    },
  ]

  const dropdownBatchActions: GridDropdownBatchActionGroup<SelectorModel>[] = useMemo(
    () => [
      {
        // icon: 'power-off',
        label: 'Actions',
        actions: [
          {
            cond: (volumes) => volumes?.length === 1,
            icon: 'upload',
            label: 'Upload as Image',
            BatchActionButton: getGridDialogButton(UploadVolumeAsImageDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            cond: (volumes) => volumes?.length === 1 && volumes?.[0]?.status === 'available',
            icon: 'plus-square',
            label: 'Attach Volume',
            BatchActionButton: getGridDialogButton(AttachVolumeDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            cond: (volumes) => volumes?.length === 1 && volumes?.[0]?.status === 'in-use',
            icon: 'minus-square',
            label: 'Detach Volume',
            BatchActionButton: getGridDialogButton(DetachVolumeDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            cond: (volumes) => {
              if (volumes?.length !== 1) {
                return false
              }
              const volumeHostAtt = volumes?.[0]?.['os-vol-host-attr:host'] || ''
              const volumeType = volumeHostAtt?.split('#')?.[1]
              const volumeTypeConfig = volumeHostAtt?.split('@')?.[1]?.split('#')?.[0]
              const volumeDriver = blueprintDetails?.[volumeType]?.[volumeTypeConfig]?.driver || ''

              return (
                volumes[0]?.status === 'available' ||
                (volumes[0]?.status === 'in-use' && volumeDriver !== 'NFS')
              )
            },
            icon: 'square-up',
            label: 'Extend Volume',
            // disabledTooltip: 'You can only extend a volume when it is in the available status',
            BatchActionButton: getGridDialogButton(ExtendVolumeDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            cond: (volumes) => volumes?.length === 1 && volumes?.[0]?.status === 'available',
            icon: 'swap',
            label: 'Retype Volume',
            // disabledTooltip: 'You can only extend a volume when it is in the available status',
            BatchActionButton: getGridDialogButton(RetypeVolumeDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            cond: (volumes) =>
              volumes?.length === 1 &&
              volumes?.[0]?.status === 'available' &&
              volumes?.[0]?.['os-vol-tenant-attr:tenant_id'] === currentTenant,
            icon: 'arrow-right-arrow-left',
            label: 'Transfer Volume',
            disabledTooltip: 'You can only transfer a volume when it is in the available status',
            BatchActionButton: getGridDialogButton(TransferVolumeDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            cond: (volumes) =>
              volumes?.length === 1 &&
              volumes?.[0]?.status === 'awaiting-transfer' &&
              volumes?.[0]?.['os-vol-tenant-attr:tenant_id'] === currentTenant,
            icon: 'ban',
            label: 'Cancel Transfer',
            BatchActionButton: getGridDialogButton(CancelTransferDialog, null, {
              className: classes.dropdownAction,
            }),
          },
        ],
      },
    ],
    [classes, blueprintDetails, currentTenant],
  )

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = useMemo(
    () => [
      {
        label: 'Edit',
        icon: 'edit',
        handleClick: (volume) => {
          setSelectedVolume(volume)
          setShowEditVolumeDialog(true)
        },
        // cond: (selected) => {
        //   return ['shutoff', 'stopped'].includes(selected?.state)
        // },
        refreshAfterSuccess: true,
        hideIfDisabled: false,
      },
      {
        label: 'Snapshot',
        icon: 'camera',
        handleClick: (volume) => {
          setSelectedVolume(volume)
          setShowSnapshotVolumeDialog(true)
        },
        // cond: (selected) => {
        //   return ['shutoff', 'stopped'].includes(selected?.state)
        // },
        refreshAfterSuccess: true,
        hideIfDisabled: false,
      },
      {
        label: 'Delete',
        icon: 'trash-alt',
        handleClick: (volume) => {
          setSelectedVolume(volume)
          setShowDeleteVolumeDialog(true)
        },
        refreshAfterSuccess: true,
        hideIfDisabled: false,
      },
      {
        label: 'Upload as Image',
        icon: 'upload',
        handleClick: (volume) => {
          setSelectedVolume(volume)
          setShowUploadVolumeDialog(true)
        },
        refreshAfterSuccess: true,
        hideIfDisabled: false,
      },
      {
        label: 'Attach Volume',
        icon: 'plus-square',
        handleClick: (volume) => {
          setSelectedVolume(volume)
          setShowAttachVolumeDialog(true)
        },
        cond: (selected) => {
          return selected?.status === 'available'
        },
        refreshAfterSuccess: true,
        hideIfDisabled: false,
      },
      {
        label: 'Detach Volume',
        icon: 'minus-square',
        handleClick: (volume) => {
          setSelectedVolume(volume)
          setShowDetachVolumeDialog(true)
        },
        cond: (selected) => {
          return selected?.status === 'in-use'
        },
        refreshAfterSuccess: true,
        hideIfDisabled: false,
      },
      {
        label: 'Extend Volume',
        icon: 'square-up',
        handleClick: (volume) => {
          setSelectedVolume(volume)
          setShowExtendVolumeDialog(true)
        },
        cond: (selected) => {
          const volumeHostAtt = selected['os-vol-host-attr:host'] || ''
          const volumeType = volumeHostAtt?.split('#')?.[1]
          const volumeTypeConfig = volumeHostAtt?.split('@')?.[1]?.split('#')?.[0]
          const volumeDriver = blueprintDetails?.[volumeType]?.[volumeTypeConfig]?.driver || ''

          return (
            selected?.status === 'available' ||
            (selected?.status === 'in-use' && volumeDriver !== 'NFS')
          )
        },
        refreshAfterSuccess: true,
        hideIfDisabled: false,
      },
      {
        label: 'Retype Volume',
        icon: 'swap',
        handleClick: (volume) => {
          setSelectedVolume(volume)
          setShowRetypeVolumeDialog(true)
        },
        cond: (selected) => {
          return selected.status === 'available'
        },
        refreshAfterSuccess: true,
        hideIfDisabled: false,
      },
      {
        label: 'Transfer Volume',
        icon: 'arrow-right-arrow-left',
        handleClick: (volume) => {
          setSelectedVolume(volume)
          setShowTransferVolumeDialog(true)
        },
        cond: (selected) => {
          return (
            selected.status === 'available' &&
            selected['os-vol-tenant-attr:tenant_id'] === currentTenant
          )
        },
        refreshAfterSuccess: true,
        hideIfDisabled: false,
      },
      {
        label: 'Cancel Transfer',
        icon: 'ban',
        handleClick: (volume) => {
          setSelectedVolume(volume)
          setShowCancelTransferVolumeDialog(true)
        },
        cond: (selected) => {
          return (
            selected.status === 'awaiting-transfer' &&
            selected['os-vol-tenant-attr:tenant_id'] === currentTenant
          )
        },
        refreshAfterSuccess: true,
        hideIfDisabled: false,
      },
    ],
    [blueprintDetails, currentTenant],
  )

  return (
    <>
      <DocumentMeta title="Volumes" />
      <PollingData
        hidden
        loading={(!allTenants && loading) || (allTenants && allLoading)}
        onReload={allTenants ? allReload : reload}
        refreshDuration={1000 * 30}
      />
      <CreateVolumeModal addRoute={routes.openstack.createVolume} />
      {showAcceptVolumeTransferDialog && (
        <AcceptVolumeTransferDialog onClose={() => setShowAcceptVolumeTransferDialog(false)} />
      )}
      {showEditVolumeDialog && (
        <EditVolumeDialog rows={[selectedVolume]} onClose={() => setShowEditVolumeDialog(false)} />
      )}
      {showSnapshotVolumeDialog && (
        <SnapshotVolumeDialog
          rows={[selectedVolume]}
          onClose={() => setShowSnapshotVolumeDialog(false)}
        />
      )}
      {showUploadVolumeDialog && (
        <UploadVolumeAsImageDialog
          rows={[selectedVolume]}
          onClose={() => setShowUploadVolumeDialog(false)}
        />
      )}
      {showAttachVolumeDialog && (
        <AttachVolumeDialog
          rows={[selectedVolume]}
          onClose={() => setShowAttachVolumeDialog(false)}
        />
      )}
      {showDetachVolumeDialog && (
        <DetachVolumeDialog
          rows={[selectedVolume]}
          onClose={() => setShowDetachVolumeDialog(false)}
        />
      )}
      {showExtendVolumeDialog && (
        <ExtendVolumeDialog
          rows={[selectedVolume]}
          onClose={() => setShowExtendVolumeDialog(false)}
        />
      )}
      {showRetypeVolumeDialog && (
        <RetypeVolumeDialog
          rows={[selectedVolume]}
          onClose={() => setShowRetypeVolumeDialog(false)}
        />
      )}
      {showTransferVolumeDialog && (
        <TransferVolumeDialog
          rows={[selectedVolume]}
          onClose={() => setShowTransferVolumeDialog(false)}
        />
      )}
      {showCancelTransferVolumeDialog && (
        <CancelTransferDialog
          rows={[selectedVolume]}
          onClose={() => setShowCancelTransferVolumeDialog(false)}
        />
      )}
      {showDeleteVolumeDialog && (
        <DeleteVolumeDialog
          rows={[selectedVolume]}
          onClose={() => setShowDeleteVolumeDialog(false)}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.OpenstackVolumes}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={(!allTenants && loading) || (allTenants && allLoading)}
        loadingMessage={(!allTenants && message) || (allTenants && allMessage)}
        onRefresh={allTenants ? allReload : reload}
        data={allTenants ? allVolumes : data}
        columns={volumeColumns}
        addUrl={routes.openstack.createVolume.path()}
        addText="Create New Volume"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteVolume}
        batchActions={batchActions}
        dropdownBatchActions={dropdownBatchActions}
        rowMenuItems={rowMenuItems}
        DeleteDialogComponent={DeleteVolumeDialog}
        extraHeaderContent={
          <>
            {isAdmin() ? (
              <ToggleSwitch
                active={allTenants}
                onClick={() => setAllTenants(!allTenants)}
                label="View Volumes in All Tenants"
              />
            ) : null}
            <CreateButton onClick={() => setShowAcceptVolumeTransferDialog(true)}>
              Accept Volume Transfer
            </CreateButton>
          </>
        }
        iconOnly
        multiSelection
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  dropdownAction: {
    background: theme.components.dropdown.background,
    border: 'none',
    '& .button-text': {
      color: theme.components.dropdown.color,
      justifyContent: 'start',
    },
  },
}))
