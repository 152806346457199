import {
  getPoolName,
  getZoneRecordsCount,
} from 'app/plugins/openstack/components/networks/dns/helper'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'

export const zonesSelector = createSharedSelector(
  getDataSelector<DataKeys.DnsZones>(DataKeys.DnsZones),
  getDataSelector<DataKeys.DnsPools>(DataKeys.DnsPools),
  getDataSelector<DataKeys.DnsRecords>(DataKeys.DnsRecords),
  (zones, pools, records) => {
    return zones.map((zone) => ({
      ...zone,
      name: zone?.name,
      backend: '',
      servers: '',
      status: { status: zone?.status, action: zone?.action },
      type: zone?.type,
      created: zone?.created_at,
      poolId: zone?.pool_id,
      poolName: getPoolName(zone?.pool_id, pools),
      recordsCount: getZoneRecordsCount(zone?.id, records),
    }))
  },
)
