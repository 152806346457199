import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import {
  listResmgrHosts,
  listHypervisors,
  disableHypervisorScheduling,
  getVmsForHost,
} from '../actions'
import { listVirtualMachines, simpleMigrateVirtualMachine } from 'openstack/components/vms/actions'
import { resmgrHostsSelector, hypervisorsSelector } from '../selectors'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import ListTableField from 'core/components/validatedForm/ListTableField'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { HostStatusCell, IpAddressCellComponent } from './HostsListPage'
import StatsCell from 'app/plugins/infrastructure/components/common/cells/StatsCell'
import Badge from 'core/elements/badge/Badge'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Info from 'core/components/validatedForm/Info'
import { waitSeconds } from 'openstack/components/infrastructure/blueprint/helpers'
import { useDispatch } from 'react-redux'
import { migrationActions } from 'openstack/components/vms/migrations/reducers'
import ExternalLink from 'core/components/ExternalLink'

const getBadgeVariant = (status) =>
  ({
    active: 'success',
    paused: 'warning',
    stopped: 'error',
    error: 'error',
    suspended: 'warning',
    building: 'success',
    rescued: 'warning',
  }[status] || 'unknown')

const getBadgeText = (status, pausedPowerState, taskState) => {
  const text =
    {
      active: 'Active',
      paused: 'Paused',
      stopped: 'Stopped',
      error: 'Error',
      suspended: 'Suspended',
      building: 'Building',
      rescued: 'Rescued',
    }[status] || status
  return taskState ? `${text} (${taskState})` : pausedPowerState ? `${text} (paused)` : text
}

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    gap: 16,
    display: 'grid',
  },
  migrations: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 16,
  },
  migrationHeader: {
    textAlign: 'center',
  },
  migratingVms: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr 1fr',
    gap: '8px 32px',
    paddingBottom: 16,
  },
}))

interface Props {
  rows: any[]
  onClose: (boolean) => void
}

const hostColumns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'role_status',
    label: 'Status',
    render: (value, item) => <HostStatusCell value={value} item={item} />,
  },
  {
    id: 'interfaces',
    label: 'IP Address',
    render: (value) => <IpAddressCellComponent value={value} />,
  },
  {
    id: 'usage',
    label: 'Resource Utilization',
    render: (value) => <StatsCell value={value} />,
  },
  {
    id: 'displayRoles',
    label: 'Assigned Roles',
    render: (values) => values.join(', '),
  },
]

const StateCell = ({ vm }) => {
  const pausedPowerState =
    vm['OS-EXT-STS:vm_state'] === 'active' && vm['OS-EXT-STS:power_state'] === 3
  const state = vm['OS-EXT-STS:vm_state']
  const taskState = vm['OS-EXT-STS:task_state']

  return ['migrating', 'resize_prep'].includes(taskState) ? (
    <Badge variant="warning" text="Migrating" />
  ) : (
    <Badge
      variant={getBadgeVariant(state)}
      text={getBadgeText(state, pausedPowerState, taskState)}
    />
  )
}

const MigrationStatus = ({ vm, vmsIssuedMigration }) => {
  const vmIds = vmsIssuedMigration.map((v) => v.id)
  const taskState = vm['OS-EXT-STS:task_state']
  const vmState = vm['OS-EXT-STS:vm_state']
  const migrationTriggered = vmIds.includes(vm.id)

  return ['migrating', 'resize_prep'].includes(taskState) ? (
    <Text variant="body2">
      <FontAwesomeIcon spin>sync</FontAwesomeIcon> In-Progress
    </Text>
  ) : ['error', 'resized'].includes(vmState) ? (
    <Text variant="body2">Invalid state for migration</Text>
  ) : migrationTriggered ? (
    <Text variant="body2">Migration to another host failed</Text>
  ) : (
    <Text variant="body2">Migration failed to trigger</Text>
  )
}

const MigratingVms = ({ vms, vmsIssuedMigration }) => {
  const classes = useStyles()
  return (
    <div className={classes.migratingVms}>
      <div />
      <Text variant="caption1">VM State</Text>
      <Text variant="caption1">Migration Status</Text>
      {vms.map((vm) => (
        <React.Fragment key={vm.id}>
          <Text variant="caption1">{vm.name}</Text>
          <StateCell vm={vm} />
          <MigrationStatus vm={vm} vmsIssuedMigration={vmsIssuedMigration} />
        </React.Fragment>
      ))}
    </div>
  )
}

export default function EnableMaintenanceModeDialog({ rows: hosts, onClose }: Props) {
  const host = hosts?.[0]
  const classes = useStyles()
  const dispatch = useDispatch()
  const [step, setStep] = useState(1)
  const [loadingVms, setLoadingVms] = useState(true)
  const [vmsOnHost, setVmsOnHost] = useState([])
  const [vmsOnHostDuringMigration, setVmsOnHostDuringMigration] = useState([])
  const [submittingMigrations, setSubmittingMigrations] = useState(false)
  const [migratingVms, setMigratingVms] = useState([])
  const [tryAgain, setTryAgain] = useState(false)

  const { reload: reloadVms } = useListAction(listVirtualMachines, {})

  const reloadVmsOnHost = useCallback(() => {
    const makeCalls = async () => {
      const vms = await getVmsForHost(host.id)
      setVmsOnHost(vms)
      setLoadingVms(false)
    }
    makeCalls()
  }, [host])

  useEffect(() => {
    // Get initial number of VMs on the host to determine
    // if migrations are necessary
    reloadVmsOnHost()
  }, [])

  const defaultParams = {
    selectHost: false,
    host: [],
  }
  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)

  const { message, loading: loadingResmgrHosts, reload } = useListAction(listResmgrHosts, {
    params,
  })

  const allHosts = useSelector(resmgrHostsSelector)
  const { loading: loadingHypervisors } = useListAction(listHypervisors)
  const hypervisors = useSelector(hypervisorsSelector)

  const validHosts = useMemo(() => {
    if (!allHosts.length || !host || !hypervisors.length) {
      return []
    }
    const hypervisorsByResmgrId = hypervisors.reduce((accum, hypervisor) => {
      return {
        ...accum,
        [hypervisor?.service?.host]: hypervisor,
      }
    }, {})
    const hostsWithHypervisor = allHosts.map((host) => {
      return {
        ...host,
        hypervisor: hypervisorsByResmgrId[host.id],
      }
    })
    return hostsWithHypervisor.filter((resmgrHost) => {
      return (
        resmgrHost.id !== host.id &&
        resmgrHost.hypervisor?.state === 'up' &&
        resmgrHost.hypervisor?.status === 'enabled'
      )
    })
  }, [allHosts, host, hypervisors])

  const {
    update: disableHypervisor,
    updating: disablingHypervisor,
    error: errorDisablingHypervisor,
    reset: resetDisableHypervisor,
  } = useUpdateAction(disableHypervisorScheduling)

  const handleMaintenanceToggle = useCallback(async () => {
    const body = {
      host: host.id,
      binary: 'nova-compute',
    }
    await disableHypervisor({ id: host.hypervisor.id, body })
    handleClose()
  }, [params, host])

  const triggerMigrations = useCallback(async () => {
    setSubmittingMigrations(true)
    const vmsWithMigrationTriggered = []
    // Trigger migrations on valid VMs (This condition may change
    // as more conditions are found)
    const validVms = vmsOnHost.filter((vm) => {
      return !['error', 'resized'].includes(vm['OS-EXT-STS:vm_state'])
    })
    const validVmIds = validVms.map((vm) => vm.id)
    for (const vm of validVms) {
      try {
        // cold migrate vs live migrate depending on vm state
        const body = ['shutoff', 'stopped'].includes(vm['OS-EXT-STS:vm_state'])
          ? {
              migrate: {
                host: params.selectHost ? params.host[0]?.id : null,
              },
            }
          : {
              'os-migrateLive': {
                host: params.selectHost ? params.host[0]?.id : null,
                block_migration: 'auto',
              },
            }
        // Use this simple migration function that doesn't refresh the VM
        // to avoid tons of API calls
        await simpleMigrateVirtualMachine({ id: vm.id, body })
        vmsWithMigrationTriggered.push(vm)
      } catch (e) {
        console.log('some VM migrations failed')
      }
    }
    // Expose vms that triggered migration to component scope
    // so that migration progress can be tracked
    setVmsOnHostDuringMigration(vmsWithMigrationTriggered)
    // Track missed VMs so we can determine when migrations on
    // triggered VMs have all finished or failed
    const missedVms = validVms.length - vmsWithMigrationTriggered.length
    setStep(2)
    setSubmittingMigrations(false)
    // Keep polling host vms list until migrations have been finished
    let migrationsComplete = false
    while (!migrationsComplete) {
      try {
        const vms = await getVmsForHost(host.id)
        setMigratingVms(vms)
        // If no more vms are on host, good to cordon host
        if (vms.length === 0) {
          migrationsComplete = true
          handleMaintenanceToggle()
          return
        }
        const failedMigrationVms = vms.filter((vm) => {
          return (
            ['error', 'resized'].includes(vm['OS-EXT-STS:vm_state']) ||
            (!['migrating', 'resize_prep'].includes(vm['OS-EXT-STS:task_state']) &&
              validVmIds.includes(vm.id))
          )
        })
        // If migrations are all finished and there are still VMs left on the host
        if (vms.length && failedMigrationVms.length + missedVms === vms.length) {
          migrationsComplete = true
          setTryAgain(true)
          return
        }
      } catch (e) {
        console.log('error getting vms')
      }
      await waitSeconds(10)
    }
  }, [params, host, vmsOnHost, handleMaintenanceToggle])

  const handleClose = () => {
    resetDisableHypervisor()
    reloadVms(true)
    // Clear migrations data so that it doesn't wrongfully show
    // failed migrations back on the vms list page
    dispatch(migrationActions.clearMigrations())
    onClose(true)
  }

  return (
    <ModalForm
      title={'Enable Maintenance Mode'}
      onSubmit={vmsOnHost.length ? triggerMigrations : handleMaintenanceToggle}
      onClose={handleClose}
      loading={loadingVms}
      submitting={disablingHypervisor || submittingMigrations}
      error={errorDisablingHypervisor}
      submitTitle={
        vmsOnHost.length ? 'Migrate VMs and Enable Maintenance Mode' : 'Enable Maintenance Mode'
      }
      panel="dialog"
      maxWidth={1200}
      disableSubmit={step === 2}
      open
    >
      <div className={classes.fields}>
        {step === 1 && (
          <>
            <Text variant="body2">
              You are about to enable maintenance mode on host <b>{host.name}</b>. Refer to this{' '}
              <ExternalLink url="https://platform9.com/docs/private-cloud-director/private-cloud-director/maintenance-mode">
                article
              </ExternalLink>{' '}
              for more information.
            </Text>
            {vmsOnHost?.length ? (
              <>
                <Text variant="body2">
                  All VMs on this host will be migrated automatically to other hosts, or to the
                  selected host. There are currently <b>{vmsOnHost.length}</b> VM(s) on this host.
                  After all migrations are completed, scheduling will be disabled on this host.
                </Text>
                <CheckboxField
                  id="selectHost"
                  label="Designate a specific host to migrate VMs to"
                  value={params.selectHost}
                  onChange={getParamsUpdater('selectHost')}
                  info="If checked, select a host to which all VMs on this host will be migrated to. If left unchecked, VMs will be distributed to new hosts automatically."
                />
              </>
            ) : (
              <Text variant="body2">
                There are no VMs on this host. Maintenance mode may be enabled, and schedulilng will
                be disabled on this host.
              </Text>
            )}
            {params.selectHost && (
              <ListTableField
                id="host"
                data={validHosts}
                loading={loadingResmgrHosts || loadingHypervisors}
                columns={hostColumns}
                onChange={getParamsUpdater('host')}
                value={params.host}
                uniqueIdentifier="id"
                searchTargets={['name']}
                noRowsPerPage
                required
              />
            )}
          </>
        )}
        {step === 2 && (
          <>
            {!tryAgain && (
              <Info>
                <Text variant="body2">
                  Migrations are currently in progress. Please do not close this window until
                  migrations are completed.
                </Text>
              </Info>
            )}
            {tryAgain && (
              <Info error>
                <Text variant="body2">
                  Migrations have finished. The remaining VMs were not migrated successfully, and
                  must be dealt with before maintenance mode can be enabled. Any VMs in error or
                  resized states must be fixed manually. Please try enabling maintenance mode again
                  after fixing these issues.
                </Text>
              </Info>
            )}
            <Text variant="subtitle2" className={classes.migrationHeader}>
              VMs Remaining on Host
            </Text>
            <MigratingVms vms={migratingVms} vmsIssuedMigration={vmsOnHostDuringMigration} />
          </>
        )}
      </div>
    </ModalForm>
  )
}
