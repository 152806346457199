import { createSelector } from '@reduxjs/toolkit'
import { customMigrationKey, defaultState } from './reducers'
import { pathOr } from 'ramda'

export const migrationsSelector = createSelector(
  (state) => pathOr(defaultState, [customMigrationKey])(state),
  (result) => {
    return result
  },
)
