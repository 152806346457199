import { makeStyles } from '@material-ui/styles'
import { DnsPageTabs } from 'app/plugins/openstack/components/networks/dns/model'
import { listZones, updateZone } from 'app/plugins/openstack/components/networks/dns/zones/actions'
import { zonesSelector } from 'app/plugins/openstack/components/networks/dns/zones/selectors'
import ZoneConfigurationFields from 'app/plugins/openstack/components/networks/dns/zones/ZoneConfigurationFields'
import { useAppSelector } from 'app/store'
import Alert from 'core/components/Alert'
import ModalForm from 'core/elements/modal/ModalForm'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import { propEq } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import useReactRouter from 'use-react-router'

const defaultParams = {
  zoneName: '',
  zoneType: '',
  dnsPool: '',
  adminEmail: '',
  defaultTtl: 3600,
  refresh: 86400,
  retry: 7200,
  expire: 2419200,
  minimumTtl: 300,
}

const UpdateZonePage = () => {
  const classes = useStyles()
  const url = new URL(window.location.href)
  const selectedView = url.searchParams.get('view')

  const { history, match } = useReactRouter()
  const zoneId = match.params.id
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { loading, message, reload } = useListAction(listZones)
  const { update, updating, error, reset } = useUpdateAction(updateZone)

  const zones = useAppSelector(zonesSelector)
  const zone = useMemo(() => zones?.find(propEq('id', zoneId)), [zones, zoneId])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(
      routes.openstack.dns.path({
        tab: DnsPageTabs.Zones,
        view: selectedView,
      }),
    )
    reload(true)
  }

  const handleSubmit = async (params) => {
    // TODO: Pending API implementation
    const body = {
      ttl: Number(params.defaultTtl),
      email: params.adminEmail,
    }
    const { success } = await update({ zoneId, body })
    if (success) handleClose()
  }

  useEffect(() => {
    if (!zone) return
    updateParams({
      zoneName: zone?.name,
      zoneType: zone?.type,
      dnsPool: zone?.poolId,
      adminEmail: zone?.email,
      defaultTtl: zone?.ttl,
      refresh: 86400,
      retry: 7200,
      expire: 2419200,
      minimumTtl: 300,
    })
  }, [zone])

  return (
    <ModalForm
      route={routes.openstack.editDnsResource}
      title={`Edit DNS Zone`}
      maxWidth={464}
      onClose={handleClose}
      onSubmit={handleSubmit}
      loading={loading}
      submitting={updating}
      loadingMessage={loading ? message : 'Submitting form...'}
      submitTitle="Save Changes"
    >
      <div>
        {error && <Alert className={classes.alert} variant="error" message={error?.message} />}
        <ZoneConfigurationFields params={params} getParamsUpdater={getParamsUpdater} />
      </div>
    </ModalForm>
  )
}

export default UpdateZonePage

const useStyles = makeStyles<Theme>((theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
  },
}))
