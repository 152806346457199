import React, { useCallback, useState } from 'react'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteLoadBalancer, listOctaviaListener } from './actions'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import Modal from 'core/elements/modal'
import { useAppSelector } from 'app/store'
import { octaviaListenersSelector } from './selectors'
import useListAction from 'core/hooks/useListAction'
import FontAwesomeIcon from 'pf9-ui-components/built/components/FontAwesomeIcon'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import clsx from 'clsx'

export default function DeleteLoadBalancerDialog({ rows, onClose }) {
  const classes = useStyles()
  const [error, setError] = useState(null)
  const { update, updating } = useUpdateAction(deleteLoadBalancer)
  const { reload: reloadOctaviaListeners } = useListAction(listOctaviaListener)

  const listenersDetails = useAppSelector(octaviaListenersSelector)

  const isLoadBalancerAssociated = rows.some((row) =>
    listenersDetails.some((detail) => detail.loadbalancers[0]?.id === row.id),
  )

  const loadBalancerNames = rows?.map((loadBalancer) => loadBalancer?.name).join(', ')

  const handleDelete = useCallback(async () => {
    const promises = []
    for (const loadBalancer of rows) {
      promises.push(update(loadBalancer))
    }
    await Promise.all(promises)
      .then(() => {
        onClose(true)
      })
      .catch((err) => {
        console.error(err)
        setError({ title: 'Error Deleting Load Balancers', message: err?.message })
      })
  }, [rows, update, onClose, setError])

  return (
    <Modal
      panel="dialog"
      title={
        !isLoadBalancerAssociated ? (
          'Delete Load Balancer'
        ) : (
          <div className={classes.root}>
            <FontAwesomeIcon solid className={clsx(classes.circleIcon, classes.circleError)}>
              circle-xmark
            </FontAwesomeIcon>
            <div className={classes.header}>Delete Load Balancer</div>
          </div>
        )
      }
      onClose={onClose}
      open
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={updating}>
            Cancel
          </Button>
          {!isLoadBalancerAssociated && (
            <Button onClick={handleDelete} loading={updating}>
              Confirm
            </Button>
          )}
        </>
      }
    >
      {!isLoadBalancerAssociated ? (
        <>
          <Text variant="body2">This will permanently delete {loadBalancerNames}.</Text>
          {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
        </>
      ) : (
        <Text variant="body2">
          You cannot delete this Load Balancer because it is associated with a Listner. Please
          delete the associated Listner first.
        </Text>
      )}
    </Modal>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    paddingLeft: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  circleIcon: {
    fontSize: '18px',
    color: '#fff',
  },
  circleError: {
    background: theme.palette.graphRed[100],
    color: theme.palette.graphRed[500],
  },
}))
