import React, { useCallback, useState } from 'react'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteVolumeSnapshot } from './actions'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import Modal from 'core/elements/modal'

export default function DeleteVolumeSnapshotDialog({ rows: volumeSnapshots, onClose }) {
  const [error, setError] = useState(null)
  const { update: deleteFn, updating: deletingVolumeSnapshot } = useUpdateAction(
    deleteVolumeSnapshot,
  )

  const volumeSnapshotNames = volumeSnapshots
    ?.map((volumeSnapshot) => volumeSnapshot?.name || volumeSnapshot?.id)
    .join(', ')

  const handleDelete = useCallback(async () => {
    const promises = []
    for (const volumeSnapshot of volumeSnapshots) {
      promises.push(deleteFn(volumeSnapshot))
    }
    await Promise.all(promises)
      .then(() => {
        onClose(true)
      })
      .catch((err) => {
        console.error(err)
        setError({ title: 'Error Deleting Volume Snapshot', message: err?.message })
      })
  }, [volumeSnapshots])

  return (
    <Modal
      panel="dialog"
      title="Delete Volume Snapshot"
      onClose={onClose}
      open
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingVolumeSnapshot}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={deletingVolumeSnapshot}>
            Confirm
          </Button>
        </>
      }
    >
      <>
        <Text variant="body2">
          You are about to permanently delete the following volume snapshots.
        </Text>
        <Text variant="body2">{volumeSnapshotNames}</Text>
        {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
      </>
    </Modal>
  )
}
