import { makeStyles } from '@material-ui/styles'
import { useAppSelector } from 'app/store'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Text from 'pf9-ui-components/built/elements/Text'

import Alert from 'core/components/Alert'
import ExternalLink from 'core/components/ExternalLink'
import SimpleLink from 'core/components/SimpleLink'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import { middleLeft } from 'core/elements/menu/defaults'
import QuantitySelectorWithHeader from 'core/elements/tooltip/QuantitySelectorWithHeader'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { Route } from 'core/plugins/route'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import { loadBalancerHelpLink } from 'k8s/links'
import PicklistField from 'pf9-ui-components/built/components/validatedForm/DropdownField'
import { topLeft, topMiddle } from 'pf9-ui-components/built/elements/menu/defaults'
import ModalForm from 'pf9-ui-components/built/elements/modal/ModalForm'
import React, { useCallback, useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import { createHealthMonitor, listOctaviaHealthMonitors, listOctaviaPools } from './actions'
import { getHealthMonitorRequestBody } from './helpers'
import { ILoadBalancerDetailsPageTabs } from './model'
import PoolsPicklist from './network-details/PoolsPicklist'
import TypePicklist from './network-details/TypePicklist'
import { octaviaPoolsSelector } from './selectors'

const useStyles = makeStyles<Theme>(() => ({
  formFieldSection: {
    marginBottom: '32px',
    border: 'none',
    padding: 0,
  },
  pageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'grid',
    gap: 16,
    gridAutoRows: 'max-content',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  iconWrapper: {
    display: 'flex',
    height: '32px',
    width: '32px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  quantitySelectorWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  quantitySelectorRow: {
    paddingLeft: '16px',
  },
  customFormFieldSection: {
    '& .content': {
      marginLeft: '0px',
    },
  },
}))

interface Props {
  addRoute: Route
}

export default function CreateHealthMonitorModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const imagePath = '/ui/images/add-health-monitor-logo.svg'
  const classes = useStyles()
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)
  const defaultParams = {
    type: '',
    pool: '',
    delay: 30,
    minDelay: 1,
    maxDelay: 300,
    timeout: 10,
    minTimeout: 1,
    maxTimeout: 120,
    retry: 3,
    minRetry: 0,
    maxRetry: 10,
    provider: 'ovn',
  }
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)
  const [delayError, setDelayError] = useState('')
  const [timeoutError, setTimeoutError] = useState('')
  const [retryError, setRetryError] = useState('')

  const { reload } = useListAction(listOctaviaHealthMonitors, {})

  const { update, updating, error, reset } = useUpdateAction(createHealthMonitor)
  useListAction(listOctaviaPools)

  const pools = useAppSelector(octaviaPoolsSelector)
  const isDisabled = pools.length === 0

  const createListenerFn = async (params) => {
    const body = getHealthMonitorRequestBody(params)
    const { success, response } = await update({ body })
    return { success, response }
  }

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(
      routes.openstack.loadBalancers.details.path({
        tab: ILoadBalancerDetailsPageTabs.Monitors,
      }),
    )
  }

  const submitForm = useCallback(async () => {
    setAttemptedSubmit(true)

    const { success } = await createListenerFn(params)
    if (success) {
      handleClose()
      reload()
    }
    setAttemptedSubmit(false)
  }, [params, createListenerFn, handleClose, reload])

  const redirectLink = () => {
    history.push(
      routes.openstack.createLoadBalancers.path({
        tab: ILoadBalancerDetailsPageTabs.Pools,
      }),
    )
  }

  useEffect(() => {
    if (params.delay < params.minDelay || params.delay > params.maxDelay) {
      setDelayError('Delay must be between 1 and 300 seconds.')
    } else {
      setDelayError('')
    }

    if (params.timeout < params.minTimeout || params.timeout > params.maxTimeout) {
      setTimeoutError('Timeout must be between 1 and 120 seconds.')
    } else {
      setTimeoutError('')
    }

    if (params.retry < params.minRetry || params.retry > params.maxRetry) {
      setRetryError('Max Retries must be between 0 and 10.')
    } else {
      setRetryError('')
    }
  }, [params.delay, params.timeout, params.retry])

  return (
    <ModalForm
      route={addRoute}
      title={`Add Health Monitor`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating || attemptedSubmit}
      error={error}
      submitTitle={`Add Health Monitor`}
      maxWidth={470}
      disableSubmit={
        isDisabled || Boolean(retryError) || Boolean(timeoutError) || Boolean(delayError)
      }
    >
      <div className={classes.formFieldSection}>
        {isDisabled && (
          <div className={classes.formFieldSection}>
            <Alert title="No Pool Detected" variant="error">
              <Text
                variant="body2"
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                In order to create a health monitor, you first need to create a pool.
              </Text>
              <SimpleLink onClick={redirectLink}>Create Pool</SimpleLink>
            </Alert>
          </div>
        )}
        <div className={classes.contentContainer}>
          <img className={classes.iconWrapper} alt="Health Monitor" src={imagePath} />
          <div className={classes.content}>
            <Text
              variant="body2"
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              Configure <ExternalLink url={loadBalancerHelpLink}>health monitoring</ExternalLink> to
              periodically check the health of load balancer pool members.
            </Text>
          </div>
        </div>
      </div>
      <FormFieldSection
        className={classes.customFormFieldSection}
        title="Health Monitor Configuration"
      >
        <PicklistField
          DropdownComponent={TypePicklist}
          name="type"
          id={`fixedIps.{idx}.type`}
          label="Type"
          compact={false}
          onChange={getParamsUpdater('type')}
          info="The type of health monitor you want to setup."
          align={middleLeft.align}
          disabled={isDisabled}
          required
        />
        <PicklistField
          DropdownComponent={PoolsPicklist}
          name="pool"
          id={`fixedIps.{idx}.pool`}
          label="Pool"
          compact={false}
          onChange={getParamsUpdater('pool')}
          info="Pool that will handle the client requests from the load balancer."
          align={middleLeft.align}
          disabled={isDisabled}
          required
        />
        <div className={classes.quantitySelectorWrapper}>
          <QuantitySelectorWithHeader
            id={'delay'}
            value={params.delay}
            onChange={(value) => updateParams({ delay: value })}
            min={params.minDelay}
            max={params.maxDelay}
            info="The time between health checks. Delay must be between 1 and 300 seconds."
            align={topMiddle.align}
            label="Delay (s)"
          />
          <div className={classes.quantitySelectorRow}>
            <QuantitySelectorWithHeader
              id={'timeout'}
              value={params.timeout}
              onChange={(value) => updateParams({ timeout: value })}
              min={params.minTimeout}
              max={params.maxTimeout}
              info="How long to wait for a response. Timeout must be between 1 and 120 seconds."
              align={topMiddle.align}
              label="Timeout (s)"
            />
          </div>
          <div className={classes.quantitySelectorRow}>
            <QuantitySelectorWithHeader
              id={'retry'}
              value={params.retry}
              onChange={(value) => updateParams({ retry: value })}
              min={params.minRetry}
              max={params.maxRetry}
              info="Number of failed checks before a member gets marked as unhealthy.Max Retry must be between 0 and 10."
              align={topLeft.align}
              label="Max Retries"
            />
          </div>
        </div>
        {delayError && <ErrorMessage>{delayError}</ErrorMessage>}
        {timeoutError && <ErrorMessage>{timeoutError}</ErrorMessage>}
        {retryError && <ErrorMessage>{retryError}</ErrorMessage>}
      </FormFieldSection>
    </ModalForm>
  )
}
