import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'

const { designate } = ApiClient.getInstance()

export const recordActions = ActionsSet.make<DataKeys.DnsRecords>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey[DataKeys.DnsRecords],
  cacheKey: DataKeys.DnsRecords,
})

export const listRecords = recordActions.add(
  new ListAction<DataKeys.DnsRecords>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get DNS Recordsets')
    return designate.getRecords()
  }),
)

export const createRecord = recordActions.add(
  new CreateAction<DataKeys.DnsRecords>(async ({ zoneId, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to create DNS Recordset')
    return designate.createRecord({ zoneId, body })
  }),
)

export const updateRecord = recordActions.add(
  new UpdateAction<DataKeys.DnsRecords>(async ({ zoneId, recordId, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update DNS Recordset')
    return designate.updateRecord({ zoneId, recordId, body })
  }),
)

export const deleteRecord = recordActions.add(
  new DeleteAction<DataKeys.DnsRecords>(async ({ zoneId, recordId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete DNS Recordset')
    return designate.deleteRecord({ zoneId, recordId })
  }),
)
