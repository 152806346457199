import React, { useCallback } from 'react'
import { styled } from '@material-ui/styles'
import Text from 'core/elements/Text'
import { listVolumes, acceptVolumeTransfer } from './actions'
import useListAction from 'core/hooks/useListAction'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import TextField from 'core/components/validatedForm/TextField'
import ServiceUnhealthyInfo from 'openstack/components/common/ServiceUnhealthyInfo'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'

const Container = styled('div')({
  gap: 16,
  display: 'grid',
})

export default function AcceptVolumeTransferDialog({ onClose }) {
  const adminUser = isAdmin()
  const { params, getParamsUpdater } = useParams({
    transferId: '',
    authKey: '',
  })

  const { reload } = useListAction(listVolumes)

  const { update, updating, error, reset } = useUpdateAction(acceptVolumeTransfer)

  const handleSubmit = useCallback(async () => {
    const body = {
      accept: {
        auth_key: params.authKey,
      },
    }
    const { success } = await update({
      id: params.transferId,
      body,
    })
    if (success) {
      reload(true)
      handleClose()
    }
  }, [params, adminUser])

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <ModalForm
      title="Accept Volume Transfer"
      onSubmit={handleSubmit}
      onClose={onClose}
      submitting={updating}
      error={error}
      submitTitle={`Accept Transfer`}
      panel="dialog"
      maxWidth={800}
      open
    >
      <ServiceUnhealthyInfo action="Accept Volume" blockStorage />
      <Container>
        <Text variant="body2">
          Accept a volume transfer that has been initiated already into the current tenant.
        </Text>
        <TextField
          id="transferId"
          label="Transfer ID"
          onChange={getParamsUpdater('transferId')}
          value={params.transferId}
          info="The transfer ID provided when initiating volume transfer"
          required
        />
        <TextField
          id="authKey"
          label="Authorization Key"
          onChange={getParamsUpdater('authKey')}
          value={params.authKey}
          info="The authorization key provided when initiating volume transfer"
          required
        />
      </Container>
    </ModalForm>
  )
}
