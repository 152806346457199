import React, { useCallback, useState } from 'react'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deletePools, listOctaviaHealthMonitors, listOctaviaPoolMembers } from './actions'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import Modal from 'core/elements/modal'
import { useAppSelector } from 'app/store'
import useListAction from 'core/hooks/useListAction'
import { octaviaHealthMonitorsSelector, octaviaPoolMembersSelector } from './selectors'
import { makeStyles } from '@material-ui/styles'
import FontAwesomeIcon from 'pf9-ui-components/built/components/FontAwesomeIcon'
import Theme from 'core/themes/model'
import clsx from 'clsx'

export default function DeletePoolsDialog({ rows, onClose }) {
  const classes = useStyles()
  const [error, setError] = useState(null)
  const { update, updating } = useUpdateAction(deletePools)

  const poolNames = rows?.map((pool) => pool?.name).join(', ')
  const poolIds = rows.map((pool) => pool.id)
  const { reload: reloadPoolMembers } = useListAction(listOctaviaPoolMembers, {
    params: { poolIds: poolIds },
  })
  const { reload: reloadHealthMonitors } = useListAction(listOctaviaHealthMonitors)

  const memberDetails = useAppSelector(octaviaPoolMembersSelector)
  const monitorDetails = useAppSelector(octaviaHealthMonitorsSelector)

  const isLoadBalancerAssociated = rows.some(
    (row) =>
      memberDetails.some((member) => member.poolId === row.id) ||
      monitorDetails.some((monitor) => monitor.poolId === row.id),
  )

  const handleDelete = useCallback(async () => {
    const promises = []
    for (const pool of rows) {
      promises.push(update(pool))
    }
    await Promise.all(promises)
      .then(() => {
        onClose(true)
      })
      .catch((err) => {
        console.error(err)
        setError({
          title: 'Error Deleting Pools',
          message: err?.message,
        })
      })
  }, [rows])

  return (
    <Modal
      panel="dialog"
      title={
        !isLoadBalancerAssociated ? (
          'Delete Pool'
        ) : (
          <div className={classes.root}>
            <FontAwesomeIcon solid className={clsx(classes.circleIcon, classes.circleError)}>
              circle-xmark
            </FontAwesomeIcon>
            <div className={classes.header}>Delete Pool</div>
          </div>
        )
      }
      onClose={onClose}
      open
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={updating}>
            Cancel
          </Button>
          {!isLoadBalancerAssociated && (
            <Button onClick={handleDelete} loading={updating}>
              Confirm
            </Button>
          )}
        </>
      }
    >
      {!isLoadBalancerAssociated ? (
        <>
          <Text variant="body2">This will permanently delete {poolNames}.</Text>
          {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
        </>
      ) : (
        <Text variant="body2">
          You cannot delete this Pool because it has active Members or an associated Health Monitor.
          Please remove all Members or detach the Health Monitor before deleting the Pool.
        </Text>
      )}
    </Modal>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    paddingLeft: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  circleIcon: {
    fontSize: '18px',
    color: '#fff',
  },
  circleError: {
    background: theme.palette.graphRed[100],
    color: theme.palette.graphRed[500],
  },
}))
