import React, { useMemo, useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import { listFloatingIps, deleteFloatingIp } from './actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { floatingIpsSelector } from './selectors'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import useReactRouter from 'use-react-router'
import { durationBetweenDates } from 'utils/misc'
import Text from 'core/elements/Text'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { routes } from 'core/utils/routes'
// import { INetworkDetailsPageTabs } from './network-details/model'
import CreateFloatingIpModal from './CreateFloatingIpModal'
// import EditFloatingIpModal from './EditFloatingIpModal'
import AssignFloatingIpDialog from './AssignFloatingIpDialog'
import DetachFloatingIpDialog from './DetachFloatingIpDialog'
import useScopedPreferences from 'core/session/useScopedPreferences'
import DeleteIPDialog from './DeleteIPDialog'

type ModelDataKey = DataKeys.FloatingIps
type SelectorModel = ArrayElement<ReturnType<typeof floatingIpsSelector>>
type ActionParams = InferActionParams<typeof listFloatingIps>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'FloatingIps',
  listTablePrefs,
)

const searchTargets = ['floating_ip_address', 'id', 'fixed_ip_address', 'allocation']

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'floating_ip_address',
    label: 'Public IP Address',
  },
  {
    key: 'id',
    label: 'UUID',
    display: false,
  },
  {
    key: 'fixed_ip_address',
    label: 'Private IP Address',
  },
  {
    key: 'allocation',
    label: 'Allocation',
    render: (val) => val?.name,
  },
  // {
  //   key: 'admin_state_up',
  //   label: 'Admin State',
  //   render: (value) => (value ? 'Up' : 'Down'),
  // },
  // {
  //   key: 'externalNetwork',
  //   label: 'External network',
  // },
]

export default function FloatingIpsListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { location } = useReactRouter()
  const { prefs } = useScopedPreferences()
  const { currentTenant } = prefs

  const { message, loading, reload } = useListAction(listFloatingIps, {
    params,
  })
  const data = useAppSelector(floatingIpsSelector)

  const [selectedFloatingIp, setSelectedFloatingIp] = useState(null)
  const [showAssignFloatingIpDialog, setShowAssignFloatingIpDialog] = useState(false)
  const [showDetachFloatingIpDialog, setShowDetachFloatingIpDialog] = useState(false)

  const tenantFloatingIps = useMemo(() => {
    return data.filter((floatingIp) => {
      return floatingIp.project_id === currentTenant
    })
  }, [data, currentTenant])

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      cond: (floatingIps) => !floatingIps[0]?.port_id,
      icon: 'plus-square',
      label: 'Assign IP',
      handleAction: (floatingIps) => {
        setSelectedFloatingIp(floatingIps[0] ?? null)
        setShowAssignFloatingIpDialog(true)
      },
    },
    {
      cond: (floatingIps) => !!floatingIps[0]?.port_id,
      icon: 'minus-square',
      label: 'Detach IP',
      handleAction: (floatingIps) => {
        setSelectedFloatingIp(floatingIps[0] ?? null)
        setShowDetachFloatingIpDialog(true)
      },
    },
  ]

  return (
    <>
      <DocumentMeta title="Public IPs" />
      <CreateFloatingIpModal addRoute={routes.openstack.createFloatingIp} />
      {showAssignFloatingIpDialog && (
        <AssignFloatingIpDialog
          rows={[selectedFloatingIp]}
          onClose={() => setShowAssignFloatingIpDialog(false)}
        />
      )}
      {showDetachFloatingIpDialog && (
        <DetachFloatingIpDialog
          rows={[selectedFloatingIp]}
          onClose={() => setShowDetachFloatingIpDialog(false)}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.FloatingIps}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={tenantFloatingIps}
        columns={columns}
        addUrl={routes.openstack.createFloatingIp.path()}
        addText="Create Public IP"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteFloatingIp}
        DeleteDialogComponent={DeleteIPDialog}
        batchActions={batchActions}
        // EditDialogComponent={EditFloatingIpModal}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
