import PoolsListPage from 'app/plugins/openstack/components/networks/dns/pools/PoolsListPage'
import { DnsPageTabs } from 'app/plugins/openstack/components/networks/dns/model'
import RecordsListPage from 'app/plugins/openstack/components/networks/dns/records/RecordsListPage'
import ZonesListPage from 'app/plugins/openstack/components/networks/dns/zones/ZonesListPage'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import { routes } from 'core/utils/routes'
import React from 'react'

const DnsTabsPage = () => {
  return (
    <Tabs route={routes.openstack.dns}>
      <Tab label="DNS Pools" value={DnsPageTabs.Pools}>
        <PoolsListPage />
      </Tab>
      <Tab label="Zones" value={DnsPageTabs.Zones}>
        <ZonesListPage />
      </Tab>
      <Tab label="Records" value={DnsPageTabs.Records}>
        <RecordsListPage />
      </Tab>
    </Tabs>
  )
}

export default DnsTabsPage
