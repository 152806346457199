// libs
import React, { useEffect, useMemo, useState } from 'react'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import useReactRouter from 'use-react-router'

// Components
import Text from 'core/elements/Text'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import DocumentMeta from 'core/components/DocumentMeta'
import { HeaderTitlePortal, TopHeaderActionPortal } from 'core/elements/header/portals'
import {
  getCinderQuotaUsage,
  getNetworkQuotaUsage,
  getComputeQuotaUsage,
} from 'account/components/userManagement/tenants/new-actions'
import { PieChart, Pie, Cell } from 'recharts'
import { makeStyles, useTheme } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'
import ProgressGraph from 'core/components/graphs/ProgressGraph'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import useListAction from 'core/hooks/useListAction'
import { useAppSelector } from 'app/store'
import { listVirtualMachines, listAllVirtualMachines } from 'openstack/components/vms/actions'
import {
  virtualMachinesSelector,
  allVirtualMachinesSelector,
} from 'openstack/components/vms/selectors'
import { routes } from 'core/utils/routes'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'
import { listHypervisors, listResmgrHosts } from 'openstack/components/infrastructure/actions'
import HostsListPageHeader from 'openstack/components/infrastructure/hosts/HostsListPageHeader'
import {
  hypervisorsSelector,
  resmgrHostsSelector,
} from 'openstack/components/infrastructure/selectors'
import ToggleSwitch from 'core/elements/ToggleSwitch'

const PieGraph = ({
  used = 0,
  capacity = 0,
  sideLength = 100,
  arcWidth = 12,
  startAngle = 90,
  endAngle = -270,
  width = sideLength + 10,
  height = sideLength + 10,
}) => {
  const theme: Theme = useTheme()
  const radius = Math.floor(sideLength / 2)
  const emptyData = useMemo(
    () => [{ name: 'unknown', value: 1, color: theme.components.graph.tray }],
    [theme.components.graph.tray],
  )
  const unlimitedData = useMemo(
    () => [{ name: 'unknown', value: 1, color: theme.components.graph.primary }],
    [theme.components.graph.primary],
  )
  const data = [
    { name: 'used', value: used, color: theme.components.graph.success },
    { name: 'unused', value: capacity - used, color: theme.components.graph.tray },
  ]
  const items = used === 0 ? emptyData : capacity === -1 ? unlimitedData : data

  return (
    // @ts-ignore
    <PieChart width={width} height={height}>
      {/* @ts-ignore */}
      <Pie
        dataKey="value"
        data={items}
        startAngle={startAngle}
        endAngle={endAngle}
        cx={radius}
        cy={radius}
        innerRadius={radius - arcWidth}
        outerRadius={radius}
        paddingAngle={0}
      >
        {items.map((entry) => (
          <Cell key={entry.name} fill={entry.color} />
        ))}
      </Pie>
    </PieChart>
  )
}

const DashboardPage = () => {
  const classes = useStyles()
  const { history } = useReactRouter()
  const { prefs } = useScopedPreferences()
  const { currentTenant } = prefs
  const [allTenants, setAllTenants] = useState(false)
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const {
    userDetails: { displayName, role, id: userId },
    features,
  } = session
  const showAdminDashboard = isAdmin()

  useListAction(listHypervisors)
  useListAction(listResmgrHosts)
  const hosts = useSelector(resmgrHostsSelector)
  const hypervisors = useAppSelector(hypervisorsSelector)

  const [quotas, setQuotas] = useState<any>({})

  const { loading: loadingVms } = useListAction(listVirtualMachines)
  const vms = useAppSelector(virtualMachinesSelector)
  const { loading: loadingAllVms } = useListAction(listAllVirtualMachines)
  const allVms = useAppSelector(allVirtualMachinesSelector)

  const vmCounts = useMemo(() => {
    const vmScope = allTenants ? allVms : vms
    return vmScope.reduce(
      (accum, vm) => {
        if (['active', 'building'].includes(vm?.state)) {
          return {
            ...accum,
            success: accum.success + 1,
          }
        } else if (['paused', 'suspended', 'stopped'].includes(vm?.state)) {
          return {
            ...accum,
            warning: accum.warning + 1,
          }
        } else {
          return {
            ...accum,
            error: accum.error + 1,
          }
        }
      },
      {
        success: 0,
        warning: 0,
        error: 0,
      },
    )
  }, [vms, allVms, allTenants])

  const hypervisorCounts = useMemo(() => {
    const resmgrHostIds = hosts.map((host) => host.id)
    const validHypervisors = hypervisors.filter((hypervisor) =>
      resmgrHostIds.includes(hypervisor?.service?.host),
    )
    const counts = validHypervisors.reduce(
      (accum, hypervisor) => {
        if (hypervisor.status === 'enabled') {
          return {
            ...accum,
            success: accum.success + 1,
          }
        } else {
          return {
            ...accum,
            error: accum.error + 1,
          }
        }
      },
      {
        success: 0,
        error: 0,
      },
    )
    const hypervisorResmgrIds = validHypervisors.map((hypervisor) => hypervisor?.service?.host)
    const hypervisorHosts = hosts.filter((host) => host.displayRoles?.includes('Hypervisor'))
    const missingScheduling = hypervisorHosts.filter(
      (host) => !hypervisorResmgrIds.includes(host.id),
    )
    return {
      ...counts,
      error: counts.error + missingScheduling.length,
    }
  }, [hypervisors, hosts])

  useEffect(() => {
    if (!userId || !currentTenant) {
      return
    }
    const makeCalls = async () => {
      let computeQuotas = {}
      let cinderQuotas = {}
      let networkQuotas = {}
      try {
        computeQuotas = await getComputeQuotaUsage({ tenantId: currentTenant })
      } catch (err) {
        console.error(err)
      }
      try {
        cinderQuotas = await getCinderQuotaUsage({ tenantId: currentTenant })
      } catch (err) {
        console.error(err)
      }
      try {
        networkQuotas = await getNetworkQuotaUsage({ tenantId: currentTenant })
      } catch (err) {
        console.error(err)
      }
      setQuotas({
        ...computeQuotas,
        ...cinderQuotas,
        ...networkQuotas,
      })
    }
    makeCalls()
  }, [userId, currentTenant])

  return (
    <>
      <DocumentMeta title="Dashboard" />
      {/* <HeaderTitlePortal>
        <Text id="dashboard-title" variant="subtitle1">
          Welcome{displayName ? ` ${displayName}` : ''}!
        </Text>
      </HeaderTitlePortal> */}
      <TopHeaderActionPortal>
        {showAdminDashboard ? (
          <ToggleSwitch
            active={allTenants}
            onClick={() => setAllTenants(!allTenants)}
            label="Show All Tenants Info"
          />
        ) : null}
      </TopHeaderActionPortal>
      <div className={classes.resourceStatuses}>
        <Card className={classes.statusCard}>
          <div className={classes.cardBody}>
            <div className={classes.statusHeader}>
              <Text variant="subtitle2">Virtual Machines</Text>
              <Text
                variant="caption1"
                className={classes.seeAll}
                onClick={() => history.push(routes.openstack.vms.path())}
              >
                See All{' '}
                <FontAwesomeIcon regular className={classes.arrow}>
                  chevron-right
                </FontAwesomeIcon>
              </Text>
            </div>
            <div className={classes.icons}>
              <div className={classes.iconWithCount}>
                <div className={classes.iconContainer}>
                  <FontAwesomeIcon className={clsx(classes.statusIcon, classes.success)}>
                    circle-check
                  </FontAwesomeIcon>
                </div>
                <Text variant="body2">{vmCounts?.success}</Text>
              </div>
              <div className={classes.divider} />
              <div className={classes.iconWithCount}>
                <div className={classes.iconContainer}>
                  <FontAwesomeIcon className={clsx(classes.statusIcon, classes.warning)}>
                    circle-exclamation
                  </FontAwesomeIcon>
                </div>
                <Text variant="body2">{vmCounts?.warning}</Text>
              </div>
              <div className={classes.divider} />
              <div className={classes.iconWithCount}>
                <div className={classes.iconContainer}>
                  <FontAwesomeIcon className={clsx(classes.statusIcon, classes.error)}>
                    triangle-exclamation
                  </FontAwesomeIcon>
                </div>
                <Text variant="body2">{vmCounts?.error}</Text>
              </div>
            </div>
          </div>
        </Card>
        {showAdminDashboard && (
          <Card className={classes.statusCard}>
            <div className={classes.cardBody}>
              <div className={classes.statusHeader}>
                <Text variant="subtitle2">Hypervisors</Text>
                <Text
                  variant="caption1"
                  className={classes.seeAll}
                  onClick={() => history.push(routes.openstack.hosts.path())}
                >
                  See All{' '}
                  <FontAwesomeIcon regular className={classes.arrow}>
                    chevron-right
                  </FontAwesomeIcon>
                </Text>
              </div>
              <div className={classes.icons}>
                <div className={classes.iconWithCount}>
                  <div className={classes.iconContainer}>
                    <FontAwesomeIcon className={clsx(classes.statusIcon, classes.success)}>
                      circle-check
                    </FontAwesomeIcon>
                  </div>
                  <Text variant="body2">{hypervisorCounts?.success}</Text>
                </div>
                <div className={classes.divider} />
                <div className={classes.iconWithCount}>
                  <div className={classes.iconContainer}>
                    <FontAwesomeIcon className={clsx(classes.statusIcon, classes.error)}>
                      triangle-exclamation
                    </FontAwesomeIcon>
                  </div>
                  <Text variant="body2">{hypervisorCounts?.error}</Text>
                </div>
              </div>
            </div>
          </Card>
        )}
      </div>
      <div className={classes.quotasHeader}>
        <Text variant="subtitle2" className={classes.headerText}>
          {allTenants ? 'Resource Utilization' : 'Tenant Quota Usage'}
        </Text>
      </div>
      {allTenants ? (
        <HostsListPageHeader hosts={hosts} />
      ) : (
        <div className={classes.cards}>
          <Card className={classes.card}>
            <div className={classes.cardBody}>
              <div className={classes.cardHeader}>
                <div className={classes.iconContainer}>
                  <FontAwesomeIcon className={classes.icon} solid>
                    calculator
                  </FontAwesomeIcon>
                </div>
                <Text variant="subtitle2">Compute Quotas</Text>
              </div>
              <ProgressGraph
                label="Cores"
                usage={quotas?.cores?.in_use}
                capacity={quotas?.cores?.limit}
              />
              <ProgressGraph
                label="RAM"
                usage={quotas?.ram?.in_use}
                capacity={quotas?.ram?.limit}
                unit="MiB"
              />
              <ProgressGraph
                label="VMs"
                usage={quotas?.instances?.in_use}
                capacity={quotas?.instances?.limit}
              />
            </div>
          </Card>
          <Card className={classes.card}>
            <div className={classes.cardBody}>
              <div className={classes.cardHeader}>
                <div className={classes.iconContainer}>
                  <FontAwesomeIcon className={classes.icon} solid>
                    database
                  </FontAwesomeIcon>
                </div>
                <Text variant="subtitle2">Block Storage Quotas</Text>
              </div>
              <ProgressGraph
                label="Total Block Storage"
                usage={quotas?.gigabytes?.in_use}
                capacity={quotas?.gigabytes?.limit}
                unit="GB"
              />
              <ProgressGraph
                label="Volumes"
                usage={quotas?.volumes?.in_use}
                capacity={quotas?.volumes?.limit}
              />
              <ProgressGraph
                label="Volume Snapshots"
                usage={quotas?.snapshots?.in_use}
                capacity={quotas?.snapshots?.limit}
              />
            </div>
          </Card>
          <Card className={classes.card}>
            <div className={classes.cardBody}>
              <div className={classes.cardHeader}>
                <div className={classes.iconContainer}>
                  <FontAwesomeIcon className={classes.icon} solid>
                    server
                  </FontAwesomeIcon>
                </div>
                <Text variant="subtitle2">Network Quotas</Text>
              </div>
              <div className={classes.cardBody}>
                <ProgressGraph
                  label="Networks"
                  usage={quotas?.network?.used}
                  capacity={quotas?.network?.limit}
                />
                <ProgressGraph
                  label="Subnets"
                  usage={quotas?.subnet?.used}
                  capacity={quotas?.subnet?.limit}
                />
                <ProgressGraph
                  label="Routers"
                  usage={quotas?.router?.used}
                  capacity={quotas?.router?.limit}
                />
                <ProgressGraph
                  label="Ports"
                  usage={quotas?.port?.used}
                  capacity={quotas?.port?.limit}
                />
                <ProgressGraph
                  label="Public IPs"
                  usage={quotas?.floatingip?.used}
                  capacity={quotas?.floatingip?.limit}
                />
                <ProgressGraph
                  label="Security Groups"
                  usage={quotas?.security_group?.used}
                  capacity={quotas?.security_group?.limit}
                />
                <ProgressGraph
                  label="Security Group Rules"
                  usage={quotas?.security_group_rule?.used}
                  capacity={quotas?.security_group_rule?.limit}
                />
              </div>
            </div>
          </Card>
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  quotasHeader: {
    borderBottom: `1px solid ${theme.components.table.border}`,
    paddingBottom: 8,
    marginBottom: 12,
  },
  headerText: {
    textTransform: 'uppercase',
    color: theme.components.typography.passive,
  },
  cards: {
    display: 'flex',
    gap: 16,
    alignItems: 'flex-start',
  },
  card: {
    width: '100%',
    flexGrow: 1,
  },
  cardBody: {
    display: 'grid',
    gap: 12,
  },
  cardHeader: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    gridTemplateColumns: 'auto auto',
    gap: 8,
    marginBottom: 8,
  },
  iconContainer: {
    background: theme.palette.grey[100],
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    width: 32,
  },
  icon: {
    fontSize: '18px',
    color: theme.palette.grey[500],
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  statistic: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  resourceStatuses: {
    marginBottom: 24,
    display: 'flex',
    gap: 8,
  },
  statusHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  statusCard: {
    flexGrow: 1,
    width: '100%',
  },
  icons: {
    display: 'flex',
    gridTemplateColumns: 'max-content max-content max-content max-content max-content',
    gap: 16,
  },
  iconWithCount: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  success: {
    background: theme.palette.graphGreen[100],
    color: theme.palette.graphGreen[500],
  },
  warning: {
    background: theme.palette.graphYellow[100],
    color: theme.palette.graphYellow[500],
  },
  error: {
    background: theme.palette.graphRed[100],
    color: theme.palette.graphRed[500],
  },
  seeAll: {
    cursor: 'pointer',
  },
  arrow: {
    fontSize: 12,
  },
  statusIcon: {
    fontSize: '18px',
    // color: theme.palette.grey[500],
  },
  divider: {
    width: 1,
    height: 32,
    background: theme.components.card.text,
  },
}))

export default DashboardPage
