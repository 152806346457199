import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'

export const recordsSelector = createSharedSelector(
  getDataSelector<DataKeys.DnsRecords>(DataKeys.DnsRecords),
  (records) => {
    return records?.map((record) => ({
      ...record,
      name: record?.name,
      type: record?.type,
      zoneId: record?.zone_id,
      ttl: record?.ttl,
      statusDetails: {
        status: record?.status,
        action: record?.action,
      },
    }))
  },
)
