import React, { useMemo, useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import { listSecurityGroups, deleteSecurityGroup } from './actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { securityGroupsSelector } from './selectors'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import useReactRouter from 'use-react-router'
import { durationBetweenDates } from 'utils/misc'
import Text from 'core/elements/Text'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { routes } from 'core/utils/routes'
// import { INetworkDetailsPageTabs } from './network-details/model'
import CreateSecurityGroupModal from './CreateSecurityGroupModal'
import EditSecurityGroupModal from './EditSecurityGroupModal'
// import AssignFloatingIpDialog from './AssignFloatingIpDialog'
// import DetachFloatingIpDialog from './DetachFloatingIpDialog'
import useScopedPreferences from 'core/session/useScopedPreferences'
import DeleteSecurityGroupDialog from './DeleteSecurityGroupDialog'

type ModelDataKey = DataKeys.SecurityGroups
type SelectorModel = ArrayElement<ReturnType<typeof securityGroupsSelector>>
type ActionParams = InferActionParams<typeof listSecurityGroups>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'SecurityGroups',
  listTablePrefs,
)

const searchTargets = ['name', 'id', 'description']

const RulesCellComponent = ({ value, item }) => {
  return (
    <div>
      <Text variant="body2">Outbound Rules: {item.outboundRules?.length}</Text>
      <Text variant="body2">Inbound Rules: {item.inboundRules?.length}</Text>
    </div>
  )
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'id',
    label: 'UUID',
    display: false,
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'outboundRules',
    label: 'Rules',
    CellComponent: RulesCellComponent,
  },
]

export default function SecurityGroupsListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { location } = useReactRouter()
  const { prefs } = useScopedPreferences()
  const { currentTenant } = prefs

  const { message, loading, reload } = useListAction(listSecurityGroups, {
    params,
  })
  const data = useAppSelector(securityGroupsSelector)

  // const [selectedFloatingIp, setSelectedFloatingIp] = useState(null)
  // const [showAssignFloatingIpDialog, setShowAssignFloatingIpDialog] = useState(false)
  // const [showDetachFloatingIpDialog, setShowDetachFloatingIpDialog] = useState(false)

  // const batchActions: GridBatchActionSpec<SelectorModel>[] = [
  //   {
  //     cond: (floatingIps) => !floatingIps[0]?.port_id,
  //     icon: 'plus-square',
  //     label: 'Assign IP',
  //     handleAction: (floatingIps) => {
  //       setSelectedFloatingIp(floatingIps[0] ?? null)
  //       setShowAssignFloatingIpDialog(true)
  //     },
  //   },
  //   {
  //     cond: (floatingIps) => !!floatingIps[0]?.port_id,
  //     icon: 'minus-square',
  //     label: 'Detach IP',
  //     handleAction: (floatingIps) => {
  //       setSelectedFloatingIp(floatingIps[0] ?? null)
  //       setShowDetachFloatingIpDialog(true)
  //     },
  //   },
  // ]

  return (
    <>
      <DocumentMeta title="Security Groups" />
      <CreateSecurityGroupModal addRoute={routes.openstack.createSecurityGroup} />
      {/* {showAssignFloatingIpDialog && (
        <AssignFloatingIpDialog
          rows={[selectedFloatingIp]}
          onClose={() => setShowAssignFloatingIpDialog(false)}
        />
      )}
      {showDetachFloatingIpDialog && (
        <DetachFloatingIpDialog
          rows={[selectedFloatingIp]}
          onClose={() => setShowDetachFloatingIpDialog(false)}
        />
      )} */}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.SecurityGroups}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addUrl={routes.openstack.createSecurityGroup.path()}
        addText="Create Security Group"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteSecurityGroup}
        DeleteDialogComponent={DeleteSecurityGroupDialog}
        // batchActions={batchActions}
        EditDialogComponent={EditSecurityGroupModal}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
