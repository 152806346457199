import React, { useCallback, useState } from 'react'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteMember } from './actions'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import Modal from 'core/elements/modal'

export default function DeleteMemberDialog({ rows, onClose }) {
  const [error, setError] = useState(null)
  const { update, updating } = useUpdateAction(deleteMember)

  const memberNames = rows?.map((member) => member?.name).join(', ')

  const handleDelete = useCallback(async () => {
    const promises = rows.map(({ poolId, id }) => update({ poolId, id }))
    await Promise.all(promises)
      .then(() => {
        onClose(true)
      })
      .catch((err) => {
        console.error(err)
        setError({ title: 'Error Deleting Member', message: err?.message })
      })
  }, [rows, update, onClose, setError])

  return (
    <Modal
      panel="dialog"
      title="Delete Member"
      onClose={onClose}
      open
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={updating}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={updating}>
            Confirm
          </Button>
        </>
      }
    >
      <>
        <Text variant="body2">
          Are you sure you want to delete this Member - {memberNames} ? This action is irreversible
          and may impact traffic distribution within the Pool.
        </Text>
        {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
      </>
    </Modal>
  )
}
