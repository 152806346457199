import { useAppSelector } from 'app/store'
import useListAction from 'core/hooks/useListAction'
import { IClusterStatus } from 'k8s/components/dashboard/model'
import {
  listLoadBalancers,
  listOctaviaHealthMonitors,
  listOctaviaListener,
  listOctaviaPoolMembers,
  listOctaviaPools,
} from '../actions'
import {
  loadBalancersSelector,
  octaviaHealthMonitorsSelector,
  octaviaListenersSelector,
  octaviaPoolMembersSelector,
  octaviaPoolsSelector,
} from '../selectors'

interface Props {}

export default function useLoadBalancerGuidedTourDetails() {
  const { reload: reloadLoadBalancer } = useListAction(listLoadBalancers)
  const { reload: reloadOctaviaListener } = useListAction(listOctaviaListener)
  const { reload: reloadOctaviaPools } = useListAction(listOctaviaPools)
  const { reload: reloadOctaviaPoolMembers } = useListAction(listOctaviaPoolMembers)
  const { reload: reloadOctaviaHealthMonitors } = useListAction(listOctaviaHealthMonitors)
  const loadBalancer = useAppSelector(loadBalancersSelector)
  const octaviaListener = useAppSelector(octaviaListenersSelector)
  const octaviaPools = useAppSelector(octaviaPoolsSelector)
  const octaviaPoolMembers = useAppSelector(octaviaPoolMembersSelector)
  const octaviaHealthMonitors = useAppSelector(octaviaHealthMonitorsSelector)
  const getStatusDetails = () => {
    if (!loadBalancer?.length) {
      return {
        step: 1,
        title: 'Create your first load balancer.',
        description:
          'Start by creating the load balancer. Since a listener must be attached to a load balancer, this step must be completed first.',
        error: null,
        subtitles: [{ label: 'Completed', value: '0 of 4 steps' }],
      }
    }

    if (!octaviaListener?.length) {
      return {
        step: 2,
        title: 'Create your first listener.',
        description:
          'Next, create a listener to handle incoming traffic. A pool must be linked to the listener, so this step must be completed before setting up a pool.',
        error: null,
        subtitles: [{ label: 'Completed', value: '1 of 4 steps' }],
      }
    }

    if (!octaviaPools?.length) {
      return {
        step: 3,
        title: 'Create your first pool.',
        description:
          'Now, configure a pool to manage traffic distribution. A member and health monitor will be associated with the pool, so this step should be completed first.',
        error: null,
        subtitles: [{ label: 'Completed', value: '2 of 4 steps' }],
      }
    }

    if (!octaviaPoolMembers?.length && !octaviaHealthMonitors?.length) {
      return {
        step: 4,
        title: 'Create your first member and health monitor.',
        description:
          'Add backend members (Virtual Machines) to the pool. These members will receive traffic based on the load balancing algorithm.',
        error: null,
        subtitles: [
          { label: 'Completed', value: '3 of 4 steps' },
          { label: 'Member', value: '0/1' },
          { label: 'Health Monitor', value: '0/1' },
        ],
      }
    }

    if (octaviaPoolMembers?.length && !octaviaHealthMonitors?.length) {
      return {
        step: 4,
        title: 'Create your first member and health monitor.',
        description: `Configure a health monitor to periodically check the status of pool members. This ensures that traffic is only routed to healthy members.`,
        error: null,
        subtitles: [
          { label: 'Completed', value: '3 of 4 steps' },
          {
            label: 'Member',
            value: `${octaviaPoolMembers?.length}/${octaviaPoolMembers?.length}`,
          },
          { label: 'Health Monitor', value: '0/1' },
        ],
      }
    }

    if (!octaviaPoolMembers?.length && octaviaHealthMonitors?.length) {
      return {
        step: 4,
        title: 'Create your first member and health monitor.',
        description: `Add backend members (Virtual Machines) to the pool. These members will receive traffic based on the load balancing algorithm.`,
        error: null,
        subtitles: [
          { label: 'Completed', value: '3 of 4 steps' },
          {
            label: 'Member',
            value: '0/1',
          },
          {
            label: 'Health Monitor',
            value: `${octaviaHealthMonitors?.length}/${octaviaHealthMonitors?.length}`,
          },
        ],
      }
    }

    return {
      title: 'Congratulations! The load balancer has been successfully created.',
      status: 'ok' as IClusterStatus,
      error: null,
      subtitles: [{ label: 'Completed', value: '4 of 4 steps' }],
    }
  }
  const updateStepDetails = () => {
    let stepDetails = [
      { stepId: '1', status: 'notStarted', subSteps: [] },
      { stepId: '2', status: 'notStarted', subSteps: [] },
      { stepId: '3', status: 'notStarted', subSteps: [] },
      { stepId: '4', status: 'notStarted', subSteps: [] },
    ]

    if (!loadBalancer?.length) {
      stepDetails[0].status = 'inProgress'
      return stepDetails
    }
    stepDetails[0].status = 'success'

    if (!octaviaListener?.length) {
      stepDetails[1].status = 'inProgress'
      return stepDetails
    }
    stepDetails[1].status = 'success'

    if (!octaviaPools?.length) {
      stepDetails[2].status = 'inProgress'
      return stepDetails
    }
    stepDetails[2].status = 'success'

    if (!octaviaPoolMembers?.length && !octaviaHealthMonitors?.length) {
      stepDetails[3].status = 'inProgress'
      return stepDetails
    }

    if (octaviaPoolMembers?.length && !octaviaHealthMonitors?.length) {
      stepDetails[3].status = 'inProgress'
      return stepDetails
    }
    if (!octaviaPoolMembers?.length && octaviaHealthMonitors?.length) {
      stepDetails[3].status = 'inProgress'
      return stepDetails
    }

    stepDetails[3].status = 'success'
    return stepDetails
  }
  const statusDetails = getStatusDetails()
  const stepDetails = updateStepDetails()

  return { statusDetails, stepDetails }
}
