import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

export enum NetworkTypes {
  FLAT = 'flat',
  VLAN = 'vlan',
}

const options = [
  { label: 'Flat (Untagged)', value: 'flat' },
  { label: 'VLAN (Tagged)', value: 'vlan' },
]

interface Props {
  noVxlan?: boolean
}

export default function PhysicalNetworkTypePicklist({
  name = 'networkType',
  label = 'Network Type',
  compact = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown compact={compact} name={name} label={label} items={options} {...rest} />
}
