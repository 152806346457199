import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DeleteAction from 'core/actions/DeleteAction'
// import CustomAction from 'core/actions/CustomAction'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import store from 'app/store'
import { someAsync } from 'utils/async'
import { flatten } from 'ramda'

const { dispatch } = store
const { neutron, octavia } = ApiClient.getInstance()

export const networkActions = ActionsSet.make<DataKeys.OpenstackNetworks>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.OpenstackNetworks,
  cacheKey: DataKeys.OpenstackNetworks,
})

export const listNetworks = networkActions.add(
  new ListAction<DataKeys.OpenstackNetworks>(async () => {
    return neutron.getNetworks()
  })
    .addDependency(DataKeys.OpenstackSubnets)
    .addDependency(DataKeys.NetworkIpAvailability)
    .addDependency(DataKeys.OpenstackPorts),
  // .addDependency(DataKeys.ManagementTenants),
)

export const createNetwork = networkActions.add(
  new CreateAction<DataKeys.OpenstackNetworks, { body }>(async ({ body }) => {
    const created = await neutron.createNetwork(body)
    const networkId = created.id
    const newNetwork = await neutron.getNetwork(networkId)
    return newNetwork
  }),
)

export const updateNetwork = networkActions.add(
  new UpdateAction<
    DataKeys.OpenstackNetworks,
    {
      id: string
      body: unknown
    }
  >(async ({ id, body }) => {
    const updatedNetwork = await neutron.updateNetwork({ id, body })
    return updatedNetwork
  }),
)

export const deleteNetwork = networkActions.add(
  new DeleteAction<DataKeys.OpenstackNetworks, { id: string }>(async ({ id }) => {
    await neutron.deleteNetwork(id)
  }),
)

export const subnetActions = ActionsSet.make<DataKeys.OpenstackSubnets>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.OpenstackSubnets,
  cacheKey: DataKeys.OpenstackSubnets,
})

export const listSubnets = subnetActions.add(
  new ListAction<DataKeys.OpenstackSubnets>(async () => {
    return neutron.getSubnets()
  }),
)

export const createSubnet = subnetActions.add(
  new CreateAction<DataKeys.OpenstackSubnets, { body }>(async ({ body }) => {
    const created = await neutron.createSubnet(body)
    const subnetId = created.id
    const newSubnet = await neutron.getSubnet(subnetId)
    return newSubnet
  }),
)

export const updateSubnet = subnetActions.add(
  new UpdateAction<
    DataKeys.OpenstackSubnets,
    {
      id: string
      body: unknown
    }
  >(async ({ id, body }) => {
    const updatedSubnet = await neutron.updateSubnet({ id, body })
    return updatedSubnet
  }),
)

export const deleteSubnet = subnetActions.add(
  new DeleteAction<DataKeys.OpenstackSubnets, { id: string }>(async ({ id }) => {
    await neutron.deleteSubnet(id)
  }),
)

export const networkAvailabilityActions = ActionsSet.make<DataKeys.NetworkIpAvailability>({
  uniqueIdentifier: 'network_id',
  entityName: entityNamesByKey.NetworkIpAvailability,
  cacheKey: DataKeys.NetworkIpAvailability,
})

export const listNetworkAvailability = networkAvailabilityActions.add(
  new ListAction<DataKeys.NetworkIpAvailability>(async () => {
    return neutron.getNetworkIpAvailability()
  }),
)

export const portActions = ActionsSet.make<DataKeys.OpenstackPorts>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.OpenstackPorts,
  cacheKey: DataKeys.OpenstackPorts,
})

export const listPorts = portActions.add(
  new ListAction<DataKeys.OpenstackPorts>(async () => {
    return neutron.getPorts()
  }).addDependency(DataKeys.SecurityGroups),
)

export const createPort = portActions.add(
  new CreateAction<DataKeys.OpenstackPorts, { body }>(async ({ body }) => {
    const created = await neutron.createPort(body)
    const portId = created.id
    const newPort = await neutron.getPort(portId)
    return newPort
  }),
)

export const updatePort = portActions.add(
  new UpdateAction<
    DataKeys.OpenstackPorts,
    {
      id: string
      body: unknown
    }
  >(async ({ id, body }) => {
    const updatedPort = await neutron.updatePort({ id, body })
    return updatedPort
  }),
)

export const deletePort = portActions.add(
  new DeleteAction<DataKeys.OpenstackPorts, { id: string }>(async ({ id }) => {
    await neutron.deletePort(id)
  }),
)

export const checkNetworksApi = async () => {
  return neutron.getApis()
}

export const loadBalancerActions = ActionsSet.make<DataKeys.LoadBalancers>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.LoadBalancers,
  cacheKey: DataKeys.LoadBalancers,
})

export const listLoadBalancers = loadBalancerActions.add(
  new ListAction<DataKeys.LoadBalancers>(async () => {
    return octavia.getLoadBalancers()
  }),
  // .addDependency(DataKeys.OpenstackSubnets)
)

export const createLoadBalancer = loadBalancerActions.add(
  new CreateAction<DataKeys.LoadBalancers, { body }>(async ({ body }) => {
    const created = await octavia.createLoadBalancer(body)
    const loadBalancerId = created.id
    const newLoadBalancer = await octavia.getLoadBalancer(loadBalancerId)
    return newLoadBalancer
  }),
)

export const updateLoadBalancer = loadBalancerActions.add(
  new UpdateAction<
    DataKeys.LoadBalancers,
    {
      id: string
      body: unknown
    }
  >(async ({ id, body }) => {
    const updatedLoadBalancer = await octavia.updateLoadBalancer({ id, body })
    return updatedLoadBalancer
  }),
)

export const deleteLoadBalancer = loadBalancerActions.add(
  new DeleteAction<DataKeys.LoadBalancers, { id: string }>(async ({ id }) => {
    await octavia.deleteLoadBalancer(id)
  }),
)

export const octaviaFlavorsActions = ActionsSet.make<DataKeys.OctaviaFlavors>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.LoadBalancers,
  cacheKey: DataKeys.OctaviaFlavors,
})

export const listOctaviaFlavors = octaviaFlavorsActions.add(
  new ListAction<DataKeys.OctaviaFlavors>(async () => {
    return octavia.getOctaviaFlavors()
  }),
)

export const listenerActions = ActionsSet.make<DataKeys.OctaviaListeners>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.OctaviaListeners,
  cacheKey: DataKeys.OctaviaListeners,
})

export const listOctaviaListener = listenerActions.add(
  new ListAction<DataKeys.OctaviaListeners>(async () => {
    return octavia.getListeners()
  }),
)

export const createListener = listenerActions.add(
  new CreateAction<DataKeys.OctaviaListeners, { body }>(async ({ body }) => {
    const created = await octavia.createListener(body)
    const listenerId = created.id
    return await octavia.getListener(listenerId)
  }),
)

export const deleteListener = listenerActions.add(
  new DeleteAction<DataKeys.OctaviaListeners, { id: string }>(async ({ id }) => {
    await octavia.deleteListener(id)
  }),
)

export const poolActions = ActionsSet.make<DataKeys.OctaviaPools>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.OctaviaPools,
  cacheKey: DataKeys.OctaviaPools,
})

export const listOctaviaPools = poolActions.add(
  new ListAction<DataKeys.OctaviaPools>(async () => {
    return octavia.getPools()
  }),
)

export const createPool = poolActions.add(
  new CreateAction<DataKeys.OctaviaPools, { body }>(async ({ body }) => {
    const created = await octavia.createPool(body)
    const poolId = created.id
    return await octavia.getPool(poolId)
  }),
)

export const deletePools = poolActions.add(
  new DeleteAction<DataKeys.OctaviaPools, { id: string }>(async ({ id }) => {
    await octavia.deletePools(id)
  }),
)

export const poolMembersActions = ActionsSet.make<DataKeys.OctaviaPoolMembers>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.OctaviaPoolMembers,
  cacheKey: DataKeys.OctaviaPoolMembers,
})

export const listOctaviaPoolMembers = poolMembersActions.add(
  new ListAction<DataKeys.OctaviaPoolMembers, { poolIds: string[] }>(async ({ poolIds }) => {
    return someAsync(poolIds.map((poolId) => octavia.getPoolMembers(poolId))).then(flatten)
  }),
)

export const addMemberToPool = poolMembersActions.add(
  new CreateAction<DataKeys.OctaviaPoolMembers, { poolId: string; body }>(
    async ({ poolId, body }) => {
      const created = await octavia.addMemberToPool(poolId, body)
      return await octavia.getPoolMember(poolId, created.id)
    },
  ),
)

export const deleteMember = poolMembersActions.add(
  new DeleteAction<DataKeys.OctaviaPoolMembers, { poolId: string; id: string }>(
    async ({ poolId, id }) => {
      await octavia.deleteMember(poolId, id)
    },
  ),
)

export const healthMonitorsActions = ActionsSet.make<DataKeys.OctaviaHealthMonitors>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.OctaviaHealthMonitors,
  cacheKey: DataKeys.OctaviaHealthMonitors,
})

export const listOctaviaHealthMonitors = healthMonitorsActions.add(
  new ListAction<DataKeys.OctaviaHealthMonitors>(async () => {
    return octavia.getHealthMonitors()
  }),
)

export const createHealthMonitor = healthMonitorsActions.add(
  new CreateAction<DataKeys.OctaviaHealthMonitors, { body }>(async ({ body }) => {
    const created = await octavia.createHealthMonitor(body)
    const healthMonitorId = created.id
    return await octavia.getHealthMonitor(healthMonitorId)
  }),
)

export const deleteHealthMonitor = healthMonitorsActions.add(
  new DeleteAction<DataKeys.OctaviaHealthMonitors, { id: string }>(async ({ id }) => {
    await octavia.deleteHealthMonitor(id)
  }),
)
