import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { flavorsByIdSelector } from '../flavors/selectors'
import { imagesByIdSelector } from '../images/selectors'
import { basicUsersByIdSelector } from 'account/components/userManagement/users/selectors'
import { emptyObj } from 'utils/fp'
import { AppSelector } from 'app/store'
import { IVirtualMachinesSelector } from './model'
import { serverGroupsByInstanceIdSelector } from './server-groups/selectors'
import {
  allTenantsSelector,
  userTenantsSelector,
} from 'app/plugins/account/components/userManagement/tenants/selectors'
import store from 'app/store'
import { migrationsSelector } from './migrations/selectors'

// Can't import this from volume selectors due to circular dependency
const rawVolumesByIdSelector = createSharedSelector(
  getDataSelector<DataKeys.AllOpenstackVolumes>(DataKeys.AllOpenstackVolumes),
  (volumes) => {
    return volumes.reduce((accum, volume) => {
      return {
        ...accum,
        [volume.id]: volume,
      }
    }, {})
  },
)

export const virtualMachinesSelector: AppSelector<IVirtualMachinesSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.OpenstackVirtualMachines>(DataKeys.OpenstackVirtualMachines),
  flavorsByIdSelector,
  imagesByIdSelector,
  basicUsersByIdSelector,
  rawVolumesByIdSelector,
  serverGroupsByInstanceIdSelector,
  allTenantsSelector,
  userTenantsSelector,
  migrationsSelector,
  (
    vms,
    flavorsById,
    imagesById,
    usersById,
    volumesById,
    serverGroupsByInstanceId,
    allTenants,
    userTenants,
    migrations,
  ) => {
    const { session } = store.getState()
    const currentUserId = session?.userDetails?.id
    const currentUserName = session?.userDetails?.displayName
    return vms.map((vm) => {
      const flavorId = vm?.flavor?.id
      const imageId = vm?.image?.id
      const user = usersById[vm?.user_id]
      const volumeIds = vm?.['os-extended-volumes:volumes_attached']?.map((vol) => vol.id) || []
      const volumeDetails = volumeIds.reduce((accum, id) => {
        return {
          ...accum,
          [id]: volumesById[id],
        }
      }, {})
      const volumeNames = volumeIds.map((id) => {
        return volumesById[id]?.name || id
      })
      const migrationAttemptedFrom = migrations?.lastMigrationByVmId?.[vm.id]?.startHost
      const migrationDestination = migrations?.lastMigrationByVmId?.[vm.id]?.endHost
      return {
        ...vm,
        flavorDetails: flavorsById[flavorId],
        flavorName: flavorsById[flavorId]?.name || 'Unknown',
        taskState: vm['OS-EXT-STS:task_state'],
        state: vm['OS-EXT-STS:vm_state'],
        pausedPowerState:
          vm['OS-EXT-STS:vm_state'] === 'active' && vm['OS-EXT-STS:power_state'] === 3,
        imageDetails: imagesById[imageId],
        imageName:
          imagesById[imageId]?.name ||
          imageId ||
          (volumeIds?.length ? 'None (Bootable Volume Used)' : 'Unknown'),
        ownerName: user?.displayname
          ? `${user?.email || vm?.user_id} (${user.displayname})`
          : user?.email || vm?.user_id,
        volumeDetails,
        volumeNames,
        volumeIds,
        serverGroup: serverGroupsByInstanceId?.[vm.id],
        tenantName: [...userTenants, ...allTenants]?.find((tenant) => tenant.id === vm?.tenant_id)
          ?.name,
        migrationAttemptedFrom,
        migrationDestination,
        securityGroupNames: vm?.security_groups?.map((group) => group?.name),
      }
    })
  },
)

export const allVirtualMachinesSelector: AppSelector<IVirtualMachinesSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.AllOpenstackVirtualMachines>(DataKeys.AllOpenstackVirtualMachines),
  flavorsByIdSelector,
  imagesByIdSelector,
  basicUsersByIdSelector,
  rawVolumesByIdSelector,
  allTenantsSelector,
  userTenantsSelector,
  migrationsSelector,
  (vms, flavorsById, imagesById, usersById, volumesById, allTenants, userTenants, migrations) => {
    return vms.map((vm) => {
      const flavorId = vm?.flavor?.id
      const imageId = vm?.image?.id
      const user = usersById[vm?.user_id]
      const volumeIds = vm?.['os-extended-volumes:volumes_attached']?.map((vol) => vol.id) || []
      const volumeDetails = volumeIds.reduce((accum, id) => {
        return {
          ...accum,
          [id]: volumesById[id],
        }
      }, {})
      const volumeNames = volumeIds.map((id) => {
        return volumesById[id]?.name || id
      })
      const migrationAttemptedFrom = migrations?.lastMigrationByVmId?.[vm.id]?.startHost
      const migrationDestination = migrations?.lastMigrationByVmId?.[vm.id]?.endHost
      return {
        ...vm,
        flavorDetails: flavorsById[flavorId],
        flavorName: flavorsById[flavorId]?.name || 'Unknown',
        taskState: vm['OS-EXT-STS:task_state'],
        state: vm['OS-EXT-STS:vm_state'],
        pausedPowerState:
          vm['OS-EXT-STS:vm_state'] === 'active' && vm['OS-EXT-STS:power_state'] === 3,
        imageDetails: imagesById[imageId],
        imageName: imagesById[imageId]?.name || 'Unknown',
        ownerName: user?.displayname
          ? `${user?.email || vm?.user_id} (${user.displayname})`
          : user?.email || vm?.user_id,
        volumeDetails,
        volumeNames,
        tenantName: [...userTenants, ...allTenants]?.find((tenant) => tenant.id === vm?.tenant_id)
          ?.name,
        migrationAttemptedFrom,
        migrationDestination,
        securityGroupNames: vm?.security_groups?.map((group) => group?.name),
      }
    })
  },
)

export const virtualMachinesByIdSelector = createSharedSelector(virtualMachinesSelector, (vms) =>
  vms.reduce((accum, vm) => {
    return {
      ...accum,
      [vm.id]: vm,
    }
  }, emptyObj),
)

export const virtualMachinesByHostSelector = createSharedSelector(virtualMachinesSelector, (vms) =>
  vms.reduce((accum, vm) => {
    const hostId = vm?.['OS-EXT-SRV-ATTR:host']
    return {
      ...accum,
      [hostId]: accum[hostId] ? [...accum[hostId], vm] : [vm],
    }
  }, emptyObj),
)

export const virtualMachinesByIpSelector = createSharedSelector(virtualMachinesSelector, (vms) =>
  vms.reduce((accum, vm) => {
    const vmAddresses =
      Object.entries(vm?.addresses)?.reduce((accum, [name, addresses]) => {
        const addressesIps = addresses?.reduce((accum, address) => {
          return {
            ...accum,
            [address?.addr]: vm,
          }
        }, {})
        return {
          ...accum,
          ...addressesIps,
        }
      }, {}) || {}
    return {
      ...accum,
      ...vmAddresses,
    }
  }, emptyObj),
)
