import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'

const { designate } = ApiClient.getInstance()

export const zoneActions = ActionsSet.make<DataKeys.DnsZones>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey[DataKeys.DnsZones],
  cacheKey: DataKeys.DnsZones,
})

export const listZones = zoneActions.add(
  new ListAction<DataKeys.DnsZones>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get DNS Zones')
    return designate.getZones()
  }),
)

export const createZone = zoneActions.add(
  new CreateAction<DataKeys.DnsZones>(async (body) => {
    Bugsnag.leaveBreadcrumb('Attempting to create DNS Zone')
    return designate.createZone(body)
  }),
)

export const updateZone = zoneActions.add(
  new UpdateAction<DataKeys.DnsZones>(async (params) => {
    const { zoneId, body } = params
    Bugsnag.leaveBreadcrumb('Attempting to update DNS Zone')
    return designate.updateZone(zoneId, body)
  }),
)

export const deleteZone = zoneActions.add(
  new DeleteAction<DataKeys.DnsZones>(async ({ zoneId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete DNS Zone')
    return designate.deleteZone({ zoneId })
  }),
)
