import { makeStyles } from '@material-ui/styles'
import ExternalLink from 'core/components/ExternalLink'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { Route } from 'core/plugins/route'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import { loadBalancerHelpLink } from 'k8s/links'
import PicklistField from 'pf9-ui-components/built/components/validatedForm/DropdownField'
import TextField from 'pf9-ui-components/built/components/validatedForm/TextField'
import { middleLeft } from 'pf9-ui-components/built/elements/menu/defaults'
import ModalForm from 'pf9-ui-components/built/elements/modal/ModalForm'
import Text from 'pf9-ui-components/built/elements/Text'
import React, { useCallback, useState } from 'react'
import useReactRouter from 'use-react-router'
import { createLoadBalancer, listLoadBalancers, listSubnets } from './actions'
import { getLoadBalancerRequestBody } from './helpers'
import { ILoadBalancerDetailsPageTabs } from './model'
import SubnetPicklist from './network-details/SubnetPicklist'

const useStyles = makeStyles<Theme>(() => ({
  formFieldSection: {
    marginBottom: '32px',
    border: 'none',
    padding: 0,
  },
  pageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'grid',
    gap: 16,
    gridAutoRows: 'max-content',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  iconWrapper: {
    display: 'flex',
    height: '32px',
    width: '32px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  customFormFieldSection: {
    '& .content': {
      marginLeft: '0px',
    },
  },
}))

interface Props {
  addRoute: Route
}

export default function CreateLoadBalancerModal({ addRoute }: Props) {
  const { history } = useReactRouter()

  const imagePath = '/ui/images/load-balancer.svg'
  const classes = useStyles()
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)
  const defaultParams = {
    name: '',
    vipSubnetId: '',
    provider: 'ovn',
  }
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { reload } = useListAction(listLoadBalancers, {})
  const { reload: reloadSubnets } = useListAction(listSubnets, {})
  const { update, updating, error, reset } = useUpdateAction(createLoadBalancer)

  const createLoadBalancerFn = async (params) => {
    const body = getLoadBalancerRequestBody(params)
    const { success, response } = await update({ body })
    return { success, response }
  }

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(
      routes.openstack.loadBalancers.details.path({
        tab: ILoadBalancerDetailsPageTabs.LoadBalancers,
      }),
    )
  }

  const submitForm = useCallback(async () => {
    setAttemptedSubmit(true)

    const { success } = await createLoadBalancerFn(params)
    if (success) {
      handleClose()
      reload()
    }
    setAttemptedSubmit(false)
  }, [params, createLoadBalancerFn, handleClose, reload])

  return (
    <ModalForm
      route={addRoute}
      title={`Create Load Balancer`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating || attemptedSubmit}
      error={error}
      submitTitle={`Create Load Balancer`}
      maxWidth={470}
    >
      <>
        <div className={classes.formFieldSection}>
          <div className={classes.contentContainer}>
            <img className={classes.iconWrapper} alt="Load Balancer" src={imagePath} />
            <div className={classes.content}>
              <Text
                variant="body2"
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                Create a <ExternalLink url={loadBalancerHelpLink}>load balancer</ExternalLink> with
                a virtual IP (VIP) in the specified subnet. After creating the load balancer, you
                will be able to create one or more listeners for the load balancer.
              </Text>
            </div>
          </div>
        </div>
        <FormFieldSection
          className={classes.customFormFieldSection}
          title="Load Balancer Configuration"
        >
          <TextField
            id="name"
            label="Load Balancer Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            required
            enterKeyHint={undefined}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
            crossOrigin={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
          <PicklistField
            DropdownComponent={SubnetPicklist}
            name="subnet"
            id={`fixedIps.{idx}.subnet`}
            label="Subnet"
            compact={false}
            onChange={getParamsUpdater('vipSubnetId')}
            info="The subnet where your load balancer will be accessible."
            align={middleLeft.align}
            required
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
