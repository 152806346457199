import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Text from 'core/elements/Text'
import ModalForm from 'core/elements/modal/ModalForm'
import {
  getVmsForHost,
  deauthorizeHypervisorRole,
  deauthorizeImageLibraryRole,
  deauthorizePersistentStorageRole,
  removeHostRole,
  removeDns,
} from '../actions'
import { listImages } from 'openstack/components/images/actions'
import { imagesSelector } from 'openstack/components/images/selectors'
import useListAction from 'core/hooks/useListAction'
import { useAppSelector } from 'app/store'
import { listAllVolumes } from 'openstack/components/storage/volumes/actions'
import { allVolumesSelector } from 'openstack/components/storage/volumes/selectors'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'grid',
    gap: 12,
  },
}))

export default function DeauthorizeRolesDialog({ rows: [host], onClose }) {
  let loadingVolumes
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [hasVms, setHasVms] = useState(false)

  const { loading: loadingImages } = useListAction(listImages)
  const imagesList = useAppSelector(imagesSelector)
  if (host?.roles?.includes('pf9-cindervolume-config')) {
    const { loading: _loading } = useListAction(listAllVolumes)
    loadingVolumes = _loading
  } else {
    loadingVolumes = false
  }
  const volumesList = useAppSelector(allVolumesSelector)

  useEffect(() => {
    const makeCalls = async () => {
      if (host.roles.includes('pf9-ostackhost-neutron')) {
        const vms = await getVmsForHost(host?.id)
        setHasVms(!!vms?.length)
      }
      setLoading(false)
    }
    setLoading(true)
    makeCalls()
  }, [host])

  const hostRoles = useMemo(() => {
    const v2Hypervisor = host?.roles?.includes('pf9-ostackhost-neutron')
    const v2ImageLibrary = host?.roles?.includes('pf9-glance-role')
    const v2PersistentStorage = host?.roles?.includes('pf9-cindervolume-config')
    const v2Dns = host?.roles?.includes('pf9-designate')
    return {
      v2: {
        hypervisor: v2Hypervisor,
        imageLibrary: v2ImageLibrary,
        persistentStorage: v2PersistentStorage,
        dns: v2Dns,
      },
      v1: host?.roles?.filter((role) => {
        if (v2Hypervisor) {
          if (
            [
              'pf9-ostackhost-neutron',
              'pf9-neutron-base',
              'pf9-neutron-ovn-controller',
              'pf9-neutron-ovn-metadata-agent',
              'pf9-ceilometer',
            ].includes(role)
          ) {
            return false
          }
        } else if (v2ImageLibrary) {
          if (role === 'pf9-glance-role') {
            return false
          }
        } else if (v2PersistentStorage) {
          if (['pf9-cindervolume-base', 'pf9-cindervolume-config'].includes(role)) {
            return false
          }
        } else if (v2Dns) {
          if (role === 'pf9-designate') {
            return false
          }
        }
        return true
      }),
    }
  }, [host])

  const disallowHypervisorRoleDeauth = hasVms
  const disallowImageLibraryDeauth = !!imagesList?.length
  const disallowPersistentStorageDeauth = useMemo(() => {
    const hasVolume = volumesList?.some((volume) =>
      volume['os-vol-host-attr:host'].includes(host.id),
    )
    return hasVolume
  }, [volumesList])

  const handleSubmit = useCallback(async () => {
    setSubmitting(true)
    if (hostRoles?.v2?.hypervisor && !disallowHypervisorRoleDeauth) {
      await deauthorizeHypervisorRole({ host })
    }
    if (hostRoles?.v2?.imageLibrary && !disallowImageLibraryDeauth) {
      await deauthorizeImageLibraryRole({ host })
    }
    if (hostRoles?.v2?.persistentStorage && !disallowPersistentStorageDeauth) {
      await deauthorizePersistentStorageRole({ host })
    }
    if (hostRoles?.v2?.dns) {
      await removeDns({ hostId: host.id })
    }
    if (hostRoles?.v1?.length) {
      await Promise.allSettled(
        hostRoles.v1.map((role) => {
          return removeHostRole({ hostId: host.id, role })
        }),
      )
    }
    handleClose()
  }, [
    host,
    disallowHypervisorRoleDeauth,
    disallowImageLibraryDeauth,
    disallowPersistentStorageDeauth,
    hostRoles,
  ])

  const handleClose = () => {
    setSubmitting(false)
    onClose()
  }

  return (
    <ModalForm
      title="Remove All Roles"
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={loading || loadingImages || loadingVolumes}
      submitting={submitting}
      // error={error}
      submitTitle={`Confirm`}
      panel="dialog"
      open
    >
      <div className={classes.container}>
        <Text variant="body2">
          Remove all assigned roles and associated packages currently eligible for removal on host{' '}
          <b>{host?.name}</b>.
        </Text>
        {disallowHypervisorRoleDeauth && hostRoles?.v2?.hypervisor && (
          <Text variant="caption1">
            <FontAwesomeIcon>circle-exclamation</FontAwesomeIcon>
            {'  '}VMs are present on this host, so hypervisor related packages will not be removed.
          </Text>
        )}
        {disallowImageLibraryDeauth && hostRoles?.v2?.imageLibrary && (
          <Text variant="caption1">
            <FontAwesomeIcon>circle-exclamation</FontAwesomeIcon>
            {'  '}Images are present on this host, so image library related packages will not be
            removed.
          </Text>
        )}
        {disallowPersistentStorageDeauth && hostRoles?.v2?.persistentStorage && (
          <Text variant="caption1">
            <FontAwesomeIcon>circle-exclamation</FontAwesomeIcon>
            {'  '}Volumes are present on this host, so persistent storage related packages will not
            be removed.
          </Text>
        )}
      </div>
    </ModalForm>
  )
}
