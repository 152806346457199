import { Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { middleLeft } from 'core/elements/menu/defaults'
import Text from 'core/elements/Text'
import Tooltip from 'core/elements/tooltip/Tooltip'
import Theme from 'core/themes/model'
import React from 'react'
import useReactRouter from 'use-react-router'

const ActionPopOver = ({
  open,
  setOpen,
  anchorEl,
  editRoute,
  disableEditCond,
  disableDeleteCond,
  setShowDeleteDialog,
}) => {
  const classes = useStyles({ disableEditCond, disableDeleteCond })
  const { history } = useReactRouter()
  const popoverRef = React.useRef(null)

  return (
    <Popover
      open={open}
      onClose={() => setOpen(false)}
      anchorEl={anchorEl}
      ref={popoverRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className={classes.settingsMenuContainer}>
        <Tooltip
          message={disableEditCond ? 'NS and SOA type records cannot be edited.' : null}
          align={middleLeft.align}
        >
          <Text
            variant="body2"
            className={clsx(classes.settingsMenuItem, classes.editButton)}
            onClick={() => history.push(editRoute)}
          >
            <FontAwesomeIcon size="md">edit</FontAwesomeIcon>
            Edit
          </Text>
        </Tooltip>
        <Tooltip
          message={disableEditCond ? 'NS and SOA type records cannot be deleted.' : null}
          align={middleLeft.align}
        >
          <Text
            variant="body2"
            className={clsx(classes.settingsMenuItem, classes.deleteButton)}
            onClick={() => {
              setOpen(false)
              setShowDeleteDialog(true)
            }}
          >
            <FontAwesomeIcon size="md">trash-can</FontAwesomeIcon>
            Delete
          </Text>
        </Tooltip>
      </div>
    </Popover>
  )
}

export default ActionPopOver

interface StyleProps {
  disableEditCond?: boolean
  disableDeleteCond?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  cardContainer: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    width: '325px',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 4,
    padding: theme.spacing(3),
    gap: theme.spacing(3),
    background: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  nameText: {
    marginTop: theme.spacing(2),
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.grey[200]}`,
    width: theme.spacing(4),
    height: theme.spacing(4),
    rotate: '90deg',
    '& i': {
      color: theme.palette.grey[500],
    },
    cursor: 'pointer',
  },
  propertiesTable: {
    '& .properties-table': {
      '& .property-row': {
        gridTemplateColumns: '1fr 1fr',
      },
    },
  },
  settingsMenuButton: {
    '& i': {
      rotate: '90deg',
    },
  },
  settingsMenuContainer: {
    padding: theme.spacing(1, 0),
    width: '154px',
  },
  settingsMenuItem: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    alignItems: 'center',
    gap: 6,
    padding: theme.spacing(1, 2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  editButton: {
    cursor: ({ disableEditCond }) => (disableEditCond ? 'not-allowed' : 'pointer'),
    pointerEvents: ({ disableEditCond }) => (disableEditCond ? 'none' : 'unset'),
    color: ({ disableEditCond }) => (disableEditCond ? theme.palette.grey[300] : 'unset'),
    '& i': {
      color: ({ disableEditCond }) => (disableEditCond ? theme.palette.grey[300] : 'unset'),
    },
  },
  deleteButton: {
    cursor: ({ disableDeleteCond }) => (disableDeleteCond ? 'not-allowed' : 'pointer'),
    pointerEvents: ({ disableDeleteCond }) => (disableDeleteCond ? 'none' : 'unset'),
    color: ({ disableEditCond }) => (disableEditCond ? theme.palette.grey[300] : 'unset'),
    '& i': {
      color: ({ disableEditCond }) => (disableEditCond ? theme.palette.grey[300] : 'unset'),
    },
  },
}))
