import React, { useCallback, useMemo } from 'react'
import Text from 'core/elements/Text'
import { updateImage } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import { without } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import { listImages } from './actions'
import ExternalLink from 'core/components/ExternalLink'
import { usefulImageProperties } from './helpers'

export default function EditImageMetadataModal({ rows: [image], onClose }) {
  const defaultParams = useMemo(
    () => ({
      properties: Object.entries(image.properties).map(([key, value]) => {
        return { key, value }
      }),
    }),
    [image.properties],
  )

  const initialKeys = useMemo(() => {
    return Object.keys(image.properties)
  }, [image.properties])

  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(updateImage)
  const { reload } = useListAction(listImages, {})

  const submitForm = useCallback(async () => {
    const properties = params.properties.reduce((accum, pair) => {
      return {
        ...accum,
        [pair.key]: pair.value,
      }
    }, {})
    const currentKeys = Object.keys(properties)
    const removedKeys = without(currentKeys, initialKeys)
    const body = []
    for (const key of currentKeys) {
      if (initialKeys.includes(key)) {
        body.push({ op: 'replace', path: `/${key}`, value: properties[key] })
      } else {
        body.push({ op: 'add', path: `/${key}`, value: properties[key] })
      }
    }
    for (const key of removedKeys) {
      body.push({ op: 'remove', path: `/${key}` })
    }
    const { success } = await update({ id: image.id, body })
    // Need to reload images because updateAction does a merge instead of replace
    // if a user removed metadata it will not be removed in the merged image
    reload(true, true)
    if (success) handleClose()
  }, [params, image, initialKeys])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  return (
    <ModalForm
      open
      title={`Edit Properties`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Update Properties`}
      maxWidth={1200}
    >
      <>
        <FormFieldSection title="Configure Properties" step={1}>
          <Text variant="body2">
            Add custom properties to the image. You may define your own custom key/value pairs to
            help identify your images, or add properties described in this{' '}
            <ExternalLink url="https://docs.openstack.org/glance/2023.1/admin/useful-image-properties.html">
              article
            </ExternalLink>{' '}
            that can be particularly useful.
          </Text>
          <KeyValuesField
            id="properties"
            label="Properties"
            value={params.properties}
            onChange={getParamsUpdater('properties')}
            addLabel="Add Property"
            keySuggestions={usefulImageProperties}
            allowMultipleValues
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
