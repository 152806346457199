import { makeStyles } from '@material-ui/styles'
import RecordTypePicklist from 'app/plugins/openstack/components/networks/dns/records/RecordTypePicklist'
import ZonePicklist from 'app/plugins/openstack/components/networks/dns/records/ZonePicklist'
import PicklistField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import { middleLeft } from 'core/elements/menu/defaults'
import Text from 'core/elements/Text'
import Theme from 'core/themes/model'
import { maxValueValidator, minValueValidator } from 'core/utils/fieldValidators'
import React from 'react'
import useReactRouter from 'use-react-router'

const recordTypeInfo = {
  A: 'IPv4 address (e.g. 192.168.1.1) that this hostname should resolve to.',
  AAAA: 'IPv6 address (e.g., 2001:db8::1) that this hostname should resolve to.',
  CNAME: 'Canonical name (another domain) that this hostname should point to.',
  MX: 'Mail server hostname that handles email for this domain.',
  TXT:
    'Text record for storing arbitrary text data, commonly used for SPF, DKIM, domain verification.',
  SRV: 'Target hostname for a specific service. Used with weight, port, and priority.',
}

const placeholders = {
  A: 'Enter Content (eg. 192.168.1.1)',
  AAAA: 'Enter Content (eg. 2001:db8::1)',
  CNAME: 'Enter Content (eg. target-hostname.example.com)',
  MX: 'Enter Content (eg. mail.example.com)',
  TXT: 'Enter Content (eg. v=spf1 include:_spf.example.com)',
  SRV: 'Target hostname for a specific service. Used with weight, port, and priority.',
}

const RecordConfigurationFields = ({ params, getParamsUpdater }) => {
  const classes = useStyles({})
  const { history } = useReactRouter()

  const isRecordEditPath = history.location.pathname.includes('edit')

  return (
    <>
      <Text className={classes.configText} variant="subtitle2">
        Record Configuration
      </Text>
      <div className={classes.formFields}>
        <TextField
          id="recordName"
          label="Record Name"
          placeholder="Enter Record Name"
          required
          value={params.recordName}
          onChange={getParamsUpdater('recordName')}
          disabled={isRecordEditPath}
        />
        <PicklistField
          DropdownComponent={ZonePicklist}
          id="zoneId"
          label="Zone"
          onChange={getParamsUpdater('zoneId')}
          value={params.zoneId}
          required
          info="The DNS zone (domain) that this record belongs to."
          align={middleLeft.align}
          disabled={isRecordEditPath}
        />
        <PicklistField
          DropdownComponent={RecordTypePicklist}
          id="recordType"
          label="Record Type"
          onChange={getParamsUpdater('recordType')}
          value={params.recordType}
          required
          info="The type of DNS record determines its purpose. A for IPv4, AAAA for IPv6, CNAME for aliases, etc."
          align={middleLeft.align}
          disabled={isRecordEditPath}
        />
        <TextField
          id="content"
          label="Content"
          placeholder={placeholders[params.recordType] || ''}
          required
          value={params.content}
          onChange={getParamsUpdater('content')}
          info={recordTypeInfo[params.recordType] || ''}
          align={middleLeft.align}
        />
        <TextField
          id="ttl"
          label="TTL"
          type="number"
          validations={[minValueValidator(300), maxValueValidator(86400)]} //5 minutes, 24 hours
          required
          value={params.ttl}
          onChange={(value) => getParamsUpdater('ttl')(value as number)}
          info="Time-to-Live in seconds. Determines how long resolvers can cache this record before requesting fresh data."
          align={middleLeft.align}
        />
        {/* {(params.recordType === 'MX' || params.recordType === 'SRV') && (
          <TextField
            id="priority"
            label="Priority"
            type="number"
            required
            value={params.priority}
            onChange={(value) => getParamsUpdater('priority')(value as number)}
            info={
              params.recordType === 'MX'
                ? 'Lower values indicate higher priority mail servers.'
                : params.recordType === 'SRV'
                ? 'Determines the order servers are tried.Minimum: 0 (Highest priority)Maximum: 65535'
                : ''
            }
            align={middleLeft.align}
            disabled={isRecordEditPath}
          />
        )}
        {params.recordType === 'SRV' && (
          <>
            <TextField
              id="weight"
              label="Weight"
              type="number"
              required
              value={params.weight}
              validations={[minValueValidator(0), maxValueValidator(65535)]} //5 minutes, 24 hours
              onChange={(value) => getParamsUpdater('weight')(value as number)}
              info="Relative weight for servers with the same priority (higher values get more traffic).
Minimum: 0 (No weight, even distribution)Maximum: 65535"
              align={middleLeft.align}
              disabled={isRecordEditPath}
            />
            <TextField
              id="port"
              label="Port"
              type="number"
              required
              value={params.port}
              onChange={(value) => getParamsUpdater('port')(value as number)}
              info="the TCP or UDP port on which the service is running (e.g., 5060 for SIP)."
              align={middleLeft.align}
              disabled={isRecordEditPath}
            />
          </>
        )} */}
      </div>
    </>
  )
}

export default RecordConfigurationFields
const useStyles = makeStyles<Theme>((theme) => ({
  configText: {
    marginBottom: theme.spacing(2),
  },
  content: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
  },
  formFields: {
    display: 'grid',
    gridTemplateRows: `repeat(3, auto)`,
    gap: theme.spacing(2),
  },
  soaSettingFields: {
    display: 'grid',
    gridTemplateColumns: `repeat(2, auto)`,
    gap: theme.spacing(3),
  },
}))
