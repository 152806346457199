import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const statuses = [
  {
    name: 'active',
    description: 'The image is active and ready for consumption in the Image service.',
  },
  {
    name: 'queued',
    description:
      'The Image service reserved an image ID for the image in the catalog but did not yet upload any image data.',
  },
  {
    name: 'deleted',
    description:
      'The Image service retains information about the image but the image is no longer available for use.',
  },
  {
    name: 'uploading',
    description:
      'Data has been staged as part of the interoperable image import process. It is not yet available for use.',
  },
  {
    name: 'importing',
    description:
      'The image data is being processed as part of the interoperable image import process, but is not yet available for use.',
  },
]

const ImageStatusInfo = () => {
  const classes = useStyles()

  return (
    <div className={classes.tooltipContainer}>
      {statuses.map((status) => (
        <p key={status.name}>
          <strong>{status.name}:</strong> {status.description}
        </p>
      ))}
    </div>
  )
}

export default ImageStatusInfo

const useStyles = makeStyles<Theme>((theme) => ({
  tooltipContainer: {
    padding: theme.spacing(1),
  },
}))
