import { makeStyles } from '@material-ui/styles'
import ActionPopOver from 'app/plugins/openstack/components/networks/dns/ActionPopover'
import { DnsPageTabs } from 'app/plugins/openstack/components/networks/dns/model'
import DeleteRecordDialog from 'app/plugins/openstack/components/networks/dns/records/DeleteRecordDialog'
import { DnsRecordType } from 'app/plugins/openstack/components/networks/dns/records/RecordTypePicklist'
import { getFieldsForCard } from 'core/components/InfoPanel'
import Badge from 'core/elements/badge'
import IconButton from 'core/elements/button/IconButton'
import Text from 'core/elements/Text'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import EllipsisText from 'k8s/components/common/EllipsisText'
import { PropertiesTable } from 'k8s/components/kaapi/cluster-upgrade/PropertyCardTable'
import React, { useCallback, useMemo, useState } from 'react'
const metadataFields = [
  { id: 'name', title: 'Zone', required: true },
  {
    id: 'records',
    title: 'Content',
    render: (value) => (
      <EllipsisText variant="caption1" showTooltip title={value}>
        {value}
      </EllipsisText>
    ),
    required: true,
  },
  {
    id: 'ttl',
    title: 'TTL',
    render: (value) => (value ? <Text variant="caption1">{value}</Text> : '-'),
    required: true,
  },
]

const RecordCard = ({ item }) => {
  const classes = useStyles()
  const url = new URL(window.location.href)
  const selectedView = url.searchParams.get('view')

  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, item)
  }, [item])

  const handleClick = useCallback((event) => {
    setOpen(true)
    setAnchorEl(event.currentTarget)
  }, [])

  const isNsOrSoaType = item?.type === DnsRecordType.NS || item?.type === DnsRecordType.SOA

  return (
    <>
      {showDeleteDialog && (
        <DeleteRecordDialog rows={[item]} onClose={() => setShowDeleteDialog(false)} />
      )}
      <div className={classes.cardContainer}>
        <div className={classes.headerContainer}>
          <div>
            <Badge className={classes.badge} text={item.type} variant={'unknown'} />
            <Text className={classes.nameText} variant="subtitle2">
              {item?.name}
            </Text>
          </div>
          <IconButton
            size="md"
            icon="ellipsis"
            solid={true}
            className={classes.settingsMenuButton}
            onClick={handleClick}
          />
        </div>
        <div className={classes.propertiesTable}>
          <PropertiesTable fields={metadata} />
        </div>
      </div>
      <ActionPopOver
        open={open}
        setOpen={setOpen}
        anchorEl={anchorEl}
        editRoute={routes.openstack.editDnsResource.path({
          tab: DnsPageTabs.Records,
          id: item?.id,
          view: selectedView,
        })}
        setShowDeleteDialog={setShowDeleteDialog}
        disableEditCond={isNsOrSoaType}
        disableDeleteCond={isNsOrSoaType}
      />
    </>
  )
}

export default RecordCard

const useStyles = makeStyles<Theme>((theme) => ({
  cardContainer: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    width: '325px',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 4,
    padding: theme.spacing(3),
    gap: theme.spacing(3),
    background: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  nameText: {
    marginTop: theme.spacing(2),
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.grey[200]}`,
    width: theme.spacing(4),
    height: theme.spacing(4),
    rotate: '90deg',
    '& i': {
      color: theme.palette.grey[500],
    },
    cursor: 'pointer',
  },
  propertiesTable: {
    '& .properties-table': {
      '& .property-row': {
        gridTemplateColumns: '65px 12px 1fr',
      },
    },
  },
  settingsMenuButton: {
    '& i': {
      rotate: '90deg',
    },
  },
  settingsMenuContainer: {
    padding: theme.spacing(1, 0),
    width: '154px',
  },
  settingsMenuItem: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    alignItems: 'center',
    gap: 6,
    padding: theme.spacing(1, 2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  badge: {
    backgroundColor: theme.palette.grey[200],
    '& span': {
      color: theme.palette.grey[700],
    },
  },
}))
