import React, { useMemo } from 'react'
import { listResmgrHosts } from 'openstack/components/infrastructure/actions'
import { resmgrHostsSelector } from 'openstack/components/infrastructure/selectors'
import {
  hasHypervisorRole,
  hasImageLibraryRole,
  hasCinderRole,
  hostUnhealthy,
  getWizardAlertMessage,
} from 'openstack/components/infrastructure/helpers'
import Info from 'core/components/validatedForm/Info'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    marginBottom: 16,
  },
}))

interface Props {
  hypervisor?: boolean
  imageLibrary?: boolean
  blockStorage?: boolean
  action: string
}

export default function ServiceUnhealthyInfo({
  hypervisor = false,
  imageLibrary = false,
  blockStorage = false,
  action,
}: Props) {
  const classes = useStyles()
  const { loading: loadingResmgrHosts } = useListAction(listResmgrHosts)
  const resmgrHosts = useSelector(resmgrHostsSelector)

  const unhealthyHosts = useMemo(() => {
    if (!resmgrHosts.length) {
      return {
        hypervisor: false,
        imageLibrary: false,
        blockStorage: false,
      }
    }
    const hypervisors = resmgrHosts.filter((host) => hasHypervisorRole(host))
    const imageLibraries = resmgrHosts.filter((host) => hasImageLibraryRole(host))
    const blockStorageNodes = resmgrHosts.filter((host) => hasCinderRole(host))
    const unhealthyHypervisors = hypervisors.filter((host) => hostUnhealthy(host))
    const unhealthyImageLibraries = imageLibraries.filter((host) => hostUnhealthy(host))
    const unhealthyBlockStorageNodes = blockStorageNodes.filter((host) => hostUnhealthy(host))
    const allHypervisorsUnhealthy = hypervisors.length === unhealthyHypervisors.length
    const allImageLibrariesUnhealthy = imageLibraries.length === unhealthyImageLibraries.length
    const allBlockStorageNodesUnhealthy =
      blockStorageNodes.length === unhealthyBlockStorageNodes.length
    return {
      hypervisor: hypervisors.length && allHypervisorsUnhealthy,
      imageLibrary: imageLibraries.length && allImageLibrariesUnhealthy,
      blockStorage: blockStorageNodes.length && allBlockStorageNodesUnhealthy,
    }
  }, [resmgrHosts])

  const message = getWizardAlertMessage({
    hypervisor: hypervisor && unhealthyHosts.hypervisor,
    imageLibrary: imageLibrary && unhealthyHosts.imageLibrary,
    blockStorage: blockStorage && unhealthyHosts.blockStorage,
    action,
  })

  return !!message ? (
    <Info className={classes.root} error>
      {message}
    </Info>
  ) : null
}
