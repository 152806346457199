import React, { useEffect, useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import {
  listResmgrHosts,
  listHostAggregates,
  deleteHostAggregate,
  getHostAggregateConfig,
} from '../actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { hostAggregatesSelector } from '../selectors'
import InferActionParams from 'core/actions/InferActionParams'
import { routes } from 'core/utils/routes'
import { useSelector } from 'react-redux'
import ApiClient from 'api-client/ApiClient'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import AddHostAggregateModal from './AddHostAggregateModal'
import EditHostAggregateModal from './EditHostAggregateModal'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import Text from 'core/elements/Text'
import StatsCell from 'app/plugins/infrastructure/components/common/cells/StatsCell'

type ModelDataKey = DataKeys.HostAggregates
type SelectorModel = ArrayElement<ReturnType<typeof hostAggregatesSelector>>
type ActionParams = InferActionParams<typeof listHostAggregates>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'HostAggregates',
  listTablePrefs,
)

const searchTargets = ['name', 'id', 'hostInfo']
const searchFunctions = [
  {
    property: 'hostInfo',
    searchFn: (value, searchString) => {
      return value.some((host) => {
        return (
          host?.name?.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
          host?.ip?.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
        )
      })
    },
  },
]

export const HostsCellComponent = ({ value }) => {
  return (
    <>
      {value.map((host) => (
        <Text variant="body2">
          {host.name} ({host.ip})
        </Text>
      ))}
    </>
  )
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
  },
  {
    key: 'id',
    label: 'ID',
    display: false,
  },
  // {
  //   key: 'availability_zone',
  //   label: 'Availability Zone',
  // },
  {
    key: 'hostInfo',
    label: 'Hosts',
    CellComponent: HostsCellComponent,
  },
  {
    key: 'usage',
    label: 'Resource Utilization',
    CellComponent: StatsCell,
  },
  {
    key: 'metadata',
    label: 'Metadata',
    disableSorting: true,
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
]

export default function HostAggregatesListPage() {
  const [selectedHostAggregates, setSelectedHostAggregates] = useState([])
  const [showEditHostAggregateModal, setShowEditHostAggregateModal] = useState(false)
  // const [showToggleHaModal, setShowToggleHaModal] = useState(false)

  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)

  // Idk why the dependency isn't working, call it explicitly here
  useListAction(listResmgrHosts, { params })
  const { message, loading, reload } = useListAction(listHostAggregates, {
    params,
  })
  const hostAggregates = useSelector(hostAggregatesSelector)

  // useEffect(() => {
  //   const aggregatesWithAz = hostAggregates.filter(
  //     (hostAggregate) => !!hostAggregate.availability_zone,
  //   )
  //   const aggregateId = aggregatesWithAz?.[0]?.id
  //   const makeCalls = async () => {
  //     const haConfig = await getHostAggregateConfig(aggregateId)
  //     const haEnabled = !!haConfig?.status?.[0]?.enabled
  //   }
  //   makeCalls()
  // }, [hostAggregates])

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      cond: (hosts) => {
        return hosts.length > 0 && hosts[0]?.name !== 'compute'
      },
      label: 'Edit Host Aggregate',
      icon: 'edit',
      handleAction: (selected) => {
        setSelectedHostAggregates(selected)
        setShowEditHostAggregateModal(true)
      },
      refreshAfterSuccess: true,
    },
    // {
    //   cond: (hosts) => {
    //     return hosts.length > 0
    //   },
    //   label: 'Toggle High Availability',
    //   icon: 'bolt-auto',
    //   handleAction: (selected) => {
    //     setSelectedHostAggregates(selected)
    //     setShowToggleHaModal(true)
    //   },
    //   refreshAfterSuccess: true,
    // },
  ]

  return (
    <>
      <DocumentMeta title="Host Aggregates" />
      <>
        <AddHostAggregateModal addRoute={routes.openstack.createHostAggregate} />
        {showEditHostAggregateModal && (
          <EditHostAggregateModal
            onClose={() => setShowEditHostAggregateModal(false)}
            rows={selectedHostAggregates}
          />
        )}
        {/* {showToggleHaModal && (
          <ToggleHaDialog
            onClose={() => setShowToggleHaModal(false)}
            rows={selectedHostAggregates}
          />
        )} */}
        <ListContainer<ModelDataKey, SelectorModel>
          dataKey={DataKeys.HostAggregates}
          searchTargets={searchTargets}
          searchFunctions={searchFunctions}
          uniqueIdentifier="id"
          addUrl={routes.openstack.createHostAggregate.path()}
          addText="Add Host Aggregate"
          loading={loading}
          loadingMessage={message}
          onRefresh={reload}
          data={hostAggregates}
          columns={columns}
          getParamsUpdater={getParamsUpdater}
          batchActions={batchActions}
          deleteAction={deleteHostAggregate}
          deleteCond={([hostAggregate]) =>
            !hostAggregate?.hosts?.length && hostAggregate?.name !== 'compute'
          }
          deleteDisabledInfo="Host Aggregate must not have any hosts in order to be deleted"
          {...pick(listTablePrefs, params)}
        />
      </>
    </>
  )
}
