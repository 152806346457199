import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import { routes } from 'core/utils/routes'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listVolumes, listAllVolumes } from '../actions'
import { volumesSelector, allVolumesSelector } from '../selectors'
import { IVolumeDetailsPageTabs } from './model'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Overview from './Overview'

const useStyles = makeStyles<Theme>((theme) => ({
  grid: {
    display: 'grid',
    gap: 32,
  },
  actions: {
    display: 'grid',
    gridAutoColumns: 'auto max-content',
    gridAutoFlow: 'column',
    gap: 16,
  },
}))

const VolumeDetailsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id } = match.params
  const { loading } = useListAction(listVolumes)
  const volumes = useSelectorWithParams(volumesSelector, {})
  const { loading: loadingAllVolumes } = useListAction(listAllVolumes)
  const allVolumes = useSelectorWithParams(allVolumesSelector, {})
  const volume = useMemo(
    () =>
      volumes.find((volume) => volume.id === id) || allVolumes.find((volume) => volume.id === id),
    [id, volumes, allVolumes],
  )

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        id: volume?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [volume?.name, id])

  return (
    <>
      <DocumentMeta title="Volume Details" breadcrumbs />
      <Tabs route={routes.openstack.volumeDetails}>
        <Tab label="Overview" value={IVolumeDetailsPageTabs.Overview}>
          <Overview volume={volume || []} loading={loading || loadingAllVolumes} />
        </Tab>
      </Tabs>
    </>
  )
}

export default VolumeDetailsPage
