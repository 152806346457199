import React from 'react'
import Card from 'core/elements/card'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  card: {
    padding: '8px 0px',
  },
  customCardHeader: {
    display: 'flex',
    padding: '16px 32px 0px',
    gap: 8,
    alignItems: 'center',
  },
  iconContainer: {
    background: theme.palette.blue[900],
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 24,
    width: 24,
  },
  icon: {
    fontSize: 14,
    color: theme.palette.grey['000'],
  },
  headerText: {
    color: theme.palette.grey[500],
  },
  pluginStatus: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  enabled: {
    fontSize: 10,
    color: theme.palette.green.main,
  },
  disabled: {
    fontSize: 10,
    color: theme.palette.red.main,
  },
  properties: {
    display: 'grid',
    gap: 24,
    marginTop: 36,
  },
  property: {
    display: 'grid',
    gap: 4,
  },
  propertyKey: {
    color: theme.palette.grey[500],
  },
}))

export default function OverviewCard({ item, data, icon }) {
  const classes = useStyles()

  if (!item) {
    return null
  }
  return (
    <Card
      className={classes.card}
      title={
        <div className={classes.customCardHeader}>
          <div className={classes.iconContainer}>
            <FontAwesomeIcon className={classes.icon}>{icon}</FontAwesomeIcon>
          </div>
          <Text variant="body2" className={classes.headerText}>
            CONFIGURATION OVERVIEW
          </Text>
        </div>
      }
    >
      <Text variant="h4">{item?.name}</Text>
      <div className={classes.properties}>
        {data.map((pair) =>
          pair?.value ? (
            <div key={pair.key} className={classes.property}>
              <Text variant="body2" className={classes.propertyKey}>
                {pair.key?.toUpperCase()}
              </Text>
              {pair.render ? pair.render(item) : <Text variant="caption1">{pair.value}</Text>}
            </div>
          ) : null,
        )}
      </div>
    </Card>
  )
}
