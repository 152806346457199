import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { flatten } from 'ramda'
import { allTenantsSelector } from 'account/components/userManagement/tenants/selectors'
import { securityGroupsByIdSelector } from './security-groups/selectors'
import { RootState } from 'app/store'

const underlayNetworkSelector = (state: RootState) => {
  return state?.session?.underlayNetwork
}

export const networkAvailabilitySelector = createSharedSelector(
  getDataSelector<DataKeys.NetworkIpAvailability>(DataKeys.NetworkIpAvailability),
  (networkIpAvailability) => {
    return networkIpAvailability.map((availability) => {
      // any model changes go here
      return availability
    })
  },
)

export const availabilityByNetworkIdSelector = createSharedSelector(
  networkAvailabilitySelector,
  (networkAvailability) => {
    return networkAvailability.reduce((accum, networkAvailability) => {
      return {
        ...accum,
        [networkAvailability.network_id]: {
          totalIps: networkAvailability.total_ips,
          usedIps: networkAvailability.used_ips,
          availableIps: networkAvailability.total_ips - networkAvailability.used_ips,
        },
      }
    }, {})
  },
)

export const availabilityBySubnetIdSelector = createSharedSelector(
  networkAvailabilitySelector,
  (networkAvailability) => {
    const subnetAvailability = flatten(
      networkAvailability.map(
        (networkAvailability) => networkAvailability.subnet_ip_availability || [],
      ),
    )
    return subnetAvailability.reduce((accum, subnetAvailability) => {
      return {
        ...accum,
        [subnetAvailability.subnet_id]: {
          totalIps: subnetAvailability.total_ips,
          usedIps: subnetAvailability.used_ips,
          availableIps: subnetAvailability.total_ips - subnetAvailability.used_ips,
        },
      }
    }, {})
  },
)

export const subnetsSelector = createSharedSelector(
  getDataSelector<DataKeys.OpenstackSubnets>(DataKeys.OpenstackSubnets),
  availabilityBySubnetIdSelector,
  (subnets, availabilityBySubnetId) => {
    return subnets.map((subnet) => {
      // any model changes go here
      return {
        ...subnet,
        availableIps: availabilityBySubnetId[subnet.id]?.availableIps,
      }
    })
  },
)

export const subnetsByIdSelector = createSharedSelector(subnetsSelector, (subnets) => {
  return subnets.reduce((accum, subnet) => {
    return {
      ...accum,
      [subnet.id]: subnet,
    }
  }, {})
})

const getNetworkType = (network, underlayNetwork) => {
  if (!underlayNetwork) {
    return 'physical'
  }
  if (network['provider:network_type'] === 'flat') {
    return 'physical'
  }
  const segmentationId = network['provider:segmentation_id']
  const inUnderlayRange = underlayNetwork?.ranges?.some((range) => {
    if (range.includes('-')) {
      const parts = range.split('-')
      return Number(parts[0]) <= segmentationId && Number(parts[1]) >= segmentationId
    } else {
      return `${network['provider:segmentation_id']}` === range
    }
  })
  if (network['provider:network_type'] === 'vlan') {
    if (network['provider:physical_network'] === underlayNetwork?.label) {
      if (inUnderlayRange) {
        return 'virtual'
      } else {
        return 'physical'
      }
    } else {
      return 'physical'
    }
  }
  return inUnderlayRange ? 'virtual' : 'physical'
}

export const portsSelector = createSharedSelector(
  getDataSelector<DataKeys.OpenstackPorts>(DataKeys.OpenstackPorts),
  securityGroupsByIdSelector,
  (ports, securityGroupsById) => {
    return ports.map((port) => {
      // any model changes go here
      return {
        ...port,
        securityGroups: port?.security_groups?.map((id) => securityGroupsById?.[id]),
      }
    })
  },
)

export const portsByNetworkId = createSharedSelector(portsSelector, (ports) => {
  return ports.reduce((accum, port) => {
    return {
      ...accum,
      [port.network_id]: accum[port.network_id] ? [...accum[port.network_id], port] : [port],
    }
  }, {})
})

export const networksSelector = createSharedSelector(
  getDataSelector<DataKeys.OpenstackNetworks>(DataKeys.OpenstackNetworks),
  subnetsByIdSelector,
  availabilityByNetworkIdSelector,
  underlayNetworkSelector,
  portsByNetworkId,
  (networks, subnetsById, availabilityByNetworkId, underlayNetwork, portsByNetworkId) => {
    return networks.map((network) => {
      const networkType = getNetworkType(network, underlayNetwork)
      // any model changes go here
      return {
        ...network,
        type: networkType,
        subnetDetails: network?.subnets
          ?.map((subnetId) => subnetsById[subnetId])
          .filter((subnet) => !!subnet),
        availableIps: availabilityByNetworkId[network.id]?.availableIps,
        ports: portsByNetworkId[network?.id],
      }
    })
  },
)

export const networksByIdSelector = createSharedSelector(networksSelector, (networks) => {
  return networks.reduce((accum, network) => {
    return {
      ...accum,
      [network.id]: network,
    }
  }, {})
})

export const virtualNetworksSelector = createSharedSelector(networksSelector, (networks) => {
  return networks.filter((network) => {
    return network.type === 'virtual'
  })
})

export const physicalNetworksSelector = createSharedSelector(networksSelector, (networks) => {
  return networks.filter((network) => {
    return network.type === 'physical'
  })
})

export const externalNetworksSelector = createSharedSelector(networksSelector, (networks) => {
  return networks.filter((network) => {
    return !!network['router:external']
  })
})

export const portsAsInterfacesSelector = createSharedSelector(
  portsSelector,
  networksByIdSelector,
  subnetsByIdSelector,
  (ports, networksById, subnetsById) => {
    return ports.map((port) => {
      return {
        ...port,
        // User shouldn't be using a port with more than one IP address here right?
        // Maybe fine to leave it, but may be needed in the future to show all fixed_ips
        staticIp: port?.fixed_ips?.[0]?.ip_address,
        subnetLabel: `${networksById?.[port?.network_id]?.name}: ${
          subnetsById?.[port?.fixed_ips?.[0]?.subnet_id]?.name
        }`,
      }
    })
  },
)

export const loadBalancersSelector = createSharedSelector(
  getDataSelector<DataKeys.LoadBalancers>(DataKeys.LoadBalancers),
  subnetsSelector,
  (loadBalancers, subnets) => {
    return loadBalancers.map((loadBalancer) => {
      const subnetDetails = subnets?.find((subnet) => loadBalancer?.vip_subnet_id === subnet?.id)
      const subnetName = subnetDetails?.name
      return {
        ...loadBalancer,
        name: loadBalancer?.name,
        ipAddress: loadBalancer?.vip_address,
        operatingStatus: loadBalancer?.operating_status,
        provisioningStatus: loadBalancer?.provisioning_status,
        adminStateUp: loadBalancer?.admin_state_up,
        subnetName: subnetName,
      }
    })
  },
)

export const octaviaFlavorsSelector = createSharedSelector(
  getDataSelector<DataKeys.OctaviaFlavors>(DataKeys.OctaviaFlavors),
  (octaviaFlavors) => {
    return octaviaFlavors.map((octaviaFlavor) => {
      return {
        ...octaviaFlavor,
      }
    })
  },
)
export const octaviaListenersSelector = createSharedSelector(
  getDataSelector<DataKeys.OctaviaListeners>(DataKeys.OctaviaListeners),
  loadBalancersSelector,
  (octaviaListeners, loadBalancers) => {
    return octaviaListeners.map((octaviaListener) => {
      const loadBalancerDetails = loadBalancers?.find(
        (loadBalancer) => octaviaListener?.loadbalancers[0]?.id === loadBalancer?.id,
      )
      const loadBalancerName = loadBalancerDetails?.name
      return {
        ...octaviaListener,
        name: octaviaListener?.name,
        loadBalancer: loadBalancerName,
        protocol: octaviaListener?.protocol,
        port: octaviaListener?.protocol_port,
      }
    })
  },
)

export const octaviaPoolsSelector = createSharedSelector(
  getDataSelector<DataKeys.OctaviaPools>(DataKeys.OctaviaPools),
  octaviaListenersSelector,
  (octaviaPools, octaviaListeners) => {
    return octaviaPools.map((octaviaPool) => {
      const listenerDetails = octaviaListeners?.find(
        (octaviaListener) => octaviaPool?.listeners[0]?.id === octaviaListener?.id,
      )
      const listenerName = listenerDetails?.name
      return {
        ...octaviaPool,
        name: octaviaPool?.name,
        listener: listenerName,
        protocol: octaviaPool?.protocol,
        algorithm: octaviaPool?.lb_algorithm,
      }
    })
  },
)

export const octaviaPoolMembersSelector = createSharedSelector(
  getDataSelector<DataKeys.OctaviaPoolMembers>(DataKeys.OctaviaPoolMembers),
  octaviaPoolsSelector,
  (octaviaPoolMembers, octaviaPools) => {
    return octaviaPoolMembers.map((octaviaPoolMember) => {
      const poolDetails = octaviaPools?.find(
        (octaviaPool) => octaviaPoolMember?.poolId === octaviaPool?.id,
      )
      const poolName = poolDetails?.name
      const poolId = poolDetails?.id
      return {
        ...octaviaPoolMember,
        name: octaviaPoolMember?.name,
        status: octaviaPoolMember?.provisioning_status,
        ipAddress: octaviaPoolMember?.address,
        pool: poolName,
        poolId: poolId,
        port: octaviaPoolMember?.protocol_port,
        weight: octaviaPoolMember?.weight,
      }
    })
  },
)

export const octaviaHealthMonitorsSelector = createSharedSelector(
  getDataSelector<DataKeys.OctaviaHealthMonitors>(DataKeys.OctaviaHealthMonitors),
  octaviaPoolsSelector,
  (octaviaHealthMonitors, octaviaPools) => {
    return octaviaHealthMonitors.map((octaviaHealthMonitor) => {
      const poolDetails = octaviaPools?.find(
        (octaviaPool) => octaviaHealthMonitor?.pools[0]?.id === octaviaPool?.id,
      )
      const poolName = poolDetails?.name
      const poolId = poolDetails?.id
      return {
        ...octaviaHealthMonitor,
        type: octaviaHealthMonitor?.type,
        status: octaviaHealthMonitor?.provisioning_status,
        delay: octaviaHealthMonitor?.delay,
        pool: poolName,
        poolId: poolId,
        timeout: octaviaHealthMonitor?.timeout,
        maxRetries: octaviaHealthMonitor?.max_retries,
      }
    })
  },
)
