import React, { useCallback, FC } from 'react'
import useToggler from 'core/hooks/useToggler'
import { ThemeProvider } from '@material-ui/styles'
import { useCustomTheme } from 'core/themes/ThemeManager'
import GridDefaultActionButton, {
  GridDefaultActionButtonProps,
} from 'core/elements/grid/buttons/GridDefaultActionButton'

export interface GridDialogProps<T> {
  onClose: (success?: boolean) => void
  rows: T[]
}

/**
 * @summary This function is used to render the dialog button out of grid. Its the variant of gridDialogButton
 * @param DialogComponent
 * @param customDialogProps
 * @param customButtonProps
 * @returns Returns a dialog with button wrapper to toggle the dialog open/close
 */
export function getDialogButton<T, DialogProps extends GridDialogProps<T>>(
  DialogComponent: FC<DialogProps>,
  customDialogProps?: DialogProps,
  customButtonProps?: Partial<GridDefaultActionButtonProps<T>>,
): FC<GridDefaultActionButtonProps<T>> {
  return ({ children, closeDropdown, ...buttonProps }) => {
    const [theme] = useCustomTheme()
    const [dialogOpened, toggleDialogOpened, setDialogOpened] = useToggler()
    const handleDialogClose = useCallback(() => {
      closeDropdown && closeDropdown()
      setDialogOpened(false)
    }, [])
    return (
      <>
        <GridDefaultActionButton
          {...buttonProps}
          {...customButtonProps}
          // The onClick here overwrites the one passed from buttonProps...
          onClick={toggleDialogOpened}
        >
          {children}
        </GridDefaultActionButton>
        {dialogOpened && (
          <ThemeProvider theme={theme}>
            <DialogComponent
              onClose={handleDialogClose}
              // We have to trick the TS engine here as it is unable to infer extraProps as the remaining props
              {...((customDialogProps || {}) as DialogProps)}
            />
          </ThemeProvider>
        )}
      </>
    )
  }
}
