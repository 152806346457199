import { UserPreferences } from 'app/constants'
import { useAppSelector } from 'app/store'
import DocumentMeta from 'core/components/DocumentMeta'
import StatusBar from 'core/components/status-bar/StatusBar'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useListAction from 'core/hooks/useListAction'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { routes } from 'core/utils/routes'
import React, { useEffect, useMemo, useState } from 'react'
import { emptyObj } from 'utils/fp'
import {
  listLoadBalancers,
  listOctaviaHealthMonitors,
  listOctaviaListener,
  listOctaviaPoolMembers,
  listOctaviaPools,
  listSubnets,
} from '../actions'
import { ILoadBalancerDetailsPageTabs } from '../model'
import {
  loadBalancersSelector,
  octaviaHealthMonitorsSelector,
  octaviaPoolMembersSelector,
  octaviaPoolsSelector,
} from '../selectors'
import ListenersListPage from './ListenersListPage'
import LoadBalancersListPage from './LoadBalancersListPage'
import MembersListPage from './MembersListPage'
import MonitorsListPage from './MonitorsListPage'
import PoolsListPage from './PoolsListPage'
import useLoadBalancerGuidedTourDetails from './useLoadBalancerGuidedTourDetails'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import Divider from 'core/elements/Divider'

function isTimeExpired(timeString: string): boolean {
  const createdAt = new Date(timeString).getTime()
  const now = Date.now()

  return now - createdAt > 60000 // 60 seconds in milliseconds
}
export default function LoadBalancerDetailsPage() {
  const { statusDetails, stepDetails } = useLoadBalancerGuidedTourDetails()
  const numColumns = statusDetails?.subtitles?.length
  const classes = useStyles({ numColumns })
  const { reload: reloadOctaviaHealthMonitors } = useListAction(listOctaviaHealthMonitors)
  const { reload: reloadOctaviaListener } = useListAction(listOctaviaListener)
  const { reload: reloadOctaviaPools } = useListAction(listOctaviaPools, {
    params: { poolId: '' },
  })
  const octaviaPools = useAppSelector(octaviaPoolsSelector)
  const poolIds = octaviaPools.map((pool) => pool.id)
  const { reload: reloadOctaviaPoolMembers } = useListAction(listOctaviaPoolMembers, {
    params: { poolIds: poolIds },
  })
  const { reload: reloadLoadBalancers } = useListAction(listLoadBalancers)
  const { reload: reloadSubnets } = useListAction(listSubnets)
  const loadBalancers = useAppSelector(loadBalancersSelector)
  const octaviaHealthMonitors = useAppSelector(octaviaHealthMonitorsSelector)
  const octaviaPoolMembers = useAppSelector(octaviaPoolMembersSelector)
  const { prefs, updateUserDefaults } = useScopedPreferences('defaults')
  const { hideLoadBalancerGuidedTourBanner = false } = (prefs[UserPreferences.Dashboard] ||
    emptyObj) as {
    hideLoadBalancerGuidedTourBanner: boolean
  }
  const [isStatusBarDisabled, setIsStatusBarDisabled] = useState(hideLoadBalancerGuidedTourBanner)
  const sortedMonitors = [...octaviaHealthMonitors, ...octaviaPoolMembers].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  )
  const localCreatedAt =
    sortedMonitors[0]?.created_at && new Date(sortedMonitors[0].created_at + 'Z').toLocaleString() // Adjust format as needed

  const isSessionTimeOut = useMemo(
    () =>
      octaviaPoolMembers?.length && octaviaHealthMonitors?.length
        ? isTimeExpired(localCreatedAt)
        : false,
    [sortedMonitors],
  )

  useEffect(() => {
    if (!loadBalancers.length) {
      updateUserDefaults(UserPreferences.Dashboard, {
        hideLoadBalancerGuidedTourBanner: false,
      })
      setIsStatusBarDisabled(false)
    }
    if (isSessionTimeOut) {
      updateUserDefaults(UserPreferences.Dashboard, {
        hideLoadBalancerGuidedTourBanner: true,
      })
      setIsStatusBarDisabled(true)
    }
  }, [loadBalancers, octaviaHealthMonitors, isSessionTimeOut])

  useEffect(() => {
    reloadLoadBalancers(true)
    reloadOctaviaListener(true)
    reloadOctaviaPools(true)
    reloadOctaviaPoolMembers(true)
    reloadOctaviaHealthMonitors(true)
    reloadSubnets(true)
  }, [])
  return (
    <>
      <DocumentMeta title="Load Balancer" />
      <div>
        {!isStatusBarDisabled && (
          <>
            <Text variant="caption1" className={classes.header}>
              LOAD BALANCER GUIDED TOUR
            </Text>
            <Divider className={classes.divider} />
            <div className={classes.statusBarGrid}>
              <StatusBar
                statusDetails={statusDetails}
                stepDetails={stepDetails}
                onClose={() => {
                  updateUserDefaults(UserPreferences.Dashboard, {
                    hideLoadBalancerGuidedTourBanner: true,
                  })
                }}
              />
            </div>
          </>
        )}
      </div>
      <Tabs route={routes.openstack.loadBalancers.details}>
        <Tab label="Load Balancers" value={ILoadBalancerDetailsPageTabs.LoadBalancers}>
          <LoadBalancersListPage />
        </Tab>
        <Tab label="Listeners" value={ILoadBalancerDetailsPageTabs.Listeners}>
          <ListenersListPage />
        </Tab>
        <Tab label="Pools" value={ILoadBalancerDetailsPageTabs.Pools}>
          <PoolsListPage />
        </Tab>
        <Tab label="Members" value={ILoadBalancerDetailsPageTabs.Members}>
          <MembersListPage />
        </Tab>
        <Tab label="Monitors" value={ILoadBalancerDetailsPageTabs.Monitors}>
          <MonitorsListPage />
        </Tab>
      </Tabs>
    </>
  )
}

const useStyles = makeStyles<Theme, { numColumns: number }>((theme) => ({
  header: {
    color: theme.palette.grey['500'],
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  statusBarGrid: {
    marginBottom: theme.spacing(3),
    '& .status-details': {
      display: 'grid',
      padding: theme.spacing(1, 0),
      marginTop: theme.spacing(1),
      gridTemplateColumns: ({ numColumns }) => `repeat(${numColumns}, auto)`,
    },
  },
}))
