import React, { useCallback, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { customValidator } from 'core/utils/fieldValidators'
import Text from 'core/elements/Text'
import PicklistField from 'core/components/validatedForm/DropdownField'
import BackendDriverPicklist from './BackendDriverPicklist'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import { GET_DRIVER_CONFIG, getPairValue, waitSeconds } from '../helpers'
import { equals, includes, uniq } from 'ramda'
import { trimInputOnBlur } from 'core/utils/helpers'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  volumeConfig: any
  currentVolumeConfigs: any[]
  updateVolumeConfigs: (config: any) => void
  onClose: () => void
  volumeType: any
}

const PAIR_TO_REMOVE = ['nfs_shares_config', 'volume_backend_name']

const DRIVERS = [
  'NFS',
  'LVM',
  'Ceph',
  'NetApp',
  'PureStorageISCSI',
  'PureStorageFC',
  'PureStorageNVME',
  'HuaweiISCSI',
  'HuaweiFC',
  'Custom',
  'HPE3PARISCSI',
  'HPE3PARFC',
]

const KEYVALUE_UPDATE_DELAY = 0.01

export default function CreateVolumeBackendConfigurationModal({
  volumeConfig,
  currentVolumeConfigs,
  updateVolumeConfigs,
  onClose,
  volumeType,
}: Props) {
  const [showKeyValues, setShowKeyValues] = useState(true)
  const [defaultDriverKeyValues, setDefaultDriverKeyValues] = useState(null)
  const [error, setError] = useState(null)
  const defaultParams = useMemo(
    () =>
      volumeConfig
        ? {
            name: volumeConfig.name,
            driver: volumeConfig.driver,
            keyValues: volumeConfig.keyValues,
            customDriver: volumeConfig.customDriver,
          }
        : {
            name: '',
            driver: '',
            keyValues: [],
            customDriver: '',
          },
    [volumeConfig?.keyValues],
  )

  // const defaultParams = {
  //   name: '',
  //   driver: '',
  //   keyValues: [],
  // }
  const classes = useStyles()
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const updateKeyValuePair = async (value) => {
    setShowKeyValues(false)
    await waitSeconds(KEYVALUE_UPDATE_DELAY)
    getParamsUpdater('keyValues')(value)
    setShowKeyValues(true)
  }

  const onKeyValueChange = async (value) => {
    let newValue = value
    const hasNfsMountPoint = value.some((pair) => pair.key === 'nfs_mount_points')

    if (params.driver === 'NetApp') {
      if (value.some((pair) => pair.key === 'netapp_storage_protocol' && pair.value === 'nfs')) {
        if (!hasNfsMountPoint) {
          newValue = [...value, { key: 'nfs_mount_points', value: '', shouldNotShowDelete: true }]
          updateKeyValuePair(newValue)
        }
      }
      if (value.some((pair) => pair.key === 'netapp_storage_protocol' && pair.value !== 'nfs')) {
        if (hasNfsMountPoint) {
          newValue = value.filter((pair) => pair.key !== 'nfs_mount_points')
          updateKeyValuePair(newValue)
        }
      }
    }

    if (value.some((pair) => PAIR_TO_REMOVE.includes(pair.key) && pair.value === '')) {
      newValue = value.map((pair) => {
        if (PAIR_TO_REMOVE.includes(pair.key))
          return {
            key: pair.key,
            value: getPairValue(params.name)[pair.key],
            disabledValue: true,
            disabledKey: true,
          }
        return pair
      })
      updateKeyValuePair(newValue)
      return
    }

    getParamsUpdater('keyValues')(newValue)
  }

  const selectedDriver = (driver) => {
    if (!DRIVERS.includes(driver)) {
      return
    }

    const runCheck = async () => {
      if (!!GET_DRIVER_CONFIG[driver]) {
        setShowKeyValues(false)
        await waitSeconds(0.5)
        // updateParams(GET_DRIVER_CONFIG[driver]) this updates the keyValues with default values
        setDefaultDriverKeyValues(GET_DRIVER_CONFIG[driver].keyValues)
        setShowKeyValues(true)
      }
    }
    runCheck()
  }

  const nameValidator = useMemo(() => {
    return customValidator((value) => {
      if (!value) {
        return true
      }
      if (volumeConfig?.name === value) {
        return true
      }
      return !currentVolumeConfigs.find((config) => config?.name === value)
    }, 'A configuration with that name already exists')
  }, [currentVolumeConfigs])

  // useEffect(() => {
  //   if (volumeConfig) {
  //     updateParams({
  //       ...volumeConfig,
  //     })
  //   }
  // }, [volumeConfig])

  const submitForm = useCallback(async () => {
    const newKeyValuePairs = params.keyValues.filter((pair) => !PAIR_TO_REMOVE.includes(pair.key))
    const keyValuePairs = [...defaultDriverKeyValues, ...newKeyValuePairs]

    const seen = new Set()
    const duplicateKey = keyValuePairs?.find((pair) => {
      if (seen.has(pair?.key)) return true
      seen.add(pair?.key)
      return false
    })?.key

    if (!!duplicateKey) {
      setError({
        title: 'May not have a duplicate key',
        message: `Key ${duplicateKey} is duplicate.`,
      })
      return
    } else {
      setError(null)
    }

    const config = {
      name: params.name,
      driver: params.driver,
      keyValues: keyValuePairs,
      customDriver: params.customDriver,
      volumeType: volumeType.type,
      volumeTypeId: volumeType.id,
    }
    updateVolumeConfigs(config)
    handleClose()
  }, [params, volumeType, defaultDriverKeyValues])

  const handleClose = () => {
    setParams(defaultParams)
    onClose()
  }

  return (
    <ModalForm
      title={`${volumeConfig ? 'Update' : 'Add'} Volume Backend Configuration`}
      onSubmit={submitForm}
      onClose={handleClose}
      // submitting={updating || updatingSubnet}
      // error={error || subnetError}
      submitTitle={`${volumeConfig ? 'Update' : 'Add'} Volume Backend Configuration`}
      open
      error={error}
    >
      <FormFieldSection title="Basic Settings">
        <TextField
          id="name"
          label="Name"
          onChange={getParamsUpdater('name')}
          onBlur={(e) => trimInputOnBlur(e, getParamsUpdater('name'))}
          value={params.name}
          validations={[nameValidator]}
          required
        />
        <PicklistField
          DropdownComponent={BackendDriverPicklist}
          name="driver"
          id="driver"
          label="Storage Driver"
          compact={false}
          value={params.driver}
          onChange={(value) => {
            getParamsUpdater('driver')(value)
            selectedDriver(value)
          }}
          required
        />
        {params.driver === 'Custom' && (
          <TextField
            id="customDriver"
            label="Custom Driver Name"
            onChange={getParamsUpdater('customDriver')}
            onBlur={(e) => trimInputOnBlur(e, getParamsUpdater('customDriver'))}
            value={params.customDriver}
            info="Must be a fully qualified driver name"
            required
          />
        )}
      </FormFieldSection>
      <FormFieldSection title="Key Value Configurations">
        {defaultDriverKeyValues?.map((item, index) => (
          <TextField
            key={item?.key}
            id="keyValues"
            label={`${item?.key} (Optional)`}
            value={defaultDriverKeyValues?.[index]?.value}
            placeholder="Value"
            onChange={(value) => {
              const updatedKeyValues = defaultDriverKeyValues?.map((kvItem) => {
                if (kvItem?.key === item?.key) {
                  return { ...kvItem, value: value }
                }
                return { ...kvItem }
              })
              setDefaultDriverKeyValues(updatedKeyValues)
            }}
          ></TextField>
        ))}
        {showKeyValues && (
          <KeyValuesField
            id="keyValues"
            label="Key Values"
            value={params.keyValues}
            onChange={onKeyValueChange}
            addLabel="Add Key/Value Pair"
            allowMultipleValues
          />
        )}
      </FormFieldSection>
    </ModalForm>
  )
}
