import { makeStyles } from '@material-ui/styles'
import { DnsPageTabs } from 'app/plugins/openstack/components/networks/dns/model'
import {
  listRecords,
  updateRecord,
} from 'app/plugins/openstack/components/networks/dns/records/actions'
import RecordConfigurationFields from 'app/plugins/openstack/components/networks/dns/records/RecordConfigurationFields'
import { recordsSelector } from 'app/plugins/openstack/components/networks/dns/records/selectors'
import { useAppSelector } from 'app/store'
import Alert from 'core/components/Alert'
import ModalForm from 'core/elements/modal/ModalForm'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import { propEq } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import useReactRouter from 'use-react-router'

const defaultParams = {
  recordName: '',
  zoneId: '',
  recordType: '',
  content: '',
  ttl: 3600,
  priority: '',
  weight: '',
  port: '',
}

const UpdateRecordPage = () => {
  const classes = useStyles()
  const url = new URL(window.location.href)
  const selectedView = url.searchParams.get('view')

  const { history, match } = useReactRouter()
  const recordId = match?.params?.id
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { loading, message, reload } = useListAction(listRecords)
  const { update, updating, error, reset } = useUpdateAction(updateRecord)

  const records = useAppSelector(recordsSelector)
  const record = useMemo(() => records?.find(propEq('id', recordId)), [records, recordId])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(
      routes.openstack.dns.path({
        tab: DnsPageTabs.Records,
        view: selectedView,
      }),
    )
    reload(true)
  }

  const handleSubmit = async (params) => {
    const body = {
      ttl: params?.ttl,
      records: [params?.content],
    }

    const { success } = await update({
      zoneId: record?.zoneId,
      recordId: record?.id,
      body,
    })
    if (success) handleClose()
  }

  useEffect(() => {
    if (!record) return
    updateParams({
      recordName: record?.name,
      zoneId: record?.zoneId,
      recordType: record?.type,
      content: (record?.records && record?.records[0]) || '',
      ttl: record?.ttl,
      priority: '',
      weight: '',
      port: '',
    })
  }, [record])

  return (
    <ModalForm
      route={routes.openstack.editDnsResource}
      title={`Edit DNS Record`}
      maxWidth={464}
      onClose={handleClose}
      onSubmit={handleSubmit}
      loading={loading}
      submitting={updating}
      loadingMessage={loading ? message : 'Submitting form...'}
      submitTitle="Save Changes"
    >
      <div>
        {error && <Alert className={classes.alert} variant="error" message={error?.message} />}
        <RecordConfigurationFields params={params} getParamsUpdater={getParamsUpdater} />
      </div>
    </ModalForm>
  )
}

export default UpdateRecordPage
const useStyles = makeStyles<Theme>((theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
  },
}))
