import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { allKey } from 'app/constants'
import clsx from 'clsx'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Progress from 'core/components/progress/Progress'
import SearchBar from 'core/components/SearchBar'
import Button from 'core/elements/button/Button'
import Divider from 'core/elements/Divider'
import DropdownToggle from 'core/elements/dropdown/DropdownToggle'
import Checkbox from 'core/elements/input/Checkbox'
import Text from 'core/elements/Text'
import Theme from 'core/themes/model'

interface Item {
  label: string
  value: string
}

interface Props {
  label: string
  type?: 'checkbox' | 'radio'
  items: Item[]
  loading?: boolean
  onChange?: (selectedItem: Item | Item[]) => void
  handleApplyFilter: (selectedItems: Item | Item[]) => void
}

const RadioDropdownFilter = ({
  label,
  type = 'checkbox',
  items,
  loading,
  onChange,
  handleApplyFilter,
}: Props) => {
  const allItem: Item = useMemo(() => ({ label: `All ${label}s`, value: allKey }), [label])
  const classes = useStyles()
  const [selectedItems, setSelectedItems] = useState<Item[] | Item>(
    type === 'checkbox' ? [...items] : allItem,
  )
  const [searchTerm, setSearchTerm] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const prevItemsRef = useRef<Item[]>(items)

  // Store the previous selection to restore when dropdown is closed without applying
  const [previousSelection, setPreviousSelection] = useState<Item[] | Item>(
    type === 'checkbox' ? [...items] : allItem,
  )

  const filteredItems = useMemo(
    () =>
      items?.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase())) || [],
    [items, searchTerm],
  )

  useEffect(() => {
    if (onChange) {
      onChange(selectedItems)
    }
  }, [selectedItems, onChange])

  useEffect(() => {
    // Only update selected items if items array length changes or when mounted
    const prevItems = prevItemsRef.current
    const itemsChanged =
      prevItems.length !== items.length ||
      items.some((item) => !prevItems.some((prevItem) => prevItem.value === item.value))

    if (itemsChanged) {
      const newSelection = type === 'checkbox' ? [...items] : items.length > 0 ? items[0] : allItem
      setSelectedItems(newSelection)
      setPreviousSelection(newSelection)
      prevItemsRef.current = items
    }
  }, [items, type, allItem])

  const dropdownRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
        // Restore previous selection when clicking outside
        setSelectedItems(previousSelection)
        setSearchTerm('')
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [previousSelection])

  const handleSelection = useCallback(
    (item: Item) => {
      if (type === 'radio') {
        setSelectedItems(item)
      } else {
        setSelectedItems((prev: Item[] | Item) => {
          const prevArray = Array.isArray(prev) ? prev : [prev]
          return prevArray.some((selected) => selected.value === item.value)
            ? prevArray.filter((selected) => selected.value !== item.value)
            : [...prevArray, item]
        })
      }
    },
    [type],
  )

  const handleSelectAllChange = useCallback(() => {
    if (type === 'radio') {
      setSelectedItems(allItem)
      return
    }

    // If all items are selected, clear selection
    if (Array.isArray(selectedItems) && selectedItems?.length === items?.length) {
      setSelectedItems([])
      return
    }

    // If no items are selected, select all
    setSelectedItems(items)
  }, [type, items, selectedItems, allItem])

  const toggleDropdown = useCallback(() => {
    if (!isOpen) {
      // When opening dropdown, store current selection as previous selection
      setPreviousSelection(selectedItems)
    }
    setIsOpen((prev) => !prev)
  }, [isOpen, selectedItems])

  const closeDropdown = useCallback(() => {
    // Restore previous selection when closing
    setSelectedItems(previousSelection)
    setIsOpen(false)
    setSearchTerm('')
  }, [previousSelection])

  const handleApplyFilterClick = useCallback(() => {
    // Update previous selection to current selection when applying filter
    setPreviousSelection(selectedItems)
    handleApplyFilter(selectedItems)
    setSearchTerm('')
    setIsOpen(false)
  }, [handleApplyFilter, selectedItems])

  const displayText = useMemo(() => {
    if (type === 'radio') {
      return (selectedItems as Item).label
    }

    if (Array.isArray(selectedItems)) {
      if (selectedItems.length === items?.length) {
        return allItem.label
      }
      if (selectedItems.length > 0) {
        return selectedItems.map((item) => item.label).join(', ')
      }
    }

    return `Select ${label}`
  }, [type, selectedItems, items, allItem, label])

  const isAllSelected = useMemo(() => {
    if (type === 'radio') {
      return (selectedItems as Item).value === allItem.value
    }
    return Array.isArray(selectedItems) && selectedItems.length === items?.length
  }, [type, selectedItems, allItem, items])

  return (
    <div className={classes.dropdownContainer} ref={dropdownRef}>
      <div className={classes.selectContainer} onClick={toggleDropdown}>
        <Text variant="caption1">{label}:</Text>
        <Progress loading={loading} inline>
          <div
            className={clsx(classes.inputFrame, 'inputFrame', {
              [classes.expandedInputFrame]: isOpen,
            })}
          >
            <div className={clsx(classes.placeholder, 'placeholder')}>
              <Text variant="caption1">{displayText}</Text>
            </div>
            <DropdownToggle type="button">
              <FontAwesomeIcon solid size="sm">
                {isOpen ? 'caret-up' : 'caret-down'}
              </FontAwesomeIcon>
            </DropdownToggle>
          </div>
        </Progress>
      </div>
      {isOpen && (
        <div className={classes.dropdownMenu}>
          <Text variant="subtitle2" className={classes.menuHeader}>
            Filter by{' '}
            <Text variant="subtitle2" component="span">
              ({label})
            </Text>
          </Text>
          <div className={classes.radioContainer}>
            {items.length ? (
              <>
                <label className={classes.radioLabel}>
                  <Checkbox checked={isAllSelected} type={type} onChange={handleSelectAllChange} />
                  <Text variant="caption1">{allItem.label}</Text>
                </label>
                <Divider className={classes.divider} />
                <SearchBar
                  className={classes.searchBar}
                  searchTerm={searchTerm}
                  onSearchChange={setSearchTerm}
                />
                <div className={classes.radioLabelContainer}>
                  {filteredItems?.map((item) => (
                    <label key={item?.value} className={classes.radioLabel}>
                      <Checkbox
                        checked={
                          type === 'radio'
                            ? (selectedItems as Item)?.value === item?.value
                            : Array.isArray(selectedItems) &&
                              selectedItems?.some((selected) => selected?.value === item?.value)
                        }
                        type={type}
                        onChange={() => handleSelection(item)}
                      />
                      <Text variant="caption1">{item?.label}</Text>
                    </label>
                  ))}
                </div>
              </>
            ) : (
              <Text variant="body2">No data found</Text>
            )}
          </div>
          <div className={classes.buttonContainer}>
            <Button variant="secondary" onClick={closeDropdown}>
              Cancel
            </Button>
            {!!items?.length && (
              <Button variant="tertiary" onClick={handleApplyFilterClick}>
                Apply Filter
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default RadioDropdownFilter

const useStyles = makeStyles((theme: Theme) => ({
  dropdownMenu: {
    width: '348px',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    position: 'absolute',
    zIndex: 20,
    right: 0,
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
  },
  radioLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    cursor: 'pointer',
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '4px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 3, 2, 0),
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
  button: {
    padding: theme.spacing(1, 2),
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 'bold',
    backgroundColor: '#ccc',
    color: '#000',
    '&:hover': {
      backgroundColor: '#b0b0b0',
    },
  },
  primaryButton: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#303f9f',
    },
  },
  menuHeader: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '& span': {
      fontWeight: 'normal',
    },
  },
  divider: {
    margin: theme.spacing(1, 0),
    background: theme.palette.grey[200],
  },
  searchBar: {
    gap: 0,
    marginBottom: theme.spacing(1),
  },
  radioLabelContainer: {
    display: 'grid',
    gap: theme.spacing(1),
    overflowY: 'auto',
    maxHeight: '200px',
  },
  dropdownContainer: {
    position: 'relative',
  },
  inputFrame: {
    ...theme.typography.inputPlaceholder,
    marginTop: 1,
    position: 'relative',
    paddingRight: 26,
    backgroundColor: theme.components.input.frame.background,
    color: theme.components.input.frame.color,
    borderRadius: 4,
    borderColor: theme.components.input.frame.border,
    borderWidth: 1,
    borderStyle: 'solid',
    padding: theme.spacing(0, 1),
    cursor: 'pointer',
    width: '200px',
  },
  expandedInputFrame: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  placeholder: {
    fontWeight: 400,
    background: 'transparent',
    minHeight: 36,
    lineHeight: 2.4,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}))
