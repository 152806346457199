import { makeStyles } from '@material-ui/styles'
import { DnsRecordType } from 'app/plugins/openstack/components/networks/dns/records/RecordTypePicklist'
import { listRecords } from 'app/plugins/openstack/components/networks/dns/records/actions'
import { recordsSelector } from 'app/plugins/openstack/components/networks/dns/records/selectors'
import { listZones, deleteZone } from 'app/plugins/openstack/components/networks/dns/zones/actions'
import { useAppSelector } from 'app/store'
import clsx from 'clsx'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import Modal from 'core/elements/modal'
import useListAction from 'core/hooks/useListAction'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Theme from 'core/themes/model'
import React from 'react'

export default function DeleteZoneDialog({ rows, onClose }) {
  const classes = useStyles()
  const zone = rows[0]

  const { update, updating, error } = useUpdateAction(deleteZone)
  const { reload } = useListAction(listZones)
  const { loading } = useListAction(listRecords)

  const records = useAppSelector(recordsSelector)

  const associatedRecord = records?.find((record) => record?.zoneId === zone?.id)
  const showDeletingErrorModal =
    associatedRecord &&
    associatedRecord.type !== DnsRecordType.NS &&
    associatedRecord.type !== DnsRecordType.SOA

  const handleDelete = async () => {
    const { success } = await update({ zoneId: zone?.id })
    if (success) {
      onClose(true)
      reload(true)
    }
  }

  if (loading) return null

  return (
    <>
      {showDeletingErrorModal ? (
        <Modal
          panel="dialog"
          className={classes.deleteDialog}
          title={
            <div className={classes.titleContainer}>
              <div className={classes.title}>
                <FontAwesomeIcon solid className={clsx(classes.circleIcon, classes.circleError)}>
                  circle-xmark
                </FontAwesomeIcon>
                <Text variant="subtitle2">Deleting Zone</Text>
              </div>
              <FontAwesomeIcon onClick={onClose} className={classes.closeIcon}>
                xmark
              </FontAwesomeIcon>
            </div>
            //   )
          }
          onClose={onClose}
          open
          footer={
            <Button variant="tertiary" onClick={onClose} disabled={updating}>
              Cancel
            </Button>
          }
        >
          <>
            <Text variant="body2">
              You cannot delete this DNS Zone because it contains active DNS Records. Please delete
              all associated Records before deleting the Zone.
            </Text>
            <div className={classes.nameConatainer}>
              <Text variant="body2">Name:</Text>
              <Text variant="caption1">{zone?.name}</Text>
            </div>
          </>
        </Modal>
      ) : (
        <ConfirmationDialog
          open
          text={
            <>
              <Text variant="body2">This zone will be permanently deleted.</Text>
              <Text variant="caption1">{zone?.name}</Text>
            </>
          }
          onCancel={onClose}
          onConfirm={handleDelete}
          error={error}
        />
      )}
    </>
  )
}
const useStyles = makeStyles<Theme>((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '338px',
  },
  circleIcon: {
    fontSize: '24px',
    color: '#fff',
  },
  circleError: {
    background: theme.palette.graphRed[100],
    color: theme.palette.graphRed[500],
  },
  deleteDialog: {
    width: '338px',
    '& header': {
      width: '394px',
      border: 'none',
    },
    '& .modal-body': {
      paddingTop: theme.spacing(1),
    },
    '& footer': {
      border: 'none',
      justifyContent: 'unset',
    },
  },
  nameConatainer: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  closeIcon: {
    cursor: 'pointer',
  },
}))
