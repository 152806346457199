export enum ILoadBalancerDetailsPageTabs {
  LoadBalancers = 'loadBalancers',
  Listeners = 'listeners',
  Pools = 'pools',
  Members = 'members',
  Monitors = 'monitors',
}
export enum IProtocols {
  TCP = 'TCP',
  UDP = 'UDP',
  SCTP = 'SCTP',
}

export enum ITypes {
  TCP = 'TCP',
  UDPCONNECT = 'UDP-CONNECT',
  SCTP = 'SCTP',
}

export enum ILoadBalancingAlgorithm {
  ROUND_ROBIN = 'ROUND_ROBIN',
  LEST_CONNECTIONS = 'LEST_CONNECTIONS',
  SOURCE_IP_PORT = 'SOURCE_IP_PORT',
}
export interface NetworksResponse {
  networks: Network[]
}

export interface NetworkDetailsResponse {
  network: Network
}

export interface Network {
  id: string
  name: string
  tenant_id: string
  admin_state_up: boolean
  mtu: number
  status: string
  subnets: any[]
  shared: boolean
  availability_zone_hints: any[]
  availability_zones: any[]
  ipv4_address_scope: null
  ipv6_address_scope: null
  'router:external': boolean
  description: string
  dns_domain: string
  port_security_enabled: boolean
  tags: any[]
  created_at: string
  updated_at: string
  revision_number: number
  project_id: string
  'provider:network_type': string
  'provider:physical_network': string
  'provider:segmentation_id': number
}

export interface INetworkSelector extends Network {
  type: string
  subnetDetails: any[]
  availableIps: number
  ports: any[]
}
