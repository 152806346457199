import { CLUSTER_HOST_SCHEDULING } from 'app/constants'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { virtualMachinesByHostSelector } from 'openstack/components/vms/selectors'
import { intersection } from 'ramda'

const roleNamesMap = {
  // 'pf9-neutron-base': 'Networking Node',
  'pf9-ostackhost': 'Hypervisor',
  'pf9-ostackhost-neutron': 'Hypervisor',
  'pf9-cindervolume-config': 'Persistent Storage',
  'pf9-glance-role': 'Image Library',
  'pf9-support': 'Advanced Remote Support',
  'pf9-designate': 'DNS',
}

// Check for partial authorization of hypervisor roles
const hypervisorRoles = [
  'pf9-ostackhost-neutron',
  'pf9-neutron-base',
  'pf9-neutron-ovn-controller',
  'pf9-neutron-ovn-metadata-agent',
  'pf9-ceilometer',
]

const ovsHypervisorRoles = [
  'pf9-ostackhost-neutron',
  'pf9-neutron-base',
  'pf9-neutron-ovs-agent',
  'pf9-neutron-l3-agent',
  'pf9-neutron-dhcp-agent',
  'pf9-neutron-metadata-agent',
  'pf9-ceilometer',
]

const checkHypervisorRolesIncomplete = (host) => {
  if (host.roles?.includes('pf9-neutron-ovn-controller')) {
    const installedHypervisorRoles = intersection(hypervisorRoles, host.roles)
    return installedHypervisorRoles.length > 0 && installedHypervisorRoles.length < 5
  } else if (host.roles?.includes('pf9-neutron-ovs-agent')) {
    const installedHypervisorRoles = intersection(ovsHypervisorRoles, host.roles)
    return installedHypervisorRoles.length > 0 && installedHypervisorRoles.length < 7
  } else if (host.roles?.length > 0) {
    return true
  }
  return false
}

const checkVmwareRolesIncomplete = (host) => {
  const installedRoles = intersection(
    ['pf9-ostackhost-neutron-vmw', 'pf9-glance-role-vmw'],
    host.roles,
  )
  return installedRoles.length > 0 && !host.roles.includes('pf9-discovery-role')
}

export const calcResourceUtilization = (host) => {
  const usage = host?.extensions?.resource_usage?.data || null
  if (!usage || typeof usage === 'string') {
    return null
  }
  const { cpu, memory, disk } = usage

  const K = 1000
  const M = 1000 * K
  const G = 1000 * M
  const Ki = 1024
  const Mi = 1024 * Ki
  const Gi = 1024 * Mi

  return {
    compute: {
      current: cpu.used / G,
      max: cpu.total / G,
      units: 'GHz',
      type: 'used',
    },
    memory: {
      current: (memory.total - memory.available) / Gi,
      max: memory.total / Gi,
      units: 'GB',
      type: 'used',
    },
    disk: {
      current: disk.used / Gi,
      max: disk.total / Gi,
      units: 'GB',
      type: 'used',
    },
  }
}

const multiCalcResourceUtilization = (hosts) => {
  const resourceUsage = hosts.map((host) => calcResourceUtilization(host))
  const aggregateUsage = resourceUsage.reduce(
    (accum, usage) => {
      // Not sure how an empty usage was passed in before, but have a
      // way to catch that to avoid crash
      if (!usage?.compute || !usage?.memory || !usage?.disk) {
        return accum
      }
      return {
        compute: {
          ...accum.compute,
          current: accum.compute.current + usage.compute.current,
          max: accum.compute.max + usage.compute.max,
        },
        memory: {
          ...accum.memory,
          current: accum.memory.current + usage.memory.current,
          max: accum.memory.max + usage.memory.max,
        },
        disk: {
          ...accum.disk,
          current: accum.disk.current + usage.disk.current,
          max: accum.disk.max + usage.disk.max,
        },
      }
    },
    {
      compute: {
        current: 0,
        max: 0,
        units: 'GHz',
        type: 'used',
      },
      memory: {
        current: 0,
        max: 0,
        units: 'GB',
        type: 'used',
      },
      disk: {
        current: 0,
        max: 0,
        units: 'GB',
        type: 'used',
      },
    },
  )
  return aggregateUsage
}

// Simple host selector for VM creation form
export const imageLibrayHostSelector = createSharedSelector(
  getDataSelector<DataKeys.ResmgrHosts>(DataKeys.ResmgrHosts),
  (hosts) => {
    return hosts.find(
      (host) =>
        host.roles.includes('pf9-glance-role') || host.roles.includes('pf9-glance-role-vmw'),
    )
  },
)

export const hypervisorsSelector = createSharedSelector(
  getDataSelector<DataKeys.Hypervisors>(DataKeys.Hypervisors),
  (hypervisors) => {
    return hypervisors.map((hypervisor) => {
      // any model changes go here
      return {
        ...hypervisor,
      }
    })
  },
)

export const resmgrHostsSelector = createSharedSelector(
  getDataSelector<DataKeys.ResmgrHosts>(DataKeys.ResmgrHosts),
  virtualMachinesByHostSelector,
  hypervisorsSelector,
  (hosts, vmsByHost, hypervisors) => {
    const hypervisorsByResmgrId = hypervisors.reduce((accum, hypervisor) => {
      return {
        ...accum,
        [hypervisor?.service?.host]: hypervisor,
      }
    }, {})
    return hosts.map((host) => {
      // any model changes go here
      const ifaces = Object.entries(host?.extensions?.interfaces?.data?.iface_info || {})
        .map(([iface, info]: any) =>
          info?.ifaces?.map((_iface) => ({
            name: iface,
            ip: _iface.addr,
          })),
        )
        .flat()
      return {
        ...host,
        name: host?.info?.hostname,
        interfaces: ifaces,
        scheduling: hypervisorsByResmgrId[host.id] ?? 'N/A',
        schedulingStatus: CLUSTER_HOST_SCHEDULING[hypervisorsByResmgrId[host?.id]?.status] ?? 'N/A',
        interfacesSearch: ifaces.map((iface) => iface.ip),
        readableInterfaces: ifaces.map((iface) => `${iface.ip} (${iface.name})`),
        displayRoles: host?.roles
          ?.map((role) => {
            return roleNamesMap[role]
          })
          .filter((role) => !!role),
        hypervisorRolesIncomplete: checkHypervisorRolesIncomplete(host),
        vmwareRolesIncomplete: checkVmwareRolesIncomplete(host),
        usage: calcResourceUtilization(host),
        vms: vmsByHost?.[host.id] || [],
      }
    })
  },
)

export const resmgrHostsByIdSelector = createSharedSelector(resmgrHostsSelector, (hosts) => {
  return hosts.reduce((accum, host) => {
    return {
      ...accum,
      [host.id]: host,
    }
  }, {})
})

export const hypervisorsByHostIdSelector = createSharedSelector(
  hypervisorsSelector,
  (hypervisors) => {
    return hypervisors.reduce((accum, host) => {
      return {
        ...accum,
        [host?.service?.host]: host,
      }
    }, {})
  },
)

export const hostAggregatesSelector = createSharedSelector(
  getDataSelector<DataKeys.HostAggregates>(DataKeys.HostAggregates),
  resmgrHostsByIdSelector,
  hypervisorsByHostIdSelector,
  (aggregates, resmgrHostsById, hypervisorsById) => {
    return aggregates.map((aggregate) => {
      // any model changes go here
      const resmgrHosts = aggregate.hosts.map((hostId) => resmgrHostsById[hostId])
      return {
        ...aggregate,
        hostInfo: aggregate.hosts.map((hostId) => {
          return {
            name: resmgrHostsById[hostId]?.name,
            ip: hypervisorsById[hostId]?.host_ip,
          }
        }),
        usage: multiCalcResourceUtilization(resmgrHosts),
      }
    })
  },
)

export const hostNetworkConfigsSelector = createSharedSelector(
  getDataSelector<DataKeys.HostNetworkConfigs>(DataKeys.HostNetworkConfigs),
  (configs) => {
    return configs.map((config) => {
      // any model changes go here
      return config
    })
  },
)
