import { makeStyles } from '@material-ui/styles'
import { useAppSelector } from 'app/store'
import Alert from 'core/components/Alert'
import ExternalLink from 'core/components/ExternalLink'
import SimpleLink from 'core/components/SimpleLink'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { Route } from 'core/plugins/route'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import { loadBalancerHelpLink } from 'k8s/links'
import PicklistField from 'pf9-ui-components/built/components/validatedForm/DropdownField'
import TextField from 'pf9-ui-components/built/components/validatedForm/TextField'
import { middleLeft } from 'pf9-ui-components/built/elements/menu/defaults'
import ModalForm from 'pf9-ui-components/built/elements/modal/ModalForm'
import Text from 'pf9-ui-components/built/elements/Text'
import React, { useCallback, useState } from 'react'
import useReactRouter from 'use-react-router'
import { createPool, listOctaviaListener } from './actions'
import { getPoolRequestBody } from './helpers'
import { ILoadBalancerDetailsPageTabs } from './model'
import ListenerPicklist from './network-details/ListenerPicklist'
import LoadBalancingAlgorithmPicklist from './network-details/LoadBalancingAlgorithmPicklist'
import ProtocolPicklist from './network-details/ProtocolPicklist'
import { octaviaListenersSelector } from './selectors'

const useStyles = makeStyles<Theme>((theme) => ({
  formFieldSection: {
    marginBottom: '32px',
    border: 'none',
    padding: 0,
  },
  pageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'grid',
    gap: 16,
    gridAutoRows: 'max-content',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  iconWrapper: {
    display: 'flex',
    height: '32px',
    width: '32px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  customFormFieldSection: {
    '& .content': {
      marginLeft: '0px',
    },
  },
}))

interface Props {
  addRoute: Route
}

export default function CreatePoolsModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const imagePath = '/ui/images/pool-logo.svg'
  const classes = useStyles()
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)
  const defaultParams = {
    name: '',
    listener: '',
    protocol: '',
    loadBalancingAlgorithm: '',
  }
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)

  const { reload } = useListAction(listOctaviaListener, {})
  const { update, updating, error, reset } = useUpdateAction(createPool)
  useListAction(listOctaviaListener)
  const listeners = useAppSelector(octaviaListenersSelector)
  const isDisabled = listeners.length === 0
  const createPoolFn = async (params) => {
    const body = getPoolRequestBody(params)
    const { success, response } = await update({ body })
    return { success, response }
  }

  const submitForm = useCallback(async () => {
    setAttemptedSubmit(true)

    const { success } = await createPoolFn(params)
    if (success) {
      handleClose()
      reload()
    }
    setAttemptedSubmit(false)
  }, [params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(
      routes.openstack.loadBalancers.details.path({
        tab: ILoadBalancerDetailsPageTabs.Pools,
      }),
    )
  }
  const redirectLink = () => {
    history.push(
      routes.openstack.createLoadBalancers.path({
        tab: ILoadBalancerDetailsPageTabs.Listeners,
      }),
    )
  }
  return (
    <ModalForm
      route={addRoute}
      title={`Create Pool`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating || attemptedSubmit}
      error={error}
      submitTitle={`Create Pool`}
      maxWidth={470}
      disableSubmit={isDisabled}
    >
      <>
        <div className={classes.formFieldSection}>
          {isDisabled && (
            <div className={classes.formFieldSection}>
              <Alert title="No Listener Detected" variant="error">
                <Text
                  variant="body2"
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  In order to create a pool, you first need to create a listener.
                </Text>
                <SimpleLink onClick={redirectLink}>Create Listener</SimpleLink>
              </Alert>
            </div>
          )}
          <div className={classes.contentContainer}>
            <img className={classes.iconWrapper} alt="Pools" src={imagePath} />
            <div className={classes.content}>
              <Text
                variant="body2"
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                Create a <ExternalLink url={loadBalancerHelpLink}>pool</ExternalLink> that sets up a
                group of virtual machines that will handle the client requests. After creating the
                pool, you will be able to assign one or more members (virtual machines) to the pool.
              </Text>
            </div>
          </div>
        </div>
        <FormFieldSection className={classes.customFormFieldSection} title="Pool Configuration">
          <TextField
            id="name"
            label="Pool Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            disabled={isDisabled}
            required
            enterKeyHint={undefined}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
            crossOrigin={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
          <PicklistField
            DropdownComponent={ListenerPicklist}
            name="listeners"
            id={`fixedIps.{idx}.listener`}
            label="Listener"
            compact={false}
            onChange={getParamsUpdater('listener')}
            info="Routes the traffic to the appropriate pool."
            align={middleLeft.align}
            disabled={isDisabled}
            required
          />
          <PicklistField
            DropdownComponent={ProtocolPicklist}
            name="protocol"
            id={`fixedIps.{idx}.protocol`}
            label="Protocol"
            compact={false}
            onChange={getParamsUpdater('protocol')}
            info="Must match the listener protocol."
            align={middleLeft.align}
            disabled={isDisabled}
            required
          />
          <PicklistField
            DropdownComponent={LoadBalancingAlgorithmPicklist}
            name="loadBalancingAlgorithm"
            id={`fixedIps.{idx}.loadBalancingAlgorithm`}
            label="Load Balancing Algorithm"
            compact={false}
            onChange={getParamsUpdater('loadBalancingAlgorithm')}
            info="Only SOURCE_IP_PORT is currently supported with OVN provider."
            align={middleLeft.align}
            disabled={isDisabled}
            required
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
