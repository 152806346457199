import React, { useCallback, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import { rescueVmAction } from './actions'
import Info from 'core/components/validatedForm/Info'
import { getCurrentUser } from 'openstack/helpers'
import { hasOtherUserVm } from './helpers'
import CopyToClipboard from 'core/components/CopyToClipboard'
import CodeBlock from 'core/components/CodeBlock'
import useParams from 'core/hooks/useParams'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import ListTableField from 'core/components/validatedForm/ListTableField'
import { listImages } from '../images/actions'
import { imagesSelector } from '../images/selectors'
import { humanReadableSize } from 'openstack/helpers'
import { Labels } from 'k8s/components/common/entity/labels-and-annotations/LabelsOrAnnotations'
import ExternalLink from 'core/components/ExternalLink'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    gap: 16,
    display: 'grid',
    paddingBottom: 32, // To leave some room for the dropdown
  },
  inlineBlock: {
    display: 'inline-block',
  },
}))

const imageColumns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'disk_format',
    label: 'Disk Format',
  },
  {
    id: 'virtual_size',
    label: 'Virtual Disk Size',
    render: humanReadableSize,
  },
  {
    id: 'properties',
    label: 'Properties',
    disableSorting: true,
    render: (properties) => (
      <Labels
        labels={properties}
        separator="="
        containerType="table"
        maxVisible={1}
        showMoreButton
      />
    ),
  },
]

export default function RescueVmDialog({ rows: vms, onClose }) {
  const vm = vms?.[0]
  const classes = useStyles()
  const [adminPass, setAdminPass] = useState(null)
  const [showVmPassword, setShowVmPassword] = useState(false)
  const [error, setError] = useState(null)
  // Check if the VM is volume backed
  const isVolumeBackedInstance =
    vms?.[0]?.volumeIds?.length > 0 &&
    vms?.[0]?.volumeIds?.some((id) => {
      return vms?.[0]?.volumeDetails[id]?.bootable
    })
  const defaultParams = {
    selectImage: isVolumeBackedInstance, // Default to true for volume backed instances
    image: [],
  }
  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)

  const { loading: loadingImages } = useListAction(listImages, {
    params: {},
  })
  const images = useSelectorWithParams(imagesSelector, {})
  const validImages = useMemo(() => {
    return images.filter((image) => {
      return image.id !== vm?.image?.id
    })
  }, [images])

  const { update, updating, error: rescueError, reset } = useUpdateAction(rescueVmAction)

  const validateImage = () => {
    if (!isVolumeBackedInstance) return true

    if (params?.image?.length === 0) {
      setError({ title: '', message: 'Please select an image' })
      return false
    }
    if (!params?.image?.[0]?.properties?.['hw_rescue_device']) {
      setError({
        title: '',
        message: '`hw_rescue_device` property missing in the selected image',
      })
      return false
    }
    if (!params?.image?.[0]?.properties?.['hw_rescue_bus']) {
      setError({
        title: '',
        message: '`hw_rescue_bus` property missing in the selected image',
      })
      return false
    }
    return true
  }

  const handleSubmit = useCallback(async () => {
    if (!validateImage()) {
      return
    }

    const image = params.image?.[0]
    const body = {
      rescue: {
        rescue_image_ref: params.selectImage && image ? image?.id : undefined,
      },
    }
    const { success } = await update({ id: vm?.id, body })
    if (success) {
      handleClose()
    }
  }, [params, vm])

  const handleClose = () => {
    reset()
    setAdminPass(null)
    onClose(true)
  }

  const { role: userRole, userId } = getCurrentUser()

  return (
    <ModalForm
      title="Rescue VM"
      onSubmit={showVmPassword ? handleClose : handleSubmit}
      onClose={handleClose}
      loading={loadingImages}
      submitting={updating}
      error={error || rescueError}
      submitTitle={showVmPassword ? `Finish` : `Rescue VM`}
      panel="dialog"
      maxWidth={1000}
      open
    >
      {!showVmPassword ? (
        <div className={classes.fields}>
          {userRole === 'member' && hasOtherUserVm({ vms, userId }) && (
            <Info error>NOTE: You are performing an operation on another user's VM.</Info>
          )}
          <Text variant="body2">
            You are about to rescue the VM <strong>{vm?.name || vm?.id}</strong>.
          </Text>
          <Text variant="body2">
            Instance rescue provides a mechanism for access, even if an image renders the instance
            inaccessible.
          </Text>
          {isVolumeBackedInstance ? (
            <>
              <Text variant="body2">
                For volume backed instances, rescue image should be different from original image
                that was used to create root disk volume. <br />
                Rescue image must have <code>hw_rescue_device</code> and <code>hw_rescue_bus</code>
                properties set.{' '}
                <ExternalLink url="https://platform9.com/docs/private-cloud-director/private-cloud-director/deploying-workloads">
                  More info
                </ExternalLink>
              </Text>
              <Text variant="caption1">Select a rescue image:</Text>
            </>
          ) : (
            <div>
              <div className={classes.inlineBlock}>
                <CheckboxField
                  id="selectImage"
                  label="Rescue with Different Image"
                  value={params.selectImage}
                  onChange={getParamsUpdater('selectImage')}
                  info="If checked, rescue the VM using a different image. If unchecked, the VM will be rescued using its current base image."
                />
              </div>
            </div>
          )}
          {params.selectImage && (
            <ListTableField
              id="image"
              data={validImages}
              loading={loadingImages}
              columns={imageColumns}
              onChange={getParamsUpdater('image')}
              value={params.image}
              uniqueIdentifier="id"
              searchTargets={['name', 'disk_format']}
              emptyText="No images available"
              noRowsPerPage
              required
            />
          )}
        </div>
      ) : (
        <div className={classes.fields}>
          <Text variant="body2">Please use this password to login to the rescued instance.</Text>
          <Text variant="body2">
            It is important to store this password now, as this data will not be accessible once you
            exit this wizard.
          </Text>
          <div>
            <Text variant="body2">Password:</Text>
            <CopyToClipboard copyText={adminPass}>
              <CodeBlock>{adminPass}</CodeBlock>
            </CopyToClipboard>
          </div>
        </div>
      )}
    </ModalForm>
  )
}
