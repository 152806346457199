import React from 'react'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import clsx from 'clsx'
import Tooltip from 'core/elements/tooltip'
import { IVirtualMachinesSelector } from './model'
import Badge from 'core/elements/badge/Badge'
import { middleLeft } from 'core/elements/menu/defaults'

const getIconOrBubbleColor = (status, theme: Theme) =>
  ({
    active: theme.palette.green.main,
    paused: theme.palette.yellow.main,
    stopped: theme.palette.red.main,
    error: theme.palette.red.main,
    suspended: theme.palette.yellow.main,
    building: theme.palette.green.main,
  }[status] || theme.palette.red.main)

const getBadgeVariant = (status) =>
  ({
    active: 'success',
    paused: 'warning',
    stopped: 'error',
    error: 'error',
    suspended: 'warning',
    building: 'success',
    rescued: 'warning',
  }[status] || 'unknown')

const getBadgeText = (status, pausedPowerState, taskState) => {
  const text =
    {
      active: 'Active',
      paused: 'Paused',
      stopped: 'Stopped',
      error: 'Error',
      suspended: 'Suspended',
      building: 'Building',
      rescued: 'Rescued',
    }[status] || status
  return taskState ? `${text} (${taskState})` : pausedPowerState ? `${text} (paused)` : text
}

interface VmStateCellComponentProps extends GridCellProps<IVirtualMachinesSelector> {
  state: string
  setErrorVm?: (value: string) => void
  hosts?: any[]
}

const useStyles = makeStyles<Theme, VmStateCellComponentProps>((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    cursor: ({ state }) => (state && state.toLowerCase() === 'error' ? 'pointer' : 'default'),
  },
  icon: {
    marginRight: 5,
  },
  circle: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'minmax(22px, max-content) 1fr',
    whiteSpace: 'nowrap',
    gridGap: 5,
    justifyItems: 'center',
    '&:before': {
      content: '""',
      display: 'inherit',
      height: 12,
      width: 12,
      borderRadius: '50%',
      backgroundColor: ({ state }) => getIconOrBubbleColor(state, theme),
    },
  },
  error: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  migration: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    marginTop: 8,
  },
  circleIcon: {
    fontSize: '18px',
    color: '#fff',
  },
  circleSuccess: {
    background: theme.palette.graphGreen[100],
    color: theme.palette.graphGreen[500],
  },
  circleError: {
    background: theme.palette.graphRed[100],
    color: theme.palette.graphRed[500],
  },
  italic: {
    color: theme.components.typography.passive,
  },
}))

// const iconMap = new Map<
//   IClusterStatus | ApiServerHealthStatus | 'degraded',
//   { icon: string; classes: string }
// >([
//   ['fail', { icon: 'times', classes: '' }],
//   ['ok', { icon: 'check', classes: '' }],
//   ['pause', { icon: 'pause-circle', classes: '' }],
//   ['unknown', { icon: 'question-circle', classes: '' }],
//   ['error', { icon: 'exclamation-triangle', classes: '' }],
//   ['loading', { icon: 'sync', classes: 'fa-spin' }],
//   ['upgrade', { icon: 'arrow-circle-up', classes: '' }],
//   ['degraded', { icon: 'check', classes: '' }],
//   ['pending', { icon: 'pause-circle', classes: '' }],
// ])

// const noCircleStates = ['resized']

const hostOffline = (host) => {
  if (host?.info?.responding === false) {
    return true
  }
  return false
}

export default function VmStateCellComponent({
  item: {
    id,
    state,
    taskState,
    pausedPowerState,
    fault,
    migrationAttemptedFrom,
    migrationDestination,
    ...rest
  },
  setErrorVm,
  hosts,
}: VmStateCellComponentProps) {
  const classes = useStyles({ state })
  const isErrorState = state && state.toLowerCase() === 'error'
  const currentHost = rest?.['OS-EXT-SRV-ATTR:hypervisor_hostname']

  const hostIsOffline = hosts?.length
    ? hostOffline(hosts.find((host) => host.id === rest?.['OS-EXT-SRV-ATTR:host']))
    : false

  return (
    <>
      <div className={classes.root}>
        {/* {taskState && (
        <FontAwesomeIcon className={classes.icon} spin>
          sync
        </FontAwesomeIcon>
      )} */}
        {!taskState && state === 'resized' && (
          <Tooltip message="Resize confirmation action required">
            <FontAwesomeIcon className={classes.icon}>circle-exclamation</FontAwesomeIcon>
          </Tooltip>
        )}
        {isErrorState ? (
          <div
            className={classes.error}
            onClick={() => {
              if (isErrorState) setErrorVm(id)
            }}
          >
            <Badge
              variant={getBadgeVariant(state)}
              text={getBadgeText(state, pausedPowerState, taskState)}
            />
          </div>
        ) : ['migrating', 'resize_prep'].includes(taskState) ? (
          <Tooltip
            align={middleLeft.align}
            offset={middleLeft.offset}
            message={
              <>
                <div>Migration in-progress</div>
                <div>from: {migrationAttemptedFrom}</div>
                <div>to: {migrationDestination}</div>
              </>
            }
          >
            <Badge variant="warning" text="Migrating" />
          </Tooltip>
        ) : (
          <Badge
            variant={getBadgeVariant(state)}
            text={getBadgeText(state, pausedPowerState, taskState)}
          />
        )}
      </div>
      {migrationAttemptedFrom && !['migrating', 'resize_prep'].includes(taskState) ? (
        currentHost === migrationDestination ? (
          <div className={classes.migration}>
            <Tooltip
              align={middleLeft.align}
              offset={middleLeft.offset}
              message={
                <>
                  <div>Migration successful</div>
                  <div>from: {migrationAttemptedFrom}</div>
                  <div>to: {migrationDestination}</div>
                </>
              }
            >
              <FontAwesomeIcon solid className={clsx(classes.circleIcon, classes.circleSuccess)}>
                circle-check
              </FontAwesomeIcon>
            </Tooltip>
            migration succeeded
          </div>
        ) : (
          <div className={classes.migration}>
            <Tooltip
              align={middleLeft.align}
              offset={middleLeft.offset}
              message={
                <>
                  <div>Migration failed</div>
                  <div>from: {migrationAttemptedFrom}</div>
                  <div>to: {migrationDestination}</div>
                </>
              }
            >
              <FontAwesomeIcon solid className={clsx(classes.circleIcon, classes.circleError)}>
                circle-xmark
              </FontAwesomeIcon>
            </Tooltip>
            migration failed
          </div>
        )
      ) : null}
      {hostIsOffline ? (
        <Tooltip
          align={middleLeft.align}
          offset={middleLeft.offset}
          message={
            <>
              <div>Last known state: {getBadgeText(state, pausedPowerState, taskState)}</div>
              <div>Current state: Unknown</div>
            </>
          }
        >
          <div className={classes.italic}>
            (<i>host offline</i>)
          </div>
        </Tooltip>
      ) : null}
    </>
  )
}
