import { listTablePrefs } from 'app/constants'
import { listResmgrHosts } from 'app/plugins/openstack/components/infrastructure/actions'
import { resmgrHostsSelector } from 'app/plugins/openstack/components/infrastructure/selectors'
import { getDnsResourceStatus } from 'app/plugins/openstack/components/networks/dns/helper'
import { DnsPageTabs } from 'app/plugins/openstack/components/networks/dns/model'
import { listPools } from 'app/plugins/openstack/components/networks/dns/pools/actions'
import { deleteZone, listZones } from 'app/plugins/openstack/components/networks/dns/zones/actions'
import AddZonePage from 'app/plugins/openstack/components/networks/dns/zones/AddZonePage'
import DeleteZoneDialog from 'app/plugins/openstack/components/networks/dns/zones/DeleteZoneDialog'
import { zonesSelector } from 'app/plugins/openstack/components/networks/dns/zones/selectors'
import UpdateZonePage from 'app/plugins/openstack/components/networks/dns/zones/UpdateZonePage'
import ZoneCard from 'app/plugins/openstack/components/networks/dns/zones/ZoneCard'
import { useAppSelector } from 'app/store'
import DateCell from 'core/components/listTable/cells/DateCell'
import ListContainer from 'core/containers/ListContainer'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import { routes } from 'core/utils/routes'
import DataKeys from 'k8s/DataKeys'
import { pick } from 'ramda'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'

const columns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'status',
    label: 'Status',
    CellComponent: createGridStatusCell({
      dataFn: (status: any) => getDnsResourceStatus(status.status, status.action),
    }),
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'poolName',
    label: 'Pool',
  },
  {
    key: 'recordsCount',
    label: 'Records',
  },
  {
    key: 'created_at',
    label: 'Craeted',
    CellComponent: DateCell,
  },
]
const searchTargets = ['name']

const ZonesListPage = () => {
  const { history } = useReactRouter()
  const url = new URL(window.location.href)
  const selectedView = url.searchParams.get('view')

  const { params, getParamsUpdater } = useParams({})

  const [selectedZone, setSelectedZone] = useState()
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const { loading, message, reload } = useListAction(listZones)
  const { loading: loadingPools } = useListAction(listPools)
  useListAction(listResmgrHosts, {
    params: {
      orderBy: 'name',
      orderDirection: 'asc',
    },
  })
  const data = useAppSelector(zonesSelector)
  const hosts = useSelector(resmgrHostsSelector)

  const rowMenuItems: Array<GridRowMenuItemSpec<any>> = [
    {
      label: 'Edit',
      icon: 'edit',
      handleClick: (item) =>
        history.push(
          routes.openstack.editDnsResource.path({
            tab: DnsPageTabs.Zones,
            id: item?.id,
          }),
        ),
      refreshAfterSuccess: true,
    },
    {
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (zone) => {
        setSelectedZone(zone)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
  ]

  const hasDnsServiceEnabled = useMemo(
    () => !!hosts?.find((host) => host?.roles?.includes('pf9-designate')),
    [hosts],
  )

  return (
    <>
      <AddZonePage />
      <UpdateZonePage />
      {showDeleteDialog && (
        <DeleteZoneDialog rows={[selectedZone]} onClose={() => setShowDeleteDialog(false)} />
      )}
      <ListContainer<any, any>
        dataKey={DataKeys.DnsZones}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading || loadingPools}
        loadingMessage={message}
        onRefresh={reload}
        data={data || []}
        columns={columns}
        addUrl={routes.openstack.createDnsResource.path({
          tab: DnsPageTabs.Zones,
          view: selectedView,
        })}
        addText="Add DNS Zone"
        disableAddButton={!hasDnsServiceEnabled}
        disabledAddButtonInfo="To enable DNS as a Service, you must assign the 'DNS' role to one of your hypervisors. Without this assignment, DNS services will not function properly."
        editUrl={(item) =>
          routes.openstack.editDnsResource.path({
            tab: DnsPageTabs.Zones,
            id: item?.id,
            view: selectedView,
          })
        }
        editCond={(selectedItems) =>
          selectedItems[0]?.action !== 'DELETE' && selectedItems[0]?.status !== 'PENDING'
        }
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteZone}
        DeleteDialogComponent={DeleteZoneDialog}
        deleteCond={(selectedItems) =>
          selectedItems[0]?.action !== 'DELETE' && selectedItems[0]?.status !== 'PENDING'
        }
        rowMenuItems={rowMenuItems}
        CardComponent={ZoneCard}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}

export default ZonesListPage
