import { listZones } from 'app/plugins/openstack/components/networks/dns/zones/actions'
import { zonesSelector } from 'app/plugins/openstack/components/networks/dns/zones/selectors'
import { useAppSelector } from 'app/store'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'
import useListAction from 'core/hooks/useListAction'
import React from 'react'

const ZonePicklist = (props) => {
  const { loading } = useListAction(listZones)
  const data = useAppSelector(zonesSelector)

  const items = data.map(({ name, backend, id }) => ({
    label: `${name}${backend ? ` (${backend})` : ''}`,
    value: id,
  }))

  return <AsyncDropdown label="DNS Zone" loading={loading} items={items} {...props} />
}

export default ZonePicklist
