import { useAppSelector } from 'app/store'
import withProgress from 'core/components/progress/withProgress'
import Dropdown from 'core/elements/dropdown'
import useListAction from 'core/hooks/useListAction'
import { listLoadBalancers } from 'openstack/components/networks/actions'
import { loadBalancersSelector } from 'openstack/components/networks/selectors'
import React, { useMemo } from 'react'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function LoadBalancerPicklist({
  disabled = false,
  name = 'loadBalancers',
  label = 'Load Balancers',
  networkId = null,
  includeNoneOption = false,
  ...rest
}) {
  const { loading } = useListAction(listLoadBalancers)
  const loadBalancers = useAppSelector(loadBalancersSelector)
  const options = useMemo(() => {
    const loadBalancerOptions = loadBalancers.map((loadBalancer) => {
      return {
        label: loadBalancer?.name,
        value: loadBalancer?.id,
      }
    })
    return loadBalancerOptions
  }, [loadBalancers])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
