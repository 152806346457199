import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import { confirmResizeVirtualMachine } from './actions'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import Info from 'core/components/validatedForm/Info'
import { getCurrentUser } from 'openstack/helpers'
import { hasOtherUserVm } from './helpers'
import { useDispatch } from 'react-redux'
import { migrationActions } from './migrations/reducers'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    gap: 16,
    display: 'grid',
    paddingBottom: 32, // To leave some room for the dropdown
  },
}))

export default function ConfirmResizeDialog({ rows: vms, onClose }) {
  const vm = vms?.[0]
  const classes = useStyles()
  const dispatch = useDispatch()

  const defaultParams = {
    revertResize: false,
  }
  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(confirmResizeVirtualMachine)
  // const { update: refreshVm, updating: refreshingVm } = useUpdateAction(refreshVirtualMachine)

  const handleSubmit = useCallback(async () => {
    const body = params.revertResize
      ? {
          revertResize: null,
        }
      : {
          confirmResize: null,
        }
    const { success } = await update({ id: vm?.id, body })
    if (success) {
      dispatch(migrationActions.removeMigration({ id: vm?.id }))
      handleClose()
    }
  }, [params, vm])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose(true)
  }

  const { role: userRole, userId } = getCurrentUser()

  return (
    <ModalForm
      title="Confirm Resize"
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={false}
      submitting={updating}
      error={error}
      submitTitle={params.revertResize ? `Revert Resize` : `Confirm Resize`}
      panel="dialog"
      // maxWidth={1200}
      open
    >
      <div className={classes.fields}>
        {userRole === 'member' && hasOtherUserVm({ vms, userId }) && (
          <Info error>NOTE: You are performing an operation on another user's VM.</Info>
        )}
        <Text variant="body2">
          Confirm the resize of this VM. If you would like to revert the resize of this VM, check
          the box below.
        </Text>
        <CheckboxField
          id="revertResize"
          label="Check to Revert Resize"
          value={params.revertResize}
          onChange={getParamsUpdater('revertResize')}
        />
      </div>
    </ModalForm>
  )
}
