import { makeStyles } from '@material-ui/styles'
import { listTablePrefs } from 'app/constants'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import Theme from 'core/themes/model'
import React, { useEffect, useMemo, useState } from 'react'
import { GridViewColumn } from 'core/elements/grid/Grid'
import ListContainer from 'core/containers/ListContainer'
import { pick } from 'ramda'
import { getStackResources } from './actions'
import DataKeys from 'k8s/DataKeys'
import { durationBetweenDates } from 'utils/misc'

// type ModelDataKey = DataKeys.OpenstackSubnets
// type SelectorModel = ArrayElement<ReturnType<typeof any>>

const useStyles = makeStyles<Theme>((theme) => ({
  resources: {
    marginTop: '16px',
  },
}))

const columns: GridViewColumn<any>[] = [
  {
    key: 'resource_name',
    label: 'Name',
    width: 'medium',
  },
  {
    key: 'resource_type',
    label: 'Type',
  },
  {
    key: 'logical_resource_id',
    label: 'Logical ID',
  },
  {
    key: 'physical_resource_id',
    label: 'Resource ID',
  },
  {
    key: 'resource_status',
    label: 'Status',
  },
  {
    key: 'resource_status_reason',
    label: 'Reason',
  },
  {
    key: 'creation_time',
    label: 'Age',
    formatFn: (value) => durationBetweenDates({ labels: ['d'] })(value),
  },
  {
    key: 'updated_time',
    label: 'Updated',
    formatFn: (value) => (value ? durationBetweenDates({ labels: ['d'] })(value) : ''),
  },
  {
    key: 'required_by',
    label: 'Required By',
    formatFn: (value: string[]) => value.join(', '),
  },
]

const usePrefParams = createUsePrefParamsHook('StackResources', listTablePrefs)

export default function SubnetsPage({ stack, loading }) {
  const classes = useStyles()
  const { params, getParamsUpdater } = usePrefParams({})
  const [loadingResources, setLoadingResources] = useState(true)
  const [resources, setResources] = useState([])
  const rest = pick(listTablePrefs, params)

  useEffect(() => {
    const makeCalls = async () => {
      setLoadingResources(true)
      const response = await getStackResources({ id: stack?.id, name: stack?.stack_name })
      setResources(response)
      setLoadingResources(false)
    }
    makeCalls()
  }, [stack])

  const reloadResources = useMemo(() => {
    return () => {
      const makeCalls = async () => {
        setLoadingResources(true)
        const response = await getStackResources({ id: stack?.id, name: stack?.stack_name })
        setResources(response)
        setLoadingResources(false)
      }
      makeCalls()
    }
  }, [stack])

  return (
    <article className={classes.resources}>
      <ListContainer
        showBreadcrumbs={false}
        dataKey={DataKeys.OpenstackSubnets}
        searchTargets={['name']}
        uniqueIdentifier="id"
        loading={loading || loadingResources}
        // loadingMessage={message}
        onRefresh={() => {
          reloadResources()
        }}
        data={resources}
        columns={columns}
        getParamsUpdater={getParamsUpdater}
        {...rest}
      />
    </article>
  )
}
