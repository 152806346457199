import React, { useEffect, useMemo, useState } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import { getHostNetworkConfigs } from 'openstack/components/infrastructure/actions'
import { flatten, uniq } from 'ramda'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function PhysicalNetworkLabelPicklist({
  disabled = false,
  name = 'networkLabel',
  label = 'Network Label',
  ...rest
}) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    const getLabels = async () => {
      const hostNetworkConfigs = await getHostNetworkConfigs()
      const labelValues = hostNetworkConfigs.map((hostNetworkConfig) =>
        Object.keys(hostNetworkConfig.networkLabels),
      )
      setData(uniq(flatten(labelValues)))
      setLoading(false)
    }
    getLabels()
  }, [])

  const options = useMemo(() => {
    return data.map((label) => {
      return {
        label: label,
        value: label,
      }
    })
  }, [data])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
