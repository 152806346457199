import { makeStyles } from '@material-ui/styles'
import DnsPoolPicklist from 'app/plugins/openstack/components/networks/dns/zones/DnsPoolPicklist'
import ZoneTypePicklist from 'app/plugins/openstack/components/networks/dns/zones/ZoneTypePicklist'
import PicklistField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import { middleLeft } from 'core/elements/menu/defaults'
import Text from 'core/elements/Text'
import Theme from 'core/themes/model'
import {
  emailValidator,
  maxValueValidator,
  minValueValidator,
  validZoneNameValidator,
} from 'core/utils/fieldValidators'
import React from 'react'
import useReactRouter from 'use-react-router'

const ZoneConfigurationFields = ({ params, getParamsUpdater }) => {
  const classes = useStyles({})
  const { history } = useReactRouter()

  const isZoneEditPath = history.location.pathname.includes('edit')

  return (
    <>
      <Text className={classes.configText} variant="subtitle2">
        Zone Configuration
      </Text>
      <div className={classes.formFields}>
        <TextField
          id="zoneName"
          label="Zone Name"
          placeholder="Enter Zone Name"
          required
          value={params.zoneName}
          validations={[validZoneNameValidator]}
          onChange={getParamsUpdater('zoneName')}
          info="The domain name for this zone, e.g. example.com. This is the primary identifier for your DNS zone."
          align={middleLeft.align}
          disabled={isZoneEditPath}
        />
        <PicklistField
          DropdownComponent={ZoneTypePicklist}
          id="zoneType"
          label="Zone Type"
          onChange={getParamsUpdater('zoneType')}
          value={params.zoneType}
          required
          info="Primary zones allow you to create and edit DNS records directly. Secondary zones are read-only copies of zones hosted elsewhere."
          align={middleLeft.align}
          disabled={isZoneEditPath}
        />
        <PicklistField
          DropdownComponent={DnsPoolPicklist}
          id="dnsPool"
          label="DNS Pool"
          onChange={getParamsUpdater('dnsPool')}
          value={params.dnsPool}
          required
          info="The DNS server pool that will host this zone. Different pools may have different configurations or geographical locations."
          align={middleLeft.align}
          disabled={isZoneEditPath}
        />
        <TextField
          id="adminEmail"
          label="Admin Email"
          placeholder="Enter Admin Email (eg. admin@example.com)"
          required
          value={params.adminEmail}
          onChange={getParamsUpdater('adminEmail')}
          validations={[emailValidator]}
          info="Contact email for the zone administrator. This appears in the SOA record and is used for notifications about the zone."
          align={middleLeft.align}
        />
        <TextField
          id="defaultTtl"
          label="Default TTL"
          type="number"
          validations={[minValueValidator(300), maxValueValidator(86400)]} //5 minutes, 24 hours
          required
          value={params.defaultTtl}
          onChange={(value) => getParamsUpdater('defaultTtl')(value as number)}
          info="Time-to-Live in seconds. This determines how long DNS records can be cached by resolvers before requesting fresh data."
          align={middleLeft.align}
        />
      </div>
      {/* no backend support for this so, commenting as of now  */}
      {/* <Text className={classes.soaSettingsText} variant="subtitle2">
        SOA Settings
      </Text>
      <div className={classes.soaSettingFields}>
        <TextField
          id="refresh"
          label="Refresh"
          type="number"
          validations={[minValueValidator(3600), maxValueValidator(86400)]} //1 hour, 24 hours
          required
          value={params.refresh}
          onChange={(value) => getParamsUpdater('refresh')(value as number)}
          info="The time interval (in seconds) before a secondary DNS server checks for updates.Min: 3600 (1 hour)Max: 86400 (24 hours)"
          align={middleLeft.align}
          disabled={isZoneEditPath}
        />
        <TextField
          id="retry"
          label="Retry"
          type="number"
          validations={[minValueValidator(600), maxValueValidator(10800)]} //10 minutes, 3 hours
          required
          value={params.retry}
          onChange={(value) => getParamsUpdater('retry')(value as number)}
          info="The time interval (in seconds) a secondary server waits before retrying a failed zone transfer.
Min: 600 (10 minutes)Max: 10800 (3 hours)"
          align={middleLeft.align}
          disabled={isZoneEditPath}
        />
        <TextField
          id="expire"
          label="Expire"
          type="number"
          validations={[minValueValidator(604800), maxValueValidator(2419200)]} //1 week, 4 weeks
          required
          value={params.expire}
          onChange={(value) => getParamsUpdater('expire')(value as number)}
          info="The time interval (in seconds) after which the zone data on a secondary server is considered no longer authoritative.
Min: 604800 (1 week)Max: 2419200 (4 weeks)"
          align={middleLeft.align}
          disabled={isZoneEditPath}
        />
        <TextField
          id="minimumTtl"
          label="Minimum TTL"
          type="number"
          validations={[minValueValidator(300), maxValueValidator(86400)]} //5 minutes, 24 hours
          required
          value={params.minimumTtl}
          onChange={(value) => getParamsUpdater('minimumTtl')(value as number)}
          info="The minimum Time-to-Live value for all records in this zone. This affects caching for negative responses.
Min: 300 (5 minutes)Max: 86400 (24 hours)"
          align={middleLeft.align}
          disabled={isZoneEditPath}
        />
      </div> */}
    </>
  )
}

export default ZoneConfigurationFields
const useStyles = makeStyles<Theme>((theme) => ({
  configText: {
    marginBottom: theme.spacing(2),
  },
  soaSettingsText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    paddingLeft: 0,
  },
  content: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
  },
  formFields: {
    display: 'grid',
    gridTemplateRows: `repeat(3, auto)`,
    gap: theme.spacing(2),
  },
  soaSettingFields: {
    display: 'grid',
    gridTemplateColumns: `repeat(2, auto)`,
    gap: theme.spacing(3),
  },
}))
