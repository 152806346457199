import React, { useCallback, useEffect, useState } from 'react'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteImage, getHostGlanceEndpoint } from './actions'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import Modal from 'core/elements/modal'

export default function DeleteImage({ rows: [item], onClose }) {
  const [showWarning, setShowWarning] = useState(false)
  useEffect(() => {
    const asyncCall = async () => {
      const endpoint = await getHostGlanceEndpoint()
      if (!endpoint) {
        setShowWarning(true)
      }
    }
    asyncCall()
  }, [])

  const { update: deleteFn, updating: deletingItem, error } = useUpdateAction(deleteImage)

  const handleDelete = useCallback(async () => {
    const { success } = await deleteFn({ id: item?.id, queued: item?.status === 'queued' })
    if (success) {
      setShowWarning(false)
      onClose(true)
    }
  }, [item])

  return (
    <Modal
      panel="dialog"
      title="Delete Image"
      onClose={onClose}
      open
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingItem}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={deletingItem}>
            Confirm
          </Button>
        </>
      }
    >
      <>
        {showWarning ? (
          <Text variant="body2">
            You are about to delete an image that belongs to a host without an Image Library role.
            This action cannot be undone. Do you want to proceed?
          </Text>
        ) : (
          <Text variant="body2">This will permanently delete {item?.name}</Text>
        )}
        {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
      </>
    </Modal>
  )
}
