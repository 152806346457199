import React, { useCallback, useMemo, useState } from 'react'
import PicklistField from 'core/components/validatedForm/DropdownField'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { bootableVolume, createVolume } from './actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listImages } from 'openstack/components/images/actions'
import { imagesSelector } from 'openstack/components/images/selectors'
import { listVolumes } from './actions'
import { volumesSelector } from './selectors'
import { listVolumeSnapshots } from '../snapshots/actions'
import { volumeSnapshotsSelector } from '../snapshots/selectors'
import ListTableField from 'core/components/validatedForm/ListTableField'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { humanReadableSize } from 'openstack/helpers'
import { durationBetweenDates } from 'utils/misc'
import TextField from 'core/components/validatedForm/TextField'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import Text from 'core/elements/Text'
import PaginatedSelectableCards from 'core/components/PaginatedSelectableCards'
import VolumeSourceCards from './VolumeSourceCards'
import SelectableCard from 'core/components/SelectableCard'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import UnitPicklist from './UnitPicklist'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import VolumeTypesPicklist from '../volume-types/VolumeTypesPicklist'
import ServiceUnhealthyInfo from 'openstack/components/common/ServiceUnhealthyInfo'

const useStyles = makeStyles<Theme>((theme) => ({
  withUnits: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  unitsDropdown: {
    position: 'relative',
    bottom: 1,
  },
}))

interface Props {
  addRoute: Route
}

const imageColumns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'disk_format',
    label: 'Disk Format',
  },
  {
    id: 'virtual_size',
    label: 'Virtual Disk Size',
    render: humanReadableSize,
  },
  {
    id: 'created_at',
    label: 'Age',
    render: (value) => durationBetweenDates({ labels: ['d'] })(value),
  },
]

const volumeColumns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'size',
    label: 'Capacity',
    render: (size) => humanReadableSize(size * 1024 * 1024 * 1024),
  },
  {
    id: 'bootable',
    label: 'Bootable',
  },
]

const snapshotColumns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'size',
    label: 'Capacity',
    render: (size) => humanReadableSize(size * 1024 * 1024 * 1024),
  },
]

export default function CreateVolumeModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const [volumeLimitError, setVolumeLimitError] = useState(null)
  const classes = useStyles()
  const defaultParams = {
    name: '',
    sourceImage: null,
    sourceVolume: null,
    sourceSnapshot: null,
    volumeSource: 'none',
    description: '',
    size: null,
    unit: 'GiB',
    volumeType: '',
    bootable: false,
  }
  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)

  const { loading: loadingImages } = useListAction(listImages, {
    params: {},
  })
  const images = useSelectorWithParams(imagesSelector, {})
  const activeImages = useMemo(() => {
    return images.filter((image) => image.status === 'active')
  }, [images])

  const { loading: loadingVolumes } = useListAction(listVolumes, {
    params: {},
  })
  const volumes = useSelectorWithParams(volumesSelector, {})

  const { loading: loadingVolumeSnapshots } = useListAction(listVolumeSnapshots, {
    params: {},
  })
  const volumeSnapshots = useSelectorWithParams(volumeSnapshotsSelector, {})

  const { update, updating, error, reset } = useUpdateAction(createVolume)

  const {
    update: updateBootVolume,
    error: bootVolumeError,
    reset: bootVolumeReset,
  } = useUpdateAction(bootableVolume)

  const submitForm = useCallback(async () => {
    const body = {
      volume: {
        name: params.name,
        description: params.description,
        size: params.unit === 'GiB' ? params.size : params.size * 1024,
        source_volid: params.volumeSource === 'volume' ? params.sourceVolume?.[0]?.id : undefined,
        snapshot_id:
          params.volumeSource === 'snapshot' ? params.sourceSnapshot?.[0]?.id : undefined,
        imageRef: params.volumeSource === 'image' ? params.sourceImage?.[0]?.id : undefined,
        volume_type: params.volumeType ? params.volumeType : undefined,
      },
    }

    const { success, response } = await update({ body })
    if (response['overLimit']) {
      const errorBlock = {
        title: 'Limit Exceeded',
        message: response['overLimit']?.message || 'Volume size limit exceeded',
        raw: null,
      }
      setVolumeLimitError(errorBlock)
      return
    }

    setVolumeLimitError(null)
    const { success: bootSuccess } = await updateBootVolume({
      id: response.id,
      isBootable: params.bootable,
    })

    if (success && bootSuccess) {
      handleClose()
    }
  }, [params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    bootVolumeReset()
    history.push(routes.openstack.volumes.path())
  }

  return (
    <ModalForm
      route={addRoute}
      title={`Create New Volume`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={volumeLimitError || error || bootVolumeError}
      submitTitle={`Create Volume`}
      maxWidth={1200}
    >
      <>
        <ServiceUnhealthyInfo action="Volume Creation" blockStorage />
        <FormFieldSection title="Choose a Source" step={1}>
          <Text variant="body2">
            The volume will be bootable if the source is an image, another bootable volume, or a
            snapshot of a bootable volume.
          </Text>
          <VolumeSourceCards
            onClick={getParamsUpdater('volumeSource')}
            value={params.volumeSource}
          />
          {params.volumeSource === 'image' && (
            <ListTableField
              id="sourceImage"
              data={activeImages}
              loading={loadingImages}
              columns={imageColumns}
              onChange={getParamsUpdater('sourceImage')}
              value={params.sourceImage}
              uniqueIdentifier="id"
              searchTargets={['name']}
              required
            />
          )}
          {params.volumeSource === 'volume' && (
            <ListTableField
              id="sourceVolume"
              data={volumes}
              loading={loadingVolumes}
              columns={volumeColumns}
              onChange={getParamsUpdater('sourceVolume')}
              value={params.sourceVolume}
              uniqueIdentifier="id"
              searchTargets={['name']}
              required
            />
          )}
          {params.volumeSource === 'snapshot' && (
            <ListTableField
              id="sourceSnapshot"
              data={volumeSnapshots}
              loading={loadingVolumeSnapshots}
              columns={snapshotColumns}
              onChange={getParamsUpdater('sourceSnapshot')}
              value={params.sourceSnapshot}
              uniqueIdentifier="id"
              searchTargets={['name']}
              required
            />
          )}
        </FormFieldSection>
        <FormFieldSection title="Volume Configuration" step={2}>
          <TextField
            id="name"
            label="Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            required
          />
          <TextField
            id="description"
            label="Description"
            onChange={getParamsUpdater('description')}
            value={params.description}
          />
          <div className={classes.withUnits}>
            <TextField
              id="size"
              label="Size"
              // @ts-ignore complaint about string vs number
              onChange={getParamsUpdater('size')}
              value={params.size}
              type="number"
              min={1}
              required
            />
            <UnitPicklist
              name="unit"
              value={params.unit}
              onChange={getParamsUpdater('unit')}
              className={classes.unitsDropdown}
            />
          </div>
          <PicklistField
            DropdownComponent={VolumeTypesPicklist}
            id="volumeType"
            onChange={getParamsUpdater('volumeType')}
            value={params.volumeType}
            label="Volume Type"
            includeNoneOption
            // tooltip=""
            // required
          />
          <CheckboxField
            id="bootable"
            label="Make Bootable"
            value={params.bootable}
            onChange={getParamsUpdater('bootable')}
            info="Bootable volumes may be used to create new virtual machine instances using that volume as its primary disk."
          />
        </FormFieldSection>
        {/* <FormFieldSection title="Metadata" step={3}>
          <div>Metadata goes here</div>
        </FormFieldSection> */}
      </>
    </ModalForm>
  )
}
