import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { ILoadBalancingAlgorithm } from '../model'

const options = [
  { label: ILoadBalancingAlgorithm.SOURCE_IP_PORT, value: ILoadBalancingAlgorithm.SOURCE_IP_PORT },
  { label: ILoadBalancingAlgorithm.ROUND_ROBIN, value: ILoadBalancingAlgorithm.ROUND_ROBIN },
  {
    label: ILoadBalancingAlgorithm.LEST_CONNECTIONS,
    value: ILoadBalancingAlgorithm.LEST_CONNECTIONS,
  },
]

const algorithmOptions = () => {
  return options.map((option) => ({
    label:
      option.label === ILoadBalancingAlgorithm.SOURCE_IP_PORT
        ? option.label
        : `${option.label} (Not Supported)`,
    value: option.value,
    disabled: option.value !== ILoadBalancingAlgorithm.SOURCE_IP_PORT,
  }))
}
interface Props {}

export default function LoadBalancingAlgorithmPicklist({
  name = 'type',
  label = 'Type',
  compact = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return (
    <AsyncDropdown
      compact={compact}
      name={name}
      label={label}
      items={algorithmOptions()}
      {...rest}
    />
  )
}
