import {
  deleteRecord,
  listRecords,
} from 'app/plugins/openstack/components/networks/dns/records/actions'
import Alert from 'core/components/Alert'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import Text from 'core/elements/Text'
import useListAction from 'core/hooks/useListAction'
import useUpdateAction from 'core/hooks/useUpdateAction'
import React from 'react'

const DeleteRecordDialog = ({ rows, onClose }) => {
  const record = rows[0]

  const { reload } = useListAction(listRecords)
  const { update, updating, error } = useUpdateAction(deleteRecord)
  const handleDelete = async () => {
    const { success } = await update({ zoneId: record?.zoneId, recordId: record?.id })
    if (success) {
      onClose(true)
      reload(true)
    }
  }
  return (
    <ConfirmationDialog
      open
      text={
        <>
          <Text variant="body2">This record will be permanently deleted.</Text>
          <Text variant="caption1">{record?.name}</Text>
        </>
      }
      onCancel={onClose}
      onConfirm={handleDelete}
      error={error}
      loading={updating}
    />
  )
}

export default DeleteRecordDialog
