import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import { routes } from 'core/utils/routes'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listVirtualMachines, listAllVirtualMachines } from '../actions'
import { virtualMachinesSelector, allVirtualMachinesSelector } from '../selectors'
import { IVmDetailsPageTabs } from './model'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Overview from './Overview'
import { BatchActionDropdown } from 'core/elements/grid/GridToolbar'
import { getDialogButton } from './helper'
import PowerVirtualMachineDialog from '../PowerVirtualMachineDialog'
import RescueVmDialog from '../RescueVmDialog'
import UnrescueVmDialog from '../UnrescueVmDialog'
import { RootState } from 'app/store'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { prop } from 'ramda'
import RebuildVirtualMachineDialog from '../RebuildVirtualMachineDialog'
import MigrateVirtualMachineDialog from '../MigrateVirtualMachineDialog'
import ConfirmResizeDialog from '../ConfirmResizeDialog'
import ResizeVirtualMachineDialog from '../ResizeVirtualMachineDialog'
import EditVmMetadataModal from '../EditVmMetadataModal'
import EditVmSecurityGroupsModal from '../EditVmSecurityGroupsModal'
import RemoveFloatingIpDialog from '../RemoveFloatingIpDialog'
import AddFloatingIpDialog from '../AddFloatingIpDialog'
import RemoveFixedIpDialog from '../RemoveFixedIpDialog'
import AddFixedIpModal from '../AddFixedIpModal'
import ManageVolumesModal from '../ManageVolumesModal'
import SnapshotVirtualMachineDialog from '../SnapshotVirtualMachineDialog'
import RenameVirtualMachineDialog from '../RenameVirtualMachineDialog'
import { HeaderPrimaryActionPortal, HeaderRefreshPortal } from 'core/elements/header/portals'
import { GridBatchActionGroupProps } from 'core/elements/grid/hooks/useGridSelectableRows'
import { ArrayElement } from 'core/actions/Action'
import Button from 'core/elements/button/Button'

const useStyles = makeStyles<Theme>((theme) => ({
  grid: {
    display: 'grid',
    gap: 32,
  },
  actions: {
    display: 'grid',
    gridAutoColumns: 'auto max-content',
    gridAutoFlow: 'column',
    gap: 16,
  },
  dropdownAction: {
    background: theme.components.dropdown.background,
    border: 'none',
    '& .button-text': {
      color: theme.components.dropdown.color,
      justifyContent: 'flex-start',
    },
  },
}))

type SelectorModel = ArrayElement<ReturnType<typeof virtualMachinesSelector>>

const VmDetailsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id } = match.params
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const userRole = session?.userDetails?.role

  const [allTenantVM, setAllTenantVM] = useState(false)
  const { loading, reload: reloadVirtualVMs } = useListAction(listVirtualMachines)
  const vms = useSelectorWithParams(virtualMachinesSelector, {})
  const { loading: loadingAllVms, reload: reloadAllVMs } = useListAction(listAllVirtualMachines)
  const allVms = useSelectorWithParams(allVirtualMachinesSelector, {})

  // Try finding VM in tenant VM list, if not found find in all Tenant's VM list
  const vm = useMemo(() => {
    const matchingVM = vms?.find((vm) => vm?.id === id)
    if (matchingVM) {
      setAllTenantVM(false)
      return matchingVM
    }
    setAllTenantVM(true)
    return allVms?.find((vm) => vm?.id === id)
  }, [id, vms, allVms])

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        id: vm?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [vm?.name, id])

  const dropdownBatchActions: GridBatchActionGroupProps<SelectorModel>[] = useMemo(
    () => [
      {
        icon: '',
        label: 'Power Actions',
        actions: [
          {
            key: 'Start',
            label: 'Start',
            icon: 'circle-play',
            disabled: !['shutoff', 'stopped'].includes(vm?.state),
            BatchActionButton: getDialogButton<SelectorModel, any>(
              PowerVirtualMachineDialog,
              { action: 'start', rows: [vm] },
              { className: classes.dropdownAction },
            ),
          },
          {
            key: 'Stop',
            label: 'Stop',
            icon: 'stop',
            disabled: vm?.state !== 'active',
            BatchActionButton: getDialogButton<SelectorModel, any>(
              PowerVirtualMachineDialog,
              { action: 'stop', rows: [vm] },
              { className: classes.dropdownAction },
            ),
          },
          {
            key: 'Reboot',
            label: 'Reboot',
            icon: 'repeat',
            disabled: vm?.state !== 'active',
            BatchActionButton: getDialogButton<SelectorModel, any>(
              PowerVirtualMachineDialog,
              { action: 'reboot', type: 'SOFT', rows: [vm] },
              { className: classes.dropdownAction },
            ),
          },
          {
            key: 'HardReboot',
            label: 'Hard Reboot',
            icon: 'rotate-exclamation',
            disabled: vm?.state !== 'active',
            BatchActionButton: getDialogButton<SelectorModel, any>(
              PowerVirtualMachineDialog,
              { action: 'reboot', type: 'HARD', rows: [vm] },
              { className: classes.dropdownAction },
            ),
          },
          {
            key: 'Suspend',
            label: 'Suspend',
            icon: 'pause',
            disabled: vm?.state !== 'active',
            BatchActionButton: getDialogButton<SelectorModel, any>(
              PowerVirtualMachineDialog,
              { action: 'suspend', rows: [vm] },
              { className: classes.dropdownAction },
            ),
          },
          {
            key: 'Resume',
            label: 'Resume',
            icon: 'play',
            disabled: vm?.state !== 'suspended',
            BatchActionButton: getDialogButton<SelectorModel, any>(
              PowerVirtualMachineDialog,
              { action: 'resume', rows: [vm] },
              { className: classes.dropdownAction },
            ),
          },
          {
            key: 'Rescue',
            label: 'Rescue',
            icon: 'laptop-medical',
            disabled: vm?.state === 'rescued',
            BatchActionButton: getDialogButton<SelectorModel, any>(
              RescueVmDialog,
              { rows: [vm] },
              {
                className: classes.dropdownAction,
              },
            ),
          },
          {
            key: 'Unrescue',
            label: 'Unrescue',
            icon: 'laptop-binary',
            disabled: vm?.state !== 'rescued',
            BatchActionButton: getDialogButton<SelectorModel, any>(
              UnrescueVmDialog,
              { rows: [vm] },
              {
                className: classes.dropdownAction,
              },
            ),
          },
        ],
      },
      {
        label: 'Other Actions',
        icon: '',
        actions: [
          {
            key: 'Rename',
            label: 'Rename',
            icon: 'edit',
            disabled: false,
            BatchActionButton: getDialogButton<SelectorModel, any>(
              RenameVirtualMachineDialog,
              { rows: [vm] },
              {
                className: classes.dropdownAction,
              },
            ),
          },
          {
            key: 'Snapshot',
            label: 'Snapshot',
            icon: 'camera',
            disabled: false,
            BatchActionButton: getDialogButton<SelectorModel, any>(
              SnapshotVirtualMachineDialog,
              { rows: [vm] },
              {
                className: classes.dropdownAction,
              },
            ),
          },
          {
            key: 'ManageVolumes',
            label: 'Manage Volumes',
            icon: 'hard-drive',
            disabled: !['active', 'suspended', 'stopped'].includes(vm?.state),
            BatchActionButton: getDialogButton<SelectorModel, any>(
              ManageVolumesModal,
              { rows: [vm] },
              {
                className: classes.dropdownAction,
              },
            ),
          },
          {
            key: 'AddPrivateIP',
            label: 'Add Private IP',
            icon: 'square-plus',
            disabled: false,
            BatchActionButton: getDialogButton<SelectorModel, any>(
              AddFixedIpModal,
              { rows: [vm] },
              {
                className: classes.dropdownAction,
              },
            ),
          },
          {
            key: 'RemovePrivateIP',
            label: 'Remove Private IP',
            icon: 'square-minus',
            disabled: false,
            BatchActionButton: getDialogButton<SelectorModel, any>(
              RemoveFixedIpDialog,
              { rows: [vm] },
              {
                className: classes.dropdownAction,
              },
            ),
          },
          {
            key: 'AddPublicIP',
            label: 'Add Public IP',
            icon: 'square-plus',
            disabled: false,
            BatchActionButton: getDialogButton<SelectorModel, any>(
              AddFloatingIpDialog,
              { rows: [vm] },
              {
                className: classes.dropdownAction,
              },
            ),
          },
          {
            key: 'RemovePublicIP',
            label: 'Remove Public IP',
            icon: 'square-minus',
            disabled: false,
            BatchActionButton: getDialogButton<SelectorModel, any>(
              RemoveFloatingIpDialog,
              { rows: [vm] },
              {
                className: classes.dropdownAction,
              },
            ),
          },
          {
            key: 'EditSecurityGroups',
            label: 'Edit Security Groups',
            icon: 'lock',
            disabled: false,
            BatchActionButton: getDialogButton<SelectorModel, any>(
              EditVmSecurityGroupsModal,
              { rows: [vm] },
              {
                className: classes.dropdownAction,
              },
            ),
          },
          {
            key: 'EditMetadata',
            label: 'Edit Metadata',
            icon: 'tags',
            disabled: false,
            BatchActionButton: getDialogButton<SelectorModel, any>(
              EditVmMetadataModal,
              { rows: [vm] },
              {
                className: classes.dropdownAction,
              },
            ),
          },
          {
            key: 'Resize',
            label: 'Resize',
            icon: 'up-right-and-down-left-from-center',
            disabled: vm?.state === 'resized',
            BatchActionButton: getDialogButton<SelectorModel, any>(
              ResizeVirtualMachineDialog,
              { rows: [vm] },
              {
                className: classes.dropdownAction,
              },
            ),
          },
          {
            key: 'ConfirmResize',
            label: 'Confirm Resize',
            icon: 'box-check',
            disabled: vm?.state !== 'resized',
            BatchActionButton: getDialogButton<SelectorModel, any>(
              ConfirmResizeDialog,
              { rows: [vm] },
              {
                className: classes.dropdownAction,
              },
            ),
          },
          {
            key: 'Migrate',
            label: 'Migrate',
            icon: 'database',
            disabled: userRole !== 'admin',
            BatchActionButton: getDialogButton<SelectorModel, any>(
              MigrateVirtualMachineDialog,
              { rows: [vm] },
              {
                className: classes.dropdownAction,
              },
            ),
          },
          {
            key: 'Rebuild',
            label: 'Rebuild',
            icon: 'screwdriver-wrench',
            disabled: false,
            BatchActionButton: getDialogButton<SelectorModel, any>(
              RebuildVirtualMachineDialog,
              { rows: [vm] },
              {
                className: classes.dropdownAction,
              },
            ),
          },
        ],
      },
    ],
    [classes, userRole, vm],
  )

  return (
    <>
      <DocumentMeta title="Virtual Machine Details" breadcrumbs />
      <HeaderRefreshPortal>
        <Button
          icon="refresh"
          variant="secondary"
          onClick={allTenantVM ? reloadAllVMs : reloadVirtualVMs}
          loading={loading || loadingAllVms}
        >
          Refresh
        </Button>
      </HeaderRefreshPortal>
      <HeaderPrimaryActionPortal>
        <>
          {dropdownBatchActions.map(({ label, actions }) => (
            <BatchActionDropdown<SelectorModel>
              renderOutsideGrid
              icon=""
              key={label}
              label={label}
              actions={actions}
            />
          ))}
        </>
      </HeaderPrimaryActionPortal>
      <Tabs route={routes.openstack.vmDetails}>
        <Tab label="Overview" value={IVmDetailsPageTabs.Overview}>
          <Overview vm={vm || {}} loading={loading || loadingAllVms} />
        </Tab>
      </Tabs>
    </>
  )
}

export default VmDetailsPage
