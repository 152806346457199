import Alert from 'core/components/Alert'
import Info from 'core/components/validatedForm/Info'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import Modal from 'core/elements/modal'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { getCurrentUser } from 'openstack/helpers'
import React, { useCallback, useState } from 'react'
import { deleteVirtualMachine } from './actions'
import { hasOtherUserVm } from './helpers'

export default function DeleteVirtualMachineDialog({ rows, onClose }) {
  const [error, setError] = useState(null)
  const { update: deleteFn, updating: deletingVm } = useUpdateAction(deleteVirtualMachine)
  const vmNames = rows?.map((vm) => vm?.name).join(', ')
  const title = `Delete Virtual Machine(s)`
  const handleDelete = useCallback(async () => {
    const promises = []
    for (const vm of rows) {
      promises.push(deleteFn(vm))
    }
    await Promise.all(promises)
      .then(() => {
        onClose(true)
      })
      .catch((err) => {
        console.error(err)
        setError({ title: 'Error Deleting Virtual Machine', message: err?.message })
      })
  }, [rows])

  const { role: userRole, userId } = getCurrentUser()

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={title}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingVm}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={deletingVm}>
            Confirm
          </Button>
        </>
      }
    >
      {userRole === 'member' && hasOtherUserVm({ vms: rows, userId }) && (
        <Info error>NOTE: You are performing an operation on another user's VM.</Info>
      )}
      {!!error && <Alert variant="error" title={error?.title} message={error?.message} />}
      <Text variant="body2">You are about to delete the below VM instance(s).</Text>
      <Text variant="body2">{vmNames}</Text>
    </Modal>
  )
}
