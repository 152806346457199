import ApiClient from 'api-client/ApiClient'
const { resMgr } = ApiClient.getInstance()

export const hasHypervisorRole = (host) => {
  return host.roles.includes('pf9-ostackhost-neutron')
}

export const hasNetworkingRole = (host) => {
  // this will change
  return host.roles.includes('pf9-neutron-base')
}

export const hasImageLibraryRole = (host) => {
  return host.roles.includes('pf9-glance-role')
}

export const hasDnsRole = (host) => {
  return host?.roles?.includes('pf9-designate') ?? false
}

export const hasCinderRole = (host) => {
  return !!host?.roles?.includes('pf9-cindervolume-config')
}

export const hasRemoteSupportRole = (host) => {
  return host.roles.includes('pf9-support')
}

export const getHostConfiguredIp = (host) => {
  return ''
}

export const getHostRoleConfig = async (host, role) => {
  const roleConfig = await resMgr.getRole(host.id, role)
  return roleConfig
}

export const hostUnhealthy = (host) => {
  return (
    ['offline', 'failed', 'error'].includes(host?.role_status) || host?.info?.responding === false
  )
}

export const getHeaderAlertMessage = ({ hypervisor, imageLibrary, blockStorage }) => {
  if (hypervisor && imageLibrary && blockStorage) {
    return 'All hosts with Hypervisor, Image Library, and Persistent Storage roles are unhealthy. Taking any action involving VMs, images, or volumes may be unsuccessful.'
  }
  if (hypervisor && imageLibrary) {
    return 'All hosts with Hypervisor and Image Library roles are unhealthy. Taking any action involving VMs or images may be unsuccessful.'
  }
  if (hypervisor && blockStorage) {
    return 'All hosts with Hypervisor and Persistent Storage roles are unhealthy. Taking any action involving VMs or volumes may be unsuccessful.'
  }
  if (imageLibrary && blockStorage) {
    return 'All hosts with Image Library and Persistent Storage roles are unhealthy. Taking any action involving images or volumes may be unsuccessful.'
  }
  if (hypervisor) {
    return 'All hosts with Hypervisor roles are unhealthy. Taking any action involving VMs may be unsuccessful.'
  }
  if (imageLibrary) {
    return 'All hosts with Image Library roles are unhealthy. Taking any action involving images may be unsuccessful.'
  }
  if (blockStorage) {
    return 'All hosts with Persistent Storage roles are unhealthy. Taking any action involving volumes may be unsuccessful.'
  }
  return ''
}

export const getWizardAlertMessage = ({ hypervisor, imageLibrary, blockStorage, action }) => {
  if (hypervisor && imageLibrary && blockStorage) {
    return `All hosts with Hypervisor, Image Library, and Persistent Storage roles are unhealthy. ${action} may be unsuccessful.`
  }
  if (hypervisor && imageLibrary) {
    return `All hosts with Hypervisor and Image Library roles are unhealthy. ${action} may be unsuccessful.`
  }
  if (hypervisor && blockStorage) {
    return `All hosts with Hypervisor and Persistent Storage roles are unhealthy. ${action} may be unsuccessful.`
  }
  if (imageLibrary && blockStorage) {
    return `All hosts with Image Library and Persistent Storage roles are unhealthy. ${action} may be unsuccessful.`
  }
  if (hypervisor) {
    return `All hosts with Hypervisor roles are unhealthy. ${action} may be unsuccessful.`
  }
  if (imageLibrary) {
    return `All hosts with Image Library roles are unhealthy. ${action} may be unsuccessful.`
  }
  if (blockStorage) {
    return `All hosts with Persistent Storage roles are unhealthy. ${action} may be unsuccessful.`
  }
  return ''
}
