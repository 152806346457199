import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Text from 'core/elements/Text'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import useListAction from 'core/hooks/useListAction'
import {
  listResmgrHosts,
  getResmgrRoleConfig,
  updateRemoteSupportRole,
  removeRemoteSupportRole,
  getBlueprints,
  assignHypervisorRole,
  assignImageLibraryRole,
  assignPersistentStorageRole,
  deauthorizeHypervisorRole,
  deauthorizeImageLibraryRole,
  deauthorizePersistentStorageRole,
  assignHostConfig,
  unassignHostConfig,
  getVmsForHost,
  removeDns,
  assignDns,
} from '../actions'
import { resmgrHostsSelector } from '../selectors'
import {
  hasHypervisorRole,
  hasImageLibraryRole,
  hasCinderRole,
  hasRemoteSupportRole,
  getHostRoleConfig,
  hasDnsRole,
} from '../helpers'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import MultiDropdownField from 'core/components/validatedForm/MultiDropdownField'
import { useSelector } from 'react-redux'
import { useAppSelector } from 'app/store'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { waitSeconds } from '../blueprint/helpers'
import PicklistField from 'core/components/validatedForm/DropdownField'
import BlockStorageTypePicklist from './BlockStorageTypePicklist'
import HostNetworkConfigPicklist from './HostNetworkConfigPicklist'
import { keys } from 'ramda'
import { listImages } from 'openstack/components/images/actions'
import { imagesSelector } from 'openstack/components/images/selectors'
import CodeBlock from 'core/components/CodeBlock'
import { DNS_DOC_LINK } from './helpers'
import ExternalLink from 'core/components/ExternalLink'
import ApiClient from 'api-client/ApiClient'
import Info from 'core/components/validatedForm/Info'

const defaultParams = { valuesByHost: {} }

const ErrorText = ({ children }) => {
  const classes = useStyles({})
  return (
    <Text variant="body1" className={classes.errorText}>
      {children}
    </Text>
  )
}

const { nova, neutron } = ApiClient.getInstance()

export default function EditServerRolesModal({ rows: hosts, onClose }) {
  const classes = useStyles()
  const [submitting, setSubmitting] = useState(false)
  const [originalHostRoles, setOriginalHostRoles] = useState({ valuesByHost: {} })
  const [blueprintSettings, setBlueprintSettings] = useState({ storageBackends: {} })
  const [loaded, setLoaded] = useState(false)
  const [showRetryMessage, setShowRetryMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [vmsByHost, setVmsByHost] = useState({})
  const [healthCheckFailed, setHealthCheckFailed] = useState(null)

  useEffect(() => {
    const makeCalls = async () => {
      const hypervisorHosts = hosts.filter((host) => host.roles.includes('pf9-ostackhost-neutron'))
      const results: any = await Promise.allSettled(
        hypervisorHosts.map((host) => getVmsForHost(host?.id)),
      )
      const values = results?.map((result) => result?.value)
      const valuesByHost = hypervisorHosts.reduce(
        (accum, host, idx) => ({
          ...accum,
          [host.id]: values[idx],
        }),
        {},
      )
      setVmsByHost(valuesByHost)
    }
    makeCalls()
  }, [hosts])

  const volumeBackendConfigs = useMemo(() => {
    const volumeTypes = keys(blueprintSettings.storageBackends)
    const configurations = volumeTypes.reduce((accum, type) => {
      const configNames = keys(blueprintSettings.storageBackends[type])
      const configsWithVolumeType = configNames.map((name) => {
        return {
          // @ts-ignore
          ...blueprintSettings.storageBackends[type][name],
          name: name,
          volumeType: type,
        }
      })
      return [...accum, ...configsWithVolumeType]
    }, [])
    return configurations
  }, [blueprintSettings])

  const volumeBackendConfigOptions = useMemo(() => {
    return volumeBackendConfigs.map((config) => {
      return {
        label: `${config.name} (${config.volumeType}, ${config.driver})`,
        value: config.name,
      }
    })
  }, [volumeBackendConfigs])

  const allHosts = useSelector(resmgrHostsSelector)
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { reload } = useListAction(listResmgrHosts, {})

  const { loading: loadingImages } = useListAction(listImages)
  const imagesList = useAppSelector(imagesSelector)

  const enableRoleCheckbox = useCallback(
    (host, roleName, hasRoleFunc) => {
      if (params.valuesByHost?.[host.id]?.[roleName]) {
        return true
      }
      const roleHost = allHosts.find(hasRoleFunc)
      const roleChecked = hosts.some((h) => !!params.valuesByHost?.[h.id]?.[roleName])
      if (roleHost) {
        return roleHost.id === host.id
      }
      return !roleChecked
    },
    [allHosts, params, hosts],
  )

  const populateHostData = (hosts, storageHostBackends) => {
    return hosts.reduce(
      (accum, host) => ({
        valuesByHost: {
          ...accum.valuesByHost,
          [host.id]: {
            hostNetworkConfig: host?.hostconfig_id,
            hypervisor: hasHypervisorRole(host),
            imageLibrary: hasImageLibraryRole(host),
            remoteSupport: hasRemoteSupportRole(host),
            dns: hasDnsRole(host),
            volumeBackends:
              keys(storageHostBackends.find((h) => h.host.id === host.id)?.backends) || [],
          },
        },
      }),
      { valuesByHost: {} },
    )
  }

  useEffect(() => {
    if (loaded) {
      return
    }
    const querySettings = async () => {
      const blueprints = await getBlueprints()
      const blueprint = blueprints?.[0]
      // @ts-ignore
      setBlueprintSettings(blueprint)
      setLoaded(true)

      const storageHosts = hosts.filter((host) => host.roles.includes('pf9-cindervolume-config'))
      const results = await Promise.allSettled(
        storageHosts.map((host) =>
          getResmgrRoleConfig({ role: 'pf9-cindervolume-config', host: host }),
        ),
      )
      const storageHostBackends = storageHosts.map((host, idx) => {
        return {
          host,
          // @ts-ignore
          backends: results[idx]?.value?.backends,
        }
      })
      const hostData = populateHostData(hosts, storageHostBackends)
      setOriginalHostRoles(hostData)
      setParams(hostData)
    }
    querySettings()
  }, [hosts])

  const checkServicesHealth = async () => {
    setHealthCheckFailed(null)
    try {
      await nova.checkHealth()
      await neutron.checkHealth()
      return true
    } catch (e) {
      setHealthCheckFailed({
        title: 'Error',
        message: 'Hypervisor services are not ready. Please try again after sometime',
      })
      return false
    }
  }

  const submitForm = useCallback(async () => {
    const rolesToAdd = []
    const rolesToRemove = []
    setSubmitting(true)

    // For enabling new hypervisor roles - Check health of nova and neutron services before submitting
    if (
      hosts.some((host) => {
        originalHostRoles?.valuesByHost?.[host.id]?.hypervisor === false &&
          params?.valuesByHost?.[host.id]?.hypervisor === true
      })
    ) {
      const heathCheckOk = await checkServicesHealth()
      if (!heathCheckOk) {
        setSubmitting(false)
        return
      }
    }

    for (const host of hosts) {
      if (
        originalHostRoles?.valuesByHost?.[host.id]?.hypervisor === false &&
        params?.valuesByHost?.[host.id]?.hypervisor === true
      ) {
        rolesToAdd.push({
          role: 'hypervisor',
          host,
        })
      }
      if (
        originalHostRoles?.valuesByHost?.[host.id]?.imageLibrary === false &&
        params?.valuesByHost?.[host.id]?.imageLibrary === true
      ) {
        rolesToAdd.push({ role: 'imageLibrary', host })
      }
      if (
        params?.valuesByHost?.[host.id]?.volumeBackends?.length &&
        originalHostRoles?.valuesByHost?.[host.id]?.volumeBackends?.sort() !==
          params?.valuesByHost?.[host.id]?.volumeBackends?.sort()
      ) {
        rolesToAdd.push({
          role: 'persistentStorage',
          host,
          volumeBackends: params.valuesByHost[host.id].volumeBackends,
        })
      }
      if (
        originalHostRoles?.valuesByHost?.[host.id]?.remoteSupport === false &&
        params?.valuesByHost?.[host.id]?.remoteSupport === true
      ) {
        rolesToAdd.push({ role: 'remoteSupport', host })
      }
      if (
        originalHostRoles?.valuesByHost?.[host.id]?.dns === false &&
        params?.valuesByHost?.[host.id]?.dns === true
      ) {
        rolesToAdd.push({ role: 'dns', host })
      }
      if (
        originalHostRoles?.valuesByHost?.[host.id]?.hypervisor === true &&
        params?.valuesByHost?.[host.id]?.hypervisor === false
      ) {
        rolesToRemove.push({ role: 'hypervisor', host })
      }
      if (
        originalHostRoles?.valuesByHost?.[host.id]?.imageLibrary === true &&
        params?.valuesByHost?.[host.id]?.imageLibrary === false
      ) {
        rolesToRemove.push({ role: 'imageLibrary', host })
      }
      if (
        originalHostRoles?.valuesByHost?.[host.id]?.volumeBackends.length &&
        !params?.valuesByHost?.[host.id]?.volumeBackends.length
      ) {
        rolesToRemove.push({ role: 'persistentStorage', host })
      }
      if (
        originalHostRoles?.valuesByHost?.[host.id]?.remoteSupport === true &&
        params?.valuesByHost?.[host.id]?.remoteSupport === false
      ) {
        rolesToRemove.push({ role: 'remoteSupport', host })
      }
      if (
        originalHostRoles?.valuesByHost?.[host.id]?.dns === true &&
        params?.valuesByHost?.[host.id]?.dns === false
      ) {
        rolesToRemove.push({ role: 'dns', host })
      }
    }

    // Need to unassign host configs that we are changing
    const addHostConfigHosts = hosts.filter((host) => {
      return host.hostconfig_id === null
    })

    const updateHostConfigHosts = hosts.filter((host) => {
      return (
        host.hostconfig_id &&
        host.hostconfig_id !== params?.valuesByHost?.[host.id]?.hostNetworkConfig
      )
    })

    const hostConfigUnassignmentResults = await Promise.allSettled(
      updateHostConfigHosts.map((host) => {
        return unassignHostConfig({
          hostId: host.id,
          configId: host.hostconfig_id,
        })
      }),
    )

    // Do not assign if they selected the None option
    const filteredConfigHosts = updateHostConfigHosts.filter(
      (host) => params?.valuesByHost?.[host.id]?.hostNetworkConfig !== 'none',
    )
    const hostConfigAssignmentResults = await Promise.allSettled(
      [...addHostConfigHosts, ...filteredConfigHosts].map((host) => {
        return assignHostConfig({
          hostId: host.id,
          configId: params?.valuesByHost?.[host.id]?.hostNetworkConfig,
        })
      }),
    )

    if (
      // @ts-ignore
      [...hostConfigAssignmentResults, ...hostConfigUnassignmentResults].some(
        (result) => result.status === 'rejected',
      )
    ) {
      // @ts-ignore
      const errIndex = hostConfigAssignmentResults.findIndex(
        (result) => result.status === 'rejected',
      )
      const errHost = hosts[errIndex]
      // @ts-ignore
      const err = `${errHost.info.hostname} - ${hostConfigAssignmentResults[errIndex]?.reason?.err}`
      setSubmitting(false)
      setErrorMessage(err)
      setShowRetryMessage(true)
      return
    }
    const results = await Promise.allSettled(
      rolesToAdd.map((role) => {
        if (role.role === 'hypervisor') {
          return assignHypervisorRole({ host: role.host })
        } else if (role.role === 'imageLibrary') {
          return assignImageLibraryRole({ host: role.host })
        } else if (role.role === 'persistentStorage') {
          return assignPersistentStorageRole({
            host: role.host,
            body: {
              backends: role.volumeBackends,
            },
          })
        } else if (role.role === 'remoteSupport') {
          return updateRemoteSupportRole({ host: role.host })
        } else if (role.role === 'dns') {
          return assignDns({ host: role.host })
        } else {
          return null
        }
      }),
    )
    if (results.some((result) => result.status === 'rejected')) {
      const errIndex = results.findIndex((result) => result.status === 'rejected')
      const errHost = rolesToAdd[errIndex]?.host
      // @ts-ignore
      const err = `${errHost.info.hostname} - ${results[errIndex]?.reason?.err}`

      setSubmitting(false)
      setErrorMessage(err)
      setShowRetryMessage(true)
      return
    }
    // if (rolesToAdd.some((role) => role.role === 'hypervisor')) {
    //   await waitSeconds(5)
    // }
    await Promise.allSettled(
      rolesToRemove.map((role) => {
        if (role.role === 'imageLibrary') {
          return deauthorizeImageLibraryRole({ host: role.host })
        } else if (role.role === 'hypervisor') {
          return deauthorizeHypervisorRole({ host: role.host })
        } else if (role.role === 'persistentStorage') {
          return deauthorizePersistentStorageRole({ host: role.host })
        } else if (role.role === 'remoteSupport') {
          return removeRemoteSupportRole({ hostId: role.host.id })
        } else if (role.role === 'dns') {
          return removeDns({ hostId: role.host.id })
        }
        return null
      }),
    )
    reload(true, true)
    handleClose()
  }, [params, hosts])

  const handleClose = () => {
    setParams(defaultParams)
    onClose()
  }

  // Only one image library for now, so just check images list
  // const vmImageLength = (host) => {
  //   return host?.vms?.map((vm) => vm?.image).length
  // }

  const noImagesPresent = useMemo(() => {
    if (loadingImages) {
      return false
    }
    return imagesList.length === 0
  }, [imagesList, loadingImages])

  return (
    <ModalForm
      open
      title={`Edit Roles`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={submitting}
      submitTitle={`Update Role Assignment`}
      maxWidth={1200}
      loading={!loaded || submitting}
      loadingMessage={
        submitting ? 'Updating host roles, please do not close this dialog' : 'Loading'
      }
      panel="dialog"
      customErrorComponent={showRetryMessage ? <ErrorText>{errorMessage}</ErrorText> : null}
      error={healthCheckFailed ? healthCheckFailed : null}
    >
      <div className={classes.planContainer}>
        <>
          <div className={classes.headerLabel}>
            <Text variant="caption1">Server</Text>
          </div>
          <div className={classes.headerLabel}>
            <Text variant="caption1">Host Network Config</Text>
          </div>
          <div className={classes.headerLabel}>
            <Text variant="caption1">Hypervisor</Text>
          </div>
          <div className={classes.headerLabel}>
            <Text variant="caption1">VM Image Library</Text>
          </div>
          <div className={classes.headerLabel}>
            <Text variant="caption1">Persistent Storage</Text>
          </div>
          <div className={classes.headerLabel}>
            <Text variant="caption1">Advanced Remote Support</Text>
          </div>
          <div className={classes.headerLabel}>
            <Text variant="caption1">DNS</Text>
          </div>
        </>
        {hosts.map((host) => {
          const configPicklistDisabled = host.roles?.filter((role) => role !== 'pf9-support')
            ?.length
          return (
            <React.Fragment key={host.id}>
              <div className={classes.valueCell}>
                <Text variant="body2" className={classes.name}>
                  {host.info.hostname}
                </Text>
              </div>
              <div className={classes.valueCell}>
                <PicklistField
                  DropdownComponent={HostNetworkConfigPicklist}
                  name="hostNetworkConfig"
                  id={`valuesByHost.${host.id}.hostNetworkConfig`}
                  label=""
                  compact={false}
                  value={params.valuesByHost?.[host.id]?.hostNetworkConfig}
                  tooltip={
                    configPicklistDisabled ? (
                      <Text variant="body2" className={classes.tooltip}>
                        You may only change the network configuration when no roles are installed on
                        the host.
                      </Text>
                    ) : null
                  }
                  disabled={configPicklistDisabled}
                  disabledMessage
                  onChange={(value) =>
                    updateParams({
                      valuesByHost: {
                        ...params.valuesByHost,
                        [host.id]: {
                          ...params.valuesByHost[host.id],
                          hostNetworkConfig: value,
                        },
                      },
                    })
                  }
                  required
                />
              </div>
              <div className={classes.valueCell}>
                <CheckboxField
                  id={`valuesByHost.${host.id}.hypervisor`}
                  label=""
                  value={params.valuesByHost?.[host.id]?.hypervisor}
                  disabled={vmsByHost?.[host.id]?.length > 0}
                  info={
                    vmsByHost?.[host.id]?.length > 0
                      ? 'Cannot remove hypervisor role while VMs are present on this host'
                      : ''
                  }
                  onChange={(value) => {
                    updateParams({
                      valuesByHost: {
                        ...params.valuesByHost,
                        [host.id]: {
                          ...params.valuesByHost[host.id],
                          hypervisor: value,
                        },
                      },
                    })
                  }}
                />
              </div>
              <div className={classes.valueCell}>
                <CheckboxField
                  id={`valuesByHost.${host.id}.imageLibrary`}
                  label=""
                  value={params.valuesByHost?.[host.id]?.imageLibrary}
                  disabled={
                    !enableRoleCheckbox(host, 'imageLibrary', hasDnsRole) ||
                    (!noImagesPresent && hasImageLibraryRole(host))
                  }
                  info={
                    !enableRoleCheckbox(host, 'imageLibrary', hasDnsRole)
                      ? 'Only one cluster server may be an image library. Please deauthorize your existing image library if you would like to assign a new image library.'
                      : !noImagesPresent && hasImageLibraryRole(host)
                      ? 'Host contains images, cannot remove role. Please delete all images before removing the image library role.'
                      : ''
                  }
                  onChange={(value) =>
                    updateParams({
                      valuesByHost: {
                        ...params.valuesByHost,
                        [host.id]: {
                          ...params.valuesByHost[host.id],
                          imageLibrary: value,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className={classes.valueCell}>
                <MultiDropdownField
                  id={`valuesByHost.${host.id}.volumeBackends`}
                  label=""
                  options={volumeBackendConfigOptions}
                  value={params.valuesByHost?.[host.id]?.volumeBackends}
                  onChange={(value) =>
                    updateParams({
                      valuesByHost: {
                        ...params.valuesByHost,
                        [host.id]: {
                          ...params.valuesByHost[host.id],
                          volumeBackends: value,
                        },
                      },
                    })
                  }
                  fullWidth
                />
                {/* <CheckboxField
                id={`valuesByHost.${host.id}.blockStorage`}
                label=""
                value={params.valuesByHost?.[host.id]?.blockStorage}
                info={
                  <Text variant="body2" className={classes.tooltip}>
                    <FontAwesomeIcon className={classes.tooltipIcon}>
                      exclamation-triangle
                    </FontAwesomeIcon>
                    Before making this host a block storage node, please ensure that the cinder
                    volume group has been created on this host.
                  </Text>
                }
                onChange={(value) =>
                  updateParams({
                    valuesByHost: {
                      ...params.valuesByHost,
                      [host.id]: {
                        ...params.valuesByHost[host.id],
                        blockStorage: value,
                      },
                    },
                  })
                }
              /> */}
              </div>
              <div className={classes.valueCell}>
                <CheckboxField
                  id={`valuesByHost.${host.id}.remoteSupport`}
                  label=""
                  value={params.valuesByHost?.[host.id]?.remoteSupport}
                  onChange={(value) =>
                    updateParams({
                      valuesByHost: {
                        ...params.valuesByHost,
                        [host.id]: {
                          ...params.valuesByHost[host.id],
                          remoteSupport: value,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className={classes.valueCell}>
                <CheckboxField
                  id={`valuesByHost.${host.id}.dnsRole`}
                  label=""
                  value={params.valuesByHost?.[host.id]?.dns}
                  disabled={!enableRoleCheckbox(host, 'dns', hasDnsRole)}
                  info={
                    !enableRoleCheckbox(host, 'dns', hasDnsRole)
                      ? 'Only one cluster server may be a dns role. Please deauthorize your existing dns if you would like to assign a new dns.'
                      : ''
                  }
                  onChange={(value) =>
                    updateParams({
                      valuesByHost: {
                        ...params.valuesByHost,
                        [host.id]: {
                          ...params.valuesByHost[host.id],
                          dns: value,
                        },
                      },
                    })
                  }
                />
              </div>
              {params.valuesByHost?.[host.id]?.dns && (
                <Info className={classes.instructions}>
                  <Text variant="body2">
                    To complete the process of making this host a Designate Node, you will need to
                    create a custom pools.yaml file.
                    <br />
                    Then log in to the host and run the command below with your custom pools.yaml
                    file:
                    <br />
                    <br />
                    <CodeBlock>
                      {'designate-manage pool update --file <path/to/pools.yaml>'}
                    </CodeBlock>
                    <br />
                    <br />
                    Refer to the following
                    <ExternalLink url={DNS_DOC_LINK} newWindow={true}>
                      {' '}
                      article{' '}
                    </ExternalLink>
                    for more information.
                  </Text>
                </Info>
              )}
            </React.Fragment>
          )
        })}
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  formContainer: {
    padding: '24px',
    background: 'white',
    width: 'max-content',
  },
  hostValuesContainer: {
    display: 'grid',
    gap: '16.5px',
  },
  planContainer: {
    display: 'grid',
    gridTemplateColumns:
      'max-content max-content max-content max-content auto max-content max-content',
    alignItems: 'center',
    gap: '12px 36px',
    overflowY: 'scroll',
    maxHeight: '360px',
    paddingBottom: 100,
  },
  headerLabel: {
    marginBottom: '25px',
  },
  valueCell: {
    // marginBottom: '25px',
    // display: 'flex',
    // justifyContent: 'center',
  },
  tooltip: {
    color: theme.components.tooltip.text,
  },
  tooltipIcon: {
    color: theme.components.tooltip.text,
    marginRight: 8,
  },
  errorText: {
    color: theme.components.graph.error,
  },
  name: {
    whiteSpace: 'nowrap',
  },
  instructions: {
    gridColumn: 'span 7',
  },
}))
