import { listPools } from 'app/plugins/openstack/components/networks/dns/pools/actions'
import { poolsSelector } from 'app/plugins/openstack/components/networks/dns/pools/selectors'
import { useAppSelector } from 'app/store'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'
import useListAction from 'core/hooks/useListAction'
import React from 'react'

const DnsPoolPicklist = (props) => {
  const { loading } = useListAction(listPools)
  const data = useAppSelector(poolsSelector)

  const defaultPool = data?.filter((pool) => pool?.name === 'default') //TOOD: remove this once https://platform9.atlassian.net/browse/PCD-1205 is fixed

  const items = defaultPool.map(({ id, name, backend }) => ({
    label: `${name}${backend ? ` (${backend})` : ''}`,
    value: id,
  }))

  return <AsyncDropdown label="DNS Pool" loading={loading} items={items} {...props} />
}

export default DnsPoolPicklist
