import { createSlice } from '@reduxjs/toolkit'
import { MigrationReducer } from './model'
import { omit, keys } from 'ramda'

export const defaultState: MigrationReducer = {
  lastMigrationByVmId: {},
}

const {
  name: customMigrationKey,
  reducer: migrationReducers,
  actions: migrationActions,
} = createSlice({
  name: 'migrations',
  initialState: defaultState,
  reducers: {
    newMigrations: (state, { payload }) => {
      const { vms, destination } = payload
      const currentMigrations = state.lastMigrationByVmId
      const updatedMigrations = vms.reduce((accum, vm) => {
        return {
          ...accum,
          [vm.id]: {
            // Consider using host ID instead if needed
            startHost: vm['OS-EXT-SRV-ATTR:hypervisor_hostname'],
            // Perhaps this is not needed at all? Difficult to dispatch with refreshed VMs, perhaps only need to store the starting host here.
            endHost: destination,
          },
        }
      }, currentMigrations)
      return {
        lastMigrationByVmId: updatedMigrations,
      }
    },
    removeMigration: (state, { payload }) => {
      const { id } = payload
      const currentMigrations = state.lastMigrationByVmId
      const updatedMigrations = omit([id], currentMigrations)
      return {
        lastMigrationByVmId: updatedMigrations,
      }
    },
    // refreshedVms: (state, { payload }) => {
    //   const { vms } = payload
    //   const vmsById = vms.reduce((accum, vm) => {
    //     return {
    //       ...accum,
    //       [vm.id]: vm,
    //     }
    //   }, {})
    //   const currentMigrations = state.lastMigrationByVmId
    //   const currentMigrationVmIds = keys(currentMigrations)
    //   const updatedMigrations = currentMigrationVmIds.reduce((accum, id) => {
    //     const vm = vmsById[id]
    //     if (vm['OS-EXT-STS:task_state'] === 'migrating') {
    //       return accum
    //     }
    //     return {
    //       ...accum,
    //       [vm.id]: {
    //         ...accum[vm.id],
    //         endHost: vm['OS-EXT-SRV-ATTR:hypervisor_hostname'],
    //       },
    //     }
    //   }, currentMigrations)
    //   return {
    //     lastMigrationByVmId: updatedMigrations,
    //   }
    // },
    clearMigrations: () => {
      return defaultState
    },
  },
})

export { customMigrationKey, migrationActions }
export default migrationReducers
