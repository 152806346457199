import { useAppSelector } from 'app/store'
import withProgress from 'core/components/progress/withProgress'
import Dropdown from 'core/elements/dropdown'
import useListAction from 'core/hooks/useListAction'
import { listOctaviaPools } from 'openstack/components/networks/actions'
import { octaviaPoolsSelector } from 'openstack/components/networks/selectors'
import React, { useMemo } from 'react'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function PoolsPicklist({
  disabled = false,
  name = 'pools',
  label = 'Pools',
  networkId = null,
  includeNoneOption = false,
  ...rest
}) {
  const { loading } = useListAction(listOctaviaPools)
  const pools = useAppSelector(octaviaPoolsSelector)

  const options = useMemo(() => {
    const poolOptions = pools.map((pool) => {
      return {
        label: pool?.name,
        value: pool?.id,
      }
    })
    return poolOptions
  }, [pools])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
