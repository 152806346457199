import React, { useCallback } from 'react'
import Text from 'core/elements/Text'
import { updateVolume } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import TextField from 'core/components/validatedForm/TextField'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'

export default function EditVolumeDialog({ rows: volumes, onClose }) {
  const volume = volumes?.[0]
  const classes = useStyles()
  const defaultParams = {
    name: volume?.name,
    description: volume?.description,
  }
  const { params, getParamsUpdater } = useParams(defaultParams)

  const { update, updating, error } = useUpdateAction(updateVolume)
  const submitForm = useCallback(async () => {
    const body = {
      volume: {
        name: params.name,
        description: params.description,
      },
    }
    const { success } = await update({
      id: volume.id,
      body,
    })
    if (success) onClose(true)
  }, [volume, params])

  return (
    <ModalForm
      open
      panel="dialog"
      title={`Edit Volume`}
      onSubmit={submitForm}
      onClose={onClose}
      submitting={updating}
      error={error}
      submitTitle={`Update Volume`}
    >
      <div className={classes.body}>
        <TextField
          id="name"
          label="Name"
          onChange={getParamsUpdater('name')}
          value={params.name}
          required
        />
        <TextField
          id="description"
          label="Description"
          onChange={getParamsUpdater('description')}
          value={params.description}
          required
        />
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  body: {
    display: 'grid',
    gap: 12,
  },
}))
