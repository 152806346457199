import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import {
  updateHostAggregate,
  listHypervisors,
  listResmgrHosts,
  addHostToAggregate,
  removeHostFromAggregate,
  updateHostAggregateMetadata,
  getResources,
  updateResourceInventory,
  listResourceInventory,
} from '../actions'
import { hypervisorsSelector, resmgrHostsSelector } from '../selectors'
import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { useSelector } from 'react-redux'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import PicklistField from 'core/components/validatedForm/DropdownField'
import AvailabilityZonesPicklist from '../availability-zones/AvailabilityZonesPicklist'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import ListTableField from 'core/components/validatedForm/ListTableField'
import useListAction from 'core/hooks/useListAction'
import { listAvailabilityZones } from '../availability-zones/actions'
import { without } from 'ramda'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  rows: any[]
  onClose: () => void
}

const hypervisorColumns = [
  {
    id: 'hypervisor_hostname',
    label: 'Host',
  },
  {
    id: 'host_ip',
    label: 'IP Address',
  },
]

export default function EditHostAggregateModal({ rows: [hostAggregate], onClose }: Props) {
  const classes = useStyles()
  const initialKeys = useMemo(() => {
    return Object.keys(hostAggregate.metadata).filter((key) => key !== 'availability_zone')
  }, [hostAggregate.metadata])

  const defaultParams = useMemo(
    () => ({
      name: hostAggregate.name,
      // availabilityZone: hostAggregate.availability_zone ? hostAggregate.availability_zone : '',
      // newAvailabilityZone: false,
      // newAvailabilityZoneName: '',
      hosts: [],
      customizeAllocationRatio: false,
      VCPU: '',
      MEMORY_MB: '',
      DISK_GB: '',
      metadata: Object.entries(hostAggregate.metadata)
        .map(([key, value]) => {
          return { key, value }
        })
        .filter(({ key }) => key !== 'availability_zone'),
    }),
    [hostAggregate],
  )

  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)
  const [submitting, setSubmitting] = useState(false)
  const [resources, setResources] = useState([])

  const { update, updating, error, reset } = useUpdateAction(updateHostAggregate)
  const {
    update: addHost,
    updating: addingHost,
    error: errorAddingHost,
    reset: resetAddHost,
  } = useUpdateAction(addHostToAggregate)
  const {
    update: removeHost,
    updating: removingHost,
    error: errorRemovingHost,
    reset: resetRemoveHost,
  } = useUpdateAction(removeHostFromAggregate)
  const {
    update: updateMetadata,
    updating: updatingMetadata,
    error: errorUpdatingMetadata,
    reset: resetUpdateMetadata,
  } = useUpdateAction(updateHostAggregateMetadata)

  // const { reload: reloadAvailabilityZones } = useListAction(listAvailabilityZones)
  const { loading: loadingHosts } = useListAction(listResmgrHosts)
  const hosts = useSelector(resmgrHostsSelector)
  const { loading: loadingHypervisors } = useListAction(listHypervisors, {
    params: {},
  })
  const hypervisorsList = useSelector(hypervisorsSelector)

  const validHypervisors = useMemo(() => {
    const resmgrHostIds = hosts.map((host) => host.id)
    return hypervisorsList.filter((hypervisor) =>
      resmgrHostIds.includes(
        hypervisor?.service?.host || hostAggregate.hosts?.includes(hypervisor?.service?.host),
      ),
    )
  }, [hosts, hypervisorsList])

  useEffect(() => {
    const getAllRsources = async () => {
      const resources = await getResources()
      setResources(resources)
    }

    getAllRsources()
  }, [])

  useEffect(() => {
    const selectedHypervisors = hypervisorsList.filter((hypervisor) => {
      return hostAggregate.hosts?.includes(hypervisor?.service?.host)
    })
    updateParams({ hosts: selectedHypervisors })
  }, [hostAggregate.hosts, hypervisorsList])

  const submitForm = useCallback(async () => {
    setSubmitting(true)
    const body = {
      aggregate: {
        name: params.name,
        // availability_zone: params.newAvailabilityZone
        //   ? params.newAvailabilityZoneName
        //   : params.availabilityZone
        //   ? params.availabilityZone
        //   : undefined,
      },
    }
    const metadata = params.metadata.reduce((accum, pair) => {
      // Don't let user set availability zone
      if (pair.key === 'availability_zone') {
        return accum
      }
      return {
        ...accum,
        [pair.key]: pair.value,
      }
    }, {})
    const currentKeys = Object.keys(metadata)
    const removedKeys = without(currentKeys, initialKeys)
    const removeObject = removedKeys.reduce((accum, key) => {
      return {
        ...accum,
        [key]: null,
      }
    }, {})
    const metadataBody = {
      set_metadata: {
        metadata: {
          ...metadata,
          ...removeObject,
        },
      },
    }
    const hostIdsToAdd = params.hosts
      .filter((host) => {
        return !hostAggregate.hosts.includes(host.service.host)
      })
      .map((host) => host.service.host)
    const hostIdsToRemove = hostAggregate.hosts.filter((host) => {
      const desiredHostIds = params.hosts.map((host) => host.service.host)
      return !desiredHostIds.includes(host)
    })

    const { success } = await update({ id: hostAggregate.id, body })
    if (!success) {
      setSubmitting(false)
      return
    }
    updateMetadata({ id: hostAggregate.id, body: metadataBody })
    const addPromises = hostIdsToAdd.map((host) => {
      return addHost({
        id: hostAggregate.id,
        body: {
          add_host: {
            host: host,
          },
        },
      })
    })
    const removePromises = hostIdsToRemove.map((host) => {
      return removeHost({
        id: hostAggregate.id,
        body: {
          remove_host: {
            host: host,
          },
        },
      })
    })

    const filterResourcesUUIDByHostName = resources
      .filter((resource) => {
        const filteredHostByName = params.hosts.map((host) => host?.hypervisor_hostname)
        return filteredHostByName.includes(resource.name)
      })
      .map((resource) => resource.uuid)

    const allocationRatioPromises = filterResourcesUUIDByHostName.map(async (uuid) => {
      const { resource_provider_generation, inventories } = await listResourceInventory({
        id: uuid,
      })

      const filteredInvetories = Object.keys(inventories).reduce((accum, current) => {
        return {
          ...accum,
          [current]: {
            ...inventories[current],
            allocation_ratio: !!params[current]
              ? Number(params[current])
              : inventories[current].allocation_ratio,
          },
        }
      }, {})

      return updateResourceInventory({
        id: uuid,
        body: {
          inventories: filteredInvetories,
          resource_provider_generation: resource_provider_generation,
        },
      })
    })

    const promiseData = await Promise.allSettled([
      ...addPromises,
      ...removePromises,
      ...allocationRatioPromises,
    ])

    if (promiseData) handleClose()

    setSubmitting(false)
  }, [hostAggregate, params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    resetAddHost()
    resetRemoveHost()
    resetUpdateMetadata()
    // reloadAvailabilityZones(true, true)
    onClose()
  }

  return (
    <ModalForm
      title={`Edit Host Aggregate`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={submitting}
      error={error}
      submitTitle={`Update Host Aggregate`}
      open
    >
      <>
        <FormFieldSection title="Host Aggregate Settings">
          <TextField
            id="name"
            label="Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            required
          />
          <CheckboxField
            id="customizeAllocationRatio"
            label="Customize Host Allocation Ratios"
            value={params.customizeAllocationRatio}
            onChange={getParamsUpdater('customizeAllocationRatio')}
          />
          {params.customizeAllocationRatio && (
            <>
              <TextField
                id="cpu"
                label="CPU Allocation Ratio"
                onChange={getParamsUpdater('VCPU')}
                value={params.VCPU}
              />
              <TextField
                id="memory"
                label="Memory Allocation Ratio"
                onChange={getParamsUpdater('MEMORY_MB')}
                value={params.MEMORY_MB}
              />
              <TextField
                id="disk"
                label="Disk Allocation Ratio"
                onChange={getParamsUpdater('DISK_GB')}
                value={params.DISK_GB}
              />
            </>
          )}
          {/* <CheckboxField
            id="newAvailabilityZone"
            label="Create New Availability Zone"
            value={params.newAvailabilityZone}
            onChange={getParamsUpdater('newAvailabilityZone')}
          />
          {!!params.newAvailabilityZone && (
            <TextField
              id="newAvailabilityZoneName"
              label="New Availability Zone Name"
              onChange={getParamsUpdater('newAvailabilityZoneName')}
              value={params.newAvailabilityZoneName}
              required
            />
          )}
          {!params.newAvailabilityZone && (
            <PicklistField
              DropdownComponent={AvailabilityZonesPicklist}
              name="availabilityZone"
              id="availabilityZone"
              label="Availability Zone"
              compact={false}
              value={params.availabilityZone}
              onChange={getParamsUpdater('availabilityZone')}
              includeNoneOption
            />
          )} */}
          <KeyValuesField
            id="metadata"
            label="Metadata"
            value={params.metadata}
            onChange={getParamsUpdater('metadata')}
            addLabel="Add Metadata"
            allowMultipleValues
          />
        </FormFieldSection>
        <FormFieldSection title="Select Hosts to Add">
          <ListTableField
            id="hosts"
            data={validHypervisors}
            loading={loadingHypervisors || loadingHosts}
            columns={hypervisorColumns}
            onChange={getParamsUpdater('hosts')}
            value={params.hosts}
            uniqueIdentifier="id"
            searchTargets={['hypervisor_hostname']}
            multiSelection
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
