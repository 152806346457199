import React, { useCallback } from 'react'
import { styled } from '@material-ui/styles'
import Text from 'core/elements/Text'
import { deleteVolumeTransfer } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import ServiceUnhealthyInfo from 'openstack/components/common/ServiceUnhealthyInfo'

const Container = styled('div')({
  gap: 16,
  display: 'grid',
})

export default function CancelTransferDialog({ rows: [volume], onClose }) {
  const transfer = volume?.transfer
  const { update, updating, error, reset } = useUpdateAction(deleteVolumeTransfer)

  const handleSubmit = useCallback(async () => {
    const { success } = await update({
      id: transfer.id,
      volumeId: volume.id,
    })
    if (success) {
      handleClose()
    }
  }, [volume, transfer])

  const handleClose = () => {
    reset()
    onClose(true)
  }

  return (
    <ModalForm
      title="Cancel Volume Transfer"
      onSubmit={handleSubmit}
      onClose={onClose}
      submitting={updating}
      error={error}
      submitTitle="Cancel Transfer"
      panel="dialog"
      maxWidth={800}
      open
    >
      <ServiceUnhealthyInfo action="Cancel Volume Transfer" blockStorage />
      <Container>
        <Text variant="body2">
          Cancel the transfer of volume <b>{volume.name || volume.id}</b> to another tenant. The
          volume will no longer be available for acceptance into another tenant.
        </Text>
      </Container>
    </ModalForm>
  )
}
