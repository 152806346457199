import { makeStyles } from '@material-ui/styles'
import { listTablePrefs } from 'app/constants'
import { listResmgrHosts } from 'app/plugins/openstack/components/infrastructure/actions'
import { resmgrHostsSelector } from 'app/plugins/openstack/components/infrastructure/selectors'
import { getDnsResourceStatus } from 'app/plugins/openstack/components/networks/dns/helper'
import { DnsPageTabs } from 'app/plugins/openstack/components/networks/dns/model'
import {
  deleteRecord,
  listRecords,
} from 'app/plugins/openstack/components/networks/dns/records/actions'
import AddRecordPage from 'app/plugins/openstack/components/networks/dns/records/AddRecordPage'
import DeleteRecordDialog from 'app/plugins/openstack/components/networks/dns/records/DeleteRecordDialog'
import RadioDropdownFilter from 'app/plugins/openstack/components/networks/dns/records/RadioDropdownFilter'
import RecordCard from 'app/plugins/openstack/components/networks/dns/records/RecordCard'
import { DnsRecordType } from 'app/plugins/openstack/components/networks/dns/records/RecordTypePicklist'
import { recordsSelector } from 'app/plugins/openstack/components/networks/dns/records/selectors'
import UpdateRecordPage from 'app/plugins/openstack/components/networks/dns/records/UpdateRecordPage'
import { listZones } from 'app/plugins/openstack/components/networks/dns/zones/actions'
import { zonesSelector } from 'app/plugins/openstack/components/networks/dns/zones/selectors'
import { useAppSelector } from 'app/store'
import ListContainer from 'core/containers/ListContainer'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import { themeActions } from 'core/session/themeReducers'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import DataKeys from 'k8s/DataKeys'
import { pick, uniq } from 'ramda'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'

const columns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'statusDetails',
    label: 'Status',
    CellComponent: createGridStatusCell({
      dataFn: (statusDetails: any) =>
        getDnsResourceStatus(statusDetails?.status, statusDetails?.action),
    }),
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'zone_name',
    label: 'Zone',
  },
  {
    key: 'records',
    label: 'Content',
  },
  {
    key: 'ttl',
    label: 'TTL',
  },
]
const searchTargets = ['name']

const recordTypesItems = Object.values(DnsRecordType).map((type) => ({ label: type, value: type }))

const getFilteredData = ({
  data,
  selectedZones,
  selectedRecordTypes,
  zoneItems,
  recordTypesItems,
}) => {
  if (!data) return []

  const isAllZones = selectedZones?.length === zoneItems?.length
  const isAllTypes = selectedRecordTypes?.length === recordTypesItems?.length
  const selectedZoneIds = selectedZones?.map((zone) => zone?.value)
  const selectedRecordIds = selectedRecordTypes?.map((type) => type?.value)
  const zoneFilteredData = data?.filter((item) => selectedZoneIds?.includes(item?.zone_id))
  const typeFilteredData = data?.filter((item) => selectedRecordIds?.includes(item?.type))

  let filteredData = data

  if (!isAllZones && isAllTypes) {
    // Only filter by zone
    filteredData = zoneFilteredData
  } else if (!isAllTypes && isAllZones) {
    // Only filter by type
    filteredData = typeFilteredData
  } else if (!isAllZones && !isAllTypes) {
    // Filter by both zone and type - find intersection
    filteredData = zoneFilteredData?.filter((item) => typeFilteredData?.includes(item))
  }
  return filteredData
}

const RecordsListPage = () => {
  const { history } = useReactRouter()
  const url = new URL(window.location.href)
  const selectedView = url.searchParams.get('view')

  const classes = useStyles()
  const { params, getParamsUpdater } = useParams({})

  const [selectedRecordTypes, setSelectedRecordTypes] = useState(null)
  const [selectedZones, setSelectedZones] = useState(null)
  const [selectedRecord, setSelectedRecord] = useState([])
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const { loading, message, reload } = useListAction(listRecords)
  const { loading: loadingHosts } = useListAction(listResmgrHosts, {
    params: {
      orderBy: 'name',
      orderDirection: 'asc',
    },
  })
  const data = useAppSelector(recordsSelector)

  const [filteredData, setFilteredData] = useState<any[]>(data)

  const { loading: loadingZones } = useListAction(listZones)
  const zones = useAppSelector(zonesSelector)
  const hosts = useSelector(resmgrHostsSelector)

  const zoneItems = useMemo(() => zones?.map((zone) => ({ label: zone?.name, value: zone?.id })), [
    zones,
  ])

  const typeItmes = useMemo(
    () => uniq(data?.map((record) => ({ label: record?.type, value: record?.type }))),
    [data],
  )

  const rowMenuItems: Array<GridRowMenuItemSpec<any>> = [
    {
      label: 'Edit',
      icon: 'edit',
      handleClick: (item) =>
        history.push(
          routes.openstack.editDnsResource.path({
            tab: DnsPageTabs.Records,
            id: item?.id,
          }),
        ),
      refreshAfterSuccess: true,
    },
    {
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (record) => {
        setSelectedRecord(record)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
  ]

  const hasDnsServiceEnabled = useMemo(
    () => !!hosts?.find((host) => host?.roles?.includes('pf9-designate')),
    [hosts],
  )

  const handleApplyFilter = () => {
    const filteredData = getFilteredData({
      data,
      selectedZones,
      selectedRecordTypes,
      zoneItems,
      recordTypesItems,
    })
    setFilteredData(filteredData)
  }

  useEffect(() => {
    setFilteredData(data)
  }, [data])

  return (
    <>
      <AddRecordPage />
      <UpdateRecordPage />
      {showDeleteDialog && (
        <DeleteRecordDialog rows={[selectedRecord]} onClose={() => setShowDeleteDialog(false)} />
      )}
      <ListContainer<any, any>
        dataKey={DataKeys.DnsRecords}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading || loadingHosts}
        loadingMessage={message}
        onRefresh={reload}
        data={filteredData || []}
        columns={columns}
        addUrl={routes.openstack.createDnsResource.path({
          tab: DnsPageTabs.Records,
          view: selectedView,
        })}
        disableAddButton={!hasDnsServiceEnabled}
        disabledAddButtonInfo="To enable DNS as a Service, you must assign the 'DNS' role to one of your hypervisors. Without this assignment, DNS services will not function properly."
        addText="Add Record"
        editUrl={(item) =>
          routes.openstack.editDnsResource.path({
            tab: DnsPageTabs.Records,
            id: item?.id,
            view: selectedView,
          })
        }
        editCond={(selectedItems) =>
          selectedItems[0]?.type !== DnsRecordType.SOA &&
          selectedItems[0]?.type !== DnsRecordType.NS
        }
        editDisabledInfo="NS and SOA type records cannot be edited."
        deleteCond={(selectedItems) =>
          selectedItems[0]?.type !== DnsRecordType.SOA &&
          selectedItems[0]?.type !== DnsRecordType.NS
        }
        deleteDisabledInfo="NS and SOA type records cannot be deleted."
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteRecord}
        DeleteDialogComponent={DeleteRecordDialog}
        rowMenuItems={rowMenuItems}
        CardComponent={RecordCard}
        HeaderActions={
          <div className={classes.filtersContainer}>
            <RadioDropdownFilter
              label="Zone"
              items={zoneItems}
              loading={loadingZones}
              onChange={(selectedItems) => setSelectedZones(selectedItems)}
              handleApplyFilter={handleApplyFilter}
            />
            <RadioDropdownFilter
              label="Record Type"
              items={typeItmes}
              onChange={(selectedItems) => setSelectedRecordTypes(selectedItems)}
              handleApplyFilter={handleApplyFilter}
            />
          </div>
        }
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}

export default RecordsListPage

const useStyles = makeStyles<Theme>((theme) => ({
  filtersContainer: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}))
