import React, { useCallback, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createImage, uploadImage, importImage, deleteImage } from './actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import RadioFields, { Orientation } from 'core/components/validatedForm/radio-fields'
import FileDrop from 'core/elements/FileDrop'
import LargeFileDrop from 'core/elements/LargeFileDrop'
import PicklistField from 'core/components/validatedForm/DropdownField'
import ContainerFormatPicklist from './ContainerFormatPicklist'
import DiskFormatPicklist from './DiskFormatPicklist'
// import VisibilityPicklist from './VisibilityPicklist'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import { useSelector } from 'react-redux'
import { isAdminRole } from 'k8s/util/helpers'
import { RootState } from 'app/store'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { prop } from 'ramda'
import Text from 'core/elements/Text'
import Progress from 'core/components/progress/Progress'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import ServiceUnhealthyInfo from '../common/ServiceUnhealthyInfo'
import ExternalLink from 'core/components/ExternalLink'
import ConfirmationDialog from 'core/components/ConfirmationDialog'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  addRoute: Route
}

export const imageUploadRadioOptions = [
  {
    value: 'file',
    label: 'Upload File',
    info: 'Select an image file to upload.',
  },
  {
    value: 'url',
    label: 'Upload via URL.',
    info: 'Select an image that is accessible for download via a URL.',
  },
]

export const vtpmRadioOptions = [
  {
    value: 'tpm-tis',
    label: 'TPM Interface Specification (TIS)',
  },
  {
    value: 'tpm-crb',
    label: 'Command-Response Buffer (CRB)',
  },
]

export default function AddImageModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const defaultParams = {
    name: '',
    method: 'file',
    containerFormat: 'bare',
    diskFormat: '',
    url: '',
    public: false,
    protected: false,
    enableVTPM: false,
    vtpmModel: 'tpm-tis',
  }
  const [fileData, setFileData] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const isAdmin = useMemo(() => isAdminRole(session), [session])

  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { update: deleteFn, updating: deletingItem, error: deleteError } = useUpdateAction(
    deleteImage,
  )
  const { update: create, updating: creating, error, reset: resetCreate } = useUpdateAction(
    createImage,
  )
  const {
    update: upload,
    updating: uploading,
    error: uploadError,
    reset: resetUpload,
  } = useUpdateAction(uploadImage)
  const {
    update: importFn,
    updating: importing,
    error: importError,
    reset: resetImport,
  } = useUpdateAction(importImage)

  const submitForm = useCallback(async () => {
    if (!fileData) {
      setFormSubmitted(true)
      return
    }

    const body = {
      name: params.name,
      container_format: params.containerFormat ? params.containerFormat : undefined,
      disk_format: params.diskFormat ? params.diskFormat : undefined,
      protected: params.protected,
      visibility: isAdmin ? (params.public ? 'public' : 'private') : undefined,
      hw_tpm_version: params.enableVTPM ? '2.0' : undefined,
      hw_tpm_model: params.enableVTPM ? params.vtpmModel : undefined,
    }
    const importBody = {
      method: {
        name: 'web-download',
        uri: params.url,
      },
      all_stores: true,
      all_stores_must_succeed: true,
    }
    const { success, response } = await create({ body })
    if (!success) {
      return
    }
    const imageId = response?.id
    const { success: uploadSuccess } =
      params.method === 'file'
        ? await upload({ id: imageId, body: fileData })
        : await importFn({ id: imageId, body: importBody })

    if (!uploadSuccess) {
      await deleteFn({ id: imageId })
    } else {
      reset()
    }
  }, [params, fileData, isAdmin])

  const handleClose = () => {
    if (fileData) {
      setShowConfirmationDialog(true)
    } else {
      // close the modal without showing the confirmation dialog
      setShowConfirmationDialog(false)
      reset()
    }
  }

  const handleConfirm = () => {
    reset()
    setShowConfirmationDialog(false)
  }

  const reset = () => {
    setParams(defaultParams)
    setFileData(null)
    resetCreate()
    resetUpload()
    resetImport()
    history.push(routes.openstack.images.path())
  }

  return (
    <>
      <ModalForm
        route={addRoute}
        title={`Add Image`}
        onSubmit={submitForm}
        onClose={handleClose}
        submitting={creating || uploading || importing || deletingItem}
        error={error || uploadError || importError || deleteError}
        submitTitle={`Add Image`}
      >
        <>
          {uploading && <Progress loading message={'Uploading Image'} />}
          {!uploading && (
            <>
              <ServiceUnhealthyInfo action="Image Creation" imageLibrary />
              <FormFieldSection title="Image Selection">
                {/* <RadioFields
            id="method"
            orientation={Orientation.Column}
            title="Image Upload Method"
            options={imageUploadRadioOptions}
            value={params.method}
            onChange={getParamsUpdater('method')}
          /> */}
                <Text variant="body2">
                  <b>Note:</b> Images larger than <b>1GB</b> are recommended to be imported via CLI
                  rather than through file upload.
                </Text>
                {params.method === 'file' && (
                  <LargeFileDrop
                    id="fileDrop"
                    value={fileData}
                    onChange={(value) => {
                      setFileData(value)
                      setFormSubmitted(false)
                    }}
                    onChangeName={(name) => {
                      if (params.name) {
                        return
                      }
                      updateParams({ name })
                    }}
                    fileTypes={[
                      '.img',
                      '.iso',
                      '.bare',
                      '.ovf',
                      '.ova',
                      '.vhd',
                      '.vhdx',
                      '.vmdk',
                      '.raw',
                      '.qcow2',
                    ]}
                  />
                )}
                {!fileData && formSubmitted && <ErrorMessage>Upload a file</ErrorMessage>}
                {params.method === 'url' && (
                  <TextField
                    id="url"
                    label="Image Download URL"
                    onChange={getParamsUpdater('url')}
                    value={params.url}
                    required
                  />
                )}
              </FormFieldSection>
              <FormFieldSection title="Image Settings">
                <TextField
                  id="name"
                  label="Name"
                  onChange={getParamsUpdater('name')}
                  value={params.name}
                  required
                />
                {/* <PicklistField
                DropdownComponent={ContainerFormatPicklist}
                id="containerFormat"
                onChange={getParamsUpdater('containerFormat')}
                value={params.containerFormat}
                label="Container Format"
                required
                // tooltip=""
              /> */}
                <Text variant="body2">
                  Choose the disk format and other settings for the image. See{' '}
                  <ExternalLink url="https://platform9.com/docs/private-cloud-director/private-cloud-director/image-library---images#image-formats">
                    here
                  </ExternalLink>{' '}
                  for details about supported disk formats, and{' '}
                  <ExternalLink url="https://platform9.com/docs/private-cloud-director/private-cloud-director/virtual-tpm">
                    here
                  </ExternalLink>{' '}
                  for details about virtual TPM.
                </Text>
                <PicklistField
                  DropdownComponent={DiskFormatPicklist}
                  id="diskFormat"
                  onChange={getParamsUpdater('diskFormat')}
                  value={params.diskFormat}
                  label="Disk Format"
                  required
                  // tooltip=""
                />
                {/* <PicklistField
            DropdownComponent={VisibilityPicklist}
            id="visibility"
            onChange={getParamsUpdater('visibility')}
            value={params.visibility}
            label="Visibility"
            tooltip=""
            // required
          /> */}
                {!!isAdmin && (
                  <CheckboxField
                    id="public"
                    label="Make Public"
                    onChange={getParamsUpdater('public')}
                    value={params.public}
                    info="Public images will be available across all tenants. Private images will only be available within the same tenant."
                  />
                )}
                <CheckboxField
                  id="protected"
                  label="Protected"
                  onChange={getParamsUpdater('protected')}
                  value={params.protected}
                  info="Protect images from deletion."
                />
                <CheckboxField
                  id="enableVTPM"
                  label="Enable Virtual TPM 2.0 "
                  onChange={getParamsUpdater('enableVTPM')}
                  value={params.enableVTPM}
                />
                {params.enableVTPM && (
                  <RadioFields
                    title="Virtual TPM Model"
                    id="vtpmModel"
                    options={vtpmRadioOptions}
                    value={params.vtpmModel}
                    onChange={getParamsUpdater('vtpmModel')}
                  />
                )}
              </FormFieldSection>
            </>
          )}
        </>
      </ModalForm>
      {showConfirmationDialog && (
        <ConfirmationDialog
          open
          title="Discard Image?"
          text="Are you sure you want to discard the image?"
          onConfirm={handleConfirm}
          onCancel={() => setShowConfirmationDialog(false)}
        />
      )}
    </>
  )
}
