import { useAppSelector } from 'app/store'
import withProgress from 'core/components/progress/withProgress'
import Dropdown from 'core/elements/dropdown'
import useListAction from 'core/hooks/useListAction'
import { listOctaviaListener } from 'openstack/components/networks/actions'
import { octaviaListenersSelector } from 'openstack/components/networks/selectors'
import React, { useMemo } from 'react'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function ListenerPicklist({
  disabled = false,
  name = 'listerners',
  label = 'Listeners',
  networkId = null,
  includeNoneOption = false,
  ...rest
}) {
  const { loading } = useListAction(listOctaviaListener)
  const listeners = useAppSelector(octaviaListenersSelector)
  const options = useMemo(() => {
    const listenerOptions = listeners.map((listener) => {
      return {
        label: listener?.name,
        value: listener?.id,
      }
    })
    return listenerOptions
  }, [listeners])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
