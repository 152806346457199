import { makeStyles } from '@material-ui/styles'
import { DnsPageTabs } from 'app/plugins/openstack/components/networks/dns/model'
import { listPools } from 'app/plugins/openstack/components/networks/dns/pools/actions'
import { poolsSelector } from 'app/plugins/openstack/components/networks/dns/pools/selectors'
import { createZone, listZones } from 'app/plugins/openstack/components/networks/dns/zones/actions'
import ZoneConfigurationFields from 'app/plugins/openstack/components/networks/dns/zones/ZoneConfigurationFields'
import { useAppSelector } from 'app/store'
import Alert from 'core/components/Alert'
import ExternalLink from 'core/components/ExternalLink'
import ModalForm from 'core/elements/modal/ModalForm'
import Text from 'core/elements/Text'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import React, { useEffect } from 'react'
import useReactRouter from 'use-react-router'

const defaultParams = {
  zoneName: '',
  zoneType: '',
  dnsPool: '',
  adminEmail: '',
  defaultTtl: 3600,
  refresh: 86400,
  retry: 7200,
  expire: 2419200,
  minimumTtl: 300,
}

const AddZonePage = () => {
  const classes = useStyles({})
  const url = new URL(window.location.href)
  const selectedView = url.searchParams.get('view')

  const { history } = useReactRouter()
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { reload } = useListAction(listZones)
  const { loading } = useListAction(listPools)
  const { update: create, updating, error, reset } = useUpdateAction(createZone)

  const pools = useAppSelector(poolsSelector)

  const defaultPool = pools.find((pool) => pool.name === 'default')

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(
      routes.openstack.dns.path({
        tab: DnsPageTabs.Zones,
        view: selectedView,
      }),
    )
    reload(true)
  }

  const handleSubmit = async (params) => {
    const body = {
      name: params.zoneName,
      email: params.adminEmail,
      type: params.zoneType,
      ttl: params.defaultTtl,
    }
    const { success } = await create(body)
    if (success) handleClose()
  }

  useEffect(() => {
    updateParams({
      ...params,
      dnsPool: defaultPool?.id,
    })
  }, [defaultPool, params])

  return (
    <ModalForm
      route={routes.openstack.createDnsResource}
      title={`Add DNS Zone`}
      maxWidth={464}
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitting={updating}
      submitTitle="Add DNS Zone"
      loading={loading}
    >
      <div>
        {error && <Alert className={classes.alert} variant="error" message={error?.message} />}
        <div className={classes.iconTextWrapper}>
          <div className={classes.icon}>
            <img src="/ui/images/golf-flag-hole.svg" alt="DNS Zone" />
          </div>
          <Text variant="body2">
            Create a new{' '}
            <ExternalLink url="https://platform9.com/docs/private-cloud-director/private-cloud-director/dns-as-a-service--dnsaas-">
              DNS zone
            </ExternalLink>{' '}
            for your domain.
          </Text>
        </div>
        <ZoneConfigurationFields params={params} getParamsUpdater={getParamsUpdater} />
      </div>
    </ModalForm>
  )
}

export default AddZonePage
const useStyles = makeStyles<Theme>((theme) => ({
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconTextWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}))
