import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@material-ui/styles'
import Text from 'core/elements/Text'
import { createVolumeTransfer } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import ServiceUnhealthyInfo from 'openstack/components/common/ServiceUnhealthyInfo'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import CopyToClipboard from 'core/components/CopyToClipboard'

const useStyles = makeStyles<Theme>((theme) => ({
  successIcon: {
    color: theme.components.badge.success.color,
    marginRight: 8,
  },
}))

const Container = styled('div')({
  gap: 16,
  display: 'grid',
})

export default function TransferVolumeDialog({ rows: [volume], onClose }) {
  const classes = useStyles()
  const [authCode, setAuthCode] = useState('')
  const [transferId, setTransferId] = useState('')
  const { update, updating, error, reset } = useUpdateAction(createVolumeTransfer)

  const handleSubmit = useCallback(async () => {
    const body = {
      transfer: {
        volume_id: volume.id,
      },
    }
    const { success, response } = await update({
      body,
      volumeId: volume.id,
    })
    if (success) {
      setAuthCode(response?.auth_key)
      setTransferId(response?.id)
    }
  }, [volume])

  const handleClose = () => {
    reset()
    onClose(true)
  }

  return (
    <ModalForm
      title="Transfer Volume"
      onSubmit={authCode ? handleClose : handleSubmit}
      onClose={onClose}
      submitting={updating}
      error={error}
      submitTitle={authCode ? `Finished` : 'Transfer Volume'}
      panel="dialog"
      maxWidth={800}
      open
    >
      <ServiceUnhealthyInfo action="Volume Transfer" blockStorage />
      <Container>
        {!!authCode ? (
          <>
            <Text variant="subtitle2">
              <FontAwesomeIcon className={classes.successIcon}>circle-check</FontAwesomeIcon>Your
              volume transfer has been initiated.
            </Text>
            <Text variant="body2">
              It is important to store the Authorization Code and Transfer ID now, as this data will
              not be accessible once you exit this dialog.
            </Text>
            <div>
              <Text variant="body2">Transfer ID:</Text>
              <CopyToClipboard copyText={transferId} codeBlock={false}>
                <Text variant="caption1">{transferId}</Text>
              </CopyToClipboard>
            </div>
            <div>
              <Text variant="body2">Authorization Code:</Text>
              <CopyToClipboard copyText={authCode} codeBlock={false}>
                <Text variant="caption1">{authCode}</Text>
              </CopyToClipboard>
            </div>
          </>
        ) : (
          <>
            <Text variant="body2">
              Transfer this volume to another tenant. After initiating a volume transfer, it must be
              accepted in another tenant.
            </Text>
            <Text variant="body2">
              <b>
                In order to accept a volume transfer, you will need to save the Volume ID and
                Authorization Code for the acceptee to use later when accepting the transfer
              </b>
              .
            </Text>
          </>
        )}
      </Container>
    </ModalForm>
  )
}
