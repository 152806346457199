import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'

export const poolsSelector = createSharedSelector(
  getDataSelector<DataKeys.DnsPools>(DataKeys.DnsPools),
  (pools) => {
    return pools.map((pool) => ({
      ...pool,
      id: pool.id,
      name: pool.name,
      backend: '',
      servers: '',
      phase: '',
      description: pool.description,
    }))
  },
)
