import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useListAction from 'core/hooks/useListAction'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ListTableField from 'core/components/validatedForm/ListTableField'
import { useAppSelector } from 'app/store'
import {
  attachVolume,
  detachVolume,
  listVolumes,
} from 'openstack/components/storage/volumes/actions'
import { volumesSelector } from 'openstack/components/storage/volumes/selectors'
import { humanReadableSize } from 'openstack/helpers'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import ServiceUnhealthyInfo from 'openstack/components/common/ServiceUnhealthyInfo'
import Info from 'core/components/validatedForm/Info'
import { getCurrentUser } from 'openstack/helpers'
import { hasOtherUserVm } from './helpers'

const useStyles = makeStyles<Theme>((theme) => ({
  nestedModal: {
    zIndex: 20000,
  },
}))

const enum VolumeStatus {
  Reserved = 'reserved',
  Detaching = 'detaching',
  Attaching = 'attaching',
  Available = 'available',
  InUse = 'in-use',
}
const InProgressStates = ['detaching', 'attaching', 'reserved']
const AvailableStates = ['available', 'detaching', 'attaching', 'reserved']

const VolumeActionButton = ({ volume, detachVolumeFromVM, attachVolumeToVm }) => {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)
  const volumeInUse = volume.status === VolumeStatus.InUse
  const actionInProgress = InProgressStates.includes(volume.status)

  return (
    <>
      <ModalForm
        title={volumeInUse ? 'Detach Volume' : 'Attach Volume'}
        onSubmit={() => {
          if (volumeInUse) {
            detachVolumeFromVM(volume)
          } else {
            attachVolumeToVm(volume)
          }
          setModalOpen(false)
        }}
        onClose={() => setModalOpen(false)}
        submitTitle={volumeInUse ? 'Detach' : 'Attach'}
        panel="dialog"
        pageClassName={classes.nestedModal}
        open={modalOpen}
      >
        {volumeInUse ? (
          <Text variant="body2">
            Are you sure you want to detach this volume <b>{volume.name || volume.id}</b>?
            Detachiing will make the volume unavailable to the virtual machine and may disrupt any
            running proceses dependent on it.
          </Text>
        ) : (
          <Text variant="body2">
            Are you sure you want to attach this volume <b>{volume.name || volume.id}</b> to the
            selected virtual machine?
          </Text>
        )}
      </ModalForm>
      <Button
        id="attachDetachVolume"
        size="small"
        disabled={actionInProgress}
        loading={actionInProgress}
        onClick={() => setModalOpen(true)}
        variant={volumeInUse ? 'tertiary' : 'primary'}
        icon={volumeInUse ? 'trash-alt' : 'plus'}
      >
        {volumeInUse ? 'Detach' : 'Attach'}
      </Button>
    </>
  )
}

const volumeColumns = ({ detachVolumeFromVM, attachVolumeToVm }) => [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'id',
    label: 'UUID',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'size',
    label: 'Capacity',
    render: (size) => humanReadableSize(size * 1024 * 1024 * 1024),
  },
  {
    id: 'bootable',
    label: 'Bootable',
  },
  {
    id: 'description',
    label: 'Action',
    render: (_description, item) => {
      return (
        <VolumeActionButton
          volume={item}
          detachVolumeFromVM={detachVolumeFromVM}
          attachVolumeToVm={attachVolumeToVm}
        />
      )
    },
  },
]

export default function ManageVolumesModal({ rows: vms, onClose }) {
  const vm = vms?.[0]
  const [timeoutId, setTimeoutId] = useState(null)
  const { role: userRole, userId } = getCurrentUser()

  // Update volumes
  const {
    update: attachVolumeFn,
    updating: attachingVolume,
    error: attachVolumeError,
  } = useUpdateAction(attachVolume)
  const {
    update: detachVolumeFn,
    updating: detachingVolume,
    error: detachingVolumeError,
  } = useUpdateAction(detachVolume)

  // Fetch volumes
  const { loading: loadingVolumes, reload } = useListAction(listVolumes, { ignoreDependency: true })
  const volumes = useAppSelector(volumesSelector)
  const availableVolumes = useMemo(() => {
    return volumes.filter(
      (volume) =>
        AvailableStates.includes(volume.status) ||
        (volume.status === VolumeStatus.InUse && volume?.attachedVm?.id === vm.id),
    )
  }, [volumes, vm])

  // Make sure to reload volumes when the modal is opened
  useEffect(() => {
    reload(true, false)

    return () => {
      // Cleanup timeout
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [])

  // Refresh volumes if for any of the volume action is in progress
  useEffect(() => {
    const actionInProgress = availableVolumes.find((volume) => {
      return InProgressStates.includes(volume.status)
    })
    if (actionInProgress && !timeoutId) refreshVolumes()
  }, [availableVolumes, timeoutId])

  // Refresh volumes after 5 seconds
  const refreshVolumes = useCallback(() => {
    const timeout = setTimeout(() => {
      reload(true, true)
      setTimeoutId(null)
    }, 5000)
    setTimeoutId(timeout)
  }, [reload])

  // Attach volume to VM
  const attachVolumeToVm = useCallback(
    async ({ id }) => {
      const body = {
        volumeAttachment: {
          volumeId: id,
        },
      }
      const { success } = await attachVolumeFn({ vmId: vm.id, id, body })
      if (success) refreshVolumes()
    },
    [vm],
  )

  // Detach volume from VM
  const detachVolumeFromVM = useCallback(
    async ({ id }) => {
      const { success } = await detachVolumeFn({ vmId: vm.id, volumeId: id })
      if (success) refreshVolumes()
    },
    [vm],
  )

  const loading = loadingVolumes || attachingVolume || detachingVolume

  return (
    <ModalForm
      open
      title={`Manage Volumes`}
      onClose={onClose}
      submitting={attachingVolume}
      error={attachVolumeError || detachingVolumeError}
      submitTitle={`Attach Volumes`}
      maxWidth={1200}
      closeText="Close"
    >
      <ServiceUnhealthyInfo action="Volume Actions" hypervisor blockStorage />
      <FormFieldSection title={`Volumes to attach or detach from VM ${vm.name || vm.id}`}>
        {userRole === 'member' && hasOtherUserVm({ vms, userId }) && (
          <Info error>NOTE: You are performing an operation on another user's VM.</Info>
        )}
        <ListTableField
          id="attachDetachVolumes"
          uniqueIdentifier="id"
          searchTargets={['name', 'id', 'status']}
          data={availableVolumes}
          loading={loading}
          columns={volumeColumns({ attachVolumeToVm, detachVolumeFromVM })}
          onReload={() => reload(true, true)}
          emptyText="No volumes available"
          showCheckboxes={false}
        />
      </FormFieldSection>
    </ModalForm>
  )
}
