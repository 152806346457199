import React, { useCallback, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createRouter } from './actions'
import { listPorts } from '../actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import AdminStatePicklist from '../AdminStatePicklist'
import ExternalNetworkPicklist from './ExternalNetworkPicklist'
import useListAction from 'core/hooks/useListAction'

// import CheckboxField from 'core/components/validatedForm/CheckboxField'
// import useListAction from 'core/hooks/useListAction'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  addRoute: Route
}

export default function CreateRouterModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const defaultParams = {
    name: '',
    adminState: 'Up',
    externalNetwork: '',
  }
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  // const { reload } = useListAction(listNetworks, {})
  const { update, updating, error, reset } = useUpdateAction(createRouter)

  const { reload: reloadPorts } = useListAction(listPorts)

  const submitForm = useCallback(async () => {
    const body = {
      router: {
        name: params.name,
        admin_state_up: params.adminState !== 'Down',
        external_gateway_info: !!params.externalNetwork
          ? {
              network_id: params.externalNetwork,
            }
          : undefined,
      },
    }
    const { success, response } = await update({ body })
    if (success) {
      reloadPorts(true, true)
      handleClose()
    }
  }, [params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.openstack.routers.path())
  }

  return (
    <ModalForm
      route={addRoute}
      title={`Create Router`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Create Router`}
    >
      <>
        <FormFieldSection title="Router Configuration">
          <TextField
            id="name"
            label="Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            required
          />
          <AdminStatePicklist value={params.adminState} onChange={getParamsUpdater('adminState')} />
          <ExternalNetworkPicklist
            value={params.externalNetwork}
            onChange={getParamsUpdater('externalNetwork')}
            includeNoneOption
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
