import { makeStyles } from '@material-ui/styles'
import { useAppSelector } from 'app/store'
import Alert from 'core/components/Alert'
import ExternalLink from 'core/components/ExternalLink'
import SimpleLink from 'core/components/SimpleLink'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { Route } from 'core/plugins/route'
import Theme from 'core/themes/model'
import { portValidator } from 'core/utils/fieldValidators'
import { routes } from 'core/utils/routes'
import { loadBalancerHelpLink } from 'k8s/links'
import PicklistField from 'pf9-ui-components/built/components/validatedForm/DropdownField'
import TextField from 'pf9-ui-components/built/components/validatedForm/TextField'
import { middleLeft } from 'pf9-ui-components/built/elements/menu/defaults'
import ModalForm from 'pf9-ui-components/built/elements/modal/ModalForm'
import Text from 'pf9-ui-components/built/elements/Text'
import React, { useCallback, useState } from 'react'
import useReactRouter from 'use-react-router'
import { createListener, listLoadBalancers, listOctaviaListener } from './actions'
import { getListenerRequestBody } from './helpers'
import { ILoadBalancerDetailsPageTabs } from './model'
import LoadBalancerPicklist from './network-details/LoadBalancerPicklist'
import ProtocolPicklist from './network-details/ProtocolPicklist'
import { loadBalancersSelector } from './selectors'

const useStyles = makeStyles<Theme>((theme) => ({
  formFieldSection: {
    marginBottom: '32px',
    border: 'none',
    padding: 0,
  },
  pageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'grid',
    gap: 16,
    gridAutoRows: 'max-content',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  iconWrapper: {
    display: 'flex',
    height: '32px',
    width: '32px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoText: {
    position: 'absolute',
    top: 'unset',
    left: 'unset',
    right: '0%',
    bottom: '0%',
    width: 'max-content',
    maxWidth: '290px',
    padding: '8px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: theme.palette.grey[900],
    zIndex: 10000,
    transition: 'opacity 0.2s, transform 0.2s',
  },
  customFormFieldSection: {
    '& .content': {
      marginLeft: '0px',
    },
  },
}))

interface Props {
  addRoute: Route
}

export default function CreateListenersModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const imagePath = '/ui/images/listeners-logo.svg'
  const classes = useStyles()
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)
  const defaultParams = {
    name: '',
    loadBalancer: '',
    protocol: '',
    port: '',
    provider: 'ovn',
  }
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)

  const { reload } = useListAction(listOctaviaListener, {})
  const { update, updating, error, reset } = useUpdateAction(createListener)
  useListAction(listLoadBalancers)
  const loadBalancers = useAppSelector(loadBalancersSelector)
  const isDisabled = loadBalancers.length === 0
  const createListenerFn = async (params) => {
    const body = getListenerRequestBody(params)
    const { success, response } = await update({ body })
    return { success, response }
  }

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(
      routes.openstack.loadBalancers.details.path({
        tab: ILoadBalancerDetailsPageTabs.Listeners,
      }),
    )
  }

  const submitForm = useCallback(async () => {
    setAttemptedSubmit(true)

    const { success } = await createListenerFn(params)
    if (success) {
      handleClose()
      reload()
    }
    setAttemptedSubmit(false)
  }, [params, createListenerFn, handleClose, reload])

  const redirectLink = () => {
    history.push(
      routes.openstack.createLoadBalancers.path({
        tab: ILoadBalancerDetailsPageTabs.LoadBalancers,
      }),
    )
  }
  return (
    <ModalForm
      route={addRoute}
      title={`Create Listener`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating || attemptedSubmit}
      error={error}
      submitTitle={`Create Listener`}
      maxWidth={470}
      disableSubmit={isDisabled}
    >
      <>
        <div className={classes.formFieldSection}>
          {isDisabled && (
            <div className={classes.formFieldSection}>
              <Alert title="No Load Balancer Detected" variant="error">
                <Text
                  variant="body2"
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  In order to create a listener, you first need to create a load balancer.
                </Text>
                <SimpleLink onClick={redirectLink}>Create Load Balancer</SimpleLink>
              </Alert>
            </div>
          )}
          <div className={classes.contentContainer}>
            <img className={classes.iconWrapper} alt="Listener" src={imagePath} />
            <div className={classes.content}>
              <Text
                variant="body2"
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                Create a <ExternalLink url={loadBalancerHelpLink}>listener</ExternalLink> that
                defines how your load balancer processes incoming requests. After creating the
                listener, you will be able to assign pools.
              </Text>
            </div>
          </div>
        </div>
        <FormFieldSection className={classes.customFormFieldSection} title="Listener Configuration">
          <TextField
            id="name"
            label="Listener Name"
            onChange={getParamsUpdater('name')}
            value={params.name}
            disabled={isDisabled}
            required
            enterKeyHint={undefined}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
            crossOrigin={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
          <PicklistField
            DropdownComponent={LoadBalancerPicklist}
            name="loadBalancer"
            id={`fixedIps.{idx}.loadBalancer`}
            label="Load Balancer"
            compact={false}
            onChange={getParamsUpdater('loadBalancer')}
            info="Select the load balancer this listener will be attached to."
            align={middleLeft.align}
            disabled={isDisabled}
            required
          />
          <PicklistField
            DropdownComponent={ProtocolPicklist}
            name="protocol"
            id={`fixedIps.{idx}.protocol`}
            label="Protocol"
            compact={false}
            onChange={getParamsUpdater('protocol')}
            info="Network protocol for accepting incoming traffic. (TCP, UDP, SCTP)"
            align={middleLeft.align}
            disabled={isDisabled}
            required
          />
          <TextField
            id="port"
            label="Port"
            onChange={getParamsUpdater('port')}
            value={params.port}
            placeholder="Enter Port (eg. 80)"
            type="number"
            info={
              <div className={classes.infoText}>
                Port number to monitor for incoming connections (e.g., 80, 443, etc).
              </div>
            }
            disabled={isDisabled}
            validations={[portValidator]}
            required
            enterKeyHint={undefined}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
            crossOrigin={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
