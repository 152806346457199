import Dropdown from 'core/elements/dropdown'
import React from 'react'

export enum DnsRecordType {
  A = 'A',
  AAAA = 'AAAA',
  CNAME = 'CNAME',
  MX = 'MX',
  TXT = 'TXT',
  SRV = 'SRV',
  NS = 'NS',
  SOA = 'SOA',
}

export const options = [
  DnsRecordType.A,
  DnsRecordType.AAAA,
  DnsRecordType.CNAME,
  DnsRecordType.MX,
  DnsRecordType.TXT,
  DnsRecordType.SRV,
].map((value) => ({
  label: value,
  value,
}))

const RecordTypePicklist = (props) => {
  return <Dropdown label="Zone Type" items={options} {...props} />
}

export default RecordTypePicklist
