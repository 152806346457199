import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import TextField from 'core/components/validatedForm/TextField'
import Tooltip from 'core/elements/tooltip'
import { topMiddle } from 'core/elements/menu/defaults'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { remove, update } from 'ramda'
import { customValidator } from 'core/utils/fieldValidators'
import * as IpAddress from 'ip-address'
import { IPVersions } from 'app/constants'

const allocationPoolInCidrValidator = customValidator((value, formValues) => {
  if (!value) {
    return true
  }
  try {
    const [start, end] = value.replace(/\s/g, '').split('-')
    if (formValues.ipVersion === IPVersions.IPv4) {
      const testCidr = new IpAddress.Address4(formValues.cidr)
      const startOk = new IpAddress.Address4(start).isInSubnet(testCidr)
      const endOk = new IpAddress.Address4(end).isInSubnet(testCidr)
      return startOk && endOk
    } else {
      const testCidr = new IpAddress.Address6(formValues.cidr)
      const startOk = new IpAddress.Address6(start).isInSubnet(testCidr)
      const endOk = new IpAddress.Address6(end).isInSubnet(testCidr)
      return startOk && endOk
    }
  } catch (err) {
    return false
  }
}, 'Allocation Pool must be inside of designated CIDR')

const startEndAddressValidator = customValidator((value, formValues) => {
  if (!value) {
    return true
  }
  try {
    const [start, end] = value.replace(/\s/g, '').split('-')
    const testCidr =
      formValues.ipVersion === IPVersions.IPv4
        ? new IpAddress.Address4(formValues.cidr)
        : new IpAddress.Address6(formValues.cidr)
    const startAddress = testCidr.startAddress().address
    const endAddress = testCidr.endAddress().address
    return start !== startAddress && end !== endAddress
  } catch (err) {
    return false
  }
}, 'Allocation Pool may not include the start or end address of the designated CIDR')

const allocationPoolOrderValidator = customValidator((value, formValues) => {
  if (!value) {
    return true
  }
  try {
    const [start, end] = value.replace(/\s/g, '').split('-')
    if (start === end) {
      return true
    } // if start and end are the same
    if (formValues.ipVersion === IPVersions.IPv4) {
      const sap = new IpAddress.Address4(start).parsedAddress.map((num) => parseInt(num)) // start address parts
      const eap = new IpAddress.Address4(end).parsedAddress.map((num) => parseInt(num)) // start address parts
      return eap[0] >= sap[0] && eap[1] >= sap[1] && eap[2] >= sap[2] && eap[3] >= sap[3]
    } else {
      const sap = new IpAddress.Address6(start).bigInteger()
      const eap = new IpAddress.Address6(end).bigInteger()
      return Object.keys(sap).every((key) => eap[key] >= sap[key])
    }
  } catch (err) {
    return false
  }
}, 'Starting allocation pool address must be lower than the ending address')

const allocationPoolStringValidator = customValidator((value, formValues) => {
  if (!value) {
    return true
  }
  try {
    const [start, end] = value.replace(/\s/g, '').split('-')
    if (!start || !end) {
      return false
    }
    const testStart =
      formValues.ipVersion === IPVersions.IPv4
        ? new IpAddress.Address4(start)
        : new IpAddress.Address6(start)
    const testEnd =
      formValues.ipVersion === IPVersions.IPv4
        ? new IpAddress.Address4(end)
        : new IpAddress.Address6(end)
    return !!testStart && !!testEnd
  } catch (err) {
    return false
  }
}, 'Allocation pool must be two valid IP addresses separated by -')

export default function AllocationPoolsField({ params, updateParams }) {
  const classes = useStyles()

  const addPool = useCallback(() => {
    updateParams({ allocationPools: [...params.allocationPools, ''] })
  }, [params.allocationPools])

  const removePool = useCallback(
    (index) => {
      updateParams({ allocationPools: remove(index, 1, params.allocationPools) })
    },
    [params.allocationPools],
  )

  return (
    <div className={classes.subnetField}>
      <div className={classes.fieldLabel}>
        <Text variant="caption1">Allocation Pools</Text>
        <Tooltip
          message="Specify the allocation pools that will be used to select new IP addresses from. These
        must be inside the range of the network's CIDR, and cannot conflict with the network's
        network address, broadcast address, or gateway IP. eg. 192.0.2.2 - 192.0.2.254"
          align={topMiddle.align}
          offset={topMiddle.offset}
          origin="right center"
        >
          <Text variant="inputLabel" className={classes.hint}>
            <FontAwesomeIcon>question-circle</FontAwesomeIcon>
          </Text>
        </Tooltip>
      </div>
      <div>
        {params.allocationPools.map((allocationPool, index) => (
          <div key={index} className={classes.inputRow}>
            <TextField
              id={`allocationPools.${index}`}
              label=""
              placeholder={`Allocation Pool ${index + 1}`}
              value={allocationPool}
              onChange={(value) =>
                updateParams({ allocationPools: update(index, value, params.allocationPools) })
              }
              validations={[
                allocationPoolStringValidator,
                allocationPoolOrderValidator,
                startEndAddressValidator,
                allocationPoolInCidrValidator,
              ]}
              required
            />
            <FontAwesomeIcon className={classes.minus} onClick={() => removePool(index)}>
              minus-circle
            </FontAwesomeIcon>
          </div>
        ))}
        <div className={classes.addEntry}>
          <FontAwesomeIcon className={classes.plus} onClick={() => addPool()} size="lg">
            plus-circle
          </FontAwesomeIcon>
          <Text variant="body2" onClick={() => addPool()} className={classes.clickable}>
            Add Allocation Pool
          </Text>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  subnetField: {
    display: 'grid',
    gap: 8,
  },
  fieldLabel: {
    display: 'grid',
    maxWidth: 400,
    minWidth: 100,
    width: '100%',
    gridTemplateColumns: 'max-content minmax(0px, max-content)',
    gap: 8,
    alignItems: 'center',
  },
  hint: {
    cursor: 'help',
    transition: 'color .2s ease',
    color: theme.components.input.label.hint,
    '& i': {
      cursor: 'help',
      marginRight: 8,
      transition: 'color .2s ease',
      color: theme.components.input.label.hint,
    },
  },
  multipleInputs: {
    display: 'grid',
    gap: 12,
  },
  inputRow: {
    display: 'grid',
    gridTemplateColumns: '356px 32px',
    gridGap: '12px',
    justifyItems: 'start',
    alignItems: 'center',
  },
  minus: {
    fontWeight: 900,
    color: theme.palette.blue.main,
    justifySelf: 'end',
    position: 'relative',
    top: 4,
  },
  addEntry: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, max-content)',
    marginTop: 12,
    marginLeft: 8,
    alignItems: 'center',
  },
  plus: {
    color: theme.palette.blue.main,
    marginRight: 8,
    fontWeight: 900,
  },
  clickable: {
    cursor: 'pointer',
  },
}))
