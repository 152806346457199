import { allKey } from 'app/constants'
import { StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'

export const getPoolName = (poolId, pools) =>
  pools?.find((pool) => pool?.id === poolId)?.name || '-'

export const filterRecords = (data, selectedZone, selectedRecordType) => {
  if (!data) return []

  const zoneValue = selectedZone?.value
  const typeValue = selectedRecordType?.value

  if (zoneValue === allKey && typeValue === allKey) return data
  if (zoneValue !== allKey && typeValue === allKey) {
    //if only zone is selected
    return data.filter((item) => item?.zone_id === zoneValue)
  }
  if (typeValue !== allKey && zoneValue === allKey) {
    //if only type is selected
    return data.filter((item) => item?.type === typeValue)
  }

  return data.filter(
    (item) =>
      (zoneValue === allKey || item?.zone_id === zoneValue) &&
      (typeValue === allKey || item?.type === typeValue),
  )
}

export const getZoneRecordsCount = (zoneId, records) => {
  if (!records) return 0
  return records.filter((record) => record.zone_id === zoneId)?.length || 0
}

const dnsResourceStatusMap = {
  ACTIVE: { variant: 'success', label: 'Active' },
  PENDING: {
    DEFAULT: { variant: 'warning', label: 'Pending' },
    DELETE: { variant: 'error', label: 'Deleting' },
    UPDATE: { variant: 'warning', label: 'Updating' },
  },
}
export const getDnsResourceStatus = (status, action): StatusCellModel => {
  if (status === 'PENDING') {
    return dnsResourceStatusMap.PENDING[action] || dnsResourceStatusMap.PENDING.DEFAULT
  }

  return dnsResourceStatusMap[status] || { variant: 'unknown', label: status }
}
