import ClusterDetails from 'k8s/components/dashboard/ClusterDetails'
import ComingSoonPage from 'k8s/ComingSoonPage'
import ClusterCapacityAndHealth from 'k8s/components/capacity-and-health/ClusterCapacityAndHealth'
import ClustersDashboardPage from 'k8s/components/clusters-dashboard/ClustersDashboardPage'
import ClustersUpgradePage from 'k8s/components/clusters-dashboard/ClustersUpgradePage'
import CustomResourceDefinitionsListPage from 'k8s/components/resources/custom-resource-definitions/CustomResourceDefinitionsListPage'
import CustomResourceDefinitionsDetailsPage from 'k8s/components/resources/custom-resource-definitions/details/CustomResourceDefinitionsDetailsPage'
import CustomResourceDetailsPage from 'k8s/components/resources/custom-resource-definitions/details/custom-resources/CustomResourceDetailsPage'
import NamespacesListPage from 'k8s/components/resources/namespaces/NamespacesListPage'
import ConfigMapsListPage from 'k8s/components/resources/config-maps/ConfigMapsListPage'
import ConfigMapsDetailsPage from 'k8s/components/resources/config-maps/details/ConfigMapsDetailsPage'
import SecretsListPage from 'k8s/components/resources/secrets/SecretsListPage'
import SecretsDetailsPage from 'k8s/components/resources/secrets/details/SecretsDetailsPage'
import RolesListPage from 'k8s/components/access-control/roles/RolesListPage'
import RoleBindingsListPage from 'k8s/components/access-control/role-bindings/RoleBindingsListPage'
import ClusterRolesListPage from 'k8s/components/access-control/cluster-roles/ClusterRolesListPage'
import ClusterRoleBindingsListPage from 'k8s/components/access-control/cluster-role-bindings/ClusterRoleBindingsListPage'
import PersistentVolumeClaimsListPage from 'k8s/components/resources/persistent-volume-claims/PersistentVolumeClaimsListPage'
import PersistentVolumeClaimDetailsPage from 'k8s/components/resources/persistent-volume-claims/details/PersistentVolumeClaimDetailsPage'
import PersistentVolumesListPage from 'k8s/components/resources/persistent-volume/PersistentVolumesListPage'
import PersistentVolumeDetailsPage from 'k8s/components/resources/persistent-volume/details/PersistentVolumeDetailsPage'
import AddBYOHHosts from 'k8s/components/nodes/byoh/AddBYOHHosts'

export const kubernetesRoutesMapping = [
  {
    name: 'Kubernetes',
    link: { path: '/kubernetes/clusters', exact: true, default: true },
    component: ClustersDashboardPage,
  },
  {
    name: 'Overview',
    link: { path: '/clusters/:cluster/manage/overview', exact: true },
    component: ClusterDetails,
  },
  {
    name: 'Upgrade Status Modal',
    link: { path: '/clusters/:cluster/manage/overview/upgrade-status', exact: true },
    component: ClusterDetails,
  },
  {
    name: 'Upgrade',
    link: { path: '/clusters/:cluster/manage/upgrade', exact: true },
    component: ClustersUpgradePage,
  },
  {
    name: 'Capacity & Health',
    link: { path: '/clusters/:cluster/manage/capacity-and-health', exact: true },
    component: ClusterCapacityAndHealth,
  },
  {
    name: 'Alerts',
    link: { path: '/clusters/:cluster/manage/alerts', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'Kubernetes Configuration',
    link: { path: '/clusters/:cluster/manage/kubernetes-configuration', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'Add-ons',
    link: { path: '/clusters/:cluster/manage/addons', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'Roles',
    link: {
      path: '/clusters/:cluster/access-control/roles',
      exact: true,
    },
    component: RolesListPage,
  },
  {
    name: 'Add Roles',
    link: {
      path: '/clusters/:cluster/access-control/roles/add',
      exact: true,
    },
    component: RolesListPage,
  },
  {
    name: 'Edit Roles',
    link: {
      path: '/clusters/:cluster/access-control/roles/:roleId/edit',
      exact: true,
    },
    component: RolesListPage,
  },
  {
    name: 'Role Bindings',
    link: {
      path: '/clusters/:cluster/access-control/role-bindings',
      exact: true,
    },
    component: RoleBindingsListPage,
  },
  {
    name: 'Add Role Bindings',
    link: {
      path: '/clusters/:cluster/access-control/role-bindings/add',
      exact: true,
    },
    component: RoleBindingsListPage,
  },
  {
    name: 'Edit Role Bindings',
    link: {
      path: '/clusters/:cluster/access-control/role-bindings/:id/edit',
      exact: true,
    },
    component: RoleBindingsListPage,
  },
  {
    name: 'Cluster Roles',
    link: {
      path: '/clusters/:cluster/access-control/cluster-roles',
      exact: true,
    },
    component: ClusterRolesListPage,
  },
  {
    name: 'Add Cluster Roles',
    link: {
      path: '/clusters/:cluster/access-control/cluster-roles/add',
      exact: true,
    },
    component: ClusterRolesListPage,
  },
  {
    name: 'Edit Cluster Roles',
    link: {
      path: '/clusters/:cluster/access-control/cluster-roles/:id/edit',
      exact: true,
    },
    component: ClusterRolesListPage,
  },
  {
    name: 'Cluster Role Bindings',
    link: {
      path: '/clusters/:cluster/access-control/cluster-role-bindings',
      exact: true,
    },
    component: ClusterRoleBindingsListPage,
  },
  {
    name: 'Add Cluster Role Bindings',
    link: {
      path: '/clusters/:cluster/access-control/cluster-role-bindings/add',
      exact: true,
    },
    component: ClusterRoleBindingsListPage,
  },
  {
    name: 'Edit Cluster Role Bindings',
    link: {
      path: '/clusters/:cluster/access-control/cluster-role-bindings/:id/edit',
      exact: true,
    },
    component: ClusterRoleBindingsListPage,
  },
  {
    name: 'Namespaces',
    link: {
      path: '/clusters/:cluster/resources/namespaces',
      exact: true,
    },
    component: NamespacesListPage,
  },
  {
    name: 'Add Namespaces',
    link: {
      path: '/clusters/:cluster/resources/namespaces/add',
      exact: true,
    },
    component: NamespacesListPage,
  },
  {
    name: 'Config Maps',
    link: {
      path: '/clusters/:cluster/resources/config-maps',
      exact: true,
    },
    component: ConfigMapsListPage,
  },
  {
    name: 'Add Config Maps',
    link: {
      path: '/clusters/:cluster/resources/config-maps/add',
      exact: true,
    },
    component: ConfigMapsListPage,
  },
  {
    name: 'Config Maps Details',
    link: {
      path: '/clusters/:cluster/resources/config-maps/:id/:tab?',
      exact: true,
    },
    component: ConfigMapsDetailsPage,
  },
  {
    name: 'Secrets',
    link: {
      path: '/clusters/:cluster/resources/secrets',
      exact: true,
    },
    component: SecretsListPage,
  },
  {
    name: 'Add Secrets',
    link: {
      path: '/clusters/:cluster/resources/secrets/add',
      exact: true,
    },
    component: SecretsListPage,
  },
  {
    name: 'Secrets Details',
    link: {
      path: '/clusters/:cluster/resources/secrets/:id/:tab?',
      exact: true,
    },
    component: SecretsDetailsPage,
  },
  {
    name: 'Custom Resource Definitions',
    link: { path: '/clusters/:cluster/resources/custom-resource-definitions/(add)?', exact: true },
    component: CustomResourceDefinitionsListPage,
  },
  {
    name: 'Custom Resource Definitions Details',
    link: {
      path: '/clusters/:cluster/resources/custom-resource-definitions/:id/:tab/(add)?',
      exact: true,
    },
    component: CustomResourceDefinitionsDetailsPage,
  },
  {
    name: 'Custom Resource Details',
    link: {
      path:
        '/clusters/:cluster/resources/custom-resource-definitions/:id/custom-resources/:name/:tab?',
      exact: true,
    },
    component: CustomResourceDetailsPage,
  },
  {
    name: 'Persistant Volume Claims',
    link: {
      path: '/clusters/:cluster/resources/persistent-volume-claims/(add)?',
      exact: true,
    },
    component: PersistentVolumeClaimsListPage,
  },
  {
    name: 'Persistent Volume Claim Details',
    link: { path: '/clusters/:cluster/resources/persistent-volume-claims/:id/:tab?', exact: true },
    component: PersistentVolumeClaimDetailsPage,
  },
  {
    name: 'Persistent Volumes',
    link: {
      path: '/clusters/:cluster/resources/persistent-volumes',
      exact: true,
    },
    component: PersistentVolumesListPage,
  },
  {
    name: 'Add Persistent Volumes',
    link: {
      path: '/clusters/:cluster/resources/persistent-volumes/add',
      exact: true,
    },
    component: PersistentVolumesListPage,
  },
  {
    name: 'Persistent Volumes Details',
    link: {
      path: '/clusters/:cluster/resources/persistent-volumes/:id/:tab?',
      exact: true,
    },
    component: PersistentVolumeDetailsPage,
  },
  {
    name: 'BYOH Hosts',
    link: { path: '/kubernetes/nodes/byoh-hosts', definition: '/kubernetes/nodes/byoh-hosts' },
    component: AddBYOHHosts,
  },
]

export const kubernetesNav = {
  name: 'Kubernetes',
  link: { path: '/kubernetes/clusters' },
  icon: 'kubernetes',
  nestedLinks: [
    { name: 'Dashboard', link: { path: '/kubernetes/clusters' }, isk8sNav: true },
    {
      name: 'Manage',
      isk8sNav: true,
      link: { path: '/clusters/:cluster/manage/overview' },
      // icon: 'cloud-word',
      nestedLinks: [
        { name: 'Overview', isk8sNav: true, link: { path: '/clusters/:cluster/manage/overview' } },
        {
          name: 'Capacity & Health',
          link: { path: '/clusters/:cluster/manage/capacity-and-health' },
        },
      ],
    },
    {
      name: 'Access Control',
      isk8sNav: true,
      link: {
        path: '/clusters/:cluster/access-control/roles',
        definition: '/clusters/:cluster/access-control/roles',
      },
      // icon: 'user-shield',
      nestedLinks: [
        {
          name: 'Roles',
          isk8sNav: true,
          link: {
            path: '/clusters/:cluster/access-control/roles',
            definition: '/clusters/:cluster/access-control/roles',
          },
        },
        {
          name: 'Role Bindings',
          isk8sNav: true,
          link: {
            path: '/clusters/:cluster/access-control/role-bindings',
            definition: '/clusters/:cluster/access-control/role-bindings',
          },
        },
        {
          name: 'Cluster Roles',
          isk8sNav: true,
          link: {
            path: '/clusters/:cluster/access-control/cluster-roles',
            definition: '/clusters/:cluster/access-control/cluster-roles',
          },
        },
        {
          name: 'Cluster Role Bindings',
          isk8sNav: true,
          link: {
            path: '/clusters/:cluster/access-control/cluster-role-bindings',
            definition: '/clusters/:cluster/access-control/cluster-role-bindings',
          },
        },
      ],
    },
    {
      name: 'Resources',
      isk8sNav: true,
      link: {
        path: '/clusters/:cluster/resources/namespaces',
        definition: '/clusters/:cluster/resources/namespaces',
      },
      nestedLinks: [
        {
          name: 'Namespaces',
          isk8sNav: true,
          link: {
            path: '/clusters/:cluster/resources/namespaces',
            definition: '/clusters/:cluster/resources/namespaces',
          },
        },
        {
          name: 'Config Maps',
          isk8sNav: true,
          link: {
            path: '/clusters/:cluster/resources/config-maps',
            definition: '/clusters/:cluster/resources/config-maps',
          },
        },
        {
          name: 'Secrets',
          isk8sNav: true,
          link: {
            path: '/clusters/:cluster/resources/secrets',
            definition: '/clusters/:cluster/resources/secrets',
          },
        },
        {
          name: 'Custom Resource Definitions',
          isk8sNav: true,
          link: {
            path: '/clusters/:cluster/resources/custom-resource-definitions',
            definition: '/clusters/:cluster/resources/custom-resource-definitions',
          },
        },
        {
          name: 'Persistent Volume Claims',
          isk8sNav: true,
          link: {
            path: '/clusters/:cluster/resources/persistent-volume-claims',
            definition: '/clusters/:cluster/resources/persistent-volume-claims',
          },
        },

        {
          name: 'Persistent Volumes',
          isk8sNav: true,
          link: {
            path: '/clusters/:cluster/resources/persistent-volumes',
            definition: '/clusters/:cluster/resources/persistent-volumes',
          },
        },
      ],
    },
    {
      name: 'Nodes',
      link: {
        path: '/kubernetes/nodes/byoh-hosts',
        definition: '/kubernetes/nodes/byoh-hosts',
      },
      nestedLinks: [
        {
          name: 'BYOH Hosts',
          link: {
            path: '/kubernetes/nodes/byoh-hosts',
            definition: '/kubernetes/nodes/byoh-hosts',
          },
        },
      ],
    },
  ],
}
