import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DeleteAction from 'core/actions/DeleteAction'
import CustomAction from 'core/actions/CustomAction'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import store from 'app/store'
import { cacheActions } from 'core/caching/cacheReducers'
import { noop } from 'utils/fp'

const { dispatch } = store
const { cinder, nova } = ApiClient.getInstance()

export const allVolumeActions = ActionsSet.make<DataKeys.AllOpenstackVolumes>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.AllOpenstackVolumes,
  cacheKey: DataKeys.AllOpenstackVolumes,
})

export const listAllVolumes = allVolumeActions.add(
  new ListAction<DataKeys.AllOpenstackVolumes>(async () => {
    return cinder.getAllVolumes()
  })
    .addDependency(DataKeys.OpenstackVirtualMachines)
    .addDependency(DataKeys.AllVolumeTransfers),
)

export const volumeActions = ActionsSet.make<DataKeys.OpenstackVolumes>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.OpenstackVolumes,
  cacheKey: DataKeys.OpenstackVolumes,
  addCacheKey: DataKeys.AllOpenstackVolumes,
})

export const listVolumes = volumeActions.add(
  new ListAction<DataKeys.OpenstackVolumes>(async () => {
    return cinder.getVolumes()
  })
    .addDependency(DataKeys.OpenstackVirtualMachines)
    .addDependency(DataKeys.VolumeTransfers),
)

export const createVolume = volumeActions.add(
  new CreateAction<DataKeys.OpenstackVolumes, { body }>(async ({ body }) => {
    const created = await cinder.createVolume(body)
    if (created['overLimit']) {
      return created
    }
    const volumeId = created.id
    const newVolume = await cinder.getVolume(volumeId)
    return newVolume
  }),
)

export const updateVolume = volumeActions.add(
  new UpdateAction<DataKeys.OpenstackVolumes, { id: string; body: any }>(async ({ id, body }) => {
    const updatedVolume = await cinder.updateVolume(id, body)
    return updatedVolume
  }),
)

export const deleteVolume = volumeActions.add(
  new DeleteAction<DataKeys.OpenstackVolumes, { id: string }>(async ({ id }) => {
    await cinder.deleteVolume(id)
  }),
)

export const attachVolume = volumeActions.add(
  new CustomAction<DataKeys.OpenstackVolumes, { id: string; vmId: string; body: any }>(
    'attachVolume',
    async ({ id, vmId, body }) => {
      await nova.attachVolume({ vmId, body })
      const volume = await cinder.getVolume(id)
      return volume
    },
    async (result, { id, vmId }) => {
      // Update the vm and volume in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVolumes,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVolumes,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      const vm = await nova.getInstance(vmId)
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVirtualMachines,
          params: { id: vmId }, // TODO: Double check this works
          item: vm,
        }),
      )
    },
  ),
)

export const detachVolume = volumeActions.add(
  new CustomAction<DataKeys.OpenstackVolumes, { volumeId: string; vmId: string }>(
    'detachVolume',
    async ({ volumeId, vmId }) => {
      await nova.detachVolume({ volumeId, vmId })
      const volume = await cinder.getVolume(volumeId)
      return volume
    },
    async (result, { volumeId, vmId }) => {
      // Update the vm and volume in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVolumes,
          params: { id: volumeId }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVolumes,
          params: { id: volumeId }, // TODO: Double check this works
          item: result,
        }),
      )
      const vm = await nova.getInstance(vmId)
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVirtualMachines,
          params: { id: vmId }, // TODO: Double check this works
          item: vm,
        }),
      )
    },
  ),
)

export const uploadVolumeAsImage = volumeActions.add(
  new CustomAction<DataKeys.OpenstackVolumes, { id: string; body: any }>(
    'uploadVolumeAsImage',
    async ({ id, body }) => {
      const response = await cinder.uploadVolumeAsImage({ id, body })
      return response
    },
    async (result, { id }) => {
      noop()
    },
  ),
)

export const bootableVolume = volumeActions.add(
  new CustomAction<DataKeys.OpenstackVolumes, { id: string; isBootable: boolean }>(
    'setBootable',
    async ({ id, isBootable }) => {
      await cinder.setBootable(id, isBootable)
      const volume = await cinder.getVolume(id)
      return volume
    },
    async (result, { id }) => {
      // Update the vm and volume in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVolumes,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllOpenstackVolumes,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)

export const extendVolume = volumeActions.add(
  new CustomAction<DataKeys.OpenstackVolumes, { volume: any; size: any }>(
    'extendVolume',
    async ({ volume, size }) => {
      await cinder.extendVolume(volume?.id, size)
      const updatedVolume = await cinder.getVolume(volume?.id)
      return updatedVolume
    },
    async (result, { volume }) => {
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVolumes,
          params: { id: volume?.id },
          item: result,
        }),
      )
    },
  ),
)

export const retypeVolume = volumeActions.add(
  new CustomAction<DataKeys.OpenstackVolumes, { id: string; body: any }>(
    'retypeVolume',
    async ({ id, body }) => {
      await cinder.retypeVolume({ id, body })
      const updatedVolume = await cinder.getVolume(id)
      return updatedVolume
    },
    async (result, { id }) => {
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVolumes,
          params: { id },
          item: result,
        }),
      )
    },
  ),
)

export const allVolumeTransferActions = ActionsSet.make<DataKeys.AllVolumeTransfers>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.AllVolumeTransfers,
  cacheKey: DataKeys.AllVolumeTransfers,
})

export const listAllVolumeTransfers = allVolumeTransferActions.add(
  new ListAction<DataKeys.AllVolumeTransfers>(async () => {
    return cinder.getAllVolumeTransfers()
  }),
)

export const volumeTransferActions = ActionsSet.make<DataKeys.VolumeTransfers>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.VolumeTransfers,
  cacheKey: DataKeys.VolumeTransfers,
  addCacheKey: DataKeys.AllVolumeTransfers,
})

export const listVolumeTransfers = volumeTransferActions.add(
  new ListAction<DataKeys.VolumeTransfers>(async () => {
    return cinder.getVolumeTransfers()
  }),
)

export const createVolumeTransfer = volumeTransferActions.add(
  new CreateAction<DataKeys.VolumeTransfers, { body: any; volumeId: string }>(
    async ({ body, volumeId }) => {
      const created = await cinder.createVolumeTransfer({ body })
      const volume = await cinder.getVolume(volumeId)
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVolumes,
          params: { id: volumeId },
          item: volume,
        }),
      )
      return created
    },
  ),
)

export const deleteVolumeTransfer = volumeTransferActions.add(
  new DeleteAction<DataKeys.VolumeTransfers, { id: string; volumeId: string }>(
    async ({ id, volumeId }) => {
      await cinder.deleteVolumeTransfer(id)
      const volume = await cinder.getVolume(volumeId)
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.OpenstackVolumes,
          params: { id: volumeId },
          item: volume,
        }),
      )
    },
  ),
)

export const acceptVolumeTransfer = volumeTransferActions.add(
  new CustomAction<DataKeys.VolumeTransfers, { id: string; body: any }>(
    'attachVolume',
    async ({ id, body }) => {
      const response = await cinder.acceptVolumeTransfer({ id, body })
      return response
    },
    async (result, { id }) => {
      // Update the vm and volume in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.VolumeTransfers,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'id',
          cacheKey: DataKeys.AllVolumeTransfers,
          params: { id }, // TODO: Double check this works
          item: result,
        }),
      )
    },
  ),
)
