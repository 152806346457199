import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { useAppSelector } from 'app/store'
import { ArrayElement } from 'core/actions/Action'
import InferActionParams from 'core/actions/InferActionParams'
import ListContainer from 'core/containers/ListContainer'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { routes } from 'core/utils/routes'
import DataKeys from 'k8s/DataKeys'
import { pick } from 'ramda'
import React, { useCallback } from 'react'
import CreateListenersModal from '../CreateListenersModal'
import DeleteListenersDialog from '../DeleteListenersDialog'
import { deleteListener, listLoadBalancers, listOctaviaListener, listSubnets } from '../actions'
import { ILoadBalancerDetailsPageTabs } from '../model'
import { octaviaListenersSelector } from '../selectors'
import EmptyContent from 'core/components/statusCard/EmptyContent'

type ModelDataKey = DataKeys.OctaviaListeners
type SelectorModel = ArrayElement<ReturnType<typeof octaviaListenersSelector>>
type ActionParams = InferActionParams<typeof listOctaviaListener>
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'OpenstackNetworks',
  listTablePrefs,
)

const searchTargets = ['name', 'loadBalancer', 'protocol', 'port']

const searchFunctions = [
  {
    property: 'subnetDetails',
    searchFn: (value, searchString) => {
      return value.some(
        (subnet) =>
          subnet.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
          subnet.cidr.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()),
      )
    },
  },
]

const loadBalancerColumns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'loadBalancer',
    label: 'Load Balancer',
  },

  {
    key: 'protocol',
    label: 'Protocol',
  },
  {
    key: 'port',
    label: 'Port',
  },
]

export default function ListenersListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)

  const { message, loading, reload } = useListAction(listOctaviaListener)
  const { reload: reloadLoadBalancers } = useListAction(listLoadBalancers)

  const { reload: reloadSubnets } = useListAction(listSubnets)
  const data = useAppSelector(octaviaListenersSelector)

  const handleRefresh = useCallback(() => {
    reload(true)
    reloadSubnets(true)
    reloadLoadBalancers(true)
  }, [reload, reloadSubnets, reloadLoadBalancers])
  return (
    <>
      <CreateListenersModal addRoute={routes.openstack.createLoadBalancers} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.OctaviaListeners}
        label="Listeners"
        searchTargets={searchTargets}
        searchFunctions={searchFunctions}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={'Loading...'}
        onRefresh={handleRefresh}
        data={data}
        columns={loadBalancerColumns}
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteListener}
        DeleteDialogComponent={DeleteListenersDialog}
        multiSelection
        addUrl={routes.openstack.createLoadBalancers.path({
          tab: ILoadBalancerDetailsPageTabs.Listeners,
        })}
        addText="Create Listener"
        emptyContent={
          <EmptyContent title={'No listeners'} subTitle={'Create one in order to see details'} />
        }
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
