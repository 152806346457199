import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import Modal from 'core/elements/modal'
import useUpdateAction from 'core/hooks/useUpdateAction'
import React, { useCallback, useState } from 'react'
import { deleteHealthMonitor } from './actions'

export default function DeleteHealthMonitorDialog({ rows, onClose }) {
  const [error, setError] = useState(null)
  const { update, updating } = useUpdateAction(deleteHealthMonitor)

  const healthMonitorNames = rows?.map((healthMonitor) => healthMonitor?.type).join(', ')
  const handleDelete = useCallback(async () => {
    const promises = []
    for (const healthMonitor of rows) {
      promises.push(update(healthMonitor))
    }
    await Promise.all(promises)
      .then(() => {
        onClose(true)
      })
      .catch((err) => {
        console.error(err)
        setError({ title: 'Error Deleting Health Monitor', message: err?.message })
      })
  }, [rows, update, onClose, setError])

  return (
    <Modal
      panel="dialog"
      title="Delete Health Monitor"
      onClose={onClose}
      open
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={updating}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={updating}>
            Confirm
          </Button>
        </>
      }
    >
      <>
        <Text variant="body2">This will permanently delete {healthMonitorNames}.</Text>
        {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
      </>
    </Modal>
  )
}
