import store from 'app/store'

export const humanReadableSize = (bytes, addUnit = true) => {
  if (bytes === 0) {
    return '0.00 B'
  }
  if (!bytes) {
    return 'N/A'
  }

  const e = Math.floor(Math.log(bytes) / Math.log(1024))
  const size = (bytes / Math.pow(1024, e)).toFixed(2)
  if (!addUnit) {
    return size
  }
  return size + ' ' + ' KMGTP'.charAt(e) + 'iB'
}

export const getCurrentUser = () => {
  const session = store.getState().session
  return session.userDetails
}
