import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { useAppSelector } from 'app/store'
import { ArrayElement } from 'core/actions/Action'
import InferActionParams from 'core/actions/InferActionParams'
import ListContainer from 'core/containers/ListContainer'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { createGridStatusCell, StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { routes } from 'core/utils/routes'
import DataKeys from 'k8s/DataKeys'
import { pick } from 'ramda'
import React, { useCallback, useEffect } from 'react'
import CreateMemberModal from '../CreateMemberModal'
import DeleteMemberDialog from '../DeleteMemberDialog'
import { deleteMember, listOctaviaPoolMembers, listOctaviaPools } from '../actions'
import { ILoadBalancerDetailsPageTabs } from '../model'
import { octaviaPoolMembersSelector, octaviaPoolsSelector } from '../selectors'
import EmptyContent from 'core/components/statusCard/EmptyContent'

type ModelDataKey = DataKeys.OctaviaPoolMembers
type SelectorModel = ArrayElement<ReturnType<typeof octaviaPoolMembersSelector>>
type ActionParams = InferActionParams<typeof listOctaviaPoolMembers>
type Params = ActionParams & {}

const defaultParams: Params = {
  poolIds: [],
}

const Phase = {
  Pending: 'Pending',
  Active: 'Active',
  Error: 'Error',
  Deleting: 'Deleting',
  Disabled: 'Disabled',
  Unknown: 'Unknown',
}

const getPhaseStatus = (phase): StatusCellModel => {
  const normalizedPhase = phase.trim().toLowerCase()
  switch (normalizedPhase) {
    case Phase.Active.toLowerCase():
      return { variant: 'success', label: 'Active' }
    case Phase.Pending.toLowerCase():
      return { variant: 'warning', label: 'Pending' }
    case Phase.Error.toLowerCase():
      return { variant: 'error', label: 'Error' }
    case Phase.Deleting.toLowerCase():
      return { variant: 'error', label: 'Deleting' }
    case Phase.Disabled.toLowerCase():
      return { variant: 'warning', label: 'Disabled' }
    default:
      return { variant: 'unknown', label: Phase.Unknown }
  }
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'OpenstackNetworks',
  listTablePrefs,
)

const searchTargets = ['name', 'ipAddress', 'operatingStatus', 'provisioningStatus', 'adminStateUp']

const searchFunctions = [
  {
    property: 'subnetDetails',
    searchFn: (value, searchString) => {
      return value.some(
        (subnet) =>
          subnet.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
          subnet.cidr.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()),
      )
    },
  },
]

const loadBalancerColumns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    // width: 'medium',
  },
  {
    key: 'status',
    label: 'Status',
    CellComponent: createGridStatusCell({
      dataFn: (phase): StatusCellModel => {
        return getPhaseStatus(phase)
      },
    }),
  },
  {
    key: 'pool',
    label: 'Pool',
  },
  {
    key: 'ipAddress',
    label: 'IP Address',
  },

  {
    key: 'port',
    label: 'Port',
  },
  {
    key: 'weight',
    label: 'Weight',
  },
]

export default function MembersListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { prefs } = useScopedPreferences()
  const { reload: reloadOctaviaPools } = useListAction(listOctaviaPools)
  const octaviaPools = useAppSelector(octaviaPoolsSelector)
  const poolIds = octaviaPools.map((pool) => pool.id)
  const { message, loading, reload } = useListAction(listOctaviaPoolMembers, {
    params: { poolIds: poolIds },
    requiredParams: ['poolIds'],
  })
  const data = useAppSelector(octaviaPoolMembersSelector)

  const handleRefresh = useCallback(() => {
    reloadOctaviaPools(true)
    reload(true)
  }, [poolIds])

  useEffect(() => {
    reload(true)
  }, [octaviaPools])
  return (
    <>
      <CreateMemberModal addRoute={routes.openstack.createLoadBalancers} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.OctaviaPoolMembers}
        label="Members"
        searchTargets={searchTargets}
        searchFunctions={searchFunctions}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={'Loading...'}
        onRefresh={handleRefresh}
        data={data}
        columns={loadBalancerColumns}
        addUrl={routes.openstack.createLoadBalancers.path({
          tab: ILoadBalancerDetailsPageTabs.Members,
        })}
        addText="Add Member"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteMember}
        DeleteDialogComponent={DeleteMemberDialog}
        multiSelection
        emptyContent={
          <EmptyContent title={'No members'} subTitle={'Create one in order to see details'} />
        }
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
