import React, { useCallback } from 'react'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import { powerVirtualMachine } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Modal from 'core/elements/modal'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import Info from 'core/components/validatedForm/Info'
import { getCurrentUser } from 'openstack/helpers'
import { hasOtherUserVm } from './helpers'

const getActionBody = (action, type = 'SOFT') => {
  return {
    suspend: { suspend: null },
    start: { 'os-start': null },
    stop: { 'os-stop': null },
    resume: { resume: null },
    reboot: { reboot: { type } },
  }[action]
}

export default function PowerVirtualMachineDialog({ rows: vms, action, type = 'SOFT', onClose }) {
  const classes = useStyles()
  const { update: powerFn, updating: poweringVm, error: powerError } = useUpdateAction(
    powerVirtualMachine,
  )
  const title = `${action[0].toUpperCase() + action.slice(1)} VMs`
  const handleAction = useCallback(() => {
    const asyncActions = async () => {
      const body = getActionBody(action, type)
      let success = true
      for (const vm of vms) {
        try {
          await powerFn({ id: vm.id, body })
        } catch (e) {
          success = false
          console.log(e)
        }
      }

      if (success) onClose(true)
    }
    asyncActions()
  }, [vms])

  const { role: userRole, userId } = getCurrentUser()

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={title}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={poweringVm}>
            Cancel
          </Button>
          <Button onClick={handleAction} loading={poweringVm}>
            Confirm
          </Button>
        </>
      }
    >
      {userRole === 'member' && hasOtherUserVm({ vms, userId }) && (
        <Info error>NOTE: You are performing an operation on another user's VM.</Info>
      )}
      <Text variant="body2">You are about to {action} the following VM instances:</Text>
      <div className={classes.vmsToPower}>
        {vms?.map((vm) => (
          <Text key={vm.id} variant="body2">
            <strong>{vm.name || vm.id}</strong>
          </Text>
        ))}
      </div>
      {!!powerError && <ErrorMessage>{powerError}</ErrorMessage>}
    </Modal>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  vmsToPower: {
    display: 'grid',
    gap: 4,
  },
}))
