import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { virtualMachinesByIdSelector } from 'openstack/components/vms/selectors'
import {
  allTenantsSelector,
  userTenantsSelector,
} from 'app/plugins/account/components/userManagement/tenants/selectors'

export const volumeTransfersSelector = createSharedSelector(
  getDataSelector<DataKeys.VolumeTransfers>(DataKeys.VolumeTransfers),
  (volumeTransfers) => {
    return volumeTransfers
  },
)

export const allVolumeTransfersSelector = createSharedSelector(
  getDataSelector<DataKeys.AllVolumeTransfers>(DataKeys.AllVolumeTransfers),
  (volumeTransfers) => {
    return volumeTransfers
  },
)

export const volumesSelector = createSharedSelector(
  getDataSelector<DataKeys.OpenstackVolumes>(DataKeys.OpenstackVolumes),
  virtualMachinesByIdSelector,
  allTenantsSelector,
  userTenantsSelector,
  volumeTransfersSelector,
  (volumes, vmsById, allTenants, userTenants, volumeTransfers) => {
    const volumeTransfersByVolumeId = volumeTransfers.reduce((accum, transfer) => {
      return {
        ...accum,
        [transfer?.volume_id]: transfer,
      }
    }, {})
    return volumes.map((volume) => {
      // any model changes go here
      const vmAttachment = volume?.attachments?.length
        ? volume.attachments.find((attachment) => !!attachment.server_id)
        : null
      const vm = vmsById[vmAttachment?.server_id]
      return {
        ...volume,
        created_at: `${volume.created_at}Z`,
        attachedVm: vm,
        device: vmAttachment?.device,
        tenantName: [...userTenants, ...allTenants]?.find(
          (tenant) => tenant.id === volume?.['os-vol-tenant-attr:tenant_id'],
        )?.name,
        transfer: volumeTransfersByVolumeId[volume?.id],
      }
    })
  },
)

export const allVolumesSelector = createSharedSelector(
  getDataSelector<DataKeys.AllOpenstackVolumes>(DataKeys.AllOpenstackVolumes),
  virtualMachinesByIdSelector,
  allTenantsSelector,
  userTenantsSelector,
  allVolumeTransfersSelector,
  (volumes, vmsById, allTenants, userTenants, volumeTransfers) => {
    const volumeTransfersByVolumeId = volumeTransfers.reduce((accum, transfer) => {
      return {
        ...accum,
        [transfer?.volume_id]: transfer,
      }
    }, {})
    return volumes.map((volume) => {
      // any model changes go here
      const vmAttachment = volume?.attachments?.length
        ? volume.attachments.find((attachment) => !!attachment.server_id)
        : null
      const vm = vmsById[vmAttachment?.server_id]
      return {
        ...volume,
        created_at: `${volume.created_at}Z`,
        attachedVm: vm,
        device: vmAttachment?.device,
        tenantName: [...userTenants, ...allTenants]?.find(
          (tenant) => tenant.id === volume?.['os-vol-tenant-attr:tenant_id'],
        )?.name,
        transfer: volumeTransfersByVolumeId[volume?.id],
      }
    })
  },
)
