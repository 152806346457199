import React, { useEffect, useMemo, useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick, prop } from 'ramda'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import {
  listResmgrHosts,
  listHypervisors,
  getHostNetworkConfigs,
  listHostNetworkConfigs,
  getBlueprints,
} from '../actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { resmgrHostsSelector, hypervisorsSelector } from '../selectors'
import InferActionParams from 'core/actions/InferActionParams'
import Text from 'core/elements/Text'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { routes } from 'core/utils/routes'
import { IHostDetailsPageTabs } from './host-details/model'
import { useSelector } from 'react-redux'
import StatsCell from 'app/plugins/infrastructure/components/common/cells/StatsCell'
import HostsListPageHeader from './HostsListPageHeader'
import ApiClient from 'api-client/ApiClient'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import {
  GridBatchActionSpec,
  GridDropdownBatchActionGroup,
} from 'core/elements/grid/hooks/useGridSelectableRows'
import EditServerRolesModal from './EditServerRolesModal'
import ToggleMaintenanceModeDialog from './ToggleMaintenanceModeDialog'
import EnableMaintenanceModeDialog from './EnableMaintenanceModeDialog'
import AuthorizeVmwareGatewayModal from './AuthorizeVmwareGatewayModal'
import DeauthorizeRolesDialog from './DeauthorizeRolesDialog'
import DeauthorizeHostDialog from './DeauthorizeHostDialog'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import FixMissingRolesDialog from './FixMissingRolesDialog'
import SimpleLink from 'core/components/SimpleLink'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import PollingData from 'core/components/PollingData'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import AllocationRatioModal from './AllocationRatioModal'
import ExternalLink from 'core/components/ExternalLink'
import { TopHeaderActionPortal } from 'core/elements/header/portals'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import store from 'app/store'

type ModelDataKey = DataKeys.ResmgrHosts
type SelectorModel = ArrayElement<ReturnType<typeof resmgrHostsSelector>>
type ActionParams = InferActionParams<typeof listResmgrHosts>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'ResmgrHosts',
  listTablePrefs,
)

const searchTargets = ['name', 'id', 'displayRoles', 'role_status', 'interfaces']
const statusOrder = ['failed', 'offline', 'converging', 'ok']

const getSearchFunctions = (isVmware) => [
  {
    property: 'role_status',
    searchFn: (value, searchString, item) => {
      // Get the responding state from the item
      const responding = item?.info?.responding
      const roles = item?.roles || []

      let statusText = getStatusText(value, roles)

      // Logic matching the HostStatusCell component
      if (!responding && roles?.length) {
        // First condition - not responding with roles
        statusText = 'offline'
      } else if (['offline', 'failed', 'error'].includes(value)) {
        // Override for specific error states
        statusText = value || 'unknown'
      } else if (responding) {
        // Responding case
        statusText = getStatusText(value, roles)
      } else if (roles?.length) {
        // Has roles but not in the previous conditions
        statusText = 'offline'
      }

      if (!responding && ['retrying'].includes(value)) {
        // Special case for 'retrying'
        statusText = value
      }

      // For VMware roles incomplete warning
      if (isVmware && item?.vmwareRolesIncomplete) {
        // Include "vSphere Gateway Authorization Incomplete" in searchable text
        return 'vsphere gateway authorization incomplete'.includes(searchString?.toLowerCase())
      }

      return statusText.toLowerCase().includes(searchString?.toLowerCase())
    },
  },
  {
    property: 'interfaces',
    searchFn: (value, searchString) => {
      return value.some((iface) => {
        return (
          iface?.name?.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
          iface?.ip?.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
        )
      })
    },
  },
]

export const IpAddressCellComponent = ({ value }) => {
  return (
    <div>
      {value.map((iface) => (
        <Text key={iface.ip} variant="body2">
          {iface.ip} ({iface.name})
        </Text>
      ))}
    </div>
  )
}

const HypervisorIncomplete = ({ host }) => {
  const classes = useStyles({})
  const [open, setOpen] = useState(false)
  return (
    <div onClick={(e) => e.stopPropagation()}>
      {open && <FixMissingRolesDialog host={host} handleClose={() => setOpen(false)} />}
      <SimpleLink onClick={(e) => setOpen(true)}>
        <FontAwesomeIcon className={classes.missingPackages}>triangle-exclamation</FontAwesomeIcon>
        Missing Packages
      </SimpleLink>
    </div>
  )
}

const getStatusText = (status, roles) => {
  return status ? status : roles.length ? 'converging' : 'unauthorized'
}

const HostStatusIndicator = ({ status, responding }) => {
  const classes = useStyles({ state: status, responding })
  return (
    <FontAwesomeIcon className={classes.icon} solid>
      circle
    </FontAwesomeIcon>
  )
}

export const HostStatusCell = ({ value, item }) => {
  const responding = item.info?.responding
  const roles = item.roles
  const session = useSelector(prop<string, SessionState>(sessionStoreKey))
  const { features } = session
  const isVmware = features?.experimental?.pmov2_du_type === 'vmware'
  const classes = useStyles({ state: value, responding })

  const renderStatusContent = () => {
    if ((!responding && roles.length) || ['offline', 'failed', 'error'].includes(value)) {
      return (
        <>
          <HostStatusIndicator status={value} responding={responding} />
          <ExternalLink url="https://platform9.com/docs/private-cloud-director/troubleshooting/hypervisor-or-host-issues">
            {value === 'ok' ? 'offline' : value || 'unknown'}
          </ExternalLink>
        </>
      )
    }

    if (responding) {
      return (
        <>
          {['converging', 'retrying'].includes(value) ? (
            <FontAwesomeIcon className={classes.marginRight} spin>
              sync
            </FontAwesomeIcon>
          ) : (
            <HostStatusIndicator status={value} responding={responding} />
          )}
          <Text variant="body2" component="span">
            {getStatusText(value, roles)}
          </Text>
        </>
      )
    }

    if (roles.length) {
      return (
        <>
          <HostStatusIndicator status={value} responding={responding} />
          <Text variant="body2" component="span">
            offline
          </Text>
        </>
      )
    }

    return (
      <Text variant="body2" component="span">
        {getStatusText(value, roles)}
      </Text>
    )
  }

  return (
    <div className={classes.hostStatusCell}>
      <div>{renderStatusContent()}</div>
      {/* This part not needed I don't think with new uber roles */}
      {/* {!isVmware && item?.hypervisorRolesIncomplete && !!item.hypervisor && (
        <HypervisorIncomplete host={item} />
      )} */}
      {isVmware && item?.vmwareRolesIncomplete && (
        <div className={classes.warning}>
          <FontAwesomeIcon className={classes.missingPackages}>
            triangle-exclamation
          </FontAwesomeIcon>
          <Text variant="body2">vSphere Gateway Authorization Incomplete</Text>
        </div>
      )}
    </div>
  )
}

const handleSortByStatus = (statusA, statusB) => {
  const indexA = statusOrder.indexOf(statusA)
  const indexB = statusOrder.indexOf(statusB)

  if (indexA === indexB) {
    return 0
  }

  return indexA > indexB ? 1 : -1
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ id }) =>
        routes.openstack.hostDetails.path({ id, tab: IHostDetailsPageTabs.Overview }),
    }),
  },
  {
    key: 'id',
    label: 'ID',
    display: false,
  },
  {
    key: 'role_status',
    label: 'Status',
    CellComponent: HostStatusCell,
    sortFn: handleSortByStatus,
  },
  {
    key: 'schedulingStatus',
    label: 'Scheduling',
    render: (value, item) => {
      if (!item.hypervisor) {
        return 'N/A'
      }
      return value
    },
  },
  {
    key: 'interfaces',
    label: 'IP Address',
    CellComponent: IpAddressCellComponent,
  },
  {
    key: 'usage',
    label: 'Resource Utilization',
    CellComponent: StatsCell,
  },
  {
    key: 'displayRoles',
    label: 'Assigned Roles',
    render: (values) => values.join(', '),
  },
]

export default function HostsListPage() {
  const { resMgr } = ApiClient.getInstance()
  const hasResmgr = resMgr.endpointPresent()
  const classes = useStyles({})

  const session = useSelector(prop<string, SessionState>(sessionStoreKey))
  const { features } = session
  const isVmware = features?.experimental?.pmov2_du_type === 'vmware'

  const [selectedHosts, setSelectedHosts] = useState([])
  const [showEditServerRolesModal, setShowEditServerRolesModal] = useState(false)
  const [showToggleMaintenanceModal, setShowToggleMaintenanceModal] = useState(false)
  const [showEnableMaintenanceModal, setShowEnableMaintenanceModal] = useState(false)
  const [showAuthorizeVmwareGatewayModal, setShowAuthorizeVmwareGatewayModal] = useState(false)
  const [showDeauthorizeHostDialog, setShowDeauthorizeHostDialog] = useState(false)

  const [hostConfigs, setHostConfigs] = useState([])
  const [showDials, setShowDials] = useState(true)
  const [blueprintAvailable, setBlueprintAvailable] = useState(false)

  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)

  const { loading: loadingHypervisors, reload: reloadHypervisors } = useListAction(
    listHypervisors,
    { params },
  )
  const { reload: reloadHostNetworkConfigs } = useListAction(listHostNetworkConfigs)
  const { message, loading, reload } = useListAction(listResmgrHosts, {
    params,
  })
  const hosts = useSelector(resmgrHostsSelector)
  const hypervisors = useSelector(hypervisorsSelector)
  const processedHosts = useMemo(() => {
    const hypervisorsByResmgrId = hypervisors.reduce((accum, hypervisor) => {
      return {
        ...accum,
        [hypervisor?.service?.host]: hypervisor,
      }
    }, {})
    return hosts.map((host) => {
      return {
        ...host,
        hypervisor: hypervisorsByResmgrId[host.id],
        name: features?.experimental?.pmov2_du_type === 'vmware' ? 'VMware Gateway' : host?.name,
      }
    })
  }, [hosts, hypervisors, features])

  // Get Cluster Blueprints
  const getClusterBlueprints = async () => {
    const blueprints = (await getBlueprints()) as Array<any>
    if (blueprints?.length) {
      setBlueprintAvailable(true)
      return
    }

    setBlueprintAvailable(false)
  }

  const searchFunctions = useMemo(() => getSearchFunctions(isVmware), [isVmware])

  useEffect(() => {
    getClusterBlueprints()
  }, [])

  useEffect(() => {
    const getHostConfigs = async () => {
      const configs: any = await getHostNetworkConfigs()
      setHostConfigs(configs)
    }
    getHostConfigs()
  }, [])

  // Todo: Need to query host roles and see if they are equal to the blueprint values, check with backend on plausibility of this

  const batchActions: GridBatchActionSpec<SelectorModel>[] = isVmware
    ? [
        {
          cond: (hosts) => {
            return (
              hosts.length === 1 &&
              hosts[0]?.extensions?.hypervisor_details?.data?.hypervisor_type === 'VMWareCluster'
            )
          },
          label: 'Manage VMware Gateway Configuration',
          icon: 'person-to-portal',
          handleAction: (selected) => {
            setSelectedHosts(selected)
            setShowAuthorizeVmwareGatewayModal(true)
          },
          refreshAfterSuccess: false,
        },
      ]
    : [
        {
          cond: (hosts) => {
            return hosts.length > 0 && hostConfigs.length > 0
          },
          disabledTooltip:
            hostConfigs.length === 0
              ? 'You must create host network configurations in the cluster blueprint before you can assign any cluster roles'
              : '',
          label: 'Edit Roles',
          icon: 'pen-to-square',
          handleAction: (selected) => {
            setSelectedHosts(selected)
            setShowEditServerRolesModal(true)
          },
          refreshAfterSuccess: false,
        },
      ]

  const dropdownBatchActions: GridDropdownBatchActionGroup<SelectorModel>[] = useMemo(
    () => [
      {
        label: 'Other',
        actions: [
          {
            cond: (hosts) => {
              return hosts.length > 0 && hosts.every((host) => host?.hypervisor)
            },
            label: 'Enable/Disable Scheduling',
            icon: 'calendar-xmark',
            tooltip: 'Scheduling is only applicable to hypervisors',
            BatchActionButton: getGridDialogButton(ToggleMaintenanceModeDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            cond: (hosts) => {
              return (
                hosts.length === 1 &&
                !!hosts?.[0]?.hypervisor &&
                hosts?.[0]?.schedulingStatus === 'Enabled'
              )
            },
            label: 'Enable Maintenance Mode',
            icon: 'screwdriver-wrench',
            tooltip: 'Maintenance Mode is only applicable to active hypervisors',
            BatchActionButton: getGridDialogButton(EnableMaintenanceModeDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            label: 'Update Allocation Ratios',
            icon: 'chart-pie',
            BatchActionButton: getGridDialogButton(AllocationRatioModal, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            cond: (hosts) => {
              return hosts.length === 1
            },
            label: 'Remove All Roles',
            icon: 'square-minus',
            BatchActionButton: getGridDialogButton(DeauthorizeRolesDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            cond: (hosts) => {
              return hosts.every((host) => host.roles?.length === 0)
            },
            label: 'Deauthorize',
            icon: 'trash-alt',
            tooltip:
              'You may only deauthorize hosts that have no roles attached. To prepare a host for deauthorization, you may run the "Remove All Roles" command on it',
            BatchActionButton: getGridDialogButton(DeauthorizeHostDialog, null, {
              className: classes.dropdownAction,
            }),
          },
        ],
      },
    ],
    [classes],
  )

  return (
    <>
      <DocumentMeta title="Cluster Hosts" />
      <TopHeaderActionPortal>
        <ToggleSwitch active={showDials} onClick={() => setShowDials(!showDials)} label="Stats" />
      </TopHeaderActionPortal>
      <PollingData
        hidden
        loading={loading}
        onReload={() => {
          reload(true)
          return reloadHypervisors(true)
        }}
        refreshDuration={1000 * 30}
      />
      {hasResmgr ? (
        <>
          {showEditServerRolesModal && (
            <EditServerRolesModal
              onClose={() => setShowEditServerRolesModal(false)}
              rows={selectedHosts}
            />
          )}
          {showToggleMaintenanceModal && (
            <ToggleMaintenanceModeDialog
              onClose={() => setShowToggleMaintenanceModal(false)}
              rows={selectedHosts}
            />
          )}
          {showEnableMaintenanceModal && (
            <EnableMaintenanceModeDialog
              onClose={() => setShowEnableMaintenanceModal(false)}
              rows={selectedHosts}
            />
          )}
          {showAuthorizeVmwareGatewayModal && (
            <AuthorizeVmwareGatewayModal
              onClose={() => setShowAuthorizeVmwareGatewayModal(false)}
              rows={selectedHosts}
            />
          )}
          {showDeauthorizeHostDialog && (
            <DeauthorizeHostDialog
              onClose={() => setShowDeauthorizeHostDialog(false)}
              rows={selectedHosts}
            />
          )}
          {showDials && !!hosts?.length && <HostsListPageHeader hosts={hosts} />}
          <ListContainer<ModelDataKey, SelectorModel>
            dataKey={DataKeys.ResmgrHosts}
            searchTargets={searchTargets}
            searchFunctions={searchFunctions}
            uniqueIdentifier="id"
            addUrl={
              isVmware ? routes.openstack.addVmwareGateway.path() : routes.openstack.addHosts.path()
            }
            addText={isVmware ? 'Add VMware Gateway' : 'Add New Hosts'}
            loading={loading}
            loadingMessage={message}
            onRefresh={() => {
              reload(true)
              reloadHypervisors(true)
              reloadHostNetworkConfigs(true)
            }}
            data={processedHosts}
            dropdownBatchActions={dropdownBatchActions}
            columns={columns}
            getParamsUpdater={getParamsUpdater}
            batchActions={batchActions}
            multiSelection
            disableAddButton={!blueprintAvailable}
            disabledAddButtonInfo="Cluster blueprint not available"
            {...pick(listTablePrefs, params)}
          />
        </>
      ) : (
        <div className={classes.noResmgr}>
          <Text variant="subtitle1">
            Host management is only available with Platform9 Managed OpenStack
          </Text>
        </div>
      )}
    </>
  )
}

const getIconOrBubbleColor = (status, theme: Theme) =>
  ({
    ok: theme.palette.green.main,
    failed: theme.palette.red.main,
  }[status] || theme.palette.grey[500])

const useStyles = makeStyles<Theme, { state?: string; responding?: boolean }>((theme) => ({
  noResmgr: {
    padding: '40px',
    textAlign: 'center',
    background: 'white',
  },
  dropdownAction: {
    background: theme.components.dropdown.background,
    border: 'none',
    '& .button-text': {
      color: theme.components.dropdown.color,
      justifyContent: 'start',
    },
  },
  marginRight: {
    marginRight: 8,
  },
  circle: {
    display: 'inline-grid',
    alignItems: 'center',
    gridTemplateColumns: 'minmax(22px, max-content) 1fr',
    whiteSpace: 'nowrap',
    '&:before': {
      content: '""',
      display: 'inherit',
      height: 12,
      width: 12,
      borderRadius: '50%',
      backgroundColor: ({ state, responding }) =>
        responding ? getIconOrBubbleColor(state, theme) : theme.palette.red.main,
    },
  },
  hostStatusCell: {
    display: 'grid',
    gap: 8,
  },
  missingPackages: {
    color: theme.palette.yellow.main,
    marginRight: 6,
  },
  warning: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 12,
    marginRight: 4,
    color: ({ state, responding }) =>
      responding ? getIconOrBubbleColor(state, theme) : theme.palette.red.main,
  },
}))
