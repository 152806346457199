import { customValidator } from 'core/utils/fieldValidators'
import { isValidIpv4Address, isValidIpv4Cidr } from 'core/utils/validators'
import * as IpAddress from 'ip-address'

export const getPhysicalNetworkInterfaceLabel = (
  physicalNetworksInterfaceMap,
  vmPhysicalNetwork,
) => {
  return physicalNetworksInterfaceMap?.find((network) => network.interface === vmPhysicalNetwork)
    ?.label
}

export const getPhysicalNetworkInterface = (physicalNetworksInterfaceMap, label) => {
  return physicalNetworksInterfaceMap?.find((network) => network.label === label)?.interface
}

export const getPhysicalNetworkInterfaceIndex = (physicalNetworksInterfaceMap, interfaceName) => {
  return physicalNetworksInterfaceMap?.findIndex((network) => network.interface === interfaceName)
}

export const getNetworkBody = ({ networkType, params, physicalNetworksInterfaceMap }) => {
  if (networkType === 'flat') {
    return {
      network: {
        name: params.vmNetworkName,
        description: 'The default VM network',
        admin_state_up: true,
        'provider:network_type': params.networkType,
        'provider:physical_network': getPhysicalNetworkInterfaceLabel(
          physicalNetworksInterfaceMap,
          params.vmNetworkPhysNet,
        ),
        'router:external': !!params.external,
        shared: !!params.shared,
      },
    }
  } else if (networkType === 'vlan') {
    return {
      network: {
        name: params.vmNetworkName,
        description: 'The default VM network',
        admin_state_up: true,
        'provider:network_type': params.networkType,
        'provider:physical_network': getPhysicalNetworkInterfaceLabel(
          physicalNetworksInterfaceMap,
          params.vmNetworkPhysNet,
        ),
        'provider:segmentation_id': params.segmentationId,
        'router:external': !!params.external,
        shared: !!params.shared,
      },
    }
  } else if (networkType === 'vxlan') {
    return {
      network: {
        name: params.vmNetworkName,
        description: 'The default VM network',
        admin_state_up: true,
        'provider:network_type': params.networkType,
        'provider:segmentation_id': params.segmentationId,
        'router:external': !!params.external,
        shared: !!params.shared,
      },
    }
  }
  return {}
}

export const cidrValidator = customValidator((value) => {
  if (!value) {
    return false
  }
  return isValidIpv4Cidr(value)
}, 'Invalid IPv4 CIDR provided')

export const gatewayInCidrValidator = customValidator((value, formValues) => {
  if (!value) {
    return true
  }
  try {
    const testCidr = new IpAddress.Address4(formValues.cidr)
    return new IpAddress.Address4(value).isInSubnet(testCidr)
  } catch (err) {
    return false
  }
}, 'Gateway IP address is not inside of designated CIDR')

export const gatewayIpValid = customValidator((value, formValues) => {
  if (!value) {
    return true
  }
  return isValidIpv4Address(value)
}, 'Gateway IP address is an invalid IP address')

export const waitSeconds = async (seconds) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('')
    }, seconds * 1000)
  })
}

export const rangeLabelMap = {
  vlan: 'VLAN Range',
  vxlan: 'VXLAN VNID Range',
  geneve: 'Geneve Tunnel ID Range',
}

export const rangeTooltipMap = {
  vlan:
    'You can specify the VLAN range. Each range must follow the min-max format (eg. 50-60). The valid VLAN ID range is 2 to 4094. Individual VLANs are not supported.',
  vxlan:
    'You can specify the VXLAN VNIDs or VNID range. Each range must follow the min-max format (eg. 3050-3060). The valid VNID range is 1 to 4095. Individual VNIDs are not supported.',
  geneve:
    'You can specify the ID range. Each range must follow the min-max format (eg. 6050-6060). The valid ID range is 1 to 65535. Individual IDs are not supported.',
}

export const rangePlaceholderMap = {
  vlan: 'eg. 50-60',
  vxlan: 'eg. 3050-3060',
  geneve: 'eg. 50-60',
}

export const allSystemTrafficSelected = (hostConfigs) => {
  for (const config of hostConfigs) {
    const condsFulfilled = config?.networkInterfaces?.reduce(
      (accum, iface) => {
        return {
          management: accum.management || iface.management,
          vmConsole: accum.vmConsole || iface.vmConsole,
          imageLibrary: accum.imageLibrary || iface.imageLibrary,
          tunnel: accum.tunnel || iface.tunnel,
          hostLiveness: accum.hostLiveness || iface.hostLiveness,
        }
      },
      {
        management: false,
        vmConsole: false,
        imageLibrary: false,
        tunnel: false,
        hostLiveness: false,
      },
    )
    if (
      !condsFulfilled.management ||
      !condsFulfilled.vmConsole ||
      !condsFulfilled.imageLibrary ||
      !condsFulfilled.tunnel ||
      !condsFulfilled.hostLiveness
    ) {
      return false
    }
  }
  return true
}

const DEFAULT_VOLUMES_DIR = '/opt/pf9/etc/pf9-cindervolume-base/volumes/'

export const GET_DRIVER_CONFIG = {
  NFS: {
    keyValues: [
      { key: 'nfs_mount_points', value: '' },
      { key: 'nfs_snapshot_support', value: 'true' },
      { key: 'nas_secure_file_permissions', value: 'false' },
      { key: 'nas_secure_file_operations', value: 'false' },
      { key: 'volumes_dir', value: DEFAULT_VOLUMES_DIR },
      { key: 'image_volume_cache_enabled', value: 'true' },
      { key: 'image_volume_cache_max_size_gb', value: '200' },
      { key: 'image_volume_cache_max_count', value: '50' },
    ],
  },
  LVM: {
    keyValues: [
      { key: 'volume_group', value: 'cinder-volumes' },
      { key: 'target_ip_address', value: '' },
      { key: 'target_protocol', value: 'iscsi' },
      { key: 'lvm_type', value: 'default' },
      { key: 'volumes_dir', value: DEFAULT_VOLUMES_DIR },
      { key: 'image_volume_cache_enabled', value: 'true' },
      { key: 'image_volume_cache_max_size_gb', value: '200' },
      { key: 'image_volume_cache_max_count', value: '50' },
    ],
  },
  Ceph: {
    keyValues: [
      { key: 'rbd_pool', value: 'libvirt-pool' },
      { key: 'rbd_flatten_volume_from_snapshot', value: 'false' },
      { key: 'rbd_store_chunk_size', value: '4' },
      { key: 'rbd_max_clone_depth', value: '5' },
      { key: 'rbd_ceph_conf', value: '/etc/ceph/ceph.conf' },
      { key: 'rbd_user', value: '' },
      { key: 'rbd_secret_uuid', value: '' },
      { key: 'volumes_dir', value: DEFAULT_VOLUMES_DIR },
      { key: 'image_volume_cache_enabled', value: 'true' },
      { key: 'image_volume_cache_max_size_gb', value: '200' },
      { key: 'image_volume_cache_max_count', value: '50' },
    ],
  },
  NetApp: {
    keyValues: [
      { key: 'netapp_storage_family', value: 'ontap_cluster' },
      { key: 'netapp_storage_protocol', value: 'nfs', shouldNotShowDelete: true },
      { key: 'netapp_vserver', value: '' },
      { key: 'netapp_server_port', value: '80' },
      { key: 'netapp_login', value: '' },
      { key: 'netapp_password', value: '' },
      { key: 'netapp_lun_space_reservation', value: '' },
      { key: 'netapp_pool_name_search_pattern', value: '' },
      { key: 'reserved_percentage', value: '' },
      { key: 'max_over_subscription_ratio', value: '' },
      { key: 'use_multipath_for_image_xfer', value: '' },
      { key: 'enforce_multipath_for_image_xfer', value: '' },
      { key: 'volumes_dir', value: DEFAULT_VOLUMES_DIR },
      { key: 'nfs_mount_points', value: '', shouldNotShowDelete: true },
      { key: 'image_volume_cache_enabled', value: 'true' },
      { key: 'image_volume_cache_max_size_gb', value: '200' },
      { key: 'image_volume_cache_max_count', value: '50' },
    ],
  },
  PureStorageISCSI: {
    keyValues: [
      { key: 'san_ip', value: '' },
      { key: 'pure_api_token', value: '80' },
      { key: 'use_multipath_for_image_xfer', value: 'true' },
      { key: 'pure_iscsi_cidr', value: '0.0.0.0/0' },
      { key: 'suppress_requests_ssl_warnings', value: 'true' },
      { key: 'volumes_dir', value: DEFAULT_VOLUMES_DIR },
      { key: 'image_volume_cache_enabled', value: 'true' },
      { key: 'image_volume_cache_max_size_gb', value: '200' },
      { key: 'image_volume_cache_max_count', value: '50' },
    ],
  },
  PureStorageFC: {
    keyValues: [
      { key: 'driver_ssl_cert_path', value: 'None' },
      { key: 'driver_ssl_cert_verify', value: 'false' },
      { key: 'max_over_subscription_ratio', value: 20.0 },
      { key: 'pure_api_token', value: 'None' },
      { key: 'pure_automatic_max_oversubscription_ratio', value: 'true' },
      { key: 'pure_eradicate_on_delete', value: 'false' },
      { key: 'pure_host_personality', value: 'None' },
      { key: 'pure_iscsi_cidr', value: '0.0.0.0/0' },
      { key: 'pure_iscsi_cidr_list', value: 'None' },
      { key: 'pure_nvme_cidr', value: '0.0.0.0/0' },
      { key: 'pure_nvme_cidr_list', value: 'None' },
      { key: 'pure_nvme_transport', value: 'roce' },
      { key: 'pure_replica_interval_default', value: 3600 },
      { key: 'pure_replica_retention_long_term_default', value: 7 },
      { key: 'pure_replica_retention_long_term_per_day_default', value: 3 },
      { key: 'pure_replica_retention_short_term_default', value: 14400 },
      { key: 'pure_replication_pg_name', value: 'cinder-group' },
      { key: 'pure_replication_pod_name', value: 'cinder-pod' },
      { key: 'pure_trisync_enabled', value: 'false' },
      { key: 'pure_trisync_pg_name', value: 'cinder-trisync' },
      { key: 'replication_device', value: 'None' },
      { key: 'reserved_percentage', value: 0 },
      { key: 'san_ip', value: '' },
      { key: 'use_chap_auth', value: 'false' },
      { key: 'volumes_dir', value: DEFAULT_VOLUMES_DIR },
      { key: 'image_volume_cache_enabled', value: 'true' },
      { key: 'image_volume_cache_max_size_gb', value: '200' },
      { key: 'image_volume_cache_max_count', value: '50' },
    ],
  },
  PureStorageNVME: {
    keyValues: [
      { key: 'driver_ssl_cert_path', value: '' },
      { key: 'driver_ssl_cert_verify', value: 'false' },
      { key: 'max_over_subscription_ratio', value: '20.0' },
      { key: 'pure_automatic_max_oversubscription_ratio', value: 'true' },
      { key: 'pure_eradicate_on_delete', value: 'false' },
      { key: 'pure_host_personality', value: '' },
      { key: 'pure_nvme_cidr', value: '0.0.0.0/0' },
      { key: 'pure_nvme_cidr_list', value: '' },
      { key: 'pure_nvme_transport', value: 'roce' },
      { key: 'use_chap_auth', value: 'false' },
      { key: 'san_ip', value: '' },
      { key: 'pure_api_token', value: '80' },
      { key: 'use_multipath_for_image_xfer', value: 'true' },
      { key: 'suppress_requests_ssl_warnings', value: 'true' },
      { key: 'volumes_dir', value: DEFAULT_VOLUMES_DIR },
      { key: 'image_volume_cache_enabled', value: 'true' },
      { key: 'image_volume_cache_max_size_gb', value: '200' },
      { key: 'image_volume_cache_max_count', value: '50' },
    ],
  },
  HuaweiISCSI: {
    keyValues: [
      { key: 'cinder_huawei_conf_file', value: '/etc/cinder/cinder_huawei_conf.xml' },
      { key: 'hypermetro_devices', value: '' },
      { key: 'metro_domain_name', value: '' },
      { key: 'metro_san_address', value: '' },
      { key: 'metro_san_password', value: '' },
      { key: 'metro_san_user', value: '' },
      { key: 'metro_storage_pools', value: '' },
      { key: 'volumes_dir', value: DEFAULT_VOLUMES_DIR },
      { key: 'image_volume_cache_enabled', value: 'true' },
      { key: 'image_volume_cache_max_size_gb', value: '200' },
      { key: 'image_volume_cache_max_count', value: '50' },
    ],
  },
  HuaweiFC: {
    keyValues: [
      { key: 'cinder_huawei_conf_file', value: '/etc/cinder/cinder_huawei_conf.xml' },
      { key: 'hypermetro_devices', value: '' },
      { key: 'metro_domain_name', value: '' },
      { key: 'metro_san_address', value: '' },
      { key: 'metro_san_password', value: '' },
      { key: 'metro_san_user', value: '' },
      { key: 'metro_storage_pools', value: '' },
      { key: 'volumes_dir', value: DEFAULT_VOLUMES_DIR },
      { key: 'image_volume_cache_enabled', value: 'true' },
      { key: 'image_volume_cache_max_size_gb', value: '200' },
      { key: 'image_volume_cache_max_count', value: '50' },
    ],
  },
  Custom: {
    keyValues: [],
  },
  HPE3PARFC: {
    keyValues: [
      { key: 'hpe3par_api_url', value: '' },
      { key: 'hpe3par_cpg', value: '[‘OpenStack’]' },
      { key: 'hpe3par_cpg_snap', value: '' },
      { key: 'hpe3par_debug', value: 'false' },
      { key: 'hpe3par_iscsi_chap_enabled', value: 'false' },
      { key: 'hpe3par_iscsi_ips', value: '[]' },
      { key: 'hpe3par_password', value: '' },
      { key: 'hpe3par_snapshot_expiration', value: '' },
      { key: 'hpe3par_snapshot_retention', value: '' },
      { key: 'hpe3par_target_nsp', value: '' },
      { key: 'hpe3par_username', value: '' },
      { key: 'max_over_subscription_ratio', value: '20.0' },
      { key: 'replication_device', value: '' },
      { key: 'reserved_percentage', value: '0' },
      { key: 'san_ip', value: '' },
      { key: 'san_login', value: 'admin' },
      { key: 'san_password', value: '' },
      { key: 'san_private_key', value: '' },
      { key: 'san_ssh_port', value: '22' },
      { key: 'ssh_conn_timeout', value: '30' },
      { key: 'target_ip_address', value: '$my_ip' },
      { key: 'target_port', value: '3260' },
      { key: 'unique_fqdn_network', value: 'true' },
      { key: 'volumes_dir', value: DEFAULT_VOLUMES_DIR },
      { key: 'image_volume_cache_enabled', value: 'true' },
      { key: 'image_volume_cache_max_size_gb', value: '200' },
      { key: 'image_volume_cache_max_count', value: '50' },
    ],
  },
  HPE3PARISCSI: {
    keyValues: [
      { key: 'hpe3par_api_url', value: '' },
      { key: 'hpe3par_cpg', value: '[‘OpenStack’]' },
      { key: 'hpe3par_cpg_snap', value: '' },
      { key: 'hpe3par_debug', value: 'false' },
      { key: 'hpe3par_iscsi_chap_enabled', value: 'false' },
      { key: 'hpe3par_iscsi_ips', value: '[]' },
      { key: 'hpe3par_password', value: '' },
      { key: 'hpe3par_snapshot_expiration', value: '' },
      { key: 'hpe3par_snapshot_retention', value: '' },
      { key: 'hpe3par_target_nsp', value: '' },
      { key: 'hpe3par_username', value: '' },
      { key: 'max_over_subscription_ratio', value: '20.0' },
      { key: 'replication_device', value: '' },
      { key: 'reserved_percentage', value: '0' },
      { key: 'san_ip', value: '' },
      { key: 'san_login', value: 'admin' },
      { key: 'san_password', value: '' },
      { key: 'san_private_key', value: '' },
      { key: 'san_ssh_port', value: '22' },
      { key: 'ssh_conn_timeout', value: '30' },
      { key: 'target_ip_address', value: '$my_ip' },
      { key: 'target_port', value: '3260' },
      { key: 'unique_fqdn_network', value: 'true' },
      { key: 'volumes_dir', value: DEFAULT_VOLUMES_DIR },
      { key: 'image_volume_cache_enabled', value: 'true' },
      { key: 'image_volume_cache_max_size_gb', value: '200' },
      { key: 'image_volume_cache_max_count', value: '50' },
    ],
  },
}

export const getPairValue = (
  name: string,
): { nfs_shares_config: string; volume_backend_name: string } => {
  return {
    nfs_shares_config: '/opt/pf9/etc/pf9-cindervolume-base/conf.d/nfs_shares',
    volume_backend_name: name || 'NA',
  }
}
